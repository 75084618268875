import gql from "graphql-tag";

export const SETTINGS_FIELD_DESCRIPTOR_DTO = gql`
    fragment SettingsFieldDescriptorDto on SettingsFieldDescriptorDto {
        name type
    }
`;

export const SETTINGS_SUBTYPES = gql`
    fragment SettingsSubtype on SettingsEntityDescriptorDto {
        name
        fields {
            ...SettingsFieldDescriptorDto
            descriptor {
                name
                fields {
                    ...SettingsFieldDescriptorDto
                    descriptor {
                        name
                        fields {
                            ...SettingsFieldDescriptorDto
                            descriptor {
                                name
                                fields {
                                    ...SettingsFieldDescriptorDto
                                }
                                contributorsForLabel
                            }
                        }
                        contributorsForLabel
                    }
                }
                contributorsForLabel
                subtypes {
                    name
                    fields {
                        ...SettingsFieldDescriptorDto
                        descriptor {
                            name
                            fields {
                                ...SettingsFieldDescriptorDto
                            }
                        }
                    }
                    contributorsForLabel
                    subtypes {
                        name
                        fields {
                            ...SettingsFieldDescriptorDto
                        }
                        contributorsForLabel
                    }
                }
            }
        }
        contributorsForLabel
        subtypes {
            name
            fields {
                ...SettingsFieldDescriptorDto
                descriptor {
                    name
                    fields {
                        ...SettingsFieldDescriptorDto
                    }
                    contributorsForLabel
                    subtypes {
                        name
                        fields {
                            ...SettingsFieldDescriptorDto
                        }
                        contributorsForLabel
                    }
                }
            }
            contributorsForLabel
            subtypes {
                name
                fields {
                    ...SettingsFieldDescriptorDto
                }
                contributorsForLabel
            }
        }
    }
    ${SETTINGS_FIELD_DESCRIPTOR_DTO}
`;

export const SETTINGS_ENTITY_DESCRIPTOR_DTO = gql`
    fragment SettingsEntityDescriptorDto on SettingsEntityDescriptorDto {
        name 
        fields {
            ...SettingsFieldDescriptorDto
            descriptor {
                name
                fields {
                    ...SettingsFieldDescriptorDto
                    descriptor {
                        name
                        fields {
                            ...SettingsFieldDescriptorDto
                            descriptor {
                                name
                                fields {
                                    ...SettingsFieldDescriptorDto
                                    descriptor {
                                        name
                                        fields {
                                            ...SettingsFieldDescriptorDto
                                            descriptor {
                                                name
                                                fields {
                                                    ...SettingsFieldDescriptorDto
                                                    descriptor {
                                                        name
                                                        fields {
                                                            ...SettingsFieldDescriptorDto
                                                            descriptor {
                                                                name
                                                                fields {
                                                                    ...SettingsFieldDescriptorDto
                                                                }
                                                                contributorsForLabel
                                                                subtypes {
                                                                    ...SettingsSubtype
                                                                }
                                                            }
                                                        }
                                                        contributorsForLabel
                                                        subtypes {
                                                            ...SettingsSubtype
                                                        }
                                                    }
                                                }
                                                contributorsForLabel
                                                subtypes {
                                                    ...SettingsSubtype
                                                }
                                            }
                                        }
                                        contributorsForLabel
                                        subtypes {
                                            ...SettingsSubtype
                                        }
                                    }
                                }
                                contributorsForLabel
                                subtypes {
                                    ...SettingsSubtype
                                }
                            }
                        }
                        contributorsForLabel
                        subtypes {
                            ...SettingsSubtype
                        }
                    }
                }
                contributorsForLabel
                subtypes {
                    ...SettingsSubtype
                }
            }
        }
        contributorsForLabel
        subtypes {
            ...SettingsSubtype
        }
    }
    ${SETTINGS_FIELD_DESCRIPTOR_DTO}
    ${SETTINGS_SUBTYPES}
`;

export const GET_DESCRIPTOR = gql`
    query settingsEntityService_getDescriptor($settingsRootType: String!) {
        settingsEntityService_descriptor(settingsRootType: $settingsRootType)  
            { ...SettingsEntityDescriptorDto }
        
    }
    ${SETTINGS_ENTITY_DESCRIPTOR_DTO}
`;

export const ORGANIZATION_DTO = gql`
    fragment OrganizationDto on Organization {
        id qualifiedName
    }
`;

export const SETTINGS_ENTITY_DTO = gql`
    fragment SettingsEntityDto on SettingsEntity {
        id enabled json rootType organization {...OrganizationDto}
    }
    ${ORGANIZATION_DTO}
`;

export const GET_SETTINGS_ROOT_TYPES = gql`
    query settingsEntityService_getSettingsRootTypes {
        settingsEntityService_settingsRootTypes
    }
`;

