import { Utils } from "@crispico/foundation-react";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { addAfterStartupRunnable, entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { SliceEntityEditorPage } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { createSliceFoundation } from "@crispico/foundation-react/reduxHelpers";
import { SAVE_USER, SAVE_USER_OPERATION_NAME } from "./queries";
import { UserEditorPage } from "./UserEditorPage";

export let userEntityDescriptor: EntityDescriptor;
export class SliceUserEditor extends SliceEntityEditorPage {
    handleDataReturnedBySaveOperation(dataReturnedBySave: any): void {
        if (dataReturnedBySave[SAVE_USER_OPERATION_NAME].notificationMessage) {
            Utils.showGlobalAlert({ message: dataReturnedBySave[SAVE_USER_OPERATION_NAME].notificationMessage, severity: Severity.INFO });
        }
    }

    getSaveOperationName(): string {
        // We override it because by default is 'userService_save'
        return SAVE_USER_OPERATION_NAME;
    }

    /**
     * Meant to be overwritten
     */
    shouldOverrideSaveMutationEntirely(): boolean {
        return true;
    }

    initQueries() {
        super.initQueries();
        if (this.shouldOverrideSaveMutationEntirely()) {
            this.saveMutation = SAVE_USER;
        }
    }

    /**
     * TODO DB: Temporary untill the mechanism for displaying the validation errors will be implemented
     */
    isDefaultErrorHandlerShownInCaseOfValidationException(): boolean {
        return true;
    }
}

addAfterStartupRunnable(() => {
    userEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "User",
        icon: "user",
        miniFields: ["username"]
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "username", type: FieldType.string })
        .addFieldDescriptor({ name: "email", type: FieldType.string })
        .addFieldDescriptor({ name: "firstName", type: FieldType.string })
        .addFieldDescriptor({ name: "lastName", type: FieldType.string })
        .addFieldDescriptor({ name: "language", type: FieldType.dropdown })
        .addFieldDescriptor({ name: "isAdmin", type: FieldType.boolean, adminOnly: true })
        .addFieldDescriptor({ name: "resetKey", type: FieldType.string })
        .addFieldDescriptor({ name: "resetDate", type: FieldType.date })
        .addFieldDescriptor({ name: "passwordExpires", type: FieldType.boolean, initialValue: true })
        .addFieldDescriptor({ name: "passwordChangedDate", type: FieldType.date })
        .addFieldDescriptor({ name: "failedLoginAttempts", type: FieldType.number })
        .addFieldDescriptor({ name: "isLocked", type: FieldType.boolean })
        .addFieldDescriptor({ name: "lastLoginDate", type: FieldType.date })
        .addFieldDescriptor({ name: "creationDate", type: FieldType.date })
        .addFieldDescriptor({ name: "mfaEnabled", type: FieldType.boolean, adminOnly: true })
        .doForFields(["resetKey", "resetDate", "passwordChangedDate", "lastLoginDate", "creationDate"],
            fieldDescriptor => { fieldDescriptor.isInDefaultColumnConfigForTable = false; fieldDescriptor.isInDefaultColumnConfigForEditor = false; })
        .removeFieldDescriptors("newPassword", "screen", "screenposition", "pricesAvailable", "showDoneTasksInFlightGantt", "showDoneTasksInMissionGantt", "unit", "initialConfiguration")
    );

    const sliceUserEditor = createSliceFoundation(SliceUserEditor);
    userEntityDescriptor.infoEditor.slice = sliceUserEditor;
    sliceUserEditor.setEntityDescriptor(userEntityDescriptor);

    userEntityDescriptor.infoEditor.wrappedComponentClass = UserEditorPage;
})"../..""../../components/ModalExt/ModalExt""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/EntityEditorPage""../../entity_crud/FieldType""../../reduxHelpers"