import React from "react";
import { connect } from "react-redux";
import { Utils } from "./Utils";
import { CompMeta } from "../CompMeta";
import { TestUtils } from "./TestUtils";

const RouteChangedObserverBasic = ({ hash, thunk, dispatch }: { hash: string, thunk: any, dispatch: (a: any) => any }) => {
    function hashChanged(newValue: string) {
        if (thunk && !newValue?.startsWith(TestState.TEST_STATE)) {
            dispatch(thunk);
        }
    }

    return (<Utils.Observer value={hash} didUpdate={hashChanged} />)
}

interface Entry {
    state: any;
    func?: Function;
}

/**
 * @author Cristian Spiescu
 */
export class TestState {

    static readonly TEST_STATE = "#testState=";
    static readonly statesForAll = new Map<any, { [stateName: string]: Entry }>();
    static RouteHashChangedObserver = connect((state: any) => ({ hash: state.router?.location.hash }))(RouteChangedObserverBasic); // the ? for using in Storybook, w/o a router

    /**
     * "state" is firstly deep cloned, and then it's added to the registry. This allows us to reuse portions of
     * states in other states. W/o clone, there are issues when switching between states: because === equality
     * is used, redux thinks the state didn't change => no issued updates.
     */
    static addTestState<T>(meta: CompMeta<any, any>, stateName: string, state: T, func?: (component: any) => void): T {
        var states = TestState.statesForAll.get(meta);
        if (!states) {
            states = {};
            TestState.statesForAll.set(meta, states);
        }
        state = TestUtils.mergeTestState(meta.getOrCreateInitialState(), state);
        states[stateName] = { state: state, func: func };
        return state;
    }

}
