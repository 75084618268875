import { Icon } from "semantic-ui-react";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import moment, { Moment } from "moment";
import { DatePickerReactCalendar, DatePickerReactCalendarProps } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FieldEditor, ScriptableUiFieldEditor } from "./FieldEditor";
import { castWithHw, HW, ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

interface RangePickerState {
    date1: string,
    date2: string,
}

export type RangePickerExtFieldEditorProps = FieldEditorProps & DatePickerReactCalendarProps

export class RangePickerExtFieldEditor extends FieldEditor<string, RangePickerExtFieldEditorProps, RangePickerState> {

    constructor(props: RangePickerExtFieldEditorProps) {
        super(props);
        const value = this.getValue();
        if (value) {
            const values = value.split(Filter.BETWEEN_DELIMITER);
            this.state = {
                date1: values[0] ? values[0] : '',
                date2: values[1] ? values[1] : ''
            }
        } else {
            this.state = { date1: '', date2: ''}
        }
    }

    onChange = (date: Moment | null, index: number, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        let newDate = date ? date.toISOString() : '';
        index === 0 ? this.setState({ date1: newDate }) : this.setState({ date2: newDate });
        let value = index === 0 ? newDate + Filter.BETWEEN_DELIMITER + this.state.date2: this.state.date1 + Filter.BETWEEN_DELIMITER + newDate;
        if (value === Filter.BETWEEN_DELIMITER) { value = ''; }
        s.setFieldValue(hw, value);
    }

    renderWithScriptableUi(s: ScriptableUiFieldEditor.Main): JSX.Element {
        const defaultValue1 = this.state.date1 ? moment(this.state.date1, moment.ISO_8601) : undefined;
        const defaultValue2 = this.state.date2 ? moment(this.state.date2, moment.ISO_8601) : undefined;
        return <div className='flex-center'>
            <HW id="startDatePicker" children={hw => <DatePickerReactCalendar value={defaultValue1} onChange={(date: Moment | null) => this.onChange(date, 0, castWithHw(s), hw)} {...this.getPropsToForward()} />} />
                <Icon name='arrow right'></Icon>
            <HW id="endDatePicker" children={hw => <DatePickerReactCalendar value={defaultValue2} onChange={(date: Moment | null) => this.onChange(date, 1, castWithHw(s), hw)} {...this.getPropsToForward()} />} />
        </div>
    }
}"../fieldRenderersEditors""../../components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar""../../components/CustomQuery/Filter"