import { FieldRendererProps } from "../fieldRenderersEditors";
import StringFieldRenderer, { StringFieldRendererProps } from "./StringFieldRenderer";
import moment from "moment";
import { Utils } from "../../utils/Utils";

export type DateFieldRendererProps = FieldRendererProps & StringFieldRendererProps & {
    format?: string,
    formatForToday?: string,
    // TODO by CS: implem lui UTC mode se face in #29586. Cand e gata, de folosit in component
    utcMode?: boolean
}

export class DateFieldRenderer extends StringFieldRenderer<DateFieldRendererProps> {

    private isToday(): boolean {
        const getDays = (date: Date) => Math.floor((date.getTime() + Utils.LOCAL_TIMEZONE_OFFSET * Utils.H_IN_MS) / (24 * Utils.H_IN_MS));
        return getDays(new Date(this.props.value)) == getDays(Utils.now())
    }

    protected getText(): String {
        const { props } = this;
        const format = props.formatForToday && this.isToday() ? props.formatForToday : props.format || Utils.dateTimeFormat;
        return props.value && moment(props.value).format(format) || '';
    }
}

export default DateFieldRenderer;
