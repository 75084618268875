import { createSliceFoundation, getBaseReducers, getBaseImpures, PropsFrom } from "@crispico/foundation-react/reduxHelpers"
import { FieldNameContentAssist, FieldNameContentAssistRRC, FormType } from "../fieldNameContentAssist/FieldNameContentAssist"
import React from "react"
import { Button, Header, Divider, Message, Form, Checkbox, Popup, Input, Modal } from "semantic-ui-react"
import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly"
import { createFilterBlock } from "./createFilterBlock"
import { FormikProps, Formik } from "formik"
import { CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY, Utils } from "@crispico/foundation-react/utils/Utils"
import lodash from 'lodash'
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants"
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors"
import { filterFieldEditorFactory } from "./FilterFieldEditorFactory"
import { Filter } from "./Filter"
import { FilterOperators, Operator } from "@crispico/foundation-gwt-js"
import { ModalExt } from "../ModalExt/ModalExt"
import { FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor"
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient"
import gql from "graphql-tag"
import { ErrorType } from "./dataStructures"
import { FieldType, fieldTypeToPrimitiveFieldTypeMapping } from "@crispico/foundation-react/entity_crud/FieldType"
import { LAST_ENTITY_MAPPING_ID_IN_FILTER } from "@crispico/foundation-react/pages/Audit/AuditUtils"
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals"
import { FieldEditorNotUsableStandAloneProps } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor"
import { AssociationEditorProps } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor"

export const sliceFilterForm = createSliceFoundation(class SliceFilterForm {

    initialState = {
        error: ErrorType.NONE,
        initialValues: {}
    }

    nestedSlices = {}

    reducers = {
        ...getBaseReducers<SliceFilterForm>(this),
    }

    impures = {
        ...getBaseImpures<SliceFilterForm>(this),
    }
})

interface FilterFormProps {
    entityName: string;
    initialFilter?: Filter;
    onClose: () => void;
    onApply: (filter: Filter) => void;
    onApplyAndAddNew?: (filter: Filter) => void;
    onApplyAndAddNewSameField?: (filter: Filter) => void;
    filterFormOpened: boolean | [number, number],
    parentFilter: Filter
}

const C2 = "yellow";
const C1 = "brown";

export class FilterForm extends React.Component<PropsFrom<typeof sliceFilterForm> & FilterFormProps> {

    valueRef = React.createRef<HTMLDivElement>();
    fieldNameContentAssistRef: FieldNameContentAssist | null = null;

    protected operatorColors = {
        // these 2 are actually for all; but it's OK, and in fact wanted
        [FilterOperators.forDate.isEmpty.value]: C2,
        [FilterOperators.forDate.isNotEmpty.value]: C2,

        // only part of them have colors; the other ones are gray; it's like this to achieve
        // a "striped" appearance to suggest a logical grouping; e.g. all days ..., all hours ...
        [FilterOperators.forDate.daysAgo.value]: C1,
        [FilterOperators.forDate.lessThanDaysAgo.value]: C1,
        [FilterOperators.forDate.moreThanDaysAgo.value]: C1,
        [FilterOperators.forDate.lessThanMinutesAgo.value]: C1,
        [FilterOperators.forDate.moreThanMinutesAgo.value]: C1,
        [FilterOperators.forDate.lessThanDaysAgo.value]: C1,
        [FilterOperators.forDate.moreThanDaysAgo.value]: C1,
        [FilterOperators.forDate.thisMonth.value]: C1,
        [FilterOperators.forDate.lastMonth.value]: C1,
    }

    onFieldNameContentAssistRefChange = (node: any) => {
        this.fieldNameContentAssistRef = node;
        if (node != null) {
            if (!this.props.initialFilter?.field) {
                let includeManyToOneFields = this.fieldNameContentAssistRef?.props.includeManyToOneFields;
                let includeScalarFields = this.fieldNameContentAssistRef?.props.includeScalarFields;
                this.fieldNameContentAssistRef?.props.r.open(this.props.entityName, includeManyToOneFields, includeScalarFields, FormType.FILTER);
            } else {
                this.fieldNameContentAssistRef?.setChain(this.props.entityName, this.props.initialFilter?.field, () => this.forceUpdate());
            }
        }
    };

    componentDidUpdate(prevProps: FilterFormProps) {
        if (prevProps.filterFormOpened != this.props.filterFormOpened && this.props.filterFormOpened) {
            this.props.dispatchers.setInReduxState({ initialValues: this.getInitialValues() });
            setTimeout(() => this.focusValueEditor(), 100);
        }
    }

    focusValueEditor() {
        this.props.initialFilter?.field && setTimeout(() => {
            this.focusInputField();
            this.valueRef.current?.getElementsByTagName("input").item(0)?.select?.()
        })
    }

    focusOnChangeField = () => {
        if (this.fieldNameContentAssistRef && this.fieldNameContentAssistRef.getChain().length > 0 && !this.props.initialFilter?.value) {
            if (this.fieldNameContentAssistRef.getChain().length >= 2) {
                this.focusInputField(); // CZ: I need this for chain.length >= 2 case. Idk why, but focusing with setTimeout doesn't work in this case
            } else {
                setTimeout(() => this.focusInputField()) // CZ: I need this for chain.length == 1 case. Doesn't focus without setTimeout
            }
        }
    }

    focusInputField = () => {
        this.valueRef.current?.getElementsByTagName("input").item(0)?.focus();
    }

    // CS: doesn't seem to me OK that we have this logic called on every render; shouldn't we have done
    // some initial processing, then save in some state and then take it from there in render()?
    getInitialValues = () => {
        if (this.props.initialFilter) {
            let filterCopy = lodash.cloneDeep(this.props.initialFilter!);
            let entityDescriptor = entityDescriptors[this.props.entityName];
            if (filterCopy.field && filterCopy.field.toString().indexOf('.') >= 0) {
                let field = filterCopy.field.toString();
                const length = field.split('.').length;
                for (let i = 0; i < length - 1; i++) {
                    entityDescriptor = entityDescriptors[entityDescriptor.getField(Utils.substringBefore(field, '.')).getType()];
                    field = Utils.substringAfter(field, '.');
                }
                field = Utils.substringAfter(field, '.');
            }
            if (filterCopy.field && Object.getOwnPropertyNames(FieldType).indexOf(fieldTypeToPrimitiveFieldTypeMapping[entityDescriptor.getField(Utils.substringAfter(filterCopy.field.toString(), '.', true)).getType()]) < 0) {
                // LA: I disabled throw new Error because it would crash for non-primitives such as an entity type. 
                // E.g. a filter like EquipmentType EQUALS Belt loaders. LA has understood what "updateValue()" does, and he thinks
                // it is needed for a filter like EquipmentType in Baggage tractors, Belt loaders.

                // throw new Error("Code was disabled; please review the comment in the code.");

                // CS: in my opinion ALL field types should have a mapping in fieldTypeToPrimitiveFieldTypeMapping; now it's the case
                // for almost all. If this exception happens, it means that either 1) that type SHOULD be in fieldTypeToPrimitiveFieldTypeMapping; or
                // 2) the updateValue() method is needed after all in some situations. Discussion to be generated in this case.

                // AC: This method is used for the IN operator, to process the value retained as a string, but also for relation type fields, 
                // for example EquipmentType, whose type is not in fieldTypeToPrimitiveFieldTypeMapping
                filterCopy.value = this.updateValue();
            } else if (filterCopy.value && filterCopy.operator === FilterOperators.forNumber.between.value) {
                if (filterCopy.value.indexOf(Filter.AND_DELIMITER) >= 1) {
                    filterCopy.value = filterCopy.value.replace(Filter.AND_DELIMITER, Filter.BETWEEN_DELIMITER);
                }
            }
            const { field, ...initialValues } = filterCopy;
            return initialValues;
        } else {
            return Filter.createForClient();
        }
    }

    updateValue() {
        let value: any = this.props.initialFilter!.value;
        if (value && typeof value == 'string') {
            let idsAndNames = value.split(Filter.IN_DELIMITER)
            if (idsAndNames.length > 1) {
                value = idsAndNames.map((o: any) => {
                    let values = o.split(Utils.defaultIdSeparator);
                    let entityDescriptor = entityDescriptors[values[2]];
                    let miniField = entityDescriptor.miniFields[0] ? entityDescriptor.miniFields[0] : 'name';
                    let obj: any = { id: values[0], __typename: values[2] };
                    obj[miniField] = values[1];
                    return obj;
                })
            } else {
                let values = idsAndNames[0].split(Utils.defaultIdSeparator);
                let entityDescriptor = entityDescriptors[values[2]];
                let miniField = entityDescriptor.miniFields[0] ? entityDescriptor.miniFields[0] : 'name';
                if (values.length === 3) {
                    value = { id: values[0], __typename: values[2] };
                    value[miniField] = values[1];
                }
                if (this.props.initialFilter?.operator === FilterOperators.forEntityOneToMany.in.value || this.props.initialFilter?.operator === FilterOperators.forEntityOneToMany.notIn.value) {
                    value = [value];
                }
            }
        }
        return value;
    }

    getField() {
        let field = '';
        if (this.fieldNameContentAssistRef && this.fieldNameContentAssistRef.getChain().length > 0) {
            this.fieldNameContentAssistRef.getChain().forEach((e: any) => {
                field += e.field + '.';
            });
            field = field.slice(0, field.length - 1);
        }
        return field;
    }

    onOperatorButtonClick = (e: any, formikProps: FormikProps<any>, op: string) => {
        if (e.detail === 0) { this.onApply(formikProps); return; }
        if (op === formikProps.values.operator) { return; }
        let selectedField = this.fieldNameContentAssistRef!.getChain()[this.fieldNameContentAssistRef!.getChain().length - 1];
        let entityDescriptor = entityDescriptors[selectedField.entity];
        const type = entityDescriptor.getField(selectedField.field).getType();
        if (entityDescriptors[type]) {
            if ((Array.isArray(formikProps.values.value) && (FilterOperators.forEntityManyToOne.in.value === op || FilterOperators.forEntityManyToOne.notIn.value === op)) ||
                (formikProps.values.value && !Array.isArray(formikProps.values.value) && FilterOperators.forEntityManyToOne.in.value !== op && FilterOperators.forEntityManyToOne.notIn.value !== op)) {
                formikProps.setFieldValue('operator', op);
                return;
            } else {
                formikProps.setFieldValue('value', '');
            }
        } else if (type === FilterOperators.TYPE_DATE && ((!FilterOperators.dateOperatorsWithNumberValue.find(operator => operator.value === op) && Number.isInteger(formikProps.values.value)) || (FilterOperators.dateOperatorsWithNumberValue.find(operator => operator.value === op) && !Number.isInteger(formikProps.values.value)))) {
            formikProps.setFieldValue('value', '');
        } else if (((FilterOperators.forNumber.in.value !== op && FilterOperators.forNumber.notIn.value !== op) && (FilterOperators.forNumber.in.value === formikProps.values.operator || FilterOperators.forNumber.notIn.value === formikProps.values.operator)) ||
            ((FilterOperators.forNumber.in.value === op || FilterOperators.forNumber.notIn.value === op) && (FilterOperators.forNumber.in.value !== formikProps.values.operator && FilterOperators.forNumber.notIn.value !== formikProps.values.operator))) {
            formikProps.setFieldValue('value', '');
        } else if ((FilterOperators.forNumber.between.value !== op && FilterOperators.forNumber.between.value === formikProps.values.operator) || (FilterOperators.forNumber.between.value === op && FilterOperators.forNumber.between.value !== formikProps.values.operator)) {
            formikProps.setFieldValue('value', '');
        } else if (FilterOperators.noValueOperators.find(opeartor => opeartor.value === op)) {
            formikProps.setFieldValue('value', '');
        }
        formikProps.setFieldValue('operator', op);
    }

    getOperators(formikProps: FormikProps<any>) {
        if (!this.fieldNameContentAssistRef || this.fieldNameContentAssistRef!.getChain().length === 0) { return []; }
        let selectedField = this.fieldNameContentAssistRef!.getChain()[this.fieldNameContentAssistRef!.getChain().length - 1];
        let entityDescriptor = entityDescriptors[selectedField.entity];
        let type = entityDescriptor.getField(selectedField.field).getType();
        type = fieldTypeToPrimitiveFieldTypeMapping[type] ? fieldTypeToPrimitiveFieldTypeMapping[type] : type
        if (type === FieldType.boolean && !formikProps.values.operator) {
            formikProps.setFieldValue('operator', FilterOperators.forBoolean.equals.value);
            formikProps.setFieldValue('value', false);
            return;
        } else if (type === FieldType.boolean) {
            return;
        }

        if (type === FieldType.dropdown && !formikProps.values.operator) {
            formikProps.setFieldValue('operator', FilterOperators.forString.equals.value);
            return;
        }

        let operators = filterFieldEditorFactory.getOperators(type).map((op: Operator) => op.value);

        if (FilterOperators.noValueOperators.find(op => op.value === formikProps.values.operator) && formikProps.values.value) {
            formikProps.setFieldValue('value', undefined);
        }

        if (!formikProps.values.operator) {
            formikProps.setFieldValue('operator', operators[0]);
        }

        return operators ? operators.map((op: string, index: number) => {
            const message = _msg({ missingKeyStrategy: "RETURN_NULL" }, 'Filter.operator.' + op + '.description');
            if (message && op === formikProps.values.operator) {
                return <Button.Group key={op} className='FilterForm_operatorsButton'>
                    <Button type={'button'} color={formikProps.values.operator === op ? 'blue' : undefined} key={index} onClick={(e) => this.onOperatorButtonClick(e, formikProps, op)}>{_msg("Filter.operator." + op)}</Button>
                    <Popup hoverable={false} content={message} trigger={<Button type='button' color='blue' icon='question circle' />} />
                </Button.Group>
            } else {
                return <Button type={'button'} color={formikProps.values.operator === op ? 'blue' : this.operatorColors[op] as any} className='FilterForm_operatorsButton' key={index} onClick={(e) => this.onOperatorButtonClick(e, formikProps, op)}>{_msg("Filter.operator." + op)}</Button>
            }
        }
        ) : [];
    }

    getValue(formikProps: FormikProps<any>) {
        if (!this.fieldNameContentAssistRef || this.fieldNameContentAssistRef!.getChain().length === 0 || !formikProps.values.operator) { return undefined; }
        const selectedField = this.fieldNameContentAssistRef!.getChain()[this.fieldNameContentAssistRef!.getChain().length - 1];
        const entityDescriptor = entityDescriptors[selectedField.entity];
        const fd = entityDescriptor.getField(selectedField.field);
        const type = fd.getType();
        const ct = fd.constructor as any;
        const fieldDescriptor: FieldDescriptor = lodash.merge(new ct(), fd);
        fieldDescriptor.isCustomField = false;
        fieldDescriptor.nameBeforeCopyToFilter = fieldDescriptor.name;
        fieldDescriptor.name = 'value';
        const props: FieldEditorNotUsableStandAloneProps = { fieldDescriptor, formikProps, refForFocus: this.valueRef }
        if (fd.typeIsEntity()) {
            (props as AssociationEditorProps).innerEntityDescriptor = entityDescriptors[type];
        }
        return filterFieldEditorFactory.renderValueEditor(formikProps.values.operator, type, props);
    }

    fieldChanged = (formikProps: FormikProps<any>) => {
        let filter = Filter.createForClient(this.fieldNameContentAssistRef!.getChain().map(item => item.field).join('.'))
        formikProps?.setValues(filter);
    }

    renderForm = (formikProps: FormikProps<any>) => {
        let operators = this.getOperators(formikProps);
        let valueField = this.getValue(formikProps);
        const errorMessage = this.props.error === ErrorType.INVALID ? _msg('BlocklyEditorTab.filterForm.invalidMessage') : this.props.error === ErrorType.DUPLICATE ? _msg('BlocklyEditorTab.filterForm.duplicateMessage') : '';
        const lastEntityMappingIdInFilter = window.sessionStorage.getItem(LAST_ENTITY_MAPPING_ID_IN_FILTER);
        return (
            <>
                <Header className='flex-center'>
                    {_msg('BlocklyEditorTab.filterForm.preview')}
                    <BlocklyReadOnly>
                        {createFilterBlock({ ...formikProps.values, field: this.getField() }, { entityDescriptorName: this.props.entityName, foundAuditEntityMappingId: lastEntityMappingIdInFilter ? lastEntityMappingIdInFilter : undefined })}
                    </BlocklyReadOnly>
                </Header>
                <Divider clearing />
                {this.props.error !== ErrorType.NONE && <Message error header={_msg('BlocklyEditorTab.filterForm.invalid')} content={errorMessage} />}
                <Form onSubmit={formikProps.handleSubmit}>
                    <Form.Field data-testid="FilterForm_field">
                        {_msg('Filter.field.label')}
                        <FieldNameContentAssistRRC id={"fieldNameContentAssist"} ref={this.onFieldNameContentAssistRefChange} formType={FormType.FILTER} onChangeCallback={() => this.fieldChanged(formikProps)} rootEntity={this.props.entityName} field={this.props.initialFilter!.field!} />
                    </Form.Field>
                    {operators && operators.length > 0 && <Form.Field>
                        {_msg('Filter.operator.label')}
                        <div className='FilterForm_operatorsButtons'>
                            {operators}
                        </div>
                    </Form.Field>}
                    {formikProps.values.operator && valueField && <Form.Field data-testid="FilterForm_value">
                        {_msg('Filter.value.label')}
                        <div ref={this.valueRef}>{valueField}</div>
                    </Form.Field>}
                    <Divider clearing />
                    <Form.Field data-testid="FilterForm_enabled">
                        {_msg('Filter.enabled.label')}
                        <div><Checkbox checked={formikProps.values.enabled} name='enabled' onChange={(event: any, data: any) => { formikProps.setFieldValue('enabled', data.checked); }} /></div>
                    </Form.Field>
                    <Form.Field>
                        {_msg('Filter.label.label')}
                        <Input name='label' value={formikProps.values.label} onChange={formikProps.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <Input type="submit" style={{ position: 'absolute', left: '-9999px' }} />
                    </Form.Field>
                </Form>
            </>
        );
    }

    onClose = (formikProps: FormikProps<any>) => {
        formikProps.resetForm();
        this.fieldNameContentAssistRef?.clearChain();
        this.props.dispatchers.setInReduxState({
            initialValues: {},
            error: ErrorType.NONE
        });
        this.props.onClose();
    }

    checkFilterSiblings = (filter: Filter) => {
        let filterSiblings = this.props.parentFilter.filters ? this.props.parentFilter.filters : [];
        for (let i = 0; i < filterSiblings.length; i++) {
            let f = filterSiblings[i];
            if ((this.props.parentFilter.operator === FilterOperators.forComposedFilter.and.value || (this.props.parentFilter.operator === FilterOperators.forComposedFilter.or.value && filter.value === f.value))
                && filter.field === f.field && filter.operator === f.operator) {
                return false;
            }
        }
        return true;
    }

    adjustFilterValue = (filter: Filter) => {
        let value: any = filter.value;
        if (filter.field) {
            if (value && filter.operator === FilterOperators.forNumber.between.value) {
                if (value.indexOf(Filter.BETWEEN_DELIMITER) >= 1) {
                    return value.replace(Filter.BETWEEN_DELIMITER, Filter.AND_DELIMITER);
                }
            }
        }
        if (value && typeof value == 'object') {
            let entityDescriptor = entityDescriptors[value.__typename];
            let miniField = entityDescriptor ? entityDescriptor.miniFields[0] ? entityDescriptor.miniFields[0] : 'name' : 'name';

            if (value.length !== undefined) {
                value = value.map((o: any) => o.id + Utils.defaultIdSeparator + o[miniField] + Utils.defaultIdSeparator + o.__typename).join(Filter.IN_DELIMITER);
            } else {
                value = value.id + Utils.defaultIdSeparator + value[miniField] + Utils.defaultIdSeparator + value.__typename;
            }
        }
        return value;
    }

    onApply = async (formikProps: FormikProps<any>) => {
        let filter = { ...formikProps.values, field: this.getField() };
        filter.value = this.adjustFilterValue(filter);
        let validated = FilterOperators.noValueOperators.find(op => op.value === filter.operator) ? filter.field && filter.operator : filter.field && filter.operator && filter.value?.toString();
        if (validated) {
            if (!this.checkFilterSiblings(filter)) {
                this.props.dispatchers.setInReduxState({ error: ErrorType.DUPLICATE })
                return;
            }
            this.props.dispatchers.setInReduxState({ error: ErrorType.NONE })
            await checkValidFilter(filter, this.props.entityName);
            this.props.onApply(filter);
            this.onClose(formikProps);
        } else {
            this.props.dispatchers.setInReduxState({ error: ErrorType.INVALID })
        }
    }

    onApplyAndAddNew = async (formikProps: FormikProps<any>) => {
        let filter = { ...formikProps.values, field: this.getField() };
        filter.value = this.adjustFilterValue(filter);
        let validated = FilterOperators.noValueOperators.find(op => op.value === filter.operator) ? filter.field && filter.operator : filter.field && filter.operator && filter.value?.toString();
        if (validated) {
            if (!this.checkFilterSiblings(filter)) {
                this.props.dispatchers.setInReduxState({ error: ErrorType.DUPLICATE })
                return;
            }
            this.props.dispatchers.setInReduxState({ error: ErrorType.NONE })
            await checkValidFilter(filter, this.props.entityName);
            this.props.onApplyAndAddNew!(filter);
            formikProps.resetForm();
        } else {
            this.props.dispatchers.setInReduxState({ error: ErrorType.INVALID })
        }
    }

    onApplyAndAddNewSameField = async (formikProps: FormikProps<any>) => {
        let filter = { ...formikProps.values, field: this.getField() };
        filter.value = this.adjustFilterValue(filter);
        let validated = FilterOperators.noValueOperators.find(op => op.value === filter.operator) ? filter.field && filter.operator : filter.field && filter.operator && filter.value?.toString();
        if (validated) {
            if (!this.checkFilterSiblings(filter) && this.props.parentFilter.operator === FilterOperators.forComposedFilter.or.value) {
                this.props.dispatchers.setInReduxState({ error: ErrorType.DUPLICATE })
                return;
            }
            this.props.dispatchers.setInReduxState({ error: ErrorType.NONE })
            await checkValidFilter(filter, this.props.entityName);
            this.props.onApplyAndAddNewSameField!(filter);
            formikProps.setFieldValue('value', '');
            formikProps.setFieldValue('operator', '');
            setTimeout(() => this.focusValueEditor())
        } else {
            this.props.dispatchers.setInReduxState({ error: ErrorType.INVALID })
        }
    }

    render() {
        let formikPropsCopy: any;
        const disabled = !AppMetaTempGlobals.appMetaInstance.hasPermission(CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY);
        return (
            <>
                {/* <Utils.Observer value={this.fieldNameContentAssistRef?.getOpened()} didUpdate={(value: any) => !value && this.focusOnChangeField()} /> */}
                <ModalExt data-testid="FilterForm" onClose={() => this.onClose(formikPropsCopy)} open={this.props.filterFormOpened} addNiceLookingOffsets size='tiny'>
                    <Modal.Header>{_msg('FilterForm.title')}</Modal.Header>
                    <Modal.Content>  {/* CZ: Didn't use 'scrolling' prop because the dropdown from FieldNameContentAssist wouldn't show over all elements (overflow problems)*/}
                        <Formik initialValues={this.props.initialValues} enableReinitialize onSubmit={() => this.onApply(formikPropsCopy)}>
                            {(formikProps: FormikProps<any>) => {
                                formikPropsCopy = formikProps;
                                return this.renderForm(formikProps);
                            }}
                        </Formik>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button data-testid="FilterForm_applyButton" className='FilterForm_applyButton' color='green' disabled={disabled} onClick={() => this.onApply(formikPropsCopy)}>{_msg('general.apply')}</Button>
                        {this.props.onApplyAndAddNew && <Button data-testid="FilterForm_applyAndAddNewButton" className='FilterForm_applyButton' color='green' disabled={disabled} onClick={() => this.onApplyAndAddNew(formikPropsCopy)}>{_msg('FilterForm.applyAddNew')}</Button>}
                        {this.props.onApplyAndAddNewSameField && <Button className='FilterForm_applyButton' data-testid="FilterForm_applyAndAddSameButton" color='green' disabled={disabled} onClick={() => this.onApplyAndAddNewSameField(formikPropsCopy)}>{_msg('FilterForm.applyAddNewSameField')}</Button>}
                        <Button className='FilterForm_applyButton' data-testid="FilterForm_cancel" color='red' onClick={() => this.onClose(formikPropsCopy)}>{_msg('general.cancel')}</Button>
                    </Modal.Actions>
                </ModalExt>
            </>
        );
    }

}

export async function checkValidFilter(filter: Filter, entityName: string) {
    let serverFilter = Filter.eliminateDisabledFilters(filter);
    if (!serverFilter) { return true; }
    const filterOperator: Operator = {value: filter.operator}
    let f = filter.filters ? Filter.createComposed(filterOperator, serverFilter.filters || []) : Filter.create(filter.field || '', filterOperator, filter.value);
    let loadOperationName = `${lodash.lowerFirst(entityName)}Service_checkValidFilter`;
    let loadQuery = gql(`query checkValidFilter($filter: FilterInput) { ${loadOperationName}(filter: $filter) }`);
    let validated = (await apolloClientHolder.apolloClient.query({ query: loadQuery, variables: { filter: f } })).data[loadOperationName];
    return validated;
}
"../../reduxHelpers""../../blockly/BlocklyReadOnly""../../utils/Utils""../../entity_crud/entityCrudConstants""../../entity_crud/fieldRenderersEditors""../../entity_crud/EntityDescriptor""../../apolloClient""../../entity_crud/FieldType""../../pages/Audit/AuditUtils""../../AppMetaTempGlobals""../../entity_crud/fieldEditors/FieldEditor""../../entity_crud/fieldEditors/AssociationFieldEditor"