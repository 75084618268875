import { AppMeta, TestUtils } from "@crispico/foundation-react";
import { atLeastOneSubmenuVisible } from "@crispico/foundation-react/utils/MenuUtils";
import React from "react";
import Measure, { ContentRect } from "react-measure";
import { NavLink } from "react-router-dom";
import { Dropdown, Icon, Menu, Ref } from "semantic-ui-react";
import { NavLinkWithPermission } from "../NavLinkWithPermission";

export type HorizontalMenuProps = {
    menuEntries: any;
}

type LocalState = {
    parentWidth: number;
    childrenWidth: number;
}

/**
 * @author Daniela Buzatu
 **/
export class HorizontalMenu extends React.Component<HorizontalMenuProps, LocalState> {

    constructor(props: HorizontalMenuProps) {
        super(props);
        this.state = { parentWidth: 0, childrenWidth: 0 };
        this.onResizeParent = this.onResizeParent.bind(this);
        this.onResizeChildren = this.onResizeChildren.bind(this);
    }

    renderMenuItem(menuEntry: any, isSubmenu: boolean, uniqueKey: number): any {
        menuEntry.key = uniqueKey;
        if (menuEntry.submenus) {
            // hide the entire parent menu if no submenu is visible 
            if (!atLeastOneSubmenuVisible(menuEntry)) {
                return null;
            } else {
                // If the entry has a submenu then this menu item needs to be a dropdown
                // Recursively render of submenu items 
                var submenuItemsUniqueKey: number = 0;
                const children = menuEntry.submenus.map((submenuEntry: any) => this.renderMenuItem(submenuEntry, true, submenuItemsUniqueKey++));
                const trigger = (
                    <>
                        {typeof menuEntry.icon === 'string' ? <Icon name={menuEntry.icon} /> : menuEntry.icon}
                        {menuEntry.content}
                    </>
                );
                return <Dropdown className={menuEntry.className} key={uniqueKey} item trigger={trigger}><Dropdown.Menu>{children}</Dropdown.Menu></Dropdown>
            }
        } else {
            // In order the navigation to work each menuItem needs to be a NavLink
            if (!menuEntry.as) {
                menuEntry.as = NavLinkWithPermission;
            }
            if (isSubmenu) {
                return React.createElement(Dropdown.Item, menuEntry);
            } else {
                return React.createElement(Menu.Item, menuEntry);
            }

        }
    }

    onResizeParent(contentRect: ContentRect) {
        if (!contentRect.scroll) {
            return;
        }
        this.setState({ parentWidth: contentRect.scroll?.width });
    }

    onResizeChildren(contentRect: ContentRect) {
        if (!contentRect.bounds) {
            return;
        }
        this.setState({ childrenWidth: Math.round(contentRect.bounds?.width) });
    }

    render() {
        // Because we can not let the children overflow the parent: see comment for .HorizontalMenu_items_hidden in foundation-react.css
        // this condition will become false only when the parentWidth == childrenWidth (i.e. will be exactly equals) 
        const itemsFitsInMenu: boolean = this.state.parentWidth > this.state.childrenWidth;
        var uniqueKey: number = 0;
        const horizontalMenuChildren = this.props.menuEntries.map((menuEntry: any) => this.renderMenuItem(menuEntry, false, uniqueKey++));
        return (
            <Measure bounds scroll onResize={this.onResizeParent}>{({ measureRef }) => (
                <Ref innerRef={measureRef}>
                    <>
                        {/*The zIndex is needed because the ColumnConfigDropdown has a zIndex of 100, so when a submenu its opened 
                        it gets below the ColumnConfigDropdown from the EntityEditorPage
                        */}

                        <Menu className="HorizontalMenu">
                            <Measure bounds scroll onResize={this.onResizeChildren}>{({ measureRef }) => (
                                <Ref innerRef={measureRef}>
                                    <>
                                        <div className={'HorizontalMenu_items ' + (!itemsFitsInMenu ? 'HorizontalMenu_items_hidden' : '')}>
                                            {horizontalMenuChildren}
                                        </div>
                                    </>
                                </Ref>
                            )}</Measure>
                        </Menu>
                    </>
                </Ref>
            )}</Measure>
        );

    }
}"../..""../../utils/MenuUtils"