import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { FieldDescriptor, addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { Optional, Utils } from "@crispico/foundation-react";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { RootReducerForPages } from "@crispico/foundation-react/reduxHelpers";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ColorRegistry } from "@crispico/foundation-react/utils/ColorRegistry";

export class EntityToTagFieldDescriptor extends FieldDescriptor {

    constructor() {
        super();
        this.name = "tags";
        this.type = FieldType.oneToMany("EntityToTag");
        this.filterAsManyToMany = "tag";
        this.enabled = false; // TODO CC: remove this after editor impl
        this.sortable = false;
    }

    protected getColor(item: any): Optional<string> {
        let color: number;
        if (item.tag.colorRegistryColor) {
            color = ColorRegistry.INSTANCE!.get(item.tag.colorRegistryColor);
        } else {
            color = item.tag.color;
        }
        return color ? Utils.convertColorToHex(color) : undefined;
    }

    protected _getLabel = (item: any) => <Label horizontal style={{ backgroundColor: this.getColor(item) }} className="TagTableCell_label" key={item[ID]}>{tagEntityDescriptor.toMiniString(item.tag)}</Label>;

    renderTags(props: FieldRendererProps, withLink: boolean = true) {

        return (<RootReducerForPages.Context.Consumer>{rootReducersForPages => props.value && props.value.map(
            (item: any) => item.tag ?
                (withLink ? <NavLink key={item.id} to={tagEntityDescriptor.getEntityEditorUrl(item.tag.id)}>{this._getLabel(item)}</NavLink> : this._getLabel(item)) : null)}
        </RootReducerForPages.Context.Consumer>);
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps): ReactNode {
        return this.renderTags(props);
    }
};

export const tagEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "Tag",
    miniFields: ["name"],
    icon: "tag"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "description", type: FieldType.string })
    .addFieldDescriptor({ name: "color", type: FieldType.color, colorType: "number" })
    .addFieldDescriptor({ name: "colorRegistryColor", type: FieldType.string })
);

export const entityToTagEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "EntityToTag",
    miniFields: ["entityId", " tag { id name color colorRegistryColor } ", "tag"],
    icon: "tag"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "entityId", type: FieldType.number })
    .addFieldDescriptor({ name: "entityName", type: FieldType.string })
    .addFieldDescriptor({ name: "tag", type: "Tag" })
);"../../entity_crud/EntityDescriptor""../../entity_crud/fieldRenderersEditors""../..""../../entity_crud/entityCrudConstants""../../reduxHelpers""../../entity_crud/FieldType""../../utils/ColorRegistry"