import { FilterOperators } from "@crispico/foundation-gwt-js";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo } from "@crispico/foundation-react";
import { RRCProps, Reducers, ReduxReusableComponents, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import Blockly from "blockly";
import { default as _, default as lodash } from 'lodash';
import React from "react";
import { Button, Container, Icon, Segment, Tab } from "semantic-ui-react";
import { EDITOR_LOGIC_OPERATION_NO_OUTPUT, EDITOR_LOGIC_OPERATION_WITH_OUTPUT, FILTER_WITH_OUTPUT, LABELED_FILTER_WITH_OUTPUT, NO_VALUE_FILTER_WITH_OUTPUT } from "../../blockly/customBlocks";
import { FormType } from "../fieldNameContentAssist/FieldNameContentAssist";
import { Filter } from "./Filter";
import { FilterForm, checkValidFilter, sliceFilterForm } from "./FilterForm";
import { createFilterBlock } from "./createFilterBlock";
import { ErrorType } from "./dataStructures";

export const DEFAULT_COMPOSED_FILTER: Filter = Filter.createComposedForClient(FilterOperators.forComposedFilter.and);

export const DEFAULT_BLOCKLY_SETTINGS = {
    move: {
        scrollbars: true
    },
    grid: {
        spacing: 20,
        length: 2,
        colour: '#ccc',
        snap: true
    },
    zoom: {
        controls: true,
        wheel: true,
        maxScale: 1,
        minScale: 0.5
    },
    trashcan: true,
    sounds: false
}

class BlocklyEditorTabState extends State {
   rootFilter = DEFAULT_COMPOSED_FILTER;
   filterFormOpened: [number, number] | boolean = false;
   selectedFilterPath = '';
   lastAddedFilterBlockId = '';
   linearBlocks = true;
}

class BlocklyEditorTabReducers<S extends BlocklyEditorTabState = BlocklyEditorTabState> extends Reducers<S> {}

type BlocklyEditorTabProps = RRCProps<BlocklyEditorTabState, BlocklyEditorTabReducers> & {
    ownRef: React.RefObject<any>,
    apply?: (filter: Filter) => void,
    onCancel?: () => void,
    entityDescriptor: string,
    initialFilter: Filter
}

export class BlocklyEditorTab extends React.Component<BlocklyEditorTabProps>{
    
    primaryWorkspace!: Blockly.WorkspaceSvg;
    blocklyComponentDivRef = React.createRef<any>();
    topBlockId!: string;
    filterFormRef = React.createRef<FilterForm>();
    confirmModalRef = React.createRef<any>();
    mousePosition = { x: 0, y: 0 };
    clickCount = 0;
    singleClickTimer: any;

    constructor(props: BlocklyEditorTabProps) {
        super(props);
        this.onFilterFormApply = this.onFilterFormApply.bind(this);
        this.onFilterFormApplyAndAddNew = this.onFilterFormApplyAndAddNew.bind(this);
        this.onFilterFormApplyAndAddNewSameField = this.onFilterFormApplyAndAddNewSameField.bind(this);
        this.componentDidUpdate();
    }

    commitWorkFromFilterForm(filter: Filter, workspace: Blockly.WorkspaceSvg, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string) {
        let value: any;
        if (value && typeof value == 'object') {
            if (value.length !== undefined) {
                value = JSON.stringify(value.map((o: any) => o.id)).replace("[", "").replace("]", "");
            } else {
                value = value.id;
            }
        }
        if (value) {
            filter.value = value;
        }
        let state = this.props.s
        let rootFilter = lodash.cloneDeep(state.rootFilter);

        const pathToParent = Utils.substringBefore(state.selectedFilterPath, Utils.defaultIdSeparator, true);
        const parent = Utils.navigate(rootFilter, pathToParent);
        const childKey = Utils.substringAfter(state.selectedFilterPath, Utils.defaultIdSeparator, true);
        parent[childKey] = filter;

        this.props.r.setInReduxState({ rootFilter, lastAddedFilterBlockId: '' }); // cumva nu ajunge de aici si in FilterBar/CustomQuery

        state = this.props.s;
        this.refreshWorkspace(workspace, state.rootFilter, blocklyEditorTabRef, entityDescriptor);
    }

    refreshWorkspace(workspace: Blockly.WorkspaceSvg, filter: Filter, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string) {
        let selected = this.getPathFromBlock(workspace.getBlockById(Blockly.selected?.id), blocklyEditorTabRef.current?.topBlockId);
        workspace.clear();
        let newTopBlockId = Blockly.Xml.appendDomToWorkspace(Blockly.Xml.textToDom(`<xml>${createFilterBlock(filter, { entityDescriptorName: entityDescriptor }, false, true, true)}</xml>`), workspace)[0];
        let topBlock = workspace.getBlockById(newTopBlockId);
        topBlock.setInputsInline(false);
        topBlock.setDeletable(false);
        topBlock.moveBy(20, 20);
        if (blocklyEditorTabRef.current) {
            blocklyEditorTabRef.current.topBlockId = newTopBlockId;
        }
        if (selected) {
            selected = selected.split('filters').join('childBlocks_');

            let topBlock = workspace.getBlockById(newTopBlockId);

            const pathToParent = Utils.substringBefore(selected, Utils.defaultIdSeparator, true);
            const parent = Utils.navigate(topBlock, pathToParent);
            const childKey = Utils.substringAfter(selected, Utils.defaultIdSeparator, true);
            parent[childKey].select();
        }
    }

    async beforeApply(workspace: Blockly.WorkspaceSvg, topBlockId: string, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string, apply?: (filter: Filter) => void) {
        let topBlock = workspace.getBlockById(topBlockId);
        let filter = this.getFilterFromBlock(topBlock);

        let filterToBeValidated = this.eliminateEmptyFilters(lodash.cloneDeep(filter));
        let validated = this.validate(filterToBeValidated, '');
        validated = validated.slice(0, validated.length - Utils.defaultIdSeparator.length);

        if (validated === '') {
            await checkValidFilter(filterToBeValidated, entityDescriptor);
            apply?.(filterToBeValidated);
            this.props.r.setInReduxState({ rootFilter: filterToBeValidated });
            this.refreshWorkspace(workspace, filterToBeValidated, blocklyEditorTabRef, entityDescriptor);
        } else {
            this.props.r.setInReduxState({ selectedFilterPath: validated, filterFormOpened: true });
            this.filterFormRef.current?.props.dispatchers.setInReduxState({ error: ErrorType.NONE });
            return;
        }
    }

    eliminateEmptyFilters(filter: Filter) {
        function check(f: Filter): boolean {
            if (f.filters) {
                if (f.filters.length === 0) {
                    return false;
                } else {
                    return f.filters.filter(filter => { return check(filter) }).length > 0;
                }
            }
            return true;
        }
        filter.filters = filter.filters?.filter(f => { return check(f) });
        return filter;
    }

    validate(filter: Filter, path: string): string {
        if (filter.filters) {
            if (filter.filters.length === 0) { return ''; }
            path = path + 'filters';
            for (let i = 0; i < filter.filters.length; i++) {
                let f = filter.filters[i];
                let validation = this.validate(f, path + Utils.defaultIdSeparator + i + Utils.defaultIdSeparator);
                if (validation !== '') { 
                    validation = validation.slice(0, validation.length - Utils.defaultIdSeparator.length)
                    return validation; 
                }
            }
            return '';
        }
        return FilterOperators.noValueOperators.find(op => op.value === filter.operator) ? filter.field && filter.operator ? '' : path : filter.field && filter.operator && filter.value?.toString() ? '' : path;
    }

    getPathFromBlock(block: Blockly.Block, topBlockId: string) {
        if (!block) { return ''; }
        let path: string = '';
        let blockParent = block.getParent();
        let fromRootFilter = blockParent ? blockParent.id === topBlockId : false;
        while (blockParent != null) {
            path = `filters${Utils.defaultIdSeparator}${blockParent.getChildren(false).indexOf(block)}${Utils.defaultIdSeparator}` + path;
            block = blockParent;
            blockParent = blockParent.getParent();
            if (blockParent && blockParent.id === topBlockId) {
                fromRootFilter = true;
            }
        }
        if (!fromRootFilter) {
            return '';
        }
        path = path.slice(0, path.length - Utils.defaultIdSeparator.length);
        return path;
    }

    getBlockFromPath(path: string, workspace: Blockly.WorkspaceSvg, topBlockId: string) {
        let topBlock = workspace.getBlockById(topBlockId);
        path = path.split('filters').join('childBlocks_');
        const pathToParent = Utils.substringBefore(path, Utils.defaultIdSeparator, true);
        const parent = Utils.navigate(topBlock, pathToParent);
        const childKey = Utils.substringAfter(path, Utils.defaultIdSeparator, true);
        return parent[childKey];
    }

    addFilter(newFilter: Filter, workspace: Blockly.WorkspaceSvg, topBlockId: string, entityDescriptor: string, duplicateMode?: boolean, openFilterForm?: boolean) {
        this.addFilterInBlockly(newFilter, workspace, topBlockId, entityDescriptor, duplicateMode);
        if (openFilterForm) {
            this.props.r.setInReduxState({ filterFormOpened: true });
        }
    }

    connectSimpleFilterToAdvancedFilter(childBlock: any, block: any, inputIndex?: number) {
        if (block && block.type && (block.type === EDITOR_LOGIC_OPERATION_NO_OUTPUT || block.type === EDITOR_LOGIC_OPERATION_WITH_OUTPUT)) {
            block.initSvg();
            block.render();
            childBlock.initSvg();
            childBlock.render();

            let parentConnection: any;
            let inputList = block.inputList.filter((input: any) => input.name.includes('ADD'));

            if (inputIndex !== undefined) {
                let input = block.getInput('ADD' + inputIndex);
                parentConnection = input.connection;
            }
            if (!parentConnection) {
                for (let i = 0; i < inputList.length; i++) {
                    let input = block.getInput('ADD' + i);
                    if (input.connection && !input.connection.targetConnection) {
                        parentConnection = input.connection;
                        break;
                    }
                }
            }

            if (!parentConnection) {
                parentConnection = block.appendInput_(1, "ADD" + inputList.length).connection;
                block.itemCount_++
            }

            var childConnection = childBlock.outputConnection;
            parentConnection.connect(childConnection);
        }
    }

    addFilterInBlockly(newFilter: Filter, workspace: Blockly.WorkspaceSvg, topBlockId: string, entityDescriptor: string, duplicateMode?: boolean, fromApplyAndAddNewSameField?: boolean, indexConnection?: number) {
        let block = workspace.getBlockById(Blockly.selected?.id);
        if (!duplicateMode && block && block.type && (block.type === FILTER_WITH_OUTPUT || block.type === NO_VALUE_FILTER_WITH_OUTPUT)) {
            block = block.getParent();
        }

        let path = this.getPathFromBlock(workspace.getBlockById(Blockly.selected?.id), topBlockId);
        if (duplicateMode && path) {
            path = Utils.substringBefore(path, Utils.defaultIdSeparator, true);
            if (path === "filters") {
                block = workspace.getBlockById(topBlockId);
            } else {
                block = block.getParent();
            }
        }
        if (!block) {
            block = workspace.getBlockById(topBlockId);
        }
        if (fromApplyAndAddNewSameField && (block as any).getOperator() !== FilterOperators.forComposedFilter.or.value) {
            let filterBlockToMove = this.getBlockFromPath(this.props.s.selectedFilterPath, workspace, topBlockId);
            let indexOfFilterToMove = Utils.substringAfter(this.props.s.selectedFilterPath, Utils.defaultIdSeparator, true);
            this.addFilterInBlockly(Filter.createComposedForClient(FilterOperators.forComposedFilter.or), workspace, topBlockId, entityDescriptor, false, false, parseInt(indexOfFilterToMove));
            let newOrBlock = this.getBlockFromPath(this.props.s.selectedFilterPath, workspace, topBlockId);
            this.connectSimpleFilterToAdvancedFilter(filterBlockToMove, newOrBlock);
            newOrBlock.select();
            this.addFilter(newFilter, workspace, topBlockId, entityDescriptor);
            return
        }
        let childBlock: any = Blockly.Xml.domToBlock(Blockly.Xml.textToDom(createFilterBlock(newFilter, { entityDescriptorName: entityDescriptor }, true, true, true)), workspace);
        if (block && block.type && (block.type === EDITOR_LOGIC_OPERATION_NO_OUTPUT || block.type === EDITOR_LOGIC_OPERATION_WITH_OUTPUT)) {
            this.connectSimpleFilterToAdvancedFilter(childBlock, block, indexConnection);

            if (this.getPathFromBlock(childBlock, topBlockId)) {
                this.props.r.setInReduxState({
                    selectedFilterPath: this.getPathFromBlock(childBlock, topBlockId),
                    rootFilter: this.getFilterFromBlock(workspace.getBlockById(topBlockId)),
                });
                if (!newFilter.filters) {
                    this.props.r.setInReduxState({ lastAddedFilterBlockId: childBlock.id });
                }
            }
        }

    }

    getFilterFromBlock(block: Blockly.Block): Filter {
        if (!block) { return Filter.createComposed(FilterOperators.forComposedFilter.and, []); }
        const childrens = block.getChildren(false);
        let f: Filter = Filter.createForClient();
        if (block.type === EDITOR_LOGIC_OPERATION_WITH_OUTPUT || block.type === EDITOR_LOGIC_OPERATION_NO_OUTPUT) {
            f.filters = [];
            for (let i = 0; i < childrens.length; i++) {
                let filter = this.getFilterFromBlock(childrens[i]);
                f.filters.push(filter);
            }
            f.operator = block.getFieldValue("OP");
            f.enabled = block.getFieldValue("ENABLED") && block.getFieldValue("ENABLED").toUpperCase() === "FALSE" ? false : true;
            return f;
        } else {
            let filterData = JSON.parse(block.data);
            let filter: Filter = {
                field: filterData.field,
                operator: filterData.operator,
                value: filterData.value,
                enabled: filterData.enabled === "false" ? false : true,
            };
            if (filterData.label) {
                filter.label = filterData.label
            }
            return filter;
        }
    }

    onWorkspaceClick(event: any, topBlockId: string, workspace: Blockly.WorkspaceSvg) {
        let block = workspace.getBlockById(event.blockId);
        if (block.id === topBlockId) {
            this.props.r.setInReduxState({ selectedFilterPath: '' });
            return;
        }
        let newSelectedFilterPath: string = this.getPathFromBlock(block, topBlockId);
        if (this.props.s.selectedFilterPath !== newSelectedFilterPath) {
            this.props.r.setInReduxState({ selectedFilterPath: newSelectedFilterPath });
        }
    }

    onWorkspaceDoubleClick(event: any, topBlockId: string, workspace: Blockly.WorkspaceSvg, mousePosition: { x: number, y: number }) {
        if (event.type === Blockly.Events.UI && event.element === 'click') {
            this.onWorkspaceClick(event, topBlockId, workspace);
            let block = workspace.getBlockById(event.blockId);
            if (block.type !== FILTER_WITH_OUTPUT && block.type !== NO_VALUE_FILTER_WITH_OUTPUT && block.type !== LABELED_FILTER_WITH_OUTPUT) {
                this.props.r.setInReduxState({ selectedFilterPath: '' });
                return;
            }
            if (this.props.s.selectedFilterPath) {
                this.props.r.setInReduxState({ filterFormOpened: [mousePosition.x, mousePosition.y] });
            }
        }
    }

    updateFilter(workspace: Blockly.WorkspaceSvg, topBlockId: string, keepSelectedPath?: boolean) {
        let newFilter = this.getFilterFromBlock(workspace.getBlockById(topBlockId));
        if (!lodash.isEqual(JSON.stringify(newFilter), JSON.stringify(this.props.s.rootFilter))) {
            this.props.r.setInReduxState({
                rootFilter: this.getFilterFromBlock(workspace.getBlockById(topBlockId)),
                selectedFilterPath: keepSelectedPath ? this.props.s.selectedFilterPath : ''
            })
        }
    }

    duplicateFilter(workspace: Blockly.WorkspaceSvg, topBlockId: string, entityDescriptor: string) {
        if (this.props.s.rootFilter.filters!.length === 0 || !Blockly.selected) { return; }
        this.props.r.setInReduxState({ selectedFilterPath: this.getPathFromBlock(workspace.getBlockById(Blockly.selected.id), topBlockId) });
        if (!this.props.s.selectedFilterPath) { return; }
        let filter = Utils.navigate(this.props.s.rootFilter, this.props.s.selectedFilterPath);
        const openFilterForm = filter.filters ? false : true;
        this.addFilter(filter, workspace, topBlockId, entityDescriptor, true, openFilterForm);
    }

    editFilter(topBlockId: string, workspace: Blockly.WorkspaceSvg) {
        if (!Blockly.selected) { return; }
        this.props.r.setInReduxState({ selectedFilterPath: this.getPathFromBlock(workspace.getBlockById(Blockly.selected.id), topBlockId) });
        if (!this.props.s.selectedFilterPath) { return; }
        let filter = Utils.navigate(this.props.s.rootFilter, this.props.s.selectedFilterPath);
        if (!filter.filters) {
            this.props.r.setInReduxState({ filterFormOpened: true });
        }
    }

    removeBlock(id: any, workspace: Blockly.WorkspaceSvg, blocklyEditorTabRef: React.RefObject<any>, entityDescriptor: string) {
        workspace.getBlockById(id) && workspace.getBlockById(id).dispose(false);
        let filter = this.getFilterFromBlock(workspace.getBlockById(blocklyEditorTabRef.current.topBlockId))
        this.refreshWorkspace(workspace, filter, blocklyEditorTabRef, entityDescriptor);
    }

    handleMouseMove = (e: any) => {
        this.mousePosition.x = e.clientX;
        this.mousePosition.y = e.clientY;
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleBlocklyEvents(e: any) {
        if (e.newValue === 'workspace' || !this.props.ownRef.current) { return; }
        if (e.type === Blockly.Events.DELETE || (e.type === Blockly.Events.MOVE && e.newParentId)) {
            this.workspace.trashcan.emptyContents();
            this.updateFilter(this.workspace, this.topBlockId);
            return;
        } else if (e.type === Blockly.Events.CHANGE && e.element === 'field' && e.name === 'OP') {
            this.onWorkspaceClick(e, this.topBlockId, this.workspace);
            this.updateFilter(this.workspace, this.topBlockId, true);
            return;
        } else if (e.type === Blockly.Events.UI && e.element === 'click') {
            this.clickCount++;
            this.onWorkspaceClick(e, this.topBlockId, this.workspace);
            if (this.clickCount === 1) {
                this.singleClickTimer = setTimeout(() => {
                    this.clickCount = 0;
                }, 500);
            } else if (this.clickCount === 2) {
                clearTimeout(this.singleClickTimer);
                this.onWorkspaceDoubleClick(e, this.topBlockId, this.workspace, this.mousePosition);
                this.clickCount = 0;
            }
        }
    }

    componentDidUpdate(prevProps?: BlocklyEditorTabProps) {
        if (prevProps && this.props.entityDescriptor !== prevProps.entityDescriptor) {
            this.props.r.setInReduxState({ rootFilter: DEFAULT_COMPOSED_FILTER})
            this.refreshWorkspace(this.workspace, DEFAULT_COMPOSED_FILTER, this.props.ownRef, this.props.entityDescriptor);
        }

        // when called from EntityFilter, the object fed in initialFilter is a local var; hence new instance on each render
        // that's why we need a deep equality test (because the normal/shallow equality test will return always false)
        if (!_.isEqual(this.props.initialFilter, prevProps?.initialFilter)) {
            console.log("bet.cdu", this.props.initialFilter, prevProps?.initialFilter)
            this.props.r.setInReduxState({ rootFilter: this.props.initialFilter })
        }
    }

    async componentDidMount() {
        // TODO CS/FILT: cred ca trebuie si operatiunea inversa de scoatere la unmount? parca la readonly facusem asta?
        this.primaryWorkspace = Blockly.inject(
            this.blocklyComponentDivRef.current,
            DEFAULT_BLOCKLY_SETTINGS
        );
        // this happens shortly after componentDidUpdate(), where we did issue an "setInReduxState" action which didn't complete;
        // it needs a draw cycle for this. Doing so, s.rootFilter will have the new value
        await Utils.setTimeoutAsync();
        this.setXml(`<xml>${createFilterBlock(this.props.s.rootFilter, { entityDescriptorName: this.props.entityDescriptor }, false, true, true) as string}</xml>`);
        this.workspace.addChangeListener((event: any) => this.handleBlocklyEvents(event));

        if (this.props.s.selectedFilterPath) {
            this.props.r.setInReduxState({ filterFormOpened: true });
        }

        if (this.confirmModalRef.current && this.confirmModalRef.current._reactInternalFiber.child.stateNode.dimmerRef.current) {
            this.confirmModalRef.current._reactInternalFiber.child.stateNode.dimmerRef.current.classList.add('SortBar_editSortModal');
        }

        document.addEventListener('mousemove', this.handleMouseMove);
    }

    get workspace() {
        return this.primaryWorkspace;
    }

    get rootFilter() {
        return this.props.s.rootFilter;
    }

    setXml(xml: string) {
        this.topBlockId = Blockly.Xml.appendDomToWorkspace(Blockly.Xml.textToDom(xml), this.workspace)[0];
        let topBlock = this.workspace.getBlockById(this.topBlockId);
        topBlock.setInputsInline(false);
        topBlock.setDeletable(false);
        topBlock.moveBy(20, 20);
    }

    
    addSimpleFilter = () => this.addFilter(Filter.createForClient(), this.workspace, this.topBlockId, this.props.entityDescriptor, false, true);

    addDuplicateFilter = () => this.duplicateFilter(this.workspace, this.topBlockId, this.props.entityDescriptor)

    handleEditFilter = () => this.editFilter(this.topBlockId, this.workspace);

    addAndFilter = () => this.addFilter(Filter.createComposedForClient(FilterOperators.forComposedFilter.and), this.workspace, this.topBlockId, this.props.entityDescriptor);

    addOrFilter = () => this.addFilter(Filter.createComposedForClient(FilterOperators.forComposedFilter.or), this.workspace, this.topBlockId, this.props.entityDescriptor);

    toggleTreeView = (block: Blockly.Block, changeOnlyChilds?: boolean) => {
        !changeOnlyChilds && block.setInputsInline(!this.props.s.linearBlocks);
        if (block.getChildren(false).length > 0) {
            block.getChildren(false).forEach(b => this.toggleTreeView(b));
        }
    }

    onToggleTreeView = (block: Blockly.Block) => {
        this.props.r.setInReduxState({ linearBlocks: !this.props.s.linearBlocks })
        this.toggleTreeView(block, true)
    }

    onApply = () => {
        this.beforeApply(this.workspace, this.topBlockId, this.props.ownRef, this.props.entityDescriptor, this.props.apply);
    }

    onClose = () => {
        if (this.props.s.lastAddedFilterBlockId) {
            this.removeBlock(this.props.s.lastAddedFilterBlockId, this.workspace, this.props.ownRef, this.props.entityDescriptor);
        }
        this.props.r.setInReduxState({
            lastAddedFilterBlockId: '',
            filterFormOpened: false,
        })
    }

  
    onFilterFormApply(filter: Filter) {
        this.commitWorkFromFilterForm(filter, this.workspace, this.props.ownRef, this.props.entityDescriptor);
        this.props.r.setInReduxState({ filterFormOpened: false });
    }

    onFilterFormApplyAndAddNew(filter: Filter) {
        this.filterFormRef.current?.fieldNameContentAssistRef?.resetState();
        this.onFilterFormApply(filter);
        // [Alex L]: onFilterFormApply closes the form when is called, but here we want the form still opened
        this.props.r.setInReduxState({ filterFormOpened: true });
        this.addFilterInBlockly(Filter.createForClient(), this.workspace, this.props.ownRef.current.topBlockId, this.props.entityDescriptor);
        this.filterFormRef.current!.fieldNameContentAssistRef?.props.r.open(this.props.entityDescriptor, true, true, FormType.FILTER);
    }

    onFilterFormApplyAndAddNewSameField(filter: Filter) {
        this.commitWorkFromFilterForm(filter, this.workspace, this.props.ownRef, this.props.entityDescriptor);
        this.addFilterInBlockly(Filter.createForClient(filter.field), this.workspace, this.props.ownRef.current.topBlockId, this.props.entityDescriptor, false, true);
    }

    render() {
        let filter = this.props.s.selectedFilterPath && this.props.s.rootFilter.filters!.length > 0 ? Utils.navigate(this.props.s.rootFilter, this.props.s.selectedFilterPath) : Filter.createForClient();
        let parentFilter: Filter;
        if (this.props.s.selectedFilterPath) {
            if (this.props.s.selectedFilterPath.split(Utils.defaultIdSeparator).length === 2) {
                parentFilter = lodash.cloneDeep(this.props.s.rootFilter);
            } else {
                let pathToParent = Utils.substringBefore(this.props.s.selectedFilterPath, Utils.defaultIdSeparator, true);
                pathToParent = Utils.substringBefore(pathToParent, Utils.defaultIdSeparator, true);
                const parent: Filter = Utils.navigate(this.props.s.rootFilter, pathToParent);
                parentFilter = lodash.cloneDeep(parent);
            }
            parentFilter.filters = parentFilter.filters?.filter((f, index) => index.toString() !== Utils.substringAfter(this.props.s.selectedFilterPath, Utils.defaultIdSeparator, true));
        }
        return (
            <Tab.Pane>
                <React.Fragment>
                    <Segment className='BlocklyEditorTab_allFiltersTabButtons'>
                        <Button icon='add' onClick={this.addSimpleFilter} color='olive' />
                        <Button icon='copy' onClick={this.addDuplicateFilter} color='olive' />
                        <Button icon='edit' onClick={this.handleEditFilter} color='olive' /> 
                        <Button color='olive' onClick={this.addAndFilter}><Icon className='FilterEditor_addAndButtonColor' name='add' />{_msg('Filter.operator.and')}</Button>
                        <Button color='olive' onClick={this.addOrFilter}><Icon className='FilterEditor_addOrButtonColor' name='add' />{_msg('Filter.operator.or')}</Button>
                        <Button floated='right' onClick={() => this.onToggleTreeView(this.workspace.getBlockById(this.topBlockId))}><Icon name='sitemap' />{_msg('BlocklyEditorTab.treeLinear')}</Button>
                    </Segment>
                    <div className='BlocklyComponent' ref={this.blocklyComponentDivRef} />
                </React.Fragment>
                <Container>{_msg('BlocklyEditorTab.hint')}</Container>
                <div className='BlocklyEditorTab_buttons'>
                    {this.props.apply && <Button color='green' onClick={this.onApply}>{_msg('general.apply')}</Button>}
                    {this.props.onCancel && <Button color='red' onClick={this.props.onCancel}>{_msg('general.cancel')}</Button>}
                </div>
                <ConnectedComponentInSimpleComponent info={new ConnectedPageInfo(sliceFilterForm, FilterForm, "filterForm", {ref: this.filterFormRef})} parentFilter={parentFilter!} filterFormOpened={this.props.s.filterFormOpened} onClose={this.onClose} onApplyAndAddNewSameField={this.onFilterFormApplyAndAddNewSameField} onApplyAndAddNew={this.onFilterFormApplyAndAddNew} onApply={this.onFilterFormApply} entityName={this.props.entityDescriptor} initialFilter={filter} />
            </Tab.Pane>
        );
    }
}

export const BlocklyEditorTabRRC = ReduxReusableComponents.connectRRC(BlocklyEditorTabState, BlocklyEditorTabReducers, BlocklyEditorTab);"../..""../../reduxReusableComponents/ReduxReusableComponents""../../utils/Utils"