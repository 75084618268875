import { Test } from "mocha";
import React from "react";
import { ClassToNameRegistry } from "../copied/ClassToNameRegistry";

interface Props {
    objectToPublish: any;
    dataTestIdSuffix?: string,
    // TODO CS: I don't think it's needed
    className?: string;
}

/**
 * @author Cristian Spiescu
 */
export class TestsAreDemoCheat extends React.Component<Props> {

    ref = React.createRef<any>();

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate(prevProps?: Readonly<Props>): void {
        if (!prevProps || this.props.objectToPublish !== prevProps.objectToPublish) {
            this.ref.current.testsAreDemoCheat = this.props.objectToPublish;
        }
    }

    render() {
        const dataTestIdSuffix = this.props.dataTestIdSuffix ? "_" + this.props.dataTestIdSuffix : "";
        return <span data-testid={`${ClassToNameRegistry.INSTANCE.getClassNameFromObject(this.props.objectToPublish)}${dataTestIdSuffix}`} ref={this.ref} className={this.props.className} />
    }
}

export const ShortcutRefForTest = TestsAreDemoCheat;