import { entityDescriptors, ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import gql from "graphql-tag";
import React from "react";
import lodash from "lodash";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { Optional } from "@crispico/foundation-react/CompMeta";

export class AuditFieldRenderer extends React.Component<{fd: Optional<FieldDescriptor>, entity: any}, {entity: any}> {

    constructor(props: {fd: Optional<FieldDescriptor>, entity: any}){
        super(props);
        this.state = {entity: undefined}
    }

    async loadData(fd: Optional<FieldDescriptor>, entity: any){
        if (!fd){
            return null;
        }

        if (!fd.typeIsEntity()){
            // normal field, nothing to do
            return entity
        } 

        // entity field, needs call for miniFields
        const loadOperationName = `${lodash.lowerFirst(entityDescriptors[fd.type].name)}Service_findById`;
        const loadQuery = gql(`query q($id: Long) { 
            ${loadOperationName}(id: $id) {
                ${ID} ${entityDescriptors[fd.type].miniFields.join(" ")} 
            }
        }`);
        
        const entityResult = (await apolloClientHolder.apolloClient.query({ query: loadQuery, variables: { id: entity[fd.name] } })).data[loadOperationName];
        if (!entityResult){
            // if the entity was deleted, we return nothing
            return null;
        }
        return {[fd.name]: entityResult}
    }

    async componentDidMount(){
        const entity = await this.loadData(this.props.fd, this.props.entity)
        if (!entity){
            return;
        }        
        this.setState({entity: entity})    
    }

    render() {
        return this.state.entity ? this.props.fd?.renderField(this.state.entity) : this.props.fd?.getFieldValue(this.props.entity);
    }
}"../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../apolloClient""../../CompMeta"