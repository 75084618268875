import React from "react";
import { Button } from "semantic-ui-react";

export type CrudHeaderProps = {
    content?: { backgroundImage?: string, component: React.ReactNode, className?: string, overlayClassName?: string },
    miniContent?: { component: React.ReactNode, className?: string }
    minMaxButtonClassName?: string
}
export class CrudHeader extends React.Component<CrudHeaderProps, { showMiniContent: boolean }> {

    public static defaultProps = {
        minMaxButtonClassName: "CrudHeader_minimizeButton_right"
    };

    constructor(props: CrudHeaderProps) {
        super(props);
        this.state = { showMiniContent: false };
    }

    renderMinimizeButton() {
        const { props , state } = this;
        return <div className="CrudHeader_minimizeButton_parent">
            <Button className={props.minMaxButtonClassName + " CrudHeader_minimizeButton less-padding"}
                circular icon={state.showMiniContent ? "window maximize" : "window maximize outline"}
                onClick={() => this.setState({ showMiniContent: !state.showMiniContent })} />
        </div>
    }

    render() {
        const { props } = this;
        const className = (props.content?.className || "") + " flex-container CrudHeader";
        const overlayClassName = (props.content?.overlayClassName || "") + " CrudHeader_overlay";

        return <>{this.renderMinimizeButton()}
            {this.state.showMiniContent
                ? <div className="CrudHeader_color">
                    <div className={(props.miniContent?.className || "") + " CrudHeader"}>
                        {props.miniContent?.component}
                    </div>
                </div>
                : <div className={className + " CrudHeader_color"} style={props.content?.backgroundImage ? { background: "url(" + (props.content?.backgroundImage) + ") no-repeat 0 0 / cover" } : undefined}>
                    <div className={className}>
                        <div className={overlayClassName}>
                            <span className="CrudHeader_blur wh100" />
                            {props.content?.component}
                        </div>
                    </div>
                </div>}</>;
    }
}