import { SelectExt, SelectExtOption, SelectExtProps } from '@crispico/foundation-react/components/selectExt/SelectExt';
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import moment from 'moment';
import { Utils } from '@crispico/foundation-react';

export interface DaysOfTheWeekFieldEditorProps extends FieldEditorProps, Partial<SelectExtProps> {
    startingDay?: number; // number from 1 to 7, 1 = Monday
}

export class DaysOfTheWeekFieldEditor extends FieldEditor<string | null, DaysOfTheWeekFieldEditorProps> {

    static defaultProps = {
        isMulti: true,
        startingDay: 1
    };

    constructor(props: DaysOfTheWeekFieldEditorProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    protected onChange(selectedOptions: SelectExtOption[], s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        let newValue;
        if (selectedOptions.length === 0) {
            newValue = null;
        } else if (!this.props.isMulti) {
            newValue = selectedOptions[0].value;
        } else {
            let result: string[] = [];
            for (var i = 1; i <= 7; i++) {
                result.push(selectedOptions.find(option => option.value === String(i))?.value || ".");
            }
            newValue = result.join("");
        }
        s.setFieldValue(hw, newValue);
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const { startingDay, isMulti } = this.props;
        const currentValue = this.getValue();
        var weekdays: SelectExtOption[] = [];
        var selectedWeekdays: SelectExtOption[] = [];
        for (var i = 1; i <= 7; i++) {
            const option: SelectExtOption = { value: String(i), label: moment().isoWeekday(startingDay! + i - 1).format("dddd") };
            if (!Utils.isNullOrEmpty(currentValue) && currentValue.indexOf(option.value) !== -1) {
                selectedWeekdays.push(option);
            }
            weekdays.push(option);
        }

        return (
            <SelectExt options={weekdays} value={selectedWeekdays} isClearable closeMenuOnSelect={!isMulti}
                onSelectedOptionsChange={(options: SelectExtOption[]) => this.onChange(options, s, hw)}
                {...this.getPropsToForward()}
            />
        );
    }
}

export default DaysOfTheWeekFieldEditor;"../../components/selectExt/SelectExt""../.."