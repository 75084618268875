import React from "react";
import { FoundationInitializationsForClient, Organization } from "@crispico/foundation-react/AppMeta";

export interface AppContainerContextValue {
    initializationsForClient: FoundationInitializationsForClient;
    organizations?: Organization[];
}

// @ts-ignore: AppContainer guarantees that it waits for initializationsForClient, and only after that it "starts" the app; 
// normally no screen would see the "undefined" below
export const AppContainerContext = React.createContext<AppContainerContextValue>(undefined);
"./AppMeta"