import React, { ReactNode } from "react";

interface WrapProps {
    render: () => ReactNode
}

/**
 * E.g. for:
 * 
 * ```
 * <MyComponent myProp={someRef.current!.getValue()} />
 * ```
 * 
 * If `someRef -> value` changes, and it emits some kind of notification, we want somehow a rerender to take in account the new
 * value. The solution:
 * 
 * ```
 * <WrapperWithRenderFunction ref={wrapperRef} render={() => <MyComponent myProp={someRef.current!.getValue()} />} />
 * 
 * // in a callback that gets called when `value` changes:
 * wrapperRef.current!.forceUpdate();
 * ```
 * 
 * @author Cristian Spiescu
 */
export class WrapperWithRenderFunction extends React.Component<WrapProps> {

    render() {
        return this.props.render();
    }

}