import { createReducer } from "@reduxjs/toolkit";
import React from "react";
import { AnyAction } from "redux";
import { Reducers, State } from "./ReduxReusableComponents";

// This file has utility functions which are "private" to this dir. They are not in the main file, to
// avoid the pollution of the public API.

export const RRC_ACTION_PREFIX = "rrc::";
export const RRC_ACTION_CREATED_SUFFIX = "/created!";
export const RRC_ACTION_DESTROYED_SUFFIX = "/destroyed!";

export function getRRCAction(longId: string, functionName: string) {
    return RRC_ACTION_PREFIX + longId + "/" + functionName;
}

function iterateFunctions(clazz: new (s: any) => any, callback: (property: string) => void) {
    let proto = clazz.prototype;

    while (proto !== Object.prototype) {
        for (const property of Object.getOwnPropertyNames(proto)) {
            if (property === "constructor" || property.startsWith("_")) { continue; }
            callback(property);
        }
        proto = Object.getPrototypeOf(proto);
    }
}

/**
 * Converts a Reducers class to a reducer, created w/ `createReducer()`. Each function of the class is transformed into a case reducer,
 * which instantiates the class and calls that function.
 */
export function createReducerFromReducersClass<S extends State, R extends Reducers<S>>(stateClass: new () => S, reducersClass: new (s: S) => R, longId: string) {
    return createReducer({ ...new stateClass() }, createPreReducerFromReducersClass(reducersClass, longId) as any);
}

export function createPreReducerFromReducersClass<S extends State, R extends Reducers<S>>(reducersClass: new (s: S) => R, longId: string) {
    const preReducers: { [actionName: string]: Function } = {};
    iterateFunctions(reducersClass, property => {
        preReducers[getRRCAction(longId, property)] = (state: S, action: AnyAction) => {
            const obj = new reducersClass(state) as any;
            (obj[property] as Function).apply(obj, action.payload);
        }
    });
    return preReducers;
}

/**
 * For each function of the class, a function is created that dispatches an action e.g. RRC_ACTION_PREFIX + 'some/path/functionTwoArgs'.
 */
export function createDispatchersFromReducersClass(reducersClass: new (s: any) => any, longId: string, dispatchFunction: (action: AnyAction) => void) {
    const result: any = {};
    iterateFunctions(reducersClass, property => {
        result[property] = (...args: any) => dispatchFunction({ type: getRRCAction(longId, property), payload: args });
    });
    return result;
}

export const ParentLongIdContext = React.createContext<string | null>(null);

/**
 * Returns a HOC that retrieves the "parentLongId" via ParentLongIdContext,
 * and injects it in the component.
 */
export function withParentLongId(Component: any) {
    class HOC extends React.Component<{ forwardedRef: any }> {
        static contextType = ParentLongIdContext;

        render() {
            return <Component {...this.props} parentLongId={this.context} />
        }
    }
    return React.forwardRef((props, ref) => <HOC {...props} forwardedRef={ref} />);
}
