import { apolloClient, ApolloContext, CatchedGraphQLError } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ConnectedPageInfo } from "@crispico/foundation-react/reduxHelpers";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { push } from "connected-react-router";
import { FormikValues } from "formik";
import React from "react";
import { Button, ButtonProps, Divider, Grid, Header, Icon, Message, Segment } from "semantic-ui-react";
import { HomePage, infoHomePage, sliceHomePage } from "../HomePage";
import { infoLoginPage } from "../loginPage/LoginPage";
import { PasswordEditor } from "../user/PasswordEditor";
import { CHANGE_EXPIRED_PASSWORD_FOR_USER, CHECK_PASSWORD_RESET_KEY, FINISH_PASSWORD_RESET } from "./queries";
import { passwordResetInitPageUrl_twoManyFailedLogins } from "./PasswordResetInitPage";

const EXPIRED_PASSWORD = "expiredPassword";

enum ChangePasswordStatus {
    TWO_MANY_FAILED_ATTEMPTS = "TWO_MANY_FAILED_ATTEMPTS",
    SUCCES = "SUCCES",
    FAIL = "FAIL"
}

export class PasswordResetFinishPage extends HomePage {
    protected refPasswordEditor = React.createRef<PasswordEditor>();
    constructor(props: any) {
        super(props);
        this.resetClickHandler = this.resetClickHandler.bind(this);
    }

    protected onMatchChanged(match: any) {
        if (match.resetKey == EXPIRED_PASSWORD) {
            return;
        }
        apolloClient.query({
            context: {
                [ApolloContext.ON_ERROR_EXPECTED_EXCEPTION]: "ValidationException",
                [ApolloContext.ON_ERROR_HANDLER]: () => {
                    // Redirect to login page
                    this.props.dispatchers.dispatch(push(infoLoginPage.routeProps!.path as string));
                    // Show the error message in a global alert
                    return true;
                }
            },
            query: CHECK_PASSWORD_RESET_KEY,
            variables: { resetKey: match.resetKey }
        });
    }

    async resetClickHandler(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) {
        // VALIDATE password
        const passwordEditor: PasswordEditor = this.refPasswordEditor.current!;
        // If we don't call this, some validation errors could be skipped 
        await passwordEditor.submitForm();
        // Invalid new password
        if (passwordEditor.hasErrors()) {
            return;
        }

        // SAVE password
        // Get the reset key from the path parameter resetKey 
        const resetKey: String = this.props.match?.params.resetKey;
        const username: String | null = new URLSearchParams(this.props.location?.search).get("username");
        const currentPassword = (passwordEditor.formikContext.values as FormikValues).currentPassword;
        const newPassword = (passwordEditor.formikContext.values as FormikValues).newPassword;
        if (resetKey.startsWith(EXPIRED_PASSWORD)) {
            if (!username) {
                // Redirect to login page
                this.props.dispatchers.dispatch(push(infoLoginPage.routeProps!.path as string));
                AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.showGlobalAlert({ message: _msg("UserService.error.resetExpiredPasswordWithoutUser"), severity: Severity.ERROR });
                return;
            }
            const res = (await apolloClient.mutate({
                mutation: CHANGE_EXPIRED_PASSWORD_FOR_USER,
                variables: { username, currentPassword, newPassword }
            })).data['userService_changeExpiredPasswordForUser'];
            if (res.status == ChangePasswordStatus.TWO_MANY_FAILED_ATTEMPTS) {
                this.props.dispatchers.dispatch(push(passwordResetInitPageUrl_twoManyFailedLogins));
            }
            if (res.status == ChangePasswordStatus.FAIL) {
                AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.showGlobalAlert({ message: _msg(res.messageKey, ...(res.messageParams || [])), severity: Severity.INFO, });
            }
            if (res.status != ChangePasswordStatus.SUCCES) {
                // don't redirect to login page
                return;
            }
        } else {
            await apolloClient.mutate({
                mutation: FINISH_PASSWORD_RESET,
                variables: { resetKey, newPassword: newPassword }
            });
        }
        // Redirect to login page
        this.props.dispatchers.dispatch(push(infoLoginPage.routeProps!.path as string));
        AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.showGlobalAlert({ message: _msg("PasswordResetFinishPage.formSubTitle.success"), severity: Severity.INFO });

    }

    renderUnderJumbotron() {
        const expiredPasswordReset: boolean = this.props.match?.params.resetKey === EXPIRED_PASSWORD;
        return (
        <Grid centered verticalAlign='middle'>
            <Utils.MatchObserver onMatchChanged={this.onMatchChanged} />
            <Grid.Row>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Message attached="top" warning={expiredPasswordReset}>
                        <Header as={expiredPasswordReset ? 'h3' : 'h2'}>
                            <Icon name={expiredPasswordReset ? 'clock outline' : 'key'} />
                            <Header.Content>
                                {_msg(expiredPasswordReset ? "PasswordResetFinishPage.formTitle.expiredPassword" : "PasswordResetFinishPage.formTitle")}
                                {expiredPasswordReset ? <Header.Subheader >{_msg("PasswordResetFinishPage.formSubTitle.expiredPassword")}</Header.Subheader> : null}
                            </Header.Content>
                        </Header>
                    </Message>
                    <Segment className="attached">
                        <PasswordEditor ref={this.refPasswordEditor} hasInputForCurrentPassword={expiredPasswordReset}></PasswordEditor>
                        <Divider hidden />
                        <Button fluid size='large' primary onClick={this.resetClickHandler}>
                            {_msg("PasswordResetFinishPage.resetButton.label")}
                        </Button>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
    }

    render() {
        return this.renderMain();
    }
}

export const passwordResetBaseUrl: string = "/passwordResetFinish/"
export const passwordResetFinishPageUrl: string = passwordResetBaseUrl + ":resetKey";
export const expiredPasswordResetPageUrl: string = passwordResetBaseUrl + EXPIRED_PASSWORD;
export const infoPasswordResetFinishPage = new ConnectedPageInfo(sliceHomePage, PasswordResetFinishPage, "PasswordResetFinishPage", undefined, false);
infoPasswordResetFinishPage.routeProps = { path: passwordResetFinishPageUrl, exact: true };"../../apolloClient""../../AppMetaTempGlobals""../../components/ModalExt/ModalExt""../../reduxHelpers""../../utils/Utils"