export type Class<T> = new (...args: any) => T;

/**
 * @author Cristian Spiescu
 */
export class ClassToNameRegistry {

    static INSTANCE = new ClassToNameRegistry();

    /**
     * Maybe in the future we'll add an inverse mapping as well, if needed.
     */
    protected classToName: Map<Class<any>, string> = new Map();

    protected count = 0;

    getClassName(clazz: Class<any>): string {
        let result = this.classToName.get(clazz);
        if (!result) {
            // e.g. "MyClass_17";
            result = clazz.name + "_" + this.count++;
            this.classToName.set(clazz, result);
        }
        return result;
    }

    getClassNameFromObject(object: any) {
        if (!object.constructor.name) {
            // i.e. an anonymous class; let's get the name of it's superclass
            object = Object.getPrototypeOf(Object.getPrototypeOf(object));
        }
        return this.getClassName(object.constructor);
    }

}