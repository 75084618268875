import "@crispico/foundation-gwt-js";
import { FoundationUtils } from "@famiprog-foundation/utils";
import React, { ReactElement } from "react";
import Draggable from "react-draggable";
import Measure, { ContentRect } from "react-measure";
import { Header, Icon, Modal, ModalDimmerProps, Ref, StrictModalProps } from "semantic-ui-react";
import "./styles.css";

export type ModalExtOpen = boolean | [number, number];
export enum Severity { INFO, WARNING, ERROR, CONFIRMATION };

// we use StrictModalProps because Omit doesn't seem to work on ModalProps
export type ModalExtProps = Omit<StrictModalProps, "open" | "trigger"> & {
    // copied from ModalProps
    [key: string]: any,
    open: ModalExtOpen,

    // new props; @see also in render(); they are referenced there as well
    // they need to be duplicated in newPropsToDeleteToAvoidArrivingInDom
    transparentDimmer?: boolean,
    modalDimmerProps?: ModalDimmerProps,
    zIndex?: number,
    addNiceLookingOffsets?: boolean,
    severity?: Severity,
    widthToReposition?: number,
    moveable?: boolean | "ifHasHeader",
    resizeable?: boolean | "ifHasHeader"
}

const newPropsToDeleteToAvoidArrivingInDom = ["transparentDimmer", "modalDimmerProps", "zIndex", "addNiceLookingOffsets", "severity", "widthToReposition", "moveable", "resizeable"];

const XY_LIMIT = 30
const X_OFFSET = 10;
const Y_OFFSET = 10;

enum Mode { NOT_YET_OPENED, DEFAULT, ABSOLUTELY_POSITIONED };

interface State {
    measuredWidth: number;
    measuredHeight: number;
    mode: Mode;
}

export const modalExtTestids = FoundationUtils.createTestids("ModalExt", {
    dimmer: "", modal: ""
});

/**
 * @author Cristian Spiescu
 * @author Cosmin Zedano-Oprisan
 */
export class ModalExt extends React.Component<ModalExtProps, State> {

    static defaultProps: ModalExtProps = {
        open: false,
        transparentDimmer: false,
        closeIcon: false,
        closeOnEscape: true, // the doc didn't specify this and its default value; I saw it in the examples
        closeOnDimmerClick: true,
        addNiceLookingOffsets: false,
        widthToReposition: 768,
        moveable: "ifHasHeader",
        resizeable: "ifHasHeader"
    }

    dimmerElement?: HTMLElement | null;
    modalElement?: HTMLElement | null;

    protected measureRef!: (ref: Element | null) => void;
    protected hasHeader = false;
    constructor(props: any) {
        super(props);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.state = { measuredWidth: -1, measuredHeight: -1, mode: Mode.NOT_YET_OPENED };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.componentDidUpdateInternal();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    protected onWindowResize() {
        this.adjustModal(this.state.measuredWidth, this.state.measuredHeight);
    }

    componentDidUpdate(prevProps: ModalExtProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected componentDidUpdateInternal(prevProps?: ModalExtProps) {
        this.updateDimmerElementProps(prevProps);

        if (prevProps?.open === this.props.open) {
            // nothing changed
            return;
        } else if (prevProps?.open && !this.props.open) {
            // just closed; either from default or abs pos mode; in render() the modal has just received a falsy (not false) expression; so it's closed
            this.setState({ measuredWidth: -1, measuredHeight: -1 });
        } else if ((Array.isArray(prevProps?.open) && this.props.open === true) || (prevProps?.open === true && Array.isArray(this.props.open))) {
            throw new Error("Invalid state change. The modal cannot switch directly from 'default' -> 'absolutely positioned' or viceversa. It should be first closed.");
        } else if (!prevProps?.open) {
            if (this.props.open === true) {
                this.setState({ mode: Mode.DEFAULT });
            } else if (Array.isArray(this.props.open)) {
                // initial display; we don't know yet if the position is valid; but let's use it. If not good, it will be moved (w/ a slight flicker, usually not noticeable)
                // but this will happen only IF the position is not valid; in many cases it will be valid. The alternative, i.e. to display centered by default, will ALWAYS cause the slight flicker
                this.adjustModal(-1, -1);
                this.setState({ mode: Mode.ABSOLUTELY_POSITIONED });
            }
        }
    }

    protected updateDimmerElementProps(prevProps?: ModalExtProps) {
        if (!this.dimmerElement) {
            return;
        }
        if (prevProps?.zIndex !== this.props.zIndex) {
            this.dimmerElement.style.zIndex = this.props.zIndex?.toString() || "0";
        }
    }

    /**
     * We take w and h as params and not from state, because they may have just been insterted into the state,
     * and this doesn't guarantee us that we can access them.
     */
    protected adjustModal(modalWidth: number, modalHeight: number) {
        if (!this.props.open || typeof this.props.open === 'boolean') { return; }

        if (!this.modalElement) {
            // not sure if this may happen the way we currently call this method
            return;
        }

        let x = this.props.open[0];
        let y = this.props.open[1];

        if (modalHeight >= 0) {
            if (y + modalHeight > window.innerHeight) {
                y = window.innerHeight - modalHeight - XY_LIMIT;
            }
            if (x + modalWidth > window.innerWidth) {
                x = window.innerWidth - modalWidth - XY_LIMIT;
            }
        } // else initial display

        if (window.innerWidth < this.props.widthToReposition!) {
            // this (hardcoded here unfortunately) is the breakpoint starting w/ which the original 
            // modal goes into "mobile" mode; so we let it work the original way, because it's better
            this.modalElement.style.left = "";
            this.modalElement.style.top = "";
        } else {
            if (this.props.addNiceLookingOffsets) {
                x = x - X_OFFSET;
                let hasHeader = false;
                if (Array.isArray(this.props.children) && this.props.children.length >= 2) {
                    const maybeHeader = this.props.children[0] as ReactElement;
                    if (maybeHeader.type === Modal.Header) {
                        hasHeader = true;
                    }
                }
                y = hasHeader ? y - (this.modalElement.firstChild! as HTMLElement).clientHeight - Y_OFFSET : y - Y_OFFSET;
            }
            if (y < XY_LIMIT) {
                y = XY_LIMIT;
            }
            if (x < XY_LIMIT) {
                x = XY_LIMIT;
            }
            this.modalElement.style.left = x + 'px';
            this.modalElement.style.top = y + 'px';
        }
    }

    protected measureOnResize = (modalContentRect: ContentRect) => {
        const newWH = { measuredWidth: modalContentRect.bounds?.width!, measuredHeight: modalContentRect.bounds?.height! };
        this.setState(newWH);
        this.adjustModal(newWH.measuredWidth, newWH.measuredHeight);
    }

    /**
     * As a separate function, because when it was an inline function we had the infinite update loop issue.
     */
    protected setDimmerRef = (dimmerElement: HTMLElement | null) => {
        // initially we were taking a ref to Modal; and we empirically discovered "ref" and "innerRef"; but not being documented,
        // as they are internal, maybe they'll change; so we use Ref + dom navigation. This may also break if in the future the modal
        // won't be the first child any more
        this.dimmerElement = dimmerElement;
        this.modalElement = dimmerElement?.firstElementChild as HTMLElement;
        if (dimmerElement && !this.modalElement) { throw new Error("Unexpected! From the dimmer, cannot grab the HTMLElement corresponding to the modal.") }
        this.measureRef?.(this.modalElement || null);
        this.updateDimmerElementProps();
    }

    protected renderHeaderIcon() {
        if (this.props.severity === Severity.ERROR) {
            return <Icon className="ModalExt_headerIcon" name="warning sign" color="red" />
        } else if (this.props.severity === Severity.WARNING) {
            return <Icon className="ModalExt_headerIcon" name="warning circle" color="orange" />
        } else if (this.props.severity === Severity.INFO) {
            return <Icon className="ModalExt_headerIcon" name="info circle" color="blue" />
        } else if (this.props.severity === Severity.CONFIRMATION) {
            return <Icon className="ModalExt_headerIcon" name="question circle" color="yellow" />
        }
    }

    protected renderHeader(props: any, children: any) {
        this.hasHeader = true;
        const style = this.props.moveable ? { cursor: "move" } : {};
        return <Header as="h2" {...props} key="modalHeader" style={style}
            children={<div className='ModalExt_headerDiv' data-testid="ModalExt_headerDiv">
                <div>{this.renderHeaderIcon()}</div>
                <div className="ModalExt_headerContent">{children}</div>
                {/* If has closeIcon (which is true by default) AND header, then insert the close icon in the header */}
                {this.props.closeIcon && <Icon className="ModalExt_closeIcon" onClick={this.props.onClose} link name='close' size='small' />}
            </div>}>
        </Header>
    }

    protected renderDefaultHeaderMessage() {
        if (this.props.severity === Severity.ERROR) {
            return _msg("general.error");
        } else if (this.props.severity === Severity.WARNING) {
            return _msg("general.warning");
        } else if (this.props.severity === Severity.INFO) {
            return _msg("general.info");
        } else if (this.props.severity === Severity.CONFIRMATION) {
            return _msg("general.confirmation");
        }
    }

    render() {
        // although we removed the field from TS, being an "open" type, people may still use it
        if (this.props.trigger) {
            throw new Error("ModalExt doesn't support trigger. Please move the component currently assigned to 'trigger': as a sibbling of the <ModalExt ...> component. ");
        }

        const dimmer = <Modal.Dimmer data-testid={modalExtTestids.dimmer} className="ModalExt_dimmer" transparent-dimmer={this.props.transparentDimmer ? "" : undefined} style={{ overflowY: "auto" }}
            {...this.props.modalDimmerProps}
        />;
        const modalProps = { ...this.props, open: !!this.props.open, dimmer, "data-testid": modalExtTestids.modal };
        // we need to remove them, because they'd be injected in the DOM (as per the behavior of Modal); not good + error in console
        for (const prop of newPropsToDeleteToAvoidArrivingInDom) {
            // @ts-ignore
            delete modalProps[prop];
        }

        if (Array.isArray(this.props.children) && this.props.children.length >= 2) { // maybe <header> + <Content>; only <Header> is useless
            // maybe the header is a child
            const maybeHeader = this.props.children[0] as ReactElement;
            if (maybeHeader.type === Modal.Header) { // if maybHeader is not a ReactElement, this condition will return false; so no problem
                const newHeader = this.renderHeader(maybeHeader.props, maybeHeader.props.children);
                modalProps.children = [newHeader].concat((this.props.children as Array<any>).slice(1, this.props.children.length));
                modalProps.closeIcon = false;
            } else if (this.props.severity != undefined) {
                // the header is not a child, but the Modal uses children elements
                // the header must be concatenated with the modal children, because if children + shorthands are present, the modal will render only children
                const newHeader = this.renderHeader(maybeHeader.props, this.renderDefaultHeaderMessage());
                modalProps.children = [newHeader].concat((this.props.children as Array<any>));
                modalProps.closeIcon = false;
            }
        } else if (this.props.header) {
            // maybe the header is a "shorthand" property
            modalProps.header = this.renderHeader(undefined, modalProps.header);
            modalProps.closeIcon = false;
        } else if (this.props.severity !== undefined) {
            // the modal uses shorthands, but the header is not present
            modalProps.header = this.renderHeader(undefined, this.renderDefaultHeaderMessage());
            modalProps.closeIcon = false;
        }
        if (!modalProps.style) {
            modalProps.style = {};
        }

        /**
         * When resizing w/ mouse, the modal has a sweet spot where it detects that it enters in "scrolling mode". 
         * It puts "scrolling" class on body, and activates hence the css rule:
         * 
         * .modals.dimmer .ui.scrolling.modal {
         *   margin: 1rem auto;
         * }
         * 
         * which introduces small margins (that bother). Hence I disable margins. I hope this doesn't break the original
         * "scrolling" behavior. I don't see how only removing the small margins could harm.
         */
        modalProps.style.margin = "0";

        if (this.props.closeIcon && modalProps.closeIcon) {
            // not 100% to allow space for closeIcon
            modalProps.style.maxHeight = "90%";
        } // else, we are in the various cases in the above IFs, that detect if a header exist

        // we found out that plain CSS offers the possiblity to resize a div
        // using the 2 properties below. Cf. https://developer.mozilla.org/en-US/docs/Web/CSS/resize
        // it's a bit odd that this is not very visible via a google search
        if ((modalProps.resizeable === "ifHasHeader" && this.hasHeader) || modalProps.resizeable === true) {
            modalProps.style.resize = "both";
            modalProps.style.overflow = "auto";
        }

        if (modalProps.moveable === true && !this.hasHeader) {
            modalProps.style.cursor = "move";
        }
        /*
         * The logic here, together w/ `Mode` are a workaround for a behavior that we observed (and which generated an error in the console).
         * If the modal is open and it is unmonuted => some error. This mechanism prevents this. I.e. when the "open" prop becomes falsy, it
         * won't unmount. But "keep" the original modal and "gracefully" close it.
         */

        /* CZ: Modal wrapped in <Measure> for DEFAULT mode too, but without onResize handler. That's because we prevent the RRC duplicate id problem this way. */
        return (this.state.mode !== Mode.NOT_YET_OPENED && <Measure bounds onResize={this.state.mode === Mode.ABSOLUTELY_POSITIONED ? this.measureOnResize : undefined}>
            {({ measureRef }) => {
                this.measureRef = measureRef;
                return (<Ref innerRef={this.setDimmerRef}>
                    {this.props.moveable === true || (this.props.moveable === 'ifHasHeader' && this.hasHeader) ? <Draggable handle={this.hasHeader ? ".header" : undefined}><Modal {...modalProps} /></Draggable> : <Modal {...modalProps} />}
                </Ref>);
            }}
        </Measure>
        );
    }
}