import { BoundFunctions, fireEvent, getByTestId, queries, Queries, screen, waitFor, within } from "@testing-library/react/pure";
import userEvent from "@testing-library/user-event";
import { typeOptions } from "@testing-library/user-event/dist/type/typeImplementation";
import { assert } from "chai";
import { ReactNode, useState } from "react";
import { Label } from "semantic-ui-react";
import { ClassToNameRegistry } from "../copied/ClassToNameRegistry";
import { Utils } from "../copied/Utils";
import { ExtensionFromDecorators, ExtensionsFromDecorators, EXTENSIONS_FOR_CLASS, getOrCreateExtensions, setGlobalsForPseudoAnnotations } from "./decorators";
import { dragRaw } from "./dragCopied";
import { MiniDb } from "./miniDb/MiniDb";
import { CustomRenderOptions, render } from "./reactTestingLibraryCustomized";
import { TestClassDescriptor, TestsToRun } from "./TestsAreDemoMaster";
import { DemoForEndUser, SpotlightParams, TestsAreDemoSlave } from "./TestsAreDemoSlave";
import { TestsAreDemoSlideShow } from "./TestsAreDemoSlideShow";
import { ConvertReturnToPromiseForAll, FoundationUtils, waitFor_options } from "@famiprog-foundation/utils";

/**
 * This file is executed both in the "master" iframe and the "slave" iframe. However, in the "master" iframe, mocha doesn't exist. And the functions from this file
 * shouldn't be called.
 * 
 * During testsAreDemo mode, in the "master" iframe, we don't have (a lot) of code of the user. The imports are processed, so this kind of user code is processed. 
 * But other functions are not (or component renders). So even if the user calls by mistake a tad function from the normal code (not test), this code won't probably
 * be executed.
 * 
 * However, in "normal" mode, there is only one frame (the "master" frame), which hosts the app. And if the user calls by mistake a tad function => I don't know if
 * 1/ they would work and 2/ the error message would be meaningfull. One thing is clear: tad functions shouldn't be called from non-test functions.
 * 
 * If this situation happens and gives us trouble, then we should implement a failsafe. Ideas: 
 * 1/ don't import/export this file in `index.ts`. And users should import this file directly.
 * 2/ if not "mocha" => replace the functions w/ proxies that throw errors + meaningful message
 * 
 * ## In node.js
 * 
 * We don't have this master + slave frame approach. So no `TestsAreDemoSlave` object exists.
 */

/**
 * Issue: for some functions, e.g. click(), it doesn't "copy" the arg names. The IDE shows: arg_0, arg_1, etc.
 * The types are correctly copied. I don't know why in other cases it work. e.g. clear(), keyboard(). As well as
 * all in assertWaitableType. Maybe it has to do w/ overloads?
 * 
 * Before we had this syntax. But the issue was identical.
 * [K in keyof Omit<typeof userEvent, "type">]: (...args: Parameters<typeof userEvent[K]>) => Promise<void>;
 */
type userEventWaitableType = ConvertReturnToPromiseForAll<typeof userEvent> & {
    // copied from node_modules/@testing-library/user-event/dist/type/typeImplementation.d.ts
    // because it's an overload, our replacement doesn't work for both
    type(element: Element, text: string, options?: typeOptions & {
        delay?: 0;
    }): Promise<void>;
    type(element: Element, text: string, options: typeOptions & {
        delay: number;
    }): Promise<void>;
}

type fireEventWaitableType = ConvertReturnToPromiseForAll<typeof fireEvent>;

type assertWaitableType = ConvertReturnToPromiseForAll<typeof assert>;

export type RenderWrappedWithPropsSetterResult<T> = ReturnType<typeof render> & {
    setProps: (t: T) => void
}

export interface ITestsAreDemoTest {
    name?: string;
}

type ParamFor_addTest = ((new (...args: any[]) => (ITestsAreDemoTest | Object)));

export const AUTO_CREATED_GROUP_PREFIX = ".<group>";

/**
 * Please only call these functions from test code.
 */
export class TestsAreDemoFunctions {

    screenCapturing: typeof screen;

    userEventWaitable: userEventWaitableType;
    fireEventWaitable: fireEventWaitableType;
    drag: typeof dragRaw;

    assertWaitable: assertWaitableType;
    waitForWhileException = waitFor;

    protected elementToObjectViaShortcutRefForTest: Map<any, any> = new Map();
    protected slides?: TestsAreDemoSlideShow;

    miniDb = new MiniDb();

    /**
     * All access to `TestsAreDemoSlave` or `TestsAreDemoMaster` (i.e. `...Slave.master`)
     * should happen through this function. The caller should be prepared to receive `undefined`. In most cases,
     * calling this function with "?.", e.g.  `getTestsAreDemoSlave()?.myFunction()`, should be enough.
     * 
     * @returns  If nodejs mode => `undefined`.
     */
    getTestsAreDemoSlave(): TestsAreDemoSlave | undefined {
        return Utils.isTest() ? undefined : TestsAreDemoSlave.INSTANCE;
    }

    constructor() {
        const that = this;

        this.createWrapperForDomSelectorFunction = this.createWrapperForDomSelectorFunction.bind(this);
        this.screenCapturing = this.iterateAndReplaceFunctions({ ...screen }, this.createWrapperForDomSelectorFunction);

        this.assertWaitable = this.iterateAndReplaceFunctions({ ...assert }, (functionName, oldFunction) => {
            return async function (...args: any[]) {
                // args[1] = the value (e.g. for equals); args[2] = the comment
                let firstArg = args[1];
                if (typeof firstArg === "object") {
                    firstArg = "Object (" + firstArg.constructor.name + "): " + Utils.consoleLogJson(args[1], false);
                }
                // await showSpotlight({ focusOnLastElementCaptured: true, message: functionName + " " + args[1] + (args[2] ? " " + args[2] : "") });
                await that.showSpotlight({
                    focusOnLastElementCaptured: true,
                    message: <><Label color="green" content="Testing" /><Label content={functionName} />{firstArg && <Label content={firstArg} />}</>
                });
                oldFunction.apply(null, args);
            }
        });

        this.createWrapperForActionFunction = this.createWrapperForActionFunction.bind(this);
        this.userEventWaitable = this.iterateAndReplaceFunctions({ ...userEvent }, this.createWrapperForActionFunction);
        this.fireEventWaitable = this.iterateAndReplaceFunctions({ ...fireEvent }, this.createWrapperForActionFunction);
        this.drag = this.createWrapperForActionFunction("drag", dragRaw);
    }

    protected iterateAndReplaceFunctions(target: any, callback: (functionName: string, oldFunction: Function) => (Function | null), shouldReplaceFunctionCallback?: (functionName: string) => boolean) {
        for (const functionName in target) {
            // Not all the functions should be replaced.
            // For example, in getObjectViaShortcutRefForTestWaitable we would have not only functions, but also props. They should
            // not be replaced. Also, all the functions that are not called explicitly from a test should not replaced because they would
            // become waitable.
            if (shouldReplaceFunctionCallback && !shouldReplaceFunctionCallback(functionName)) {
                continue;
            }
            const oldFunction = target[functionName];
            const newFunction = callback(functionName, oldFunction);
            if (!newFunction) {
                continue;
            }
            target[functionName] = newFunction;
        }
        return target;
    }

    protected createWrapperForActionFunction(functionName: string, oldFunction: Function, oldThis: any = null) {
        const that = this;
        return function (...args: any[]) {
            // for "type" for now
            let maybeStringArg = "";
            if (functionName === "type") {
                maybeStringArg = args[1];
            }
            const maybePromiseForWaiting = that.showSpotlight({
                focusOnLastElementCaptured: true,
                message: <><Label color="blue" content="Will perform action" /><Label content={functionName} />{maybeStringArg && <Label content={maybeStringArg} />}</>
            });
            if (!maybePromiseForWaiting) {
                // waiting is disabled
                return oldFunction.apply(oldThis, args);
            }
            // waiting is enabled
            const resultPromise = new Promise(resolve => {
                maybePromiseForWaiting.then(() => {
                    // waiting finished
                    const maybePromiseFromOldFunction = oldFunction.apply(oldThis, args);
                    if (maybePromiseFromOldFunction instanceof Promise) {
                        // we keep waiting; when the old promise returns, we'll finish the waiting as well
                        (maybePromiseFromOldFunction as Promise<any>).then(value => resolve(value));
                    } else {
                        // no more waiting; return now
                        resolve(maybePromiseForWaiting);
                    }
                });
            });

            if (functionName !== "type") {
                return resultPromise;
            } else {
                // for type, we add another wait, so that the user can review what was typed
                return new Promise(resolve => resultPromise.then(value =>
                    // ?. because it may be undefined if we started in "step by step" mode, and then, for "type",
                    // instead of clicking "next", we clicked "run normally"
                    that.showSpotlight({ focusOnLastElementCaptured: true, message: "was typed" + maybeStringArg })?.then(() => resolve(value))));
            }
        }
    };

    protected createWrapperForDomSelectorFunction(functionName: string, oldFunction: Function) {
        if (!this.getTestsAreDemoSlave()) {
            return oldFunction;
        }
        const that = this;
        if (functionName.startsWith("get") || functionName.startsWith("query")) {
            return function (...args: any[]) {
                const result: any = oldFunction.apply(null, args);
                let captured = result;
                if (Array.isArray(result)) {
                    captured = result[0];
                }
                that.getTestsAreDemoSlave()!.setLastElementCaptured(captured);
                return result;
            }
        } else if (functionName.startsWith("find")) {
            return function (...args: any[]) {
                const promise: Promise<any> = oldFunction.apply(null, args);
                promise.then(result => {
                    let captured = result;
                    if (Array.isArray(result)) {
                        captured = result[0];
                    }
                    that.getTestsAreDemoSlave()!.setLastElementCaptured(captured);
                    return captured; // I don't know if this is taken into account / chained to the next "then" or so
                });
                return promise;
            }
        } else {
            return null;
        }
    }

    /**
     * This requires @testing-library/dom >= 8.13. Because older versions only have 1 generic param.
     */
    withinCapturing<QueriesToBind extends Queries = typeof queries, T extends QueriesToBind = QueriesToBind>(element: HTMLElement, queriesToBind?: T): BoundFunctions<T> {
        const original = within<QueriesToBind, T>(element, queriesToBind);
        const result = this.iterateAndReplaceFunctions({ ...original }, this.createWrapperForDomSelectorFunction);
        return result;
    }

    showSpotlight(params?: SpotlightParams | string) {
        if (!this.getTestsAreDemoSlave()?.stepByStep) { // ?. includes also when !TADSlave
            return undefined;
        }
        if (this.getTestsAreDemoSlave()!.master.state.endUser) {
            if (this.getTestsAreDemoSlave()!.demoForEndUser === DemoForEndUser.HIDE) {
                return undefined;
            } else if (this.getTestsAreDemoSlave()!.demoForEndUser === DemoForEndUser.HIDE_NEXT) {
                this.demoForEndUserShow();
                return undefined;
            }
        }
        if (typeof params === "string") {
            params = { message: params } as SpotlightParams; // the cast is not mandatory; I put it for readability
        }

        if (!params) {
            // This would be the case when we capture a slide and want to show only the content of the slide.
            params = { message: "", focusOnLastElementCaptured: true }
        }

        return new Promise(resolve => this.getTestsAreDemoSlave()!.showSpotlight((params as SpotlightParams)!, resolve));
    }

    /**
     * Initially this was using `waitFor` (which is reexported by us as `waitForWhileException`). However, on timeout, the output message was
     * not readable. Hence it is reimplemented.
     * 
     * @param callback While the callback returns a truthish value => the wait will continue. When the callback returns falsy => the waiting will end.
     * @param options Because it's a reimplementation, only a few of the original options are supported.
     */
    async waitForWhile(callback: () => Promise<boolean | undefined | null> | boolean | undefined | null, options?: waitFor_options) {
        await FoundationUtils.waitForWhile(callback, options);
    }

    protected nextTimeout = 300;

    waitForCommunicationFinishedNextTimeoutTemp(millis: number) {
        this.nextTimeout = millis;
    }

    async waitForCommunicationFinished(options?: waitFor_options) {
        await FoundationUtils.setTimeoutAsync(this.nextTimeout);
        this.nextTimeout = 300;
        // // will be set to null when we'll see communication in progress
        // // so it will be valid until we'll see comm in progress
        // let invokedAt: number | null = Date.now();
        // await this.waitForWhile(() => {
        //     if (apolloClientHolder.communicationInProgress) {
        //         invokedAt = null;
        //     }
        //     return apolloClientHolder.communicationInProgress // is communicating
        //         // not communicating, but finished recently. Let a bit of time, because maybe after the finish of an async call, 
        //         // other calls will start, e.g. due to change of state + update/redraw
        //         || Date.now() - apolloClientHolder.lastCommunicationEndedAt < 250
        //         // not yet started to communicate
        //         || invokedAt !== null && Date.now() - invokedAt < 250
        // }, options);
    }

    enableStepByStep() {
        this.getTestsAreDemoSlave()?.enableStepByStepProgrammatically();
    }

    /**
     * In some cases, simulating some actions and/or performing some verifications, can be exponentially difficult. In such
     * cases we can "cheat". I.e. within the tested component we expose an object via `<TestsAreDemoCheat>`. Within the test,
     * we use this function to retrieve the object. And then we use this object, either to perform actions, or to retrieve values.
     * 
     * If the component is a class component, we usually expose it directly, via `this`. For functional components, an intermediate object
     * is used. We could have make it "shorter" to use, but we would have lost typing, thus IDE navigation via CTRL + click. And
     * besides, it's quick to create such an object.
     * 
     * Which functions should we call within the object? Often they exist already. In rare cases we might need to add new functions
     * specially for the test. We recommend prefixing them: `tad...()`, and adding a comment. This is to make it clear that these
     * functions are needed only by the test.
     * 
     * So, if you "cheated" specially for the test, by publishing an object via `<TestsAreDemoCheat>`, you can
     * retrieve it w/ this function. Generally a `data-testid` is not needed, because one is generated from the class name.
     * However the data-testid can be customized via `dataTestIdSuffix`.
     * 
     * This function is generally used to retrieve values (that we will then verify via `assert`). It is not blocking/async.
     * 
     * @param withinElement Where to look for the "cheat". Default = `<body>`.
     * 
     * @see getObjectViaCheatWaitable() for the "waitable" version, used for performing actions. 
     */
    getObjectViaCheat<T>(clazz: new (...args: any) => T, withinElement?: HTMLElement): T;

    /**
     * @see Doc in the other overload of this function.
     */
    getObjectViaCheat<T>(clazz: new (...args: any) => T, dataTestIdSuffix?: string, withinElement?: HTMLElement): T;

    getObjectViaCheat<T>(clazz: new (...args: any) => T, dataTestIdSuffix?: string | HTMLElement, withinElement?: HTMLElement): T {
        if (dataTestIdSuffix instanceof HTMLElement) {
            withinElement = dataTestIdSuffix;
            dataTestIdSuffix = undefined;
        }
        const dataTestId = ClassToNameRegistry.INSTANCE.getClassName(clazz) + (dataTestIdSuffix ? "_" + dataTestIdSuffix : "");
        const element = getByTestId(withinElement || document.body, dataTestId) as HTMLElement & { testsAreDemoCheat: T };
        this.getTestsAreDemoSlave()?.setLastElementCaptured(element.parentElement);
        return element.testsAreDemoCheat;
    }

    /**
     * Look at the doc of `getObjectViaCheat()`.
     * 
     * Use this function to simulate performing actions that are hard to simulate the "normal" way.
     * 
     * This function returns a proxy around the original object. All the wrapped functions are "waitable". I.e. they are async,
     * and during the "step by step" mode, the execution is halted, and the user will see a spot light saying "performing action ...".
     * The program waits for the user to click on "Next".
     * 
     * @see getObjectViaCheat() for the "non-waitable" version, used for retrieving values for verification/asserting.
     */
    getObjectViaCheatWaitable<T>(clazz: new (...args: any) => T, userUnderstandableAction: string, withinElement?: HTMLElement): ConvertReturnToPromiseForAll<T>;

    /**
     * @see Doc in the other overload of this function.
     */
    getObjectViaCheatWaitable<T>(clazz: new (...args: any) => T, userUnderstandableAction: string, dataTestIdSuffix?: string, withinElement?: HTMLElement): ConvertReturnToPromiseForAll<T>;

    getObjectViaCheatWaitable<T>(clazz: new (...args: any) => T, userUnderstandableAction: string, dataTestIdSuffix?: string | HTMLElement, withinElement?: HTMLElement): ConvertReturnToPromiseForAll<T> {
        // @ts-ignore
        const object: any = this.getObjectViaCheat(clazz, dataTestIdSuffix, withinElement);
        const that = this;

        return new Proxy(object, {
            get(target, p: string, receiver) {
                return that.createWrapperForActionFunction(userUnderstandableAction, target[p], target);
            },
        }) as any;
    }


    /**
     * When we'll delete it, delete also TADCheat.className. Also this.elementToObjectViaShortcutRefForTest. 
     * 
     * @deprecated
     */
    getObjectViaShortcutRefForTestWaitable<T>(clazz: new (...args: any) => T, dataTestIdSuffix?: string): T {
        const dataTestId = ClassToNameRegistry.INSTANCE.getClassName(clazz) + (dataTestIdSuffix ? "_" + dataTestIdSuffix : "");
        const captured = getByTestId(document.body, dataTestId) as HTMLElement & { testsAreDemoCheat: T };
        this.getTestsAreDemoSlave()?.setLastElementCaptured(captured);

        // we should not replace the functions for the same element multiple times
        let result = this.elementToObjectViaShortcutRefForTest.get(captured);
        if (!result) {
            result = (this.iterateAndReplaceFunctions(captured.testsAreDemoCheat, this.createWrapperForActionFunction,
                functionName => functionName.startsWith("tad")));
            this.elementToObjectViaShortcutRefForTest.set(captured, result);
        }
        return result;
    }

    /**
     * We see little or no interest to use this directly.
     * 
     * @see renderWrappedWithPropsSetter()
     */
    wrapWithPropsSetter<T>(t: T, callback: (t: T) => ReactNode) {
        let result = {
            Component: () => {
                const [state, setState] = useState(t);
                result.setProps = setState;
                return <>{callback(state)}</>;
            },
            // when the caller will use this, it won't be null any more. And we don't want the caller
            // to always use !. Because ? would defeat the purpose.
            setProps: null as unknown as ((t: T) => void)
        }
        return result;
    }

    /**
     * With this, in tests, you may want to modify a prop of the component that is rendered. Example:
     * 
     * ```js
     * const { setProps } = tad.renderWrappedWithPropsSetter({ myProp: "myValue"}, props => <MyComponent something={props.myProp} />)
     * // code for test
     * setProps({ myProp: "myOtherValue" });
     * // other code for test
     * ```
     * 
     * It works by wrapping the callback w/ a component. And the state of that wrapper is copied to the props of the component.
     * 
     * NOTE: the callback that you provide may also be a functional component. However, the function will be called (i.e. used
     * as a render function); it won't be used as an element.
     * 
     * Before this function either 1/ a new call to render() would have been needed, or 2/ the component would have needed to offer "shortcut"
     * functions. 
     * 
     * 1/ is (still) OK to use, but it's rather suited for small tests. For a TestsAreDemo "story", the function is longer, and rerenders
     * are not possible, because it may break the slide show presented to the user.
     * 2/ is not recommended. I.e. don't create shortcut functions that double the props.
     */
    renderWrappedWithPropsSetter<T>(t: T, callback: (t: T) => ReactNode, renderOptions?: CustomRenderOptions): RenderWrappedWithPropsSetterResult<T> {
        const withSetter = tad.wrapWithPropsSetter(t, callback);
        return { ...render(<withSetter.Component />, renderOptions), setProps: withSetter.setProps };
    }

    /**
     * Stores the given comment. And then it will be displayed during the next "waiting",  i.e. functions from `tad.*Waitable`. 
     * E.g. `tad.assertWaitable.equal(...)` or `tad.userEventWaitable.click(...)`. Such functions invoke `showSpotlight()` who
     * actually looks at the comment. So invoking explicitly `showSpotlight()` also uses the current comment.
     */
    currentComment(comment: ReactNode) {
        if (!this.getTestsAreDemoSlave()) {
            return;
        }
        this.getTestsAreDemoSlave()!.currentComment = comment;
        this.getTestsAreDemoSlave()!.currentCommentSlideIndex = undefined;
    }

    /**
     * Alias for `currentComment()`.
     * 
     * @see currentComment()
     */
    cc(comment: ReactNode) {
        this.currentComment(comment);
    }

    async loadSlides(url: string) {
        const mdFile = await fetch(url);
        this.slides = new TestsAreDemoSlideShow(await mdFile.text());
    }

    /**
     * Idem as w/ `currentComment()`. But the comments are taken from the "slides" that are found in the `.md` file
     * loaded by `loadSlides()` (which should of course be invoked previously).
     * 
     * A validation is in place to avoid some possible mistakes. E.g. you cannot do things like:
     * 
     * ```ts
     * cs(10);
     * cs(10); // error, slide was already used
     * 
     * cs(10);
     * cs(12); // error, slide 11 was skipped
     * ```
     * 
     * @see currentComment()
     */
    currentSlide(index: number) {
        if (!this.slides) {
            throw new Error("Slides not loaded. Use 'loadSlides()'.");
        }
        this.slides.slide(index);
    }

    /**
     * Alias for `currentSlide()`.
     * 
     * @see currentSlide()
     */
    cs(index: number) {
        this.currentSlide(index);
    }

    /**
     * The demo / step by step mode is used either by a dev, or by an end user. The difference is that for an
     * end user we might want to hide some steps, because they may be boring (although important for testing
     * and for a dev).
     * 
     * To hide steps (during the "end user" mode), call this function. All the next steps will be ran, but they
     * won't show the spotlight + popup in the UI. To cancel this "hiding", call `demoForEndUserShow()`.
     * 
     * When a new test starts, the "hiding" reverts to the default, i.e. not "hiding".
     * 
     * @see demoForEndUserShow()
     * @see demoForEndUserHideNext()
     */
    demoForEndUserHide() {
        if (!this.getTestsAreDemoSlave()) {
            return;
        }
        this.getTestsAreDemoSlave()!.demoForEndUser = DemoForEndUser.HIDE;
        this.getTestsAreDemoSlave()!.demoForEndUserSavedCurrentCommentBeforeHide = this.getTestsAreDemoSlave()!.currentComment;
    }

    /**
     * Same as `demoForEndUserHide(), but the "hiding" deactivates automatically after the next step. So calling
     * `demoForEndUserShow()` is not needed.
     * 
     * @see demoForEndUserHide()
     * @see demoForEndUserShow()
     */
    demoForEndUserHideNext() {
        if (!this.getTestsAreDemoSlave()) {
            return;
        }
        this.getTestsAreDemoSlave()!.demoForEndUser = DemoForEndUser.HIDE_NEXT;
        this.getTestsAreDemoSlave()!.demoForEndUserSavedCurrentCommentBeforeHide = this.getTestsAreDemoSlave()!.currentComment;
    }

    /**
     * Cancels/disables the effect of `demoForEndUserHide()`.
     * 
     * @see demoForEndUserHide()
     * @see demoForEndUserHideNext()
     */
    demoForEndUserShow() {
        if (!this.getTestsAreDemoSlave()) {
            return;
        }
        this.getTestsAreDemoSlave()!.demoForEndUser = DemoForEndUser.SHOW;
        this.getTestsAreDemoSlave()!.currentComment = this.getTestsAreDemoSlave()!.demoForEndUserSavedCurrentCommentBeforeHide;
        this.getTestsAreDemoSlave()!.demoForEndUserSavedCurrentCommentBeforeHide = null;
    }

    /**
     * This is the way to "tell" TAD which are your classes that contain TestsAreDemo tests/scenarios. I.e. functions annotated
     * w/ `@Scenario`. 
     */
    addTests(...testClasses: ParamFor_addTest[]) {
        const tests: TestClassDescriptor[] = [];
        const testClassDescriptorsLoadingPromise = new Promise<void>(async resolve => {
            for (let testClass of testClasses) {
                await this.createTestClassDescriptor(tests, undefined, testClass);
            }
            this.getTestsAreDemoSlave()?.master.setState({ testClassDescriptors: tests });
            resolve();
        });
        this.getTestsAreDemoSlave()?.master.setState({ testClassDescriptorsLoadingPromise });
    }

    async createTestClassDescriptor(descriptors: TestClassDescriptor[], parentsPrefix: string | undefined, testClassOrObject: ParamFor_addTest) {
        const testClass = testClassOrObject;
        const test: ITestsAreDemoTest & Record<string, any> = new testClass();

        // when minified, testClass.name will be gibberish :(
        const name = test.name || testClass.name;
        const qualifiedName = parentsPrefix ? parentsPrefix + "." + name : name;
        const tcd: TestClassDescriptor = { testClass, name: qualifiedName, functions: [], hooks: [] };

        const proto = Object.getPrototypeOf(test);
        // this will create it if not existing; we need this below, for the case of "pseudo" annotations
        const extensions: ExtensionsFromDecorators = getOrCreateExtensions(proto);

        // let's find the functions defined w/ @DemoComponent
        // we do an additional iteration here because othewise we'd need to do 1/ the iteration below (for functions 
        // in the prototype, i.e. defined as func() {...}, and 2/ another iteration on the obj instance, for functions
        // defined as func = () => ...)
        for (const key in extensions) {
            const extension = extensions[key];
            if (!extension.isDemoComponent) {
                continue;
            }
            const tcdComp: TestClassDescriptor = { ...tcd, demoComponent: test[key](), name: tcd.name + " > " + (extension.scenario || "Demo") };
            descriptors.push(tcdComp);

            continue;

        }

        descriptors.push(tcd);

        const properties = Object.getOwnPropertyNames(proto);
        let annotationFunctionForNextProperty: Function | undefined;
        let groupProperties: string[] = [];
        for (let i = 0; i < properties.length; i++) {
            const property = properties[i];
            if (typeof test[property] !== "function" || property === "constructor") {
                continue;
            }
            const func: Function = test[property];

            // is this function a "pseudo" annotation?
            if (property === "annotation") {
                // pseudo annotation for class
                setGlobalsForPseudoAnnotations(proto, EXTENSIONS_FOR_CLASS);
                func.apply(test);
                continue;
            } else if (property.startsWith("annotation")) {
                // pseudo annotation for property
                annotationFunctionForNextProperty = func;
                continue;
            }

            if (property === "before" || property === "after" || property === "beforeEach" || property === "afterEach") {
                tcd.hooks!.push(property);
                continue;
            }

            if (annotationFunctionForNextProperty) {
                setGlobalsForPseudoAnnotations(proto, property);
                annotationFunctionForNextProperty.apply(test);
            }

            const extension: ExtensionFromDecorators | undefined = extensions[property];

            if (property.startsWith("group")) {
                // we'll deal w/ groups at the end 
                groupProperties.push(property);
                continue;
            }

            if (!extension || extension.isDemoComponent || extension.scenario === undefined) { // "scenario" is used as title for demo comp
                // i.e. the function doesn't have the @Scenario annotation
                continue;
            }
            // if we get rid of @Comment, we'll have only one comment. And we should get it via the promise
            await extension.retrieveCommentFromTsDocFromSourceMap?.();
            tcd.functions.push({ functionName: property, scenario: extension.scenario, comments: extension.comments })
        }
        setGlobalsForPseudoAnnotations(undefined, undefined);

        // processing stuff for the class at the end because maybe there were pseudo annotations
        tcd.comments = extensions[EXTENSIONS_FOR_CLASS]?.comments;

        // now we deal w/ groups
        for (let property of groupProperties) {
            await this.createTestClassDescriptor(descriptors, qualifiedName, test[property]());
        }
    }

    addTestsFromDescriptors(descriptors?: TestClassDescriptor[], testsToRun?: TestsToRun, puppeteer?: boolean) {
        const executableDescriptors: TestClassDescriptor[] = [];
        const addAll = puppeteer && !testsToRun; // else, if puppeteer && testsToRun => probably during dev of TAD (to enable only a few tests); in "prod", such a situation shouldn't exist
        if (!descriptors || !addAll && !testsToRun) {
            return;
        }
        for (let descriptor of descriptors) {
            if (!addAll) {
                // normal mode, from UI
                if (!testsToRun![descriptor.name] || Object.getOwnPropertyNames(testsToRun![descriptor.name]).length === 0) {
                    continue;
                }
            } // else, if we are in "add all mode"/puppeteer, don't look at testsToRun
            if (typeof descriptor.testClass !== "function") {
                // not a test; but a collection of demo components
                continue;
            }
            describe(descriptor.name, () => {
                const newDescriptor: TestClassDescriptor = { ...descriptor, functions: [] };
                executableDescriptors.push(newDescriptor);

                const test = new descriptor.testClass();
                for (let property of descriptor.hooks!) {
                    const boundFunc = (test[property] as Function).bind(test);
                    if (property === "before") { before(boundFunc); }
                    else if (property === "after") { after(boundFunc); }
                    else if (property === "beforeEach") { beforeEach(boundFunc); }
                    else if (property === "afterEach") { afterEach(boundFunc); }
                    else { throw new Error("Unexpected hook: " + property); }
                }

                for (let functionDescriptor of descriptor.functions) {
                    if (!addAll && !testsToRun![descriptor.name][functionDescriptor.functionName]) {
                        continue;
                    }
                    newDescriptor.functions.push(functionDescriptor);
                    const boundFunc = (test[functionDescriptor.functionName] as Function).bind(test);
                    it(functionDescriptor.functionName, boundFunc);
                }
            });
        }
        return executableDescriptors;
    }

    // protected addTest(parentsPrefix: string | undefined, testClass: any, tests: TestClassDescriptor[], only?: boolean) {
    //     // when minified, testClass.name will be gibberish :(
    //     const qualifiedName = parentsPrefix ? parentsPrefix + "." + testClass.name : testClass.name;
    //     const tcd: TestClassDescriptor = { name: qualifiedName, functions: [] };
    //     tests.push(tcd);

    //     const desc = only ? describe.only : describe;

    //     desc(qualifiedName, () => {
    //         const test = new testClass();
    //         const proto = Object.getPrototypeOf(test);
    //         // this will create it if not existing; we need this below, for the case of "pseudo" annotations
    //         const extensions: ExtensionsFromDecorators = getOrCreateExtensions(proto);

    //         const properties = Object.getOwnPropertyNames(proto);
    //         const hasGroups = properties.some(property => property.startsWith("group"));
    //         const functionsThatNeedOnly = this.getFunctionsThatNeedOnly(extensions, properties);
    //         let annotationFunctionForNextProperty: Function | undefined;
    //         for (let i = 0; i < properties.length; i++) {
    //             const property = properties[i];
    //             if (typeof test[property] !== "function" || property === "constructor") {
    //                 continue;
    //             }
    //             const func: Function = test[property];

    //             // is this function a "pseudo" annotation?
    //             if (property === "annotation") {
    //                 // pseudo annotation for class
    //                 setGlobalsForPseudoAnnotations(proto, EXTENSIONS_FOR_CLASS);
    //                 func.apply(test);
    //                 continue;
    //             } else if (property.startsWith("annotation")) {
    //                 // pseudo annotation for property
    //                 annotationFunctionForNextProperty = func;
    //                 continue;
    //             }

    //             const boundFunc = (func as Function).bind(test);
    //             if (property === "before") { before(boundFunc); }
    //             else if (property === "after") { after(boundFunc); }
    //             else if (property === "beforeEach") { beforeEach(boundFunc); }
    //             else if (property === "afterEach") { afterEach(boundFunc); }
    //             else {
    //                 if (annotationFunctionForNextProperty) {
    //                     setGlobalsForPseudoAnnotations(proto, property);
    //                     annotationFunctionForNextProperty.apply(test);
    //                 }
    //                 const extension: ExtensionFromDecorators | undefined = extensions[property];
    //                 if (property.startsWith("group")) {
    //                     this.addTest(qualifiedName, test[property](), tests, extension?.options?.runOnlyThisScenario);
    //                     continue;
    //                 }
    //                 if (extension?.scenario === undefined) {
    //                     // i.e. the function doesn't have the @Scenario annotation
    //                     continue;
    //                 }
    //                 tcd.functions.push({ functionName: property, scenario: extension?.scenario, comments: extension?.comments })
    //                 const addIt = () => {
    //                     if (functionsThatNeedOnly[i]) {
    //                         it.only(property, boundFunc);
    //                     } else {
    //                         it(property, boundFunc);
    //                     }
    //                 }
    //                 if (hasGroups) {
    //                     describe(qualifiedName + AUTO_CREATED_GROUP_PREFIX, addIt);
    //                 } else {
    //                     addIt();
    //                 }
    //             }
    //             setGlobalsForPseudoAnnotations(undefined, undefined);
    //         }

    //         // processing stuff for the class at the end because maybe there were pseudo annotations
    //         tcd.comments = extensions[EXTENSIONS_FOR_CLASS]?.comments;
    //     });
    // }

    /**
     * We mark an it w/ `.only` if it was marked w/ @Only. But in this case, we also mark the
     * previous or next dependents (regardless if they have @Only or not).
     */
    protected getFunctionsThatNeedOnly(extensions: ExtensionsFromDecorators, properties: string[]) {
        const result = new Array<boolean>(properties.length);
        const thisScenarioNeedsPrevious = new Array<boolean | undefined>(properties.length);
        let previousScenarioNeedsNext: boolean | undefined = false;
        // example: Only + NeedsNext, NN, -, NN, -, O + NN, NN, NN, O
        for (let i = 0; i < properties.length; i++) {
            const extension: ExtensionFromDecorators | undefined = extensions?.[properties[i]];
            if (extension?.options?.runOnlyThisScenario || previousScenarioNeedsNext) {
                result[i] = true;
                previousScenarioNeedsNext = extension?.options?.linkWithNextScenario;
            } // else if current scenario is not selected, either explicitly or via NN => we don't care if it has NN or not

            if (i < properties.length - 1) {
                // remember the reverse link
                thisScenarioNeedsPrevious[i + 1] = extension?.options?.linkWithNextScenario;
            } // else last one; so no need
        }

        // we iterate now in reverse, to discover "cascading" based on next which needs previous
        let nextScenarioNeedPrevious: boolean | undefined = false;
        for (let i = properties.length - 1; i >= 0; i--) {
            const extension: ExtensionFromDecorators | undefined = extensions?.[properties[i]];
            // I don't think we need to look if the previous iteration generated some "indirect" onlys based on cascading
            if (extension?.options?.runOnlyThisScenario || nextScenarioNeedPrevious) {
                result[i] = true;
                nextScenarioNeedPrevious = thisScenarioNeedsPrevious[i];
            } // else idem/symmetric cf. above
        }
        return result;
    }

}

export let tad: TestsAreDemoFunctions;

export function setTad(value: TestsAreDemoFunctions) {
    if (window.testsAreDemoMaster) {
        // we are in the master frame; TADFunctions shouldn't be called from here; hence adding a dummy object that always throws errors
        const handler = {
            get(target: any, prop: any, receiver: any) {
                throw new Error("Attempting to use 'tad' outside the slave IFrame (which runs the test). Please review the stack trace to understand who's doing this illegal call.");
            },
        }
        tad = new Proxy({}, handler);
    } else {
        tad = value;
    }
}

export function createTestids<T>(prefix: String, testids: T): T {
    for (let k in testids) {
        // @ts-ignore
        testids[k] = prefix + "_" + k;
    }
    return testids;
}