import React from "react";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import moment from "moment";

export interface ClockProps {
    format?: string,
    interval?: number,
}

export class Clock extends React.Component<ClockProps, { currentTime: any }> {

    static defaultProps = {
        interval: 1000
    }

    private timer: number | undefined = undefined;

    constructor(props: ClockProps) {
        super(props);
        this.state = { currentTime: Utils.now() };
    }

    private startTimer() {
        this.timer = window.setTimeout(() => {
            this.setState({ currentTime: Utils.now() });
            this.startTimer();
        }, this.props.interval);
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    render() {
        return <>{moment(this.state.currentTime).format(this.props.format || Utils.timeFormat)}</>;
    }

}
"../../utils/Utils"