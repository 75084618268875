import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { addAfterStartupRunnable } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTablePage, EntityTablePagePartialProps, EntityTablePageReducers, EntityTablePageState } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { QueryOptions } from "apollo-client";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { CustomFindByFilterParams } from "./NotificationComponent";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export let notificationEntityDescriptor: EntityDescriptor;
export let problemEntityDescriptor: EntityDescriptor;

class NotificationTablePageState extends EntityTablePageState {
    hideRead: boolean = true;
    hideIgnoredCodes: boolean = true;
}
export class NotificationTablePageReducers<S extends NotificationTablePageState = NotificationTablePageState> extends EntityTablePageReducers<S> {
}

type NotificationTablePageProps = RRCProps<NotificationTablePageState, NotificationTablePageReducers> & EntityTablePagePartialProps;

class NotificationTablePage<P extends NotificationTablePageProps = NotificationTablePageProps> extends EntityTablePage<P> {
    loadParamType = "CustomFindByFilterParamsInput";

    async invokeLoadQuery(options: QueryOptions<FindByFilterParams>) {
        const { hideIgnoredCodes, hideRead } = this.props.s;
        options.variables = CustomFindByFilterParams.create().setParams(options.variables!.params).setAdditionalFields({ hideIgnoredCodes, hideRead, onlyRead: false, userId: this.context.initializationsForClient.currentUser?.id });
        return await super.invokeLoadQuery(options);
    }

    private onCheckboxChange = (field: string) => (e: any, data: CheckboxProps) => {
        this.props.r.setInReduxState({ [field]: data.checked });
        this.refresh();
    };

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        return [
            ...super.preRenderButtons(params),
            { elementType: Checkbox, props: { key: "hideRead", checked: this.props.s.hideRead, label: _msg("Notification.hideRead"), onChange: this.onCheckboxChange("hideRead") } },
            { element: <>&nbsp;</> },
            { elementType: Checkbox, props: { key: "hideIgnoredCodes", checked: this.props.s.hideIgnoredCodes, label: _msg("Notification.hideIgnoredCodes"), onChange: this.onCheckboxChange("hideIgnoredCodes") } },
        ];
    }
};

const NotificationTablePageRRC = ReduxReusableComponents.connectRRC(NotificationTablePageState, NotificationTablePageReducers, NotificationTablePage);

addAfterStartupRunnable(() => {

    problemEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "Problem",
        icon: "bell outline",
        miniFields: ["id"]
    }, false)
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "creationDate", type: FieldType.date })
        .addFieldDescriptor({ name: "entityName", type: FieldType.string })
        .addFieldDescriptor({ name: "entityId", type: FieldType.number })
        .addFieldDescriptor({ name: "code", type: FieldType.string })
        .addFieldDescriptor({ name: "severity", type: FieldType.string })
        .addFieldDescriptor({ name: "description", type: FieldType.string })
        .addFieldDescriptor({ name: "organization", type: "Organization" })
    );

    class NotificationEntityDescriptor extends EntityDescriptor {

        constructor() {
            super({
                name: "Notification",
                icon: "bell",
                miniFields: ["id"]
            }, false);
        }

        renderTable() {
            return <NotificationTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
        }

    }

    notificationEntityDescriptor = addEntityDescriptor(new NotificationEntityDescriptor()
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "creationDate", type: FieldType.date })
        .addFieldDescriptor({ name: "entityName", type: FieldType.string })
        .addFieldDescriptor({ name: "entityId", type: FieldType.number })
        .addFieldDescriptor({ name: "code", type: FieldType.string })
        .addFieldDescriptor({ name: "severity", type: FieldType.string })
        .addFieldDescriptor({ name: "description", type: FieldType.string })
        .addFieldDescriptor({ name: "deletionDate", type: FieldType.date })
        .addFieldDescriptor({ name: "organization", type: "Organization" })
    );

});"../../components/TabbedPage/TabbedPage""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/EntityTablePage""../../entity_crud/FieldType""../../entity_crud/FindByFilterParams""../../reduxReusableComponents/ReduxReusableComponents"