import { Capture } from "./Capture";
import type { ScriptableUi } from "./ScriptableUi";
import { ScriptableUiHighlightWrapper } from "./ScriptableUiHighlightWrapper";
import { Scriptable } from "./v2/Scriptable";

export class ScriptableUiInterceptor {

    /**
     * We store the interceptor in the window (global) and not as a static. For the case where there are multiple libs included.
     * Actually we have a big warning message for this. However, it happens also for the case of scriptable-ui-app (for demo/tests).
     * scriptable-ui comes also from TAD, who installs an interceptor to couple its "step by step" mode / next button to running recorded
     * script instructions in step by step mode.
     */
    static get INSTANCE(): ScriptableUiInterceptor | undefined {
        return globalThis.scriptableUiInterceptor;
    }
    
    static set INSTANCE(value: ScriptableUiInterceptor | undefined) {
        globalThis.scriptableUiInterceptor = value;
    }

    recording = false;

    install() {
        ScriptableUiInterceptor.INSTANCE = this;
    }

    uninstall() {
        ScriptableUiInterceptor.INSTANCE = undefined;
    }

    async onIntercept(capture: Capture, scriptableUi: ScriptableUi<any> | undefined, highlighWrapper: ScriptableUiHighlightWrapper | Scriptable): Promise<boolean> {
        if (this.recording) {
            const result = await highlighWrapper?.highlightAndConfirmRecordedInstruction(capture.getGeneratedSourceCode2());
            if (!result) {
                return false;
            } else {
                this.onRecordInstruction(capture);
                return true;
            }
        }
        return true;
    }

    onRecordInstruction(capture: Capture) {
        console.log(capture.getGeneratedSourceCode2());
    }
}