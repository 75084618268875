import { Utils } from "@crispico/foundation-react";
import { TraceMapCache } from "@famiprog-foundation/tests-are-demo/dist-lib/lib/TraceMapCache";
import { TestableTimers } from "../utils/TestableTimers";

// #region testable-timers-field1
export class ClientErrorLogger {
// #endregion

    queueCapacity = 10;
    retryIntervalMs = 15000;

    protected errorQueue: string[] | undefined;

    /**
     * Type is number. But there seem to be an error in the types. Or rather conflict w/ the function from node.js
     */
    protected retryTimeoutId?: any;

    protected sendInProgress = false;

    // #region testable-timers-field2
    timers = new TestableTimers();
    // #endregion

    constructor() {
        this.addToQueueAndTryToSend = this.addToQueueAndTryToSend.bind(this);
        this.sendFromQueue = this.sendFromQueue.bind(this);
        this.onError = this.onError.bind(this);
        this.onUnhandledRejection = this.onUnhandledRejection.bind(this);
    }

    public start() {
        if (this.errorQueue) {
            throw new Error("Cannot call start() twice");
        }
        this.errorQueue = [];
        window.addEventListener("error", this.onError);
        // when a promise was rejected (returns an error) cf. https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
        window.addEventListener("unhandledrejection", this.onUnhandledRejection);
        
        return this;
    }

    public stop() {
        window.removeEventListener("error", this.onError);
        window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
        this.errorQueue = undefined;
    }

    protected onError(e: ErrorEvent) {
        this.addToQueueAndTryToSend(e.error);
    }

    protected onUnhandledRejection(e: PromiseRejectionEvent) {
        this.addToQueueAndTryToSend(e.reason);
    }

    protected async addToQueueAndTryToSend(e: Error) {
        const error = await TraceMapCache.INSTANCE.decodeStackTrace(e);
        if (!error || !this.errorQueue || this.errorQueue.length >= this.queueCapacity) {
            return;
        }

        this.errorQueue.push(error);
        this.sendFromQueue();
    }

    protected async sendFromQueue() {
        if (!this.errorQueue || this.sendInProgress) {
            // the previous error is "in flight". So the previous call to sendQueue() is "paused". We don't do anything. When
            // it will be back, the loop will find the newly added error in the queue and send it also
            return;
        }
        // if a timer is in progress, let's stop it; we don't want it to interfere during the async call below
        this.clearTimeout();
        // in theory (and in practice also I hope), if we enter this function, we have at least one error. Even
        // for the timer.
        do {
            const error = this.errorQueue[0];
            try {
                this.sendInProgress = true;
                await this.sendError(error);
            } catch {
                this.setTimeout();
                this.sendInProgress = false;
                return;
            } finally {
                this.sendInProgress = false;
            }
            // send to server succeeded
            this.errorQueue = this.errorQueue.slice(1);
        } while (this.errorQueue.length);
    }

    protected async sendError(error: string) {
        const url = Utils.adjustUrlToServerContext('clientErrorLogger/logClientError');
        await fetch(url!, { method: 'PUT', body: error });
    }

    protected setTimeout() {
        // #region testable-timers-use
        this.retryTimeoutId = this.timers.setTimeout(this.sendFromQueue, this.retryIntervalMs);
        // #endregion testable-timers-use
    }

    protected clearTimeout() {
        this.retryTimeoutId =  this.timers.clearTimeout(this.retryTimeoutId);
    }
}
"./.."