import { ScriptableUi } from "./ScriptableUi";

export class Capture {

    constructor(public componentName: string, public id: string | undefined, public functionName: string, public args?: any[], public highlightHelperId?: string | number) {
    }

    getTestId() {
        return this.componentName + "_" + this.id + "_" + this.functionName;
    }

    getGeneratedSourceCode() {
        const args = this.args?.map(arg => {
            if (typeof arg === "string") {
                return '"' + arg + '"';
            } else {
                return arg
            }
        }).join(",");
        return `await uiApi${this.componentName}(${this.id ? this.id : ""}).dispatcher.${this.functionName}(${args});`;
    }

    getGeneratedSourceCode2() {
        const args = this.args?.map(arg => JSON.stringify(arg)).join(",");
        const id = this.id === ScriptableUi.SINGLETON ? "ScriptableUi.SINGLETON" : '"' + this.id + '"';
        return `    await s.get(${this.componentName}, ${id}, ${JSON.stringify(this.highlightHelperId)})\n        .${this.functionName}(${args});`;
    }
}

export class CaptureV2 extends Capture {
    
    constructor(qualifiedId: string, scriptableId: string | number, functionName: string, args: any[]) {
        super("", qualifiedId, functionName, args, scriptableId);
    }

    getGeneratedSourceCode2() {
        const args = this.args?.map(arg => JSON.stringify(arg)).join(",");
        return `    await (await s.p("${this.id}", ${JSON.stringify(this.highlightHelperId)}))\n        .${this.functionName}(${args});`;
    }
}