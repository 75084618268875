import React from "react";
import Measure from "react-measure";
import lodash from 'lodash';
import moment from "moment";
import { Button, Icon } from "semantic-ui-react";
import { Slider } from "antd";
import { Utils } from "@crispico/foundation-react";

type ZoomProps = {
    initialStartDate: number,
    initialEndDate: number,
    currentStartDate: number,
    currentEndDate: number,
    sliderZoomFactor?: number,
    sliderTooltipFormat?: (value: number) => string,
    onButtonAreResize?: (width: number) => void,
    onRangeChange?: (startDate: number, endDate: number) => void,
    style: { rightPadding: any }
};

type ZoomLocalState = {
    startDate: number,
    endDate: number
};

export class Zoom extends React.Component<ZoomProps, ZoomLocalState> {

    private sliderRef = React.createRef<any>();

    static defaultProps = {
        sliderZoomFactor: 0.5,
        style: { rightPadding: 0 }
    }

    constructor(props: ZoomProps) {
        super(props);

        this.onZoomIn = this.onZoomIn.bind(this);
        this.onZoomOut = this.onZoomOut.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.format = this.format.bind(this);

        this.state = { startDate: this.props.initialStartDate, endDate: this.props.initialEndDate };
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: ZoomProps, prevState: ZoomLocalState) {
        this.componentDidUpdateInternal(prevProps, prevState);
    }

    private componentDidUpdateInternal(prevProps?: ZoomProps, prevState?: ZoomLocalState) {
        if (!prevProps || !lodash.isEqual(prevProps.initialStartDate, this.props.initialStartDate) || !lodash.isEqual(prevProps?.initialEndDate, this.props.initialEndDate)) {
            this.setState({ startDate: this.props.initialStartDate, endDate: this.props.initialEndDate });
        }
        if (!prevProps || !lodash.isEqual(prevProps.currentStartDate, this.props.currentStartDate) || !lodash.isEqual(prevProps?.currentEndDate, this.props.currentEndDate)) {
            this.setState({ startDate: this.props.currentStartDate, endDate: this.props.currentEndDate });
        }
        if (prevState && (!lodash.isEqual(prevState.startDate, this.state.startDate) || !lodash.isEqual(prevState?.endDate, this.state.endDate))) {
            this.sliderRef.current?.setState({ bounds: [this.state.startDate, this.state.endDate] });
            this.props.onRangeChange?.call(null, this.state.startDate, this.state.endDate);
        }
    }

    private format(value: number | undefined) {
        if (!value) {
            return "";
        }
        if (this.props.sliderTooltipFormat) {
            return this.props.sliderTooltipFormat(value);
        }
        return moment(value).format(Utils.dateFormatShorter + " " + Utils.timeFormat);
    }

    private onSliderBtnResize(width: number | undefined) {
        if (width) {
            this.props.onButtonAreResize?.call(null, width);
        }
    }

    private getRange(type: "zoomIn" | "zoomOut" | "change", startDate: number, endDate: number, factor: number): number[] | undefined {
        const offset = Math.floor((endDate - startDate) * factor / 2);

        switch (type) {
            case "zoomIn": {
                startDate = startDate + offset;
                endDate = endDate - offset;
                if (startDate >= endDate || endDate - startDate < 60 * 1000) {
                    return undefined;
                }
                break;
            }
            case "zoomOut": {
                startDate = startDate - offset;
                endDate = endDate + offset;
                if (this.props.initialStartDate >= startDate || this.props.initialEndDate <= endDate) {
                    return undefined;
                }
                break;
            }
        }
        return [startDate, endDate];
    }

    private canZoomIn(startDate: number, endDate: number): boolean {
        return this.getRange("zoomIn", startDate, endDate, this.props.sliderZoomFactor!) !== undefined;
    }

    private canZoomOut(startDate: number, endDate: number): boolean {
        const values = this.getRange("zoomOut", startDate, endDate, this.props.sliderZoomFactor!);
        return values !== undefined && (values[0] !== startDate || values[1] !== endDate);
    }

    private onZoomIn() {
        const newValues = this.getRange("zoomIn", this.state.startDate, this.state.endDate, this.props.sliderZoomFactor!);
        if (newValues) {
            this.changeRange(newValues[0], newValues[1]);
        }
    }

    private onZoomOut() {
        const newValues = this.getRange("zoomOut", this.state.startDate, this.state.endDate, this.props.sliderZoomFactor!);
        if (newValues) {
            this.changeRange(newValues[0], newValues[1]);
        }
    }

    private onChange(values: any[]) {
        if (values !== undefined) {
            this.changeRange(values[0], values[1]);
        } else {
            this.sliderRef.current.setState({ bounds: [this.state.startDate, this.state.endDate] });
        }
    }

    private onReset() {
        this.changeRange(this.props.initialStartDate, this.props.initialEndDate);
    }

    private changeRange(startDate: number, endDate: number) {
        this.setState({ startDate: startDate, endDate: endDate });
    }

    render() {
        const that = this;
        const props = this.props;

        const rightPadding = props.style.rightPadding;
        const leftPadding = 10;

        // TODO CSR: ar trebui sa facem storybook (si TAD?) si pentru acest component
        return this.state.startDate < this.state.endDate &&
            <div className="flex-container-row flex-center" >
                {/* nu stiu exact ce face; dar intuiesc; si nu inteleg de ce am avea nevoie aici de measure */}
                <Measure bounds onResize={contentRect => that.onSliderBtnResize(contentRect.bounds?.width)}>
                    {({ measureRef }) => (<div className="flex-container-row" ref={measureRef}>
                        <Button size='mini' icon positive disabled={!this.canZoomIn(this.state.startDate, this.state.endDate)} onClick={this.onZoomIn}><Icon name='zoom-in' /></Button>
                        <Button icon size='mini' negative disabled={!this.canZoomOut(this.state.startDate, this.state.endDate)} onClick={this.onZoomOut}><Icon name='zoom-out' /></Button>
                        <Button icon size='mini' color="orange" onClick={this.onReset}><Icon name='remove circle' /></Button>
                    </div>)}
                </Measure>
                <Slider ref={this.sliderRef} style={{ width: "100%", right: rightPadding, marginLeft: leftPadding + rightPadding, marginRight: 0 }}
                    range
                    step={1} min={this.state.startDate} max={this.state.endDate}
                    defaultValue={[this.state.startDate, this.state.endDate]}
                    onAfterChange={this.onChange} tipFormatter={this.format} />
            </div>
    }
}"../.."