import React, { ReactNode } from "react";
import ReactSyntaxHighlighter from "react-syntax-highlighter";
import { Button, Container, Divider, Header, Icon, Label, Modal, Popup, Tab, Table } from "semantic-ui-react";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt";
import { Utils } from "../../copied/Utils";
import { LOCAL_STORAGE_CURRENT_CLASS_NAME, LOCAL_STORAGE_CURRENT_METHOD_NAME, TestsToRun } from "../TestsAreDemoMaster";
import { TestsTreeComponent } from "./TestsTreeComponent";
import { MarkdownExt } from "../MarkdownExt";
import { FoundationUtils } from "@famiprog-foundation/utils";
import { getTestToRunCountLabel } from "../featurebook/functions";

interface TestsAreDemoReport { elements: ReportElement[] }

interface ReportElement { type: string }
interface ReportElementWithText { text: string }
interface ReportElementHeading extends ReportElementWithText { }
interface ReportElementParagraph extends ReportElementWithText { }
interface ReportElementCode extends ReportElementWithText { language: string }

interface ReportElementTable extends ReportElement { rows: ReportElementTableRow[] }
interface ReportElementTableRow { cells: ReportElementTableCell[] }
interface ReportElementTableCell { text: string, color: number, comment: string }

export interface TestsTreeItem {
    kind: "CLASS" | "METHOD";
    // we need to review how the data is generated on Java, for old / non @Scenario based test
    // because for some cases, name comes null; that's why I added ?
    name?: string;
    description?: string;
    children: TestsTreeItem[];
    report?: TestsAreDemoReport;
    reportAsMarkdown?: string;
    failure?: string;

    // TODO: client only/temp?
    indent: number;
    parent?: TestsTreeItem;
}

interface Props {
    serverUrlPrefix: string;
    testsToRun?: TestsToRun
}

interface State {
    running?: boolean,
    serverError?: ReactNode,

    testsTreeRoot?: TestsTreeItem,
    linearizedItems?: TestsTreeItem[],
    selectedItem?: TestsTreeItem,

    linearizedItemsCurrent?: TestsTreeItem[],
    currentSelectedIndexAfterRun?: number;

    currentClassName?: string,
    currentMethodName?: string,
};

interface Result2 { foundIndex: number, foundItem: TestsTreeItem }

export class FeatureBookServer extends React.Component<Props, State> {

    state: Readonly<State> = {};

    // Odd. In VS code, it works w/ input: RequestInfo | URL. But not from command line build
    protected async fetchSafe(input: RequestInfo, init?: RequestInit): Promise<Response> {
        const result = await fetch(input, init);
        if (result.status === 500) {
            const json = await result.json();
            this.setState({ serverError: <><b>Path:</b> {json.path}<br /><b>Message:</b> {json.message}</> })
            throw new Error("Server error");
        }
        return result;
    }

    async componentDidMount() {
        let url = this.props.serverUrlPrefix + "testGoodiesController/getTestsTree";
        const testsTreeRoot: TestsTreeItem = await (await this.fetchSafe(url, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(this.props.testsToRun)
        })).json();
        const linearizedItems = this.linearizeNode(testsTreeRoot, [], -1);
        this.setState({ testsTreeRoot, linearizedItems });
        this.setCurrent(localStorage.getItem(LOCAL_STORAGE_CURRENT_CLASS_NAME), localStorage.getItem(LOCAL_STORAGE_CURRENT_METHOD_NAME));
        await this.runTests();
    }

    protected async runCurrent() {
        this.setState({ currentSelectedIndexAfterRun: undefined })

        let url = `${this.props.serverUrlPrefix}testGoodiesController/runTest?className=${this.state.currentClassName}`
        if (this.state.currentMethodName) {
            url += `&methodName=${this.state.currentMethodName}`;
        }

        const testsTreeRoot: TestsTreeItem = await (await this.fetchSafe(url)).json();
        const linearizedItems = this.linearizeNode(testsTreeRoot, [], -1);

        this.setState({ testsTreeRoot, linearizedItems });
        const result = this.setCurrent(this.state.currentClassName!, this.state.currentMethodName);
        this.setState({ selectedItem: result?.foundItem, currentSelectedIndexAfterRun: result?.foundIndex })
    }

    protected async runTests() {
        this.setState({ running: true });
        const testsTreeRoot: TestsTreeItem = await (await this.fetchSafe(
            `${this.props.serverUrlPrefix}testGoodiesController/runTests`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(this.props.testsToRun)
            }
        )).json();

        const linearizedItems = this.linearizeNode(testsTreeRoot, [], -1);

        const selectedItemIndex = this.state.selectedItem ? this.state.linearizedItems!.indexOf(this.state.selectedItem) : -1;
        this.setState({ testsTreeRoot, linearizedItems, selectedItem: linearizedItems[selectedItemIndex], running: false });
    }

    protected linearizeNode(item: TestsTreeItem, linearizedItems: TestsTreeItem[], indent: number, path?: string[], result2?: Result2) {
        indent++;
        if (path && indent < path.length && path[indent] !== item.name) {
            return linearizedItems;
        }
        item.indent = indent;
        if (indent > 0) {
            linearizedItems.push(item);
        }

        if (path && indent === path.length - 1) {
            result2!.foundIndex = linearizedItems.length - 1;
            result2!.foundItem = item;
        }

        for (let child of item.children) {
            if (!path) {
                child.parent = item;
            }
            this.linearizeNode(child, linearizedItems, indent, path, result2);
        }
        return linearizedItems;
    }

    protected setCurrent(className?: string | null, methodName?: string | null, duringLoad?: boolean) {
        // to please the compiler
        if (className === null) {
            className = undefined;
        }
        if (methodName === null) {
            methodName = undefined;
        }

        const result: Result2 = {} as any;
        if (className) {
            const path: string[] = [];
            if (!this.deduceFullPath(this.state.testsTreeRoot!, className, methodName, false, path)) {
                // not found
                return;
            }
            const linearizedItemsCurrent = this.linearizeNode(this.state.testsTreeRoot!, [], -1, path, result);
            this.setState({ linearizedItemsCurrent });
        } else {
            this.setState({ linearizedItemsCurrent: undefined });
        }

        this.setState({ currentClassName: className, currentMethodName: methodName });

        if (duringLoad) {
            // we don't want to update the local storage
            return result;
        }

        if (className) {
            localStorage.setItem(LOCAL_STORAGE_CURRENT_CLASS_NAME, className);
        } else {
            localStorage.removeItem(LOCAL_STORAGE_CURRENT_CLASS_NAME);
        }
        if (methodName) {
            localStorage.setItem(LOCAL_STORAGE_CURRENT_METHOD_NAME, methodName);
        } else {
            localStorage.removeItem(LOCAL_STORAGE_CURRENT_METHOD_NAME);
        }
        return result;
    }

    protected deduceFullPath(item: TestsTreeItem, className: string, methodName: string | undefined, classFound: boolean, path: string[]) {
        if (!classFound) {
            if (item.name === className) {
                // found the searched class
                if (!methodName) {
                    // no searched method
                    path.splice(0, 0, item.name);
                    return true;
                } else {
                    classFound = true;
                }
            }
        } else {
            if (item.name === methodName) {
                path.splice(0, 0, item.name!);
                return true;
            }
        }
        for (let child of item.children) {
            if (this.deduceFullPath(child, className, methodName, classFound, path)) {
                path.splice(0, 0, item.name!);
                return true;
            }
        }
        return false;
    }

    render() {
        const { selectedItem, serverError } = this.state;
        let failureShort: string | undefined;
        if (selectedItem?.failure) {
            failureShort = Utils.substringBefore(selectedItem?.failure, "at");
            if (failureShort.length > 100) {
                failureShort = failureShort.substring(0, 100) + "...";
            }
        }
        const helpMsg = "**Markdown** is supported. Words [within brackets] or ALL_CAPS are converted like this";
        const legend = <Popup trigger={<Icon size="big" color="blue" name='question circle' />} wide content={<>
            <Header dividing>Legend</Header>
            <ClassLabel /> Class
            <p /><MethodLabel /> Method
            <p /><ReportLabel /> Has report
            <p /><FailureLabel /> Has error
            <Divider />
            {helpMsg}:
            <br /> <MarkdownExt>{helpMsg}</MarkdownExt>
        </>} />;
        return <SplitPaneExt size={"50%"} split="vertical">
            <div className="w100">
                {serverError && <Modal open>
                    <Modal.Header>Error</Modal.Header>
                    <Modal.Content>{serverError}</Modal.Content>
                    <Modal.Actions>
                        <Button positive content="OK" onClick={() => this.setState({ serverError: undefined })} />
                    </Modal.Actions>
                </Modal>}
                {this.props.testsToRun
                    ? <>
                        <Button icon="play" content={"Run " + getTestToRunCountLabel(this.props.testsToRun)} disabled={this.state.running} onClick={() => this.runTests()} />
                        {legend}
                    </>
                    : <>
                        <Button icon="play" content="Run current" disabled={!this.state.currentClassName} onClick={() => this.runCurrent()} />
                        <Button icon="delete" content="Clear current" disabled={!this.state.currentClassName} onClick={() => this.setCurrent()} />
                        {legend}
                        <Divider />
                        Current: &nbsp;
                        {!this.state.currentClassName ? "none" : <>
                            <ClassLabel /> {this.state.currentClassName}
                            <MethodLabel /> {this.state.currentMethodName}
                            <Divider />
                            <TestsTreeComponent linearizedItems={this.state.linearizedItemsCurrent} selectedIndexOnce={this.state.currentSelectedIndexAfterRun} onSelect={item => this.setState({ selectedItem: item })} />
                        </>}
                    </>}
                <Divider />
                <TestsTreeComponent linearizedItems={this.state.linearizedItems}
                    onSelect={item => this.setState({ selectedItem: item })}
                    onSetCurrent={this.props.testsToRun ? undefined : item => {
                        if (item.kind === "CLASS") {
                            this.setCurrent(item.name);
                        } else {
                            this.setCurrent(item.parent!.name, item.name);
                        }
                    }}
                />
            </div>
            <Container>
                <Tab panes={[
                    { menuItem: "Markdown", render: () => <Tab.Pane>{selectedItem?.reportAsMarkdown && <MarkdownExt>{selectedItem?.reportAsMarkdown}</MarkdownExt>}</Tab.Pane> },
                    {
                        menuItem: "Initial, w/ custom renderers", render: () => <Tab.Pane>
                            {selectedItem?.failure && <>
                                <Header as="h2" color="red" dividing>
                                    <Icon name='warning circle' />
                                    <Header.Content>
                                        Error
                                        <Header.Subheader>{failureShort}</Header.Subheader>
                                    </Header.Content>
                                </Header>
                                <pre>
                                    {selectedItem.failure}
                                </pre>
                                <Divider />
                            </>}
                            {selectedItem?.description && <Header as='h2' dividing>
                                <Icon name='film' />
                                <Header.Content>
                                    {selectedItem?.name}
                                    <Header.Subheader><MarkdownExt>{selectedItem?.description}</MarkdownExt></Header.Subheader>
                                </Header.Content>
                            </Header>}
                            {selectedItem?.report?.elements.map((e, i) => {
                                // @ts-ignore
                                const Component = reportElementComponents[e.type];
                                return <React.Fragment key={i}>
                                    {Component ? <Component element={e} /> : <p>Unknown element type: {e.type}</p>}
                                </React.Fragment>
                            })}
                        </Tab.Pane>
                    }
                ]} />
            </Container>
        </SplitPaneExt>
    }
}

export const ClassLabel = () => <Label circular color="green" content="C" />;
export const MethodLabel = () => <Label circular color="yellow" content="M" />;
export const ReportLabel = () => <Label circular color="orange" content="R" />;
export const FailureLabel = () => <Label circular color="red" content="E" />;

const HeadingComponent = ({ element }: { element: ReportElementHeading }) => <Header><MarkdownExt>{element.text}</MarkdownExt></Header>
const ParagraphComponent = ({ element }: { element: ReportElementParagraph }) => <p><MarkdownExt>{element.text}</MarkdownExt></p>
const CodeComponent = ({ element }: { element: ReportElementCode }) => <ReactSyntaxHighlighter language={element.language}>{element.text}</ReactSyntaxHighlighter>
const TableComponent = ({ element }: { element: ReportElementTable }) => <Table celled>
    <Table.Header>
        <Table.Row>
            {element.rows[0]?.cells.map((cell, i) => <Table.HeaderCell key={i}>{cell.text}</Table.HeaderCell>)}
        </Table.Row>
    </Table.Header>
    <Table.Body>
        {element.rows.map((row, i) => {
            if (i === 0) {
                return null;
            }
            return <Table.Row key={i}>
                {row.cells.map((cell, j) => {
                    let style = undefined;
                    if (cell.color !== null && cell.color !== undefined) {
                        style = { backgroundColor: Utils.convertColorToHex(cell.color), color: Utils.convertColorToHex(Utils.getContrastingForegroundColor(cell.color)) };
                    }
                    return <Table.Cell style={style} key={j}>
                        {cell.comment && <><small><i><MarkdownExt>{cell.comment}</MarkdownExt></i></small><br /></>}
                        <MarkdownExt>{cell.text}</MarkdownExt>
                    </Table.Cell>
                })}
            </Table.Row>
        })}
    </Table.Body>
</Table>

const reportElementComponents = {
    ReportElementHeading: HeadingComponent,
    ReportElementParagraph: ParagraphComponent,
    ReportElementCode: CodeComponent,
    ReportElementTable: TableComponent
}
