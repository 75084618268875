import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSlug from "rehype-slug";
import rehypeAutolinkHeadings from "rehype-autolink-headings";
import SyntaxHighlighter from "react-syntax-highlighter";
import { Element } from "hast";

export const MarkdownExt = ({ children: text, enrich, baseUrlForImages }: { children?: string, enrich?: boolean, baseUrlForImages?: string }) => {
    if (!text) {
        return null;
    }
    // if (enrich === undefined) {
    //     enrich = true;
    // }
    // if (enrich) {
    //     // in GIVEN current org = [none], WHEN findByFilter() is invoked, THEN it returns records belonging to [all orgs]
    //     // matches "none" and "all orgs"
    //     text = text.replaceAll(/\[.*?\]/g, "`$&`");
    //     // in G1I_VEN2 current, WHEN findByFilter()
    //     // matches G1I_VEN2 and WHEN
    //     text = text.replaceAll(/[A-Z_][A-Z0-9_]+/g, "`$&`");
    // }
    return <ReactMarkdown className="FeatureBookServer_Markdown" children={text} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSlug, 
        // the doc suggest using hastscript: https://github.com/rehypejs/rehype-autolink-headings#example-content-from-h-builder
        // I tried to import it, and I had a weird TS error. Hence I created the element manually cf. https://github.com/syntax-tree/hast#element
        [rehypeAutolinkHeadings, { content: { type: "element",  children: [], tagName: "i", properties: { className: ["icon", "linkify", "small"] } } as Element } ]]} 
        components={{
            // cf. https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
            code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                    <SyntaxHighlighter
                        {...props as Omit<typeof props, "style">} // w/ @types/react-syntax-highlighter v15.5.9, TS complains about an incompatibility. I think there is an error in how they define "style"
                        children={String(children).replace(/\n$/, '')}
                        language={match[1]}
                        PreTag="div"
                    />
                ) : (
                    <code {...props} className={className}>
                        {children}
                    </code>
                )
            }
        }}
        transformImageUri={src => baseUrlForImages ? baseUrlForImages + "/" + src : src}
        />
}