import React, { HTMLAttributes } from "react";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import moment, { Moment } from "moment";
import { Icon, Label, Modal } from "semantic-ui-react";
import { ModalExt } from "../ModalExt/ModalExt";
import { PopupWithHelpTooltip } from "../semanticUiReactExt";
import { keyBy } from "lodash";
import { createTestids, TestsAreDemoCheat } from "@famiprog-foundation/tests-are-demo";

const VALID_DAY_REGEX = "((([0_])([1-9_]))|(([12_])([0-9_]))|(3([01_])))";
const VALID_MONTH_REGEX = "([0_][1-9_]|1[012_]|_0)";
const VALID_YEAR_REGEX = "(\\d|_)(\\d|_)(\\d|_)(\\d|_)";
const VALID_HOUR_REGEX = "([01_][0-9_]|(2[0-3_]))";
const VALID_MINUTES_REGEX = "[0-5_][0-9_]";
const VALID_SECONDS_REGEX = "[0-5_][0-9_]";

const DAY_REGEX = "([0-9_][0-9_])";
const MONTH_REGEX = "([0-9_][0-9_])";
const YEAR_REGEX = "(\\d|_)(\\d|_)(\\d|_)(\\d|_)";
const HOUR_REGEX = "([0-9_][0-9_])";
const MINUTES_REGEX = "[0-9_][0-9_]";
const SECONDS_REGEX = "[0-9_][0-9_]";

const DATE_PATTERNS = ["DD", "MM", "YYYY"];
const TIME_PATTERNS = ["HH", "mm", "ss"];
const PATTERNS = DATE_PATTERNS.concat(TIME_PATTERNS);

const SPACE_DELIMITER = " ";
const NUMBER_PLACEHOLDER = "_";

const MAX_DAYS_PER_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const DEFAULT_FORMAT = Utils.dateFormat;

enum DIRECTIONS {
    LEFT,
    RIGHT
}

type Key = Partial<KeyboardEvent> & { codeOrKey: string };

type RegExPair = {
    withoutValidation: string,
    withValidation: string
};

const patternToRegEx: ReadonlyMap<string, RegExPair> = new Map<string, RegExPair>([
    ["DD", { withoutValidation: DAY_REGEX, withValidation: VALID_DAY_REGEX }],
    ["MM", { withoutValidation: MONTH_REGEX, withValidation: VALID_MONTH_REGEX }],
    ["YYYY", { withoutValidation: YEAR_REGEX, withValidation: VALID_YEAR_REGEX }],
    ["HH", { withoutValidation: HOUR_REGEX, withValidation: VALID_HOUR_REGEX }],
    ["mm", { withoutValidation: MINUTES_REGEX, withValidation: VALID_MINUTES_REGEX }],
    ["ss", { withoutValidation: SECONDS_REGEX, withValidation: VALID_SECONDS_REGEX }]
]);

export type SectionPosition = {
    startPosition: number,
    endPosition: number,
    order: number
}

type DatePickerState = {
    format: string,
    lastValidValue: string | null,
    value: string,
    cursorPosition: number,
    regEx: RegExp | undefined,
    validationRegEx: RegExp | undefined,
    sections: Map<string, SectionPosition>,
    selection: { startPosition: number; endPosition: number },
    invalid: boolean,
    delimiters: { date: string, time: string },
    modalOpen: boolean | [number, number],
    displayMask: string | undefined,
    // #35456 - Flag to do input.setSelectionRange() in componentDidUpdate. Previously, setSelectionRange() was called every time componentDidUpdate() was called if the selection positions changed, to select the new section. 
    // If there was no selection active, it was called to set the cursor position, even if it did not change. This worked fine in every browser, except Safari because on this browser, the input is focused after calling
    // setSelectionRange() which triggered componentDidUpdate(), which called setSelectionRange() and so on. This flag prevents repetitive calls of setSelectionRange()
    updateSelection: boolean
}

export const initialState: DatePickerState = {
    format: DEFAULT_FORMAT,
    lastValidValue: null,
    value: "",
    cursorPosition: 0,
    regEx: undefined,
    validationRegEx: undefined,
    sections: new Map<string, SectionPosition>([]),
    selection: {
        startPosition: 0,
        endPosition: 0
    },
    invalid: false,
    delimiters: {
        date: "",
        time: ""
    },
    modalOpen: false,
    displayMask: undefined,
    updateSelection: false
}

export interface DatePickerProps {
    format?: string,
    showButton?: boolean,
    value?: Moment | null,
    style?: React.CSSProperties,
    onChange?: (value: Moment | null) => void,
    fieldName?: string,
    disabledDate?: (current: Moment) => boolean,
    className?: string,
    allowClear?: boolean,
    placeholder?: string,
    autoFocus?: boolean,
}

export const datePickerTestids = createTestids("DatePicker", {
    datePicker: "", datePickerInput: "", datePickerIcon: "", datePickerPopupHeader: "", datePickerPopupHeaderFormat: "", datePickerPopupHeaderTooltip: "", datePickerCalendar: ""
});

export class DatePicker<T extends DatePickerProps> extends React.Component<T, DatePickerState>{

    static defaultProps = {
        format: DEFAULT_FORMAT,
        showButton: true,
        allowClear: true
    };

    inputRef = React.createRef<HTMLInputElement>();
    clickWhileSelectionActive = false;

    constructor(props: T) {
        super(props);
        this.state = { ...initialState };
    }

    componentDidMount() {
        this.initDatePicker();
    }

    initDatePicker() {
        try {
            this.init(this.props.format ? this.props.format : DEFAULT_FORMAT);
        } catch (e) {
            console.log(e);
            console.log(`Format used in props is not valid. Default format will be used (${DEFAULT_FORMAT})`);
            // if the format is invalid, it will use the default format
            this.init(DEFAULT_FORMAT);
        }
    }

    init(format: string) {
        let sections: Map<string, SectionPosition> = new Map<string, SectionPosition>();
        let delimiters;
        let regEx;
        let validationRegEx;
        if (format.length < 2) {
            throw new Error("Format length is too short to match any of the patterns available.");
        }
        const formats = format.split(SPACE_DELIMITER);
        if (formats.length > 2) {
            throw new Error("Format has more than 1 space delimiter. At most one space delimiter should exist in the format to separate date section from time section.");
        }
        const patterns = this.getPatternsFromFormat(format);
        if (!patterns.every(pattern => PATTERNS.includes(pattern))) {
            throw new Error("Invalid pattern found in format given.");
        }
        if (formats.length === 1) {
            if (!(patterns.every(pattern => DATE_PATTERNS.includes(pattern)) || patterns.every(pattern => TIME_PATTERNS.includes(pattern)))) {
                throw new Error("Invalid combination of patterns found in format given. Date patterns have to be separated from time patterns by the space delimiter.");
            }
            const delimiter = this.getDelimiter(format, patterns.length);
            const isDate = this.isDate(format);
            sections = this.getSections(format, patterns);
            delimiters = {
                date: isDate ? delimiter : '',
                time: isDate ? '' : delimiter
            };
            regEx = "^" + patterns.map(pattern => patternToRegEx.get(pattern)?.withoutValidation).join(delimiter) + "$";
            validationRegEx = "^" + patterns.map(pattern => patternToRegEx.get(pattern)?.withValidation).join(delimiter) + "$";
        } else {
            if (formats[0].length < 2 || formats[1].length < 2) {
                throw new Error("One of the formats length is too short to match any of the patterns available. Check if a space delimiter was added by mistake.");
            }
            const firstSectionPatterns = this.getPatternsFromFormat(formats[0]);
            const secondSectionPatterns = this.getPatternsFromFormat(formats[1]);
            if (!(firstSectionPatterns.every(pattern => DATE_PATTERNS.includes(pattern)) || firstSectionPatterns.every(pattern => TIME_PATTERNS.includes(pattern)))
                || !(secondSectionPatterns.every(pattern => DATE_PATTERNS.includes(pattern)) || secondSectionPatterns.every(pattern => TIME_PATTERNS.includes(pattern)))) {
                throw new Error("Invalid combination of patterns found in format given. Date patterns have to be separated from time patterns by the space delimiter");
            }
            const firstDelimiter = this.getDelimiter(formats[0], firstSectionPatterns.length);
            const secondDelimiter = this.getDelimiter(formats[1], secondSectionPatterns.length);
            const isDateFirst = this.isDateFirst(format);
            sections = this.getSections(format, firstSectionPatterns.concat(secondSectionPatterns));
            delimiters = {
                date: isDateFirst ? firstDelimiter : secondDelimiter,
                time: isDateFirst ? secondDelimiter : firstDelimiter
            };
            regEx = "^" + firstSectionPatterns.map(pattern => patternToRegEx.get(pattern)?.withoutValidation).join(firstDelimiter) + SPACE_DELIMITER +
                secondSectionPatterns.map(pattern => patternToRegEx.get(pattern)?.withoutValidation).join(secondDelimiter) + "$";
            validationRegEx = "^" + firstSectionPatterns.map(pattern => patternToRegEx.get(pattern)?.withValidation).join(firstDelimiter) + SPACE_DELIMITER +
                secondSectionPatterns.map(pattern => patternToRegEx.get(pattern)?.withValidation).join(secondDelimiter) + "$";
        }
        const displayMask = format.replace(/[DMYHms]/g, NUMBER_PLACEHOLDER);
        let value;
        if (this.props.value) {
            value = moment(this.props.value, format).format(format)
        } else {
            value = this.state.lastValidValue ? moment(this.state.lastValidValue, format).format(format) : displayMask;
        }
        this.setState({
            format,
            lastValidValue: this.isValidExternal(value) ? value : this.props.allowClear === false ? moment().format(this.props.format) : null,
            value,
            regEx: new RegExp(regEx),
            validationRegEx: new RegExp(validationRegEx),
            sections,
            selection: {
                startPosition: 0,
                endPosition: 0
            },
            displayMask,
            delimiters
        });
    }

    getSections(format: string, patterns: string[]) {
        let sections: Map<string, SectionPosition> = new Map<string, SectionPosition>();
        patterns.forEach((s, i) => {
            const sectionPattern = s.charAt(0);
            const startPosition = format.indexOf(sectionPattern);
            const endPosition = format.lastIndexOf(sectionPattern) + 1;
            sections.set(sectionPattern, {
                startPosition: startPosition,
                endPosition: endPosition,
                order: i
            });
        });
        return sections;
    }

    getDelimiter(format: string, numberOfPatterns: number) {
        const delimiters = format.replace(/[DMYHms]/gi, '');
        if (numberOfPatterns === 1 && delimiters.length === 0) {
            return '';
        } else if (numberOfPatterns === 1 && delimiters.length > 0) {
            throw new Error("Delimiter found on format of only one section. There should be no delimiters");
        }
        const delimiter = delimiters[0];
        let validDelimiter = true;
        delimiters.split("").forEach(c => {
            if (c !== delimiter) {
                validDelimiter = false;
            }
        })
        if (!validDelimiter) {
            throw new Error("Multiple delimiters used for one section. Only one delimiters should be used to separate the patterns in a section (date or time).");
        }

        if (delimiters.length !== numberOfPatterns - 1) {
            throw new Error("Format has more delimiters than it should. Delimiters should be 1 character only, not multiple.");
        }
        return delimiter;
    }

    getPatternsFromFormat(format: string) {
        // Remove all non letters from the format because patterns are only letters.
        const formatWithoutDelimiters = format.replace(/[^A-Za-z]/gi, '');
        const patterns = [];
        let pattern = formatWithoutDelimiters[0];
        // Get pairs of repeated adjacent letters.
        for (let i = 0; i < formatWithoutDelimiters.length - 1; i++) {
            if (formatWithoutDelimiters[i] === formatWithoutDelimiters[i + 1]) {
                pattern += formatWithoutDelimiters[i + 1];
            } else {
                patterns.push(pattern);
                pattern = formatWithoutDelimiters[i + 1];
            }
        }
        patterns.push(pattern);
        return patterns;
    }

    updateValue(value: string) {
        if (value === this.state.value) { return; }
        if (value.match(this.state.regEx!)) {
            this.setState({ value });
            let valueForExternal = value === this.state.displayMask ? null : value;
            if (this.props.allowClear === false && valueForExternal === null) {
                return;
            }
            if (this.isValidExternal(valueForExternal)) {
                this.setState({ lastValidValue: valueForExternal });
            }
        }
    }

    updateLastValidValue(value: string) {
        if (value === this.state.lastValidValue) { return; }
        if (this.isValidExternal(value)) {
            this.setState({
                lastValidValue: value,
                value: this.state.value === this.state.displayMask ? value : this.state.value
            });
        }
    }

    setSelection(startPosition: number, endPosition: number = startPosition, cursorPosition: number = startPosition, updateSelection: boolean = true) {
        this.setState({
            cursorPosition,
            selection: {
                startPosition: startPosition,
                endPosition: endPosition
            },
            updateSelection: updateSelection
        });
    }

    setSelectionRange(start: number, end: number) {
        this.inputRef.current?.setSelectionRange(start, end);
        this.setState({ updateSelection: false });
    }

    componentDidUpdate(prevProps: DatePickerProps, prevState: DatePickerState) {
        if (prevState) {
            this.updateValueOnSectionLeave(prevState.cursorPosition, this.state.cursorPosition);
        }
        if (this.state.updateSelection && this.state.selection.startPosition < this.state.selection.endPosition) {
            this.setSelectionRange(this.state.selection.startPosition, this.state.selection.endPosition);
        } else if (this.state.updateSelection) {
            this.setSelectionRange(this.state.cursorPosition, this.state.cursorPosition);
        }
        if (prevProps.format !== this.props.format) {
            this.initDatePicker();
        }
        if (this.state.invalid && prevState.value !== this.state.value) {
            this.setState({ invalid: !this.isValid(this.state.value) });
        }
        const value = moment(this.props.value, this.state.format).format(this.state.format);
        if (!prevProps.value?.isSame(this.props.value)) {
            this.updateLastValidValue(value);
        }
        if (this.props.value !== prevProps.value) {
            this.updateValue(value);
        }
    }

    /**
     * When moving the cursor position to other section, if the section that is being left is not fully completed, it fills the remaining placeholders with 0.
     * In case it has only placeholders, it won't change it.
     * The value after filling with 0 will be a valid one (e.g. '9_' value for day section will become '09', not '90').
     */
    updateValueOnSectionLeave(prevPosition: number, newPosition: number) {
        const previousSectionPattern = this.getSectionPattern(prevPosition);
        const currentSectionPattern = this.getSectionPattern(newPosition);
        if (previousSectionPattern !== currentSectionPattern) {
            const previousSection = this.state.sections.get(previousSectionPattern)!;
            let currentValue = this.getSectionCurrentValue(previousSectionPattern) + "";
            currentValue = this.replacePlaceholders(currentValue, previousSectionPattern);
            let newValue = this.state.value.substring(0, previousSection.startPosition) + currentValue + this.state.value.substring(previousSection.endPosition);
            this.updateValue(newValue);
            this.setState({ invalid: !this.isValid(newValue) });
        }
    }

    replacePlaceholders(value: string, sectionPattern: string) {
        let newValue = value.replace(/_/g, "");
        if (!newValue.length || newValue === value) {
            return value;
        }
        if (newValue.length === 1) {
            if (newValue === "0" && (sectionPattern === "D" || sectionPattern === "M")) {
                newValue = "1";
            }
            return String(newValue).padStart(value.length, '0');
        } else {
            return value.replace(/_/g, "0");
        }
    }

    isDateFirst(format: string) {
        return !this.isTimeFirst(format);
    }

    isTimeFirst(format: string) {
        if (!this.isTime(format)) {
            return false;
        }
        let firstPattern = format.charAt(0);
        return TIME_PATTERNS.some(pattern => pattern.includes(firstPattern));
    }

    isDateTime(format: string) {
        return this.isTime(format) && this.isDate(format);
    }

    isDate(format: string) {
        return DATE_PATTERNS.some(pattern => format.includes(pattern));
    }

    isOnlyDate(format: string) {
        return this.isDate(format) && !this.isTime(format);
    }

    isOnlyTime(format: string) {
        return !this.isDate(format) && this.isTime(format);
    }

    isTime(format: string) {
        return TIME_PATTERNS.some(pattern => format.includes(pattern))
    }

    isDelimiter(char: string) {
        if (this.isDateTime(this.state.format)) {
            return char === this.state.delimiters.date || char === this.state.delimiters.time || char === SPACE_DELIMITER;
        } else if (this.isOnlyDate(this.state.format)) {
            return char === this.state.delimiters.date || char === SPACE_DELIMITER;
        } else {
            return char === this.state.delimiters.time || char === SPACE_DELIMITER;
        }
    }

    onInput(event: React.FormEvent<HTMLInputElement>) {
        let position = this.inputRef.current!.selectionStart!;
        const data = (event.nativeEvent as InputEvent).data;
        if (position > this.state.format.length || !data) {
            return;
        }
        const value = Utils.replaceCharAt(this.state.value, position - 1, data);

        if (this.isDelimiter(value[position])) {
            position += 1;
        }

        this.updateValue(value);
        this.selectIfSectionChange(this.inputRef.current!.selectionStart!, position);
    }

    /**
     * If next position is on a different section then it was on prev position, it will select the new section.
     */
    selectIfSectionChange(prevPosition: number, nextPosition: number) {
        const previousSectionPattern = this.getSectionPattern(prevPosition);
        const currentSectionPattern = this.getSectionPattern(nextPosition);
        if (previousSectionPattern !== currentSectionPattern) {
            this.selectSection(nextPosition);
        } else {
            this.setSelection(nextPosition);
        }
    }

    onPaste(event: React.ClipboardEvent<HTMLInputElement>) {
        // CZ: prevents handleInputChange being called for CTRL + V.
        event.stopPropagation();
        event.preventDefault();

        let clipboardData = event.clipboardData;
        let pastedData = clipboardData.getData('Text');

        if (this.matchFormat(pastedData)) {
            pastedData = this.getValidDate(pastedData);
            if (this.isValid(pastedData)) {
                this.updateValue(pastedData);
                this.setSelection(0);
            }
        }
    }

    matchFormat(value: string) {
        const displayMask = this.state.displayMask!;
        if (value.length != displayMask.length) {
            return false;
        }

        for (let i = 0; i < displayMask.length; i++) {
            if (displayMask.charAt(i) == "_" && (value.charCodeAt(i) < 48 || value.charCodeAt(i) > 57)) {
                return false;
            }
        }
        return true;
    }

    getValidDate(value: string) {
        const format = this.state.format;
        const dateDelimiterIdx = this.getIndicesOf(this.state.delimiters.date, format);
        dateDelimiterIdx.forEach(idx => {
            value = Utils.replaceCharAt(value, idx, this.state.delimiters.date);
        })
        const timeDelimiterIdx = this.getIndicesOf(this.state.delimiters.time, format);
        timeDelimiterIdx.forEach(idx => {
            value = Utils.replaceCharAt(value, idx, this.state.delimiters.time);
        })
        const spaceDelimiterIdx = this.getIndicesOf(SPACE_DELIMITER, format);
        spaceDelimiterIdx.forEach(idx => {
            value = Utils.replaceCharAt(value, idx, SPACE_DELIMITER);
        })
        return value;
    }

    getIndicesOf(searchStr: string, str: string) {
        let searchStrLen = searchStr.length;
        if (searchStrLen == 0) {
            return [];
        }
        let startIndex = 0, index, indices = [];
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        return indices;
    }

    isSameKey(e: React.KeyboardEvent<HTMLInputElement>, key: Key) {
        return (key.codeOrKey === e.code || key.codeOrKey === e.key) && key.ctrlKey === e.ctrlKey && key.shiftKey === e.shiftKey;
    }

    onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (this.getKeysForKeepDefaultBehavior().some(key => this.isSameKey(e, key))) {
            return;
        }

        if (this.getKeysForDelete().some(key => this.isSameKey(e, key))) {
            this.onBackspace();
        }

        if (this.getKeysForApplyChanges().some(key => this.isSameKey(e, key))) {
            this.applyOnChange(this.getValueForExternal());
        }

        if (this.getKeysForSetCursorAtEnd().some(key => this.isSameKey(e, key))) {
            this.setSelection(this.state.format.length);
        }

        if (this.getKeysForSetCursorAtStart().some(key => this.isSameKey(e, key))) {
            this.setSelection(0);
        }

        if (this.getKeysForPreviousGroup().some(key => this.isSameKey(e, key))) {
            this.onArrowLeft();
        }

        if (this.getKeysForIncrementGroup().some(key => this.isSameKey(e, key))) {
            this.updateSection(1);
        }

        if (this.getKeysForNextGroup().some(key => this.isSameKey(e, key))) {
            this.onArrowRight();
        }

        if (this.getKeysForDecrementGroup().some(key => this.isSameKey(e, key))) {
            this.updateSection(-1);
        }

        if (this.getKeysForNow().some(key => this.isSameKey(e, key))) {
            this.setNow();
        }

        if (!((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105))) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    setNow() {
        this.updateValue(moment().format(this.state.format));
        this.setSelection(0);
    }

    onBackspace() {
        /* If there is no selection, deletes from the editable position at the left of cursor position and cursor position will move 1 position to the left or 2 positions if cursor position
           is right to the left of a separator. If there is a selection, it will delete it and cursor position will stay at the start of the deleted selection. */
        let position = this.state.cursorPosition;
        let value = this.state.value;
        if (this.hasSelection()) {
            this.deleteSelection();
            this.setSelection(this.state.selection.startPosition);
        } else if (position !== 0) {
            position--;
            if (value[position] !== NUMBER_PLACEHOLDER) {
                if (this.isDelimiter(value[position])) {
                    position--;
                }
                value = Utils.replaceCharAt(value, position, NUMBER_PLACEHOLDER);
                this.updateValue(value);
            }
            this.setSelection(position);
        }
    }

    deleteSelection() {
        const selectionStart = this.state.selection.startPosition;
        const selectionEnd = this.state.selection.endPosition;
        let selection = this.state.value.substring(selectionStart, selectionEnd);
        selection = selection.replace(/[0-9]/g, NUMBER_PLACEHOLDER);
        let newValue = this.state.value.substring(0, selectionStart) + selection + this.state.value.substring(selectionEnd);
        this.updateValue(newValue);
    }

    /**
     * If a selection is active, it will move to the section on the left. If no selection is active, 
     * then a section is being edited and it will move to the next editable position on the left.
     */
    onArrowLeft() {
        let value = this.state.value;
        if (this.hasSelection()) {
            let nextSectionLeft = this.getNextSection(DIRECTIONS.LEFT);
            this.selectSection(nextSectionLeft.startPosition);
        } else {
            let cursorPosition = this.state.cursorPosition;
            if (cursorPosition > 0) {
                cursorPosition--;
                if (cursorPosition > 0 && this.isDelimiter(value[cursorPosition])) {
                    cursorPosition--;
                }
            }
            this.selectIfSectionChange(this.state.cursorPosition, cursorPosition);
        }

    }

    /**
     * If a selection is active, it will move to the section on the right. If no selection is active, 
     * then a section is being edited and it will move to the next editable position on the right.
     */
    onArrowRight() {
        let value = this.state.value;
        if (this.hasSelection()) {
            let nextSectionRight = this.getNextSection(DIRECTIONS.RIGHT);
            this.selectSection(nextSectionRight.startPosition);
        } else {
            let cursorPosition = this.state.cursorPosition;
            if (cursorPosition < this.state.format.length) {
                cursorPosition++;
                if (cursorPosition < this.state.format.length && this.isDelimiter(value[cursorPosition])) {
                    cursorPosition++;
                }
            }
            this.selectIfSectionChange(this.state.cursorPosition, cursorPosition);
        }
    }

    isValid(value: string) {
        if (!value.match(this.state.validationRegEx!)) {
            return false;
        }
        const dayValue = this.getSectionCurrentValue("D", value);
        if (dayValue && dayValue.search(NUMBER_PLACEHOLDER) === -1) {
            const max = this.getMaxDay(this.getValidNumberFromString(this.getSectionCurrentValue("M", value)), this.getValidNumberFromString(this.getSectionCurrentValue("Y", value)));
            if (parseInt(dayValue) > max) {
                return false;
            }
        }
        return true;
    }

    isValidExternal(value: string | null) {
        return value === null || (value.search(NUMBER_PLACEHOLDER) === -1 && this.isValid(value) && !this.props.disabledDate?.(moment(value, this.state.format)));
    }

    getNextSection(direction: DIRECTIONS) {
        const section = this.state.sections.get(this.getSectionPattern(this.state.cursorPosition))!;
        switch (direction) {
            case DIRECTIONS.LEFT:
                if (section.order > 0) {
                    return this.getSectionByOrder(section.order - 1)!;
                }
                break;
            case DIRECTIONS.RIGHT:
                if (section.order < this.state.sections.size - 1) {
                    return this.getSectionByOrder(section.order + 1)!;
                }
                break;
        }
        return section;
    }

    getSectionByOrder(order: number) {
        for (const section of this.state.sections.values()) {
            if (section.order === order) {
                return section;
            }
        }
    }

    getSectionPattern(position: number) {
        const format = this.state.format;
        let currentSectionPattern = format[position];
        if (!currentSectionPattern || this.isDelimiter(currentSectionPattern)) {
            currentSectionPattern = format[position - 1];
        }
        return currentSectionPattern;
    }

    selectSection(position: number) {
        const currentSectionPattern = this.getSectionPattern(position);
        const startPosition = this.state.sections.get(currentSectionPattern)!.startPosition;
        const endPosition = this.state.sections.get(currentSectionPattern)!.endPosition;
        this.setSelection(startPosition, endPosition, startPosition);
    }

    hasSelection() {
        return this.state.selection.startPosition !== this.state.selection.endPosition;
    }

    updateSection(increment: number) {
        const currentSectionPattern = this.getSectionPattern(this.state.cursorPosition);
        const startPosition = this.state.sections.get(currentSectionPattern)!.startPosition;
        const endPosition = this.state.sections.get(currentSectionPattern)!.endPosition;
        let sectionValue: string = this.state.value.substring(startPosition, endPosition);
        let value = this.state.value;
        switch (currentSectionPattern) {
            case "D":
                const max = this.getMaxDay(this.getValidNumberFromString(this.getSectionCurrentValue("M")), this.getValidNumberFromString(this.getSectionCurrentValue("Y")));
                sectionValue = this.updateSelectedSectionValue(sectionValue, increment, "D", "__", 1, max);
                break;
            case "M":
                sectionValue = this.updateSelectedSectionValue(sectionValue, increment, "M", "__", 1, 12);
                break;
            case "Y":
                sectionValue = this.updateSelectedSectionValue(sectionValue, increment, "y", "____", 0, 9999);
                break;
            case "H":
                sectionValue = this.updateSelectedSectionValue(sectionValue, increment, "h", "__", 0, 23);
                break;
            case "m":
                sectionValue = this.updateSelectedSectionValue(sectionValue, increment, "m", "__", 0, 59);
                break;
            case "s":
                sectionValue = this.updateSelectedSectionValue(sectionValue, increment, "second", "__", 0, 59);
                break;
        }
        let newValue = value.substring(0, startPosition) + sectionValue + value.substring(endPosition);
        this.updateValue(newValue);
        this.selectSection(this.state.cursorPosition);
    }

    updateSelectedSectionValue(value: string, increment: number, sectionPattern: moment.unitOfTime.All, sectionPlaceholder: string, min: number, max: number) {
        let newValue;
        if (value === sectionPlaceholder) {
            newValue = moment().get(sectionPattern);
            // CZ: moment() returns values from 0 to 11 for months instead of 1 to 12. I have to add 1 to the value.
            if (sectionPattern === 'M') {
                newValue += 1;
            }
        } else {
            newValue = this.getValidNumberFromString(value) + increment;
        }
        return this.validateNumber(newValue, sectionPlaceholder.length, min, max);
    }

    getValidNumberFromString(value: string | undefined) {
        if (!value) {
            return NaN;
        }
        let validNumString = value.replace(/_/g, "");
        return parseInt(validNumString);
    }

    validateNumber(value: number, length: number, min: number, max: number) {
        if (value > max) {
            value = min;
        }
        if (value < min) {
            value = max;
        }
        return String(value).padStart(length, '0');
    }

    getMaxDay(month: number | undefined, year: number | undefined) {
        if (!month || isNaN(month)) {
            return 31;
        }
        let max = MAX_DAYS_PER_MONTH[month - 1];
        if (year && !isNaN(year) && month === 2) {
            const isLeapYear = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
            if (isLeapYear) {
                max += 1;
            }
        }
        return max;
    }

    getSectionCurrentValue(sectionPattern: string, value?: string) {
        if (!sectionPattern) { return; }
        const s = this.state.sections.get(sectionPattern);
        if (!s) { return; }
        return (value ? value : this.state.value).substring(s.startPosition, s.endPosition);
    }

    onFocus() {
        if (this.props.placeholder && this.state.value === '' && this.props.allowClear) {
            if (this.props.placeholder) {
                this.setState({
                    value: this.state.displayMask!,
                    lastValidValue: null
                });
            }
            this.selectSection(0);
        }
        if (this.state.value === this.state.displayMask) {
            this.selectSection(0);
        }
    }

    onBlur() {
        if (this.state.value === this.state.displayMask && this.props.placeholder && this.props.allowClear) {
            this.setState({
                value: '',
                lastValidValue: null
            })
            return;
        }
        this.setState({ value: this.state.lastValidValue ? this.state.lastValidValue : this.state.displayMask! });
        this.setSelection(0, 0, 0, false);
        this.applyOnChange(this.getValueForExternal());
    }

    getValueForExternal() {
        return this.state.lastValidValue !== null ? moment(this.state.lastValidValue, this.state.format) : null;
    }

    applyOnChange(date: Moment | null) {
        if (this.props.onChange && this.props.value?.format(this.props.format) !== date?.format(this.props.format)) {
            this.props.onChange(date);
        }
    }

    onButtonClick() {
        const rect = this.inputRef.current!.getBoundingClientRect();
        this.setState({ modalOpen: [rect.left, rect.bottom] });
    }

    /** 
     * Should be overwritten to return the calendar component. It will usually need 2 props: value and onChange.
     * But it depends, it can be different for some libraries. 
     */
    getCalendarComponent() {
        return undefined;
    }

    focusTime() {
        if (!this.isTime(this.state.format)) { return; }
        if (this.isTimeFirst(this.state.format)) {
            const endPosition = this.state.format.search(SPACE_DELIMITER);
            this.setSelection(0, endPosition, 0);
        } else {
            const startPosition = this.state.format.search(SPACE_DELIMITER) + 1;
            this.setSelection(startPosition, this.state.format.length, startPosition);
        }
        this.inputRef.current!.focus();
    }

    getClassNames() {
        return "flex-center" + (this.props.className ? " " + this.props.className : "")
    }

    onClick(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        e.preventDefault();
        if (this.inputRef.current!.selectionStart === this.inputRef.current!.selectionEnd) {
            this.selectSection(this.inputRef.current!.selectionStart!);
        } else {
            if (this.hasSelection() && this.inputRef.current!.selectionStart === this.state.selection.startPosition && this.inputRef.current!.selectionStart === this.state.selection.startPosition) {
                this.clickWhileSelectionActive = true
            }
            this.setSelection(this.inputRef.current!.selectionStart!, this.inputRef.current!.selectionEnd!, this.inputRef.current!.selectionStart!);
        }
    }

    onSelect() {
        if (this.clickWhileSelectionActive) {
            this.clickWhileSelectionActive = false
            this.setSelection(this.inputRef.current!.selectionStart!, this.inputRef.current!.selectionEnd!, this.inputRef.current!.selectionStart!);
        }
    }

    getTooltipMessage() {
        // Keep the elements in this order to match the instructions in tooltip message.
        const args = [this.getKeysForIncrementGroup(), this.getKeysForDecrementGroup(), this.getKeysForPreviousGroup(), this.getKeysForNextGroup(), this.getKeysForNow(),
        this.getKeysForDelete(), this.getKeysForSetCursorAtStart(), this.getKeysForSetCursorAtEnd()];
        return _msg("DatePicker.tooltip.message", ...args.map((keys: Key[]) => keys.map(key => key.codeOrKey.replace(/([a-z])([A-Z])/g, '$1 $2'))));
    }

    render() {
        const calendarComponent = this.getCalendarComponent();
        const classNames = this.state.invalid ? "DatePicker_invalidInput" : "";
        return (
            <>
                <TestsAreDemoCheat objectToPublish={this} dataTestIdSuffix={this.props.fieldName} />
                <div className={this.getClassNames()} data-testid={datePickerTestids.datePicker} style={this.props.style}>
                    <input autoFocus={this.props.autoFocus} data-testid={datePickerTestids.datePickerInput} className={classNames} ref={this.inputRef} type='text' value={this.state.value}
                        onInput={e => this.onInput(e)} onKeyDown={e => this.onKeyDown(e)}
                        onPaste={e => this.onPaste(e)} onBlur={() => this.onBlur()} onFocus={() => this.onFocus()}
                        placeholder={this.props.placeholder} onClick={e => this.onClick(e)} onSelect={() => this.onSelect()} />
                    {this.props.showButton && <Icon data-testid={datePickerTestids.datePickerIcon} name="calendar" link className="DatePicker_calendarIcon" onClick={() => this.onButtonClick()} />}
                    <ModalExt widthToReposition={400} className="DatePicker_modal" open={this.state.modalOpen} onClose={() => this.setState({ modalOpen: false })} >
                        <Modal.Header className="DatePicker_modalHeader">
                            <div data-testid={datePickerTestids.datePickerPopupHeader} className="DatePicker_modalHeaderContent">
                                <Label data-testid={datePickerTestids.datePickerPopupHeaderFormat}>Format: {this.state.format}</Label>
                                {/* TODO CSR: vreau sa afisam in mesaj anumite chestii, care sunt parametrabile de user; precum tasta de pgup/down; aceste lucruri, de dat ca parametrii. De asemenea de pus in <Markdown>, ca sa putem avea tooltip-ul cu formatare */}
                                <PopupWithHelpTooltip dataTestId={datePickerTestids.datePickerPopupHeaderTooltip} tooltip={this.getTooltipMessage()} />
                            </div>
                        </Modal.Header>
                        {calendarComponent && <Modal.Content data-testid={datePickerTestids.datePickerCalendar} className="DatePicker_modalContent">
                            {calendarComponent}
                        </Modal.Content>}
                    </ModalExt>
                </div>
            </>
        );
    }


    ////////////////////////////////////////////////////////////////////////////////////////
    ////// Available keys
    ////////////////////////////////////////////////////////////////////////////////////////

    createKey(codeOrKey: string, ctrlKey: boolean = false, shiftKey: boolean = false): Key {
        return { codeOrKey, ctrlKey, shiftKey };
    }

    getKeysForNow(): Key[] {
        return [this.createKey("KeyN"), this.createKey("NumpadAdd")];
    }

    getKeysForIncrementGroup(): Key[] {
        return [this.createKey("ArrowUp"), this.createKey("PageUp")];
    }

    getKeysForDecrementGroup(): Key[] {
        return [this.createKey("ArrowDown"), this.createKey("PageDown")];
    }

    getKeysForNextGroup(): Key[] {
        return [this.createKey("ArrowRight")];
    }

    getKeysForPreviousGroup(): Key[] {
        return [this.createKey("ArrowLeft")];
    }

    getKeysForApplyChanges(): Key[] {
        return [this.createKey("Enter")];
    }

    getKeysForSetCursorAtStart(): Key[] {
        return [this.createKey("Home")];
    }

    getKeysForSetCursorAtEnd(): Key[] {
        return [this.createKey("End")];
    }

    getKeysForDelete(): Key[] {
        return [this.createKey("Backspace")];
    }

    getKeysForKeepDefaultBehavior() {
        return [this.createKey("KeyC", true), this.createKey("KeyV", true), this.createKey("Tab"), this.createKey("Tab", false, true), this.createKey("ArrowRight", false, true), this.createKey("ArrowLeft", false, true)]
    }

    ////////////////////////////////////////////////////////////////////////////////////////
    ////// Test functions
    ////////////////////////////////////////////////////////////////////////////////////////

    tadSetCursorPositionAndInputSelection = (startPosition: number, endPosition: number = startPosition, cursorPosition: number = startPosition) => {
        this.setSelection(startPosition, endPosition, cursorPosition);
        this.inputRef.current?.focus();
    }

    tadSelectSection = (position: number) => {
        this.selectSection(position);
        this.inputRef.current?.focus();
    }

    tadOnPaste = (data: string) => {
        let pasteEvent = {
            clipboardData: {
                getData: () => data
            }, preventDefault: () => { }, stopPropagation: () => { }
        };
        this.onPaste(pasteEvent as unknown as React.ClipboardEvent<HTMLInputElement>);
    }

}"../../utils/Utils"