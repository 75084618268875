import chai from "chai";
import chaiSubset from "chai-subset";
import ErrorStackParser from "error-stack-parser";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import ReactFloater from "react-floater";
import { Divider, Label, Modal } from "semantic-ui-react";
import { MarkdownExt } from "./MarkdownExt";
import { Report } from "./Report";
import { ReporterFromSlaveToMaster } from "./ReporterFromSlaveToMaster";
import { TestsAreDemoFunctions, setTad, tad } from "./TestsAreDemoFunctions";
import { StepByStepMode, TestClassDescriptor, TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { SourceCodeInfo, TraceMapCache } from "./TraceMapCache";
import { UiApiHelper } from "./uiApi/UiApiHelper";
import { Capture } from "./uiApi/UiApi";
import { ScriptableUi, ScriptableUiHighlightWrapper, ScriptableUiInterceptor, Spotlight } from "@famiprog-foundation/scriptable-ui";
import { render } from "./reactTestingLibraryCustomized";
import { FoundationUtils } from "@famiprog-foundation/utils";

/**
 * Made a data structure (instead of a simple message) because maybe in the future we'll
 * want to have additional graphical params, e.g. color, etc.
 * 
 * If `!focusOnLastElementCaptured` => modal.
 * If `!message && focusOnLastElementCaptured` => only focus; no message.
 * If `!message && !focusOnLastElementCaptured` => nothing appears at the screen; but the "Next step" button is enabled.
 */
export interface SpotlightParams {
    message?: ReactNode;
    focusOnLastElementCaptured: boolean;
}

interface SlaveState {
    spotlightParams?: SpotlightParams,
    inspectedElement?: HTMLElement,
    inspectedElementPopup?: ReactElement,
    testClassDescriptorForDemoComponent?: TestClassDescriptor
}

interface SlaveProps {
    /**
     * We force the tests to be imported async (via this callback), to make sure that they are imported only by the "slave".
     * Importing tests in "master" or in normal app, would trigger importing from "instrumentedFunctions", which throws error
     * if not "slave" mode.
     */
    importTestsCallback: () => Promise<any>;

    createTestsAreDemoFunctions?: () => TestsAreDemoFunctions;
}

declare global {
    namespace Mocha {
        interface TestFunction {
            onlyUntil: ExclusiveTestFunction;
        }
    }
}

export enum DemoForEndUser { SHOW, HIDE, HIDE_NEXT }

/**
 * Runs the tests (via `mocha.run()`) and displays the spotlight.
 */
export class TestsAreDemoSlave extends React.Component<SlaveProps, SlaveState> {

    static _INSTANCE: TestsAreDemoSlave;

    state: SlaveState = {};

    master!: TestsAreDemoMaster;

    uiApiHelper = UiApiHelper.INSTANCE;

    /**
     * I don't store this in the state, because it's a high traffic item. And I'm afraid
     * that the state is proxified (e.g. to output warnings in the dev mode), hence slower.
     */
    stepByStep = false;

    /**
     * Idem as `stepByStep`. W/ the difference that there it's a precaution. And here is a certitude,
     * because the access to this attribute is in write mode. So every set would generate additional logic
     * being executed.
     */
    lastElementCaptured?: Element | null;

    /**
     * Recreated before each test.
     */
    report!: Report;

    static mochaInitialized = false;
    protected runRequestedWhileMochaInitializing = false;
    reporterFromSlaveToMaster!: ReporterFromSlaveToMaster;

    currentComment: ReactNode;
    currentCommentSlideIndex?: number;

    onlyCalled = false;
    onlyUntilCalled = false;
    demoForEndUser = DemoForEndUser.SHOW;
    demoForEndUserSavedCurrentCommentBeforeHide: ReactNode;

    /**
     * See comment where its used, in `render()`.
     */
    keyForRenderDemoComponent = false;

    setLastElementCaptured(lastElementCaptured: Element | null) {
        this.lastElementCaptured = lastElementCaptured;
    }

    async setTestClassDescriptorForDemoComponent(testClassDescriptorForDemoComponent: TestClassDescriptor) {
        // I observed that unmounting the <div> used by React Testing Library / render(), doesn't lead to a 
        // graceful unmount of what was mounted there by the last test. This generated issues at least for libs such as
        // ScriptableUi or RRC, who require unique ID. E.g. I ran the test using CompA, then I clicked on "demo component" for
        // CompA, and I was having an error that CompA exists twice.
        // Hence I render something in order to unmount what the last test mounted there. I mention that this cannot be done
        // by the last test at the end, because, by design, we want to keep mounted (also after test) the comp used during test.
        render(<p />);
        await FoundationUtils.setTimeoutAsync();

        /**
         * Before having this setter, this code was in componentShouldUpdate(); moving the comment:
         * Note: we use this function, because it is called before the render. W/o it, when switching to "demo2", I would have:
         * * render(): old key, demo2
         * * componentDidUpdate(): new key = ...; set in state so trigger render
         * * render(): new key, demo2
         * 
         * So we would have an additional render(), w/ old key, but new comp. We don't want this.
         */
        if (this.state.testClassDescriptorForDemoComponent?.demoComponent && testClassDescriptorForDemoComponent?.demoComponent
            && this.state.testClassDescriptorForDemoComponent !== testClassDescriptorForDemoComponent) {
            this.keyForRenderDemoComponent = !this.keyForRenderDemoComponent;
        }

        this.setState({ testClassDescriptorForDemoComponent });
    }

    constructor(props: SlaveProps) {
        super(props);
        this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    /**
     * After the execution, either the connection to the parent window / TestsAreDemoSlave is correctly
     * established or an error is thrown.
     */
    async componentDidMount() {
        // @see TestsAreDemoMaster.componentDidMount(); curious thing though, the double invocation didn't happen here as well
        if (TestsAreDemoSlave._INSTANCE) {
            throw new Error("Cannot create more than one instances of TestsAreDemoSlave");
        }
        TestsAreDemoSlave._INSTANCE = this;

        if (!window.parent?.testsAreDemoMaster) {
            throw new Error("Looking at the parent of this IFrame to find TestsAreDemoMaster. But was not found.");
        }
        this.master = window.parent.testsAreDemoMaster;
        this.master.slave = this;

        const tad = this.props.createTestsAreDemoFunctions?.() || new TestsAreDemoFunctions();
        setTad(tad);

        this.initMocha();
    }

    protected async initMocha() {
        // In some webpack based setups, the polyfill (for node) cf. below is not enough:
        // resolve: {
        //     fallback: {
        //       "stream": false,
        // These are added via: add patches until it works; we didn't find a doc to do it.
        if (!window.process) {
            // @ts-ignore
            window.process = { browser: true }
        }
        // :( after switching to mono repo, some types from node (such as this one) started to misbehave.
        // Fortunately this happened only for 2 files; so it's not worth the effort to start digging.
        // (search this text to find the other place(s))
        // @ts-ignore
        if (!window.Buffer) {
            // @ts-ignore
            window.Buffer = {
                // @ts-ignore
                isBuffer: () => false
            }
        }

        // for a "normal" import, @ts-ignore wasn't needed; however it seems to be needed here
        // @ts-ignore
        // await import("script-loader!mocha/mocha-es2018"); // eslint-disable-line import/no-webpack-loader-syntax
        await import("mocha"); // eslint-disable-line import/no-webpack-loader-syntax
        mocha.setup({
            ui: "bdd", // I don't exactly known what this is; but is needed
            timeout: 1000000, // for the moment a high value, for the "step by step case"
        });

        const that = this;
        mocha.reporter(class extends Mocha.reporters.Base {
            constructor(runner: Mocha.Runner, options: Mocha.MochaOptions) {
                super(runner, options);
                that.reporterFromSlaveToMaster = that.master.onMochaReporterCreated(this, runner, options);
            }
        });

        // @ts-ignore 
        mocha.cleanReferencesAfterRun(false); // needed to be able to rerun the tests
        // This doesn't work. I didn't look if we could do something or not.
        // mocha.checkLeaks();

        this.monkeyPatchMocha();

        // Configure global "hooks" for mocha

        beforeEach(() => {
            this.onlyUntilCalled = false;
            this.demoForEndUser = DemoForEndUser.SHOW;
            this.report = new Report(this.master);
        });

        afterEach(async () => {
            // When this is called, the code from the reporter (which un-indents "path") has already been executed.
            // The runner callback doesn't wait after async callbacks. But hooks (like this) do.
            await this.report.sendMarkdownToPuppeteer();
        });

        TestsAreDemoSlave.mochaInitialized = true;

        await this.props.importTestsCallback();

        // copied from common.test.ts
        chai.use(chaiSubset);

        if (this.runRequestedWhileMochaInitializing) {
            this.run();
        }
        // I don't see any reason not to activate this. It's common to have long stack traces for errors that begin from the test
        // file. And w/o this, we won't always see the place where the error originated from
        Error.stackTraceLimit = Infinity;
    }

    protected monkeyPatchMocha() {
        const oldIt = globalThis.it;
        if ((oldIt as any).onlyUntil) {
            // I was affraid of HMR; that's why I put this as a fail fast mechanism; however it didn't ever trigger
            throw new Error("'it' already monkeypatched");
        }
        const it: typeof oldIt = (...args: any) => {
            if (!this.onlyUntilCalled) {
                return oldIt.apply(null, args)
            } else {
                // here we break the contract. If someone relies on the return value, then :(
                return null as unknown as Mocha.Test;
            }
        }
        globalThis.it = it;
        // we should loop and copy all, except the "normal" functions; maybe in the future more functions will be added to "it"
        it.only = oldIt.only;
        it.skip = oldIt.skip;
        it.retries = oldIt.retries;

        it.onlyUntil = (...args: any) => {
            if (!this.onlyCalled) {
                // this is not bullet proof. Maybe another "describe" has only, and the check would pass.
                // but it's difficult to add proper validation
                throw new Error("'it.onlyUntil()' detected, but the parent suite should be 'describe.only()'");
            }
            const result = it.apply(null, args);
            this.onlyUntilCalled = true;
            return result;

        }

        const oldDescribe = globalThis.describe;
        const describe: typeof oldDescribe = (...args: any) => {
            return oldDescribe.apply(null, args);
        }
        globalThis.describe = describe;
        describe.skip = oldDescribe.skip;
        describe.only = (...args: any) => {
            this.onlyCalled = true;
            return oldDescribe.only.apply(null, args);
        }

    }

    getMochaConstants(): any {
        // this is not exposed in the .d.ts files
        // @ts-ignore
        return Mocha.Runner.constants;
    }

    /**
     * So that the master can access it.
     */
    getTestsAreDemoFunctions() {
        return tad;
    }

    static get INSTANCE() {
        if (!TestsAreDemoSlave._INSTANCE) {
            throw new Error("There is no TestsAreDemoSlave singleton available. This might happen if somehow you run tests that are NOT in the IFrame of TestsAreDemoMaster (i.e. in TestsAreDemoSlave)");
        }
        return TestsAreDemoSlave._INSTANCE;
    }

    async run() {
        // this probably happens if "auto run" is checked
        if (!TestsAreDemoSlave.mochaInitialized) {
            this.runRequestedWhileMochaInitializing = true;
            return;
        }

        mocha.suite.suites = [];
        // loading of comments from tsdoc is async; so we need to wait to make sure we processed all the comments
        // I discovered this in pupeteer. But looking at the code, I'd say the issue was present also in web; but somehow the async call
        // happened there sufficiently fast
        await this.master.state.testClassDescriptorsLoadingPromise;
        const executableDescriptors = tad.addTestsFromDescriptors(this.master.state.testClassDescriptors, this.master.state.testsToRun, this.master.props.puppeteer);
        if (this.master.props.puppeteer) {
            await this.master.puppeteerBridge.sendMessageToPuppeteer("writeFile", { path: "testClassDescriptors.json", content: JSON.stringify(executableDescriptors) });
        }

        const that = this;

        const interceptor = new class extends ScriptableUiInterceptor {
            async onIntercept(capture: Capture, scriptableUi: ScriptableUi<any>, highlighWrapper: ScriptableUiHighlightWrapper) {
                await highlighWrapper!.highlight();
                // needed so that puppeteer knows the focused rect, to take screenshot
                that.setLastElementCaptured(highlighWrapper.getDomElement()!);
                await tad.showSpotlight({ focusOnLastElementCaptured: false });
                highlighWrapper.cancelHighlight();
                return true;
            }

        }();
        interceptor.install();

        mocha.run(() => {
            this.master.onRunFinished();
            interceptor.uninstall();
        }).addListener("suite", () => { // before a suite
            // If importing "@testing-library/react", such a cleanup() is called at the end of the test. This is not 
            // OK, because usually we want to continue to see UI that was just tested. That's why we import "@testing-library/react/pure".
            // Then we moved the clean() at the beginning of the suite. But since the introduction of groups (hence sub-suites), this is
            // not OK any more. But anyway, I don't see why this is actually needed, since every test calls render()
            // cleanup();
        });
    }

    async showSpotlight(spotlightParams: SpotlightParams, finishWaitingCallback: Function) {
        // Parse the source code, to update the source code view (in UI) and the markdown report(in puppeteer)
        let sourceCodeInfo: SourceCodeInfo | undefined;
        try {
            /*
            * Cf. https://udn.realityripple.com/docs/Archive/Web/JavaScript/Microsoft_Extensions/Error.stackTraceLimit
            * by default Error.stackTraceLimit = 10 and we can have the *TestsAreDemo.* file deeper in stack trace
            * 
            * UPDATE: now, we set in this file, at init Error.stackTraceLimit = Infinity. But let's keep this code. Maybe the
            * dev has changed this global setting.
            */
            const stackTraceLimit = Error.stackTraceLimit;
            Error.stackTraceLimit = Infinity;
            let stackFrames: ErrorStackParser.StackFrame[];
            try {
                stackFrames = ErrorStackParser.parse(new Error());
            } finally {
                Error.stackTraceLimit = stackTraceLimit;
            }

            for (let stackFrame of stackFrames) {
                if (!stackFrame.getFileName() || !stackFrame.getLineNumber() || !stackFrame.getColumnNumber()) {
                    // we never saw this case; but based on the return types we conclude that there may be cases when the
                    // values are not available
                    continue;
                }
                sourceCodeInfo = await TraceMapCache.INSTANCE.getSourceCodeState(stackFrame.getFileName()!, stackFrame.getLineNumber()!, stackFrame.getColumnNumber()!);
                if (sourceCodeInfo && (sourceCodeInfo.sourceFile.includes("TestsAreDemo.") || sourceCodeInfo.sourceFile.includes("Tad.") || sourceCodeInfo.sourceFile.includes("TestsAreDemoInner."))) {
                    this.master.setState({ sourceCodeInfo });
                    break;
                }
            }
        } catch (e) {
            // This breaks my policy of "fail fast". Explanation:
            //
            // I don't know how robust will be the "source map" code in the future. But for the moment
            // I observe that bugs keep appearing. It's already the second time when this throws an error
            // and breaks TAD completely. 
            // The idea of this "catch" is to allow TAD to continue, even if the "source map" code fails.
            //
            // UPDATE: the parsing lib was changed; the code has now more "ifs". In theory it should be robust, 
            // and the program won't arrive here any more. However, I'm still keeping (at least for the moment) this.
            console.error("Error caught while trying to process the source map.", e);
        }

        this.master.enableNextStepButton(finishWaitingCallback);
        this.setState({ spotlightParams });

        this.report.onShowSpotlight(sourceCodeInfo, this.lastElementCaptured);
    }

    async hideSpotlight() {
        tad.miniDb.removeHighlight();
        this.setState({ spotlightParams: undefined });
    }

    public enableStepByStepProgrammatically() {
        if (this.master.state.stepByStepMode === StepByStepMode.OFF) {
            throw new Error("You are calling 'enableStepByStepProgrammatically()', but 'Step by step' is not 'Controlled by program'. This function is meant to be used temporarily; maybe you forgot to remove it?");
        } // else "controlled by program" or "on". For "on", this practically doesn't do anything
        // if a comment was stored, we need to empty it, because it wasn't consumed by the UI
        this.currentComment = null;
        this.stepByStep = true;
    }

    protected onDocumentMouseMove(event: MouseEvent) {
        let current = event.target as HTMLElement | null;
        let inspectedElements: HTMLElement[] | undefined = [];
        while (current) {
            const testid = current.getAttribute(this.master.testIdProp);
            if (testid) {
                inspectedElements.push(current);
            }
            current = current.parentElement
        }
        if (inspectedElements.length === 0) {
            this.setState({ inspectedElement: undefined });
            inspectedElements = undefined;
        } else {
            this.setState({ inspectedElement: inspectedElements[0] });
        }
        this.master.setState({ inspectedElements });
    }

    protected onDocumentClick() {
        this.toggleInspect();
    }

    public toggleInspect() {
        if (this.master.state.inspecting) {
            document.removeEventListener("mousemove", this.onDocumentMouseMove);
            document.removeEventListener("click", this.onDocumentClick);
        } else {
            document.addEventListener("mousemove", this.onDocumentMouseMove);
            document.addEventListener("click", this.onDocumentClick);
        }
        this.setState({ inspectedElement: undefined });
        this.master.setState({ inspecting: !this.master.state.inspecting });
    }

    protected renderFloater(target: Element, content: any) {
        return <ReactFloater content={content} open styles={{ options: { zIndex: 10001 } /* TestsAreDemo_overlay + 1 */, container: { borderRadius: "4px" }, floater: { maxWidth: "800px" } }}
            target={target} // it accepts a class name also (e.g. for the spotlight), which would have been more convenient; however, when the spotlight moves, the popup doesn't follow
        />
    }

    render() {
        const messageAndComment = <MessageAndComment tads={this} spotlightParams={this.state.spotlightParams} />;
        if (this.state.testClassDescriptorForDemoComponent?.demoComponent) {
            const Comp = this.state.testClassDescriptorForDemoComponent.demoComponent;
            
            /**
             * Case: displaying demo1, and switching to demo2. Suppose they are very similar, and the root component
             * that is rendered is the same. E.g.:
             * 
             * <ReduxReusableComponents.WrapWithEnhancedStore>
             *   <PeriodPickerRRC id="periodPickerRRC" prop1="..." />
             * </ReduxReusableComponents.WrapWithEnhancedStore>
             * 
             * Switching wouldn't normally trigger an unmount for demo1 and remount of demo2. Being the same components (but only
             * w/ different properties), the same component (that already exists and is synced w/ the DOM) will be reused.
             * 
             * Apparently for a setup like in the above example, this is not OK. Maybe there is some bug and props/state is not
             * properly listened to.
             * 
             * So what we do here: when switching between demo1 and demo2, we change the key. React sees different keys, so it doesn't
             * try to reuse. 
             * 
             * So is it a bug in RRC? Maybe yes, maybe no. But Storybook had this unmount logic.
             * 
             * Referenced in #34673.
             * 
             * UPDATE: meanwhile, the user provides a component; not a render function. I don't know if all this thing w/ key is needed now.
             */
            return <React.Fragment key={String(this.keyForRenderDemoComponent)}>
                <Comp />
            </React.Fragment>
        }
        return <>
            <div id="rtl" className="flex-container flex-grow" />
            <div id="mocha" />
            {this.state.spotlightParams && this.lastElementCaptured && this.state.spotlightParams.focusOnLastElementCaptured && <>
                <Spotlight element={this.lastElementCaptured}>
                    <div className="TestsAreDemo_screenshotPath">Screenshot dir/file: {this.reporterFromSlaveToMaster.currentClass}/{this.reporterFromSlaveToMaster.currentFunction}/{this.report.lastScreenshotName}</div>
                </Spotlight>
                {!this.master.props.forceStepByStep && this.renderFloater(this.lastElementCaptured, messageAndComment)}
            </>}
            {this.state.spotlightParams?.message && (!this.lastElementCaptured || !this.state.spotlightParams.focusOnLastElementCaptured) && <>
                <Modal open>
                    <Modal.Content>{messageAndComment}</Modal.Content>
                </Modal>
            </>}
            {this.state.inspectedElement && <>
                <Spotlight element={this.state.inspectedElement} />
                {this.state.inspectedElementPopup && this.renderFloater(this.state.inspectedElement, this.state.inspectedElementPopup)}
            </>}
        </>
    }
}

/**
 * `spotlightParams` is optional, because maybe the user only wants to show the comment.
 */
function MessageAndComment({ tads, spotlightParams }: { tads: TestsAreDemoSlave, spotlightParams?: SpotlightParams }) {
    const [comment, setComment] = useState<ReactNode>(null);
    const [lastSlideIndex, setLastSlideIndex] = useState<number | undefined>(undefined);
    useEffect(() => {
        if (typeof tads.currentComment === "string") {
            setComment(<MarkdownExt>{tads.currentComment}</MarkdownExt>);
        } else {
            // an element
            setComment(tads.currentComment);
        }
        setLastSlideIndex(tads.currentCommentSlideIndex);

        // clear lastCapturedSlide after it's used; but we saved it in the state; so in case of
        // re-render, we'll still have the value
        tads.currentComment = undefined;
        tads.currentCommentSlideIndex = undefined
    }, []);
    let message = spotlightParams?.message;
    // use divider only when message is not empty;
    // for example, if we capture a slide and we want to show only the content of that slide we don't have an
    // additional message
    const hasMessage = message ? true : false;
    if (typeof message === "string") {
        message = <MarkdownExt>{message}</MarkdownExt>
    } // else is an element
    return <>
        {/* {lastSlideIndex && <Label circular color="brown" content={lastSlideIndex} />} */}
        {/* I think there may be glitches because this doesn't come from the state, and we don't explicitly re-render; 
        I saw a bit for the first test. Idem in the other place where .report is used */}
        {<Label circular color="brown" content={tads.report.lastScreenshotNumber} />}
        {message}
        {comment &&
            <>
                {hasMessage && <Divider />}
                <p />
                {comment}
            </>}
    </>
}