import React, { ReactElement } from "react";
import { UiApiHelper } from "./UiApiHelper";
import { ConvertReturnToPromiseForAll } from "@famiprog-foundation/utils";

export class Capture {

    constructor(public componentName: string, public id: string | undefined, public functionName: string, public args?: any[], public highlightHelperId?: string | number) {
    }

    getTestId() {
        return this.componentName + "_" + this.id + "_" + this.functionName;
    }

    getGeneratedSourceCode() {
        const args = this.args?.map(arg => {
            if (typeof arg === "string") {
                return '"' + arg + '"';
            } else {
                return arg
            }
        }).join(",");
        return `await uiApi${this.componentName}(${this.id ? this.id : ""}).dispatcher.${this.functionName}(${args});`;
    }

}

type SomeFunctions = { [key: string]: Function };

interface ListenerProps {
    componentName: string;
    id: string;
    implementation: SomeFunctions
}

class Listener extends React.Component<ListenerProps> {

    static listeners: { [componentNameAndKey: string]: Listener } = {};

    static getKey(componentName: string, id: string) {
        return componentName + "_" + id;
    }

    componentDidMount(): void {
        Listener.listeners[Listener.getKey(this.props.componentName, this.props.id)] = this;
    }

    componentWillUnmount(): void {
        delete Listener.listeners[Listener.getKey(this.props.componentName, this.props.id)];
    }

    render() {
        return null;
    }
}

export class UiApi<P> {

    testids: { [K in keyof P]: string };
    dispatcher: ConvertReturnToPromiseForAll<P>;
    protected _Listener?: (props: { implementation: P }) => ReactElement;

    get Listener(): (props: { implementation: P }) => ReactElement {
        if (!this._Listener) {
            this._Listener = (props: { implementation: P }) => <Listener componentName={this.componentName} id={this.id} implementation={props.implementation as any} />
        }
        return this._Listener;
    }

    constructor(protected uiApiHelper: UiApiHelper, protected componentName: string, protected id: string) {
        const that = this;
        this.testids = new Proxy({}, {
            get(target, p, receiver) {
                const capture = new Capture(that.componentName, that.id, p as string);
                return capture.getTestId();
            },
        }) as any;

        this.dispatcher = new Proxy({}, {
            get(target, p, receiver) {
                return async (...args: any[]) => {
                    const listener = Listener.listeners[Listener.getKey(componentName, id)];
                    if (!listener) {
                        throw new Error("Trying to access UiApi, but listener is not mounted for: " + Listener.getKey(componentName, id));
                    }
                    console.debug("UiApi", componentName, id, p, args);

                    const capture = new Capture(componentName, id, p as string, args);
                    if (await uiApiHelper.onBeforeCallImplementation(that._Listener !== undefined, capture)) {
                        listener.props.implementation[p as string].apply(listener, args);
                    }
                }
            }

        }) as any;
    }

}

