import { GlobalTimers } from "@sinonjs/fake-timers";

export class TestableTimers implements Omit<GlobalTimers<number>, "Date"> {
    setTimeout(callback: (...args: any[]) => void, timeout: number, ...args: any[]): number {
        // :( after switching to mono repo, some types from node (such as this one) started to misbehave.
        // Fortunately this happened only for 2 files; so it's not worth the effort to start digging.
        // (search this text to find the other place(s))
        // applies to all ts-ignores from this file
        // @ts-ignore
        return setTimeout.apply(null, [callback, timeout, ...args]) as any;
    }

    clearTimeout(id: number): void {
        clearTimeout(id);
    }

    setInterval(callback: (...args: any[]) => void, timeout: number, ...args: any[]): number {
        // @ts-ignore
        return setInterval.apply(null, [callback, timeout, ...args]) as any;
    }

    clearInterval(id: number): void {
        clearInterval(id);
    }

    setImmediate(callback: (...args: any[]) => void, ...args: any[]): number {
        // @ts-ignore
        return setImmediate.apply(null, [callback, ...args]) as any;
    }

    clearImmediate(id: number): void {
        clearImmediate(id as any);
    }
}
