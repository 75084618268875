import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import React from "react";
import { Button, Segment } from "semantic-ui-react";

/**
 * INFO: this is a template. Use it cf. instructions below.
 * 
 * 1) copy/paste this dir, and rename it -> ...
 * 1a) rename this file -> ...
 * 2) in the DIR, search replace "TemplateConnectedComponent" -> ... (select dir -> ALT + SHIFT + F)
 * 
 * Is it a page? =>
 * 3) Go to bottom; icon/title = ?
 * 4) Remove the template for component
 * 
 * Is it a component => 
 * 3) Go bottom
 * 4) Remove the comment for page and uncomment the one for component
 *
 * 5) remove this message
 * 
 * NOTE: this is just a template. There is also a demo showing different possibilities in: leave-management-react\src\pages\DemoSliceFoundation
 */
export const sliceTemplateConnectedComponent = createSliceFoundation(class SliceTemplateConnectedComponent {
    initialState = {
        counter: 1,
    }

    reducers = {
        ...getBaseReducers<SliceTemplateConnectedComponent>(this),

        multiplyAndIncrement(state: StateFrom<SliceTemplateConnectedComponent>, p: { multiplyExisting: number, add: number }) {
            state.counter = state.counter * p.multiplyExisting + p.add;
        }
    }

    impures = {
        ...getBaseImpures<SliceTemplateConnectedComponent>(this),

        impureIncrementCounter1(p: number) {
            // as an example set timeout simulates an async call; it's not a constraint to use it!
            setTimeout(() => this.getDispatchers().multiplyAndIncrement({ multiplyExisting: 10, add: 10 }));
        }
    }
})

export class TemplateConnectedComponent extends TabbedPage<PropsFrom<typeof sliceTemplateConnectedComponent>> {
    protected getTitle() {
        return { icon: "cog", title: _msg({ missingKeyStrategy: "RETURN_KEY" }, "TemplateConnectedComponent") };
    }

    protected renderMain() {
        const props = this.props;
        return (<Segment>
            App: <b>{_msg("foundation.app.title")}</b>, comp: <b>TemplateConnectedComponent</b>, counter: <span data-cy="something-important">{props.counter}</span>&nbsp;
            <Button onClick={() => props.dispatchers.multiplyAndIncrement({ multiplyExisting: 2, add: 1 })}>multiplyAndIncrement</Button>
        </Segment>);
    }
}

export const infoTemplateConnectedComponent = new ConnectedPageInfo(sliceTemplateConnectedComponent, TemplateConnectedComponent, _msg({ missingKeyStrategy: "RETURN_KEY" }, "TemplateConnectedComponent"));

// INSTRUCTIONS:
// If this is a page => REMOVE this message + below
// If this is a component => REMOVE the 2 exports above and this message; and then 
// uncomment below

// export function TemplateConnectedComponent(props: PropsFrom<typeof sliceTemplateConnectedComponent>) {
//     return (<Segment>
//          App: <b>{_msg("foundation.app.title")}</b>, comp: <b>TemplateConnectedComponent</b>, counter: <span data-cy="something-important">{props.counter}</span>&nbsp;
//         <Button onClick={() => props.dispatchers.multiplyAndIncrement({ multiplyExisting: 2, add: 1 })}>multiplyAndIncrement</Button>
//     </Segment>);
// }
"../../..""../../../components/TabbedPage/TabbedPage"