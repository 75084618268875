import { AppMetaTempGlobals } from "../AppMetaTempGlobals";

export function atLeastOneSubmenuVisible(parentMenu: any): boolean {
    // The submenus are hidden when the global settings removeMenuEntryWhenNoPermission = true and the permissions are missing
    var submenuVisible: boolean = false;
    for (var i = 0; i < parentMenu.submenus.length; i++) {
        if ((parentMenu.submenus[i].submenus?.length || 0) > 0) {
            submenuVisible = atLeastOneSubmenuVisible(parentMenu.submenus[i]);
        } else {
            submenuVisible = !AppMetaTempGlobals.appMetaInstance.removeMenuEntryWhenNoPermission || AppMetaTempGlobals.appMetaInstance.hasPermission(parentMenu.submenus[i].permission);
        }
        if (submenuVisible) {
            break;
        }
    } 
    
    return submenuVisible;
}