import { createSliceFoundation, getBaseReducers, PropsFrom, StateFrom, getBaseImpures } from "@crispico/foundation-react/reduxHelpers";
import React from "react";
import { sliceFilterForm, FilterForm } from "./FilterForm";
import { FilterBlock } from "./CustomQueryBlock";
import { ModalExt } from "../ModalExt/ModalExt";
import { Icon, Button, Label, Modal } from "semantic-ui-react";
import lodash from 'lodash';
import { BlocklyEditorTab, BlocklyEditorTabRRC, DEFAULT_COMPOSED_FILTER } from "./BlocklyEditorTab";
import { PopupWithHelpTooltip } from "../semanticUiReactExt";
import { Filter } from "./Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { ErrorType } from "./dataStructures";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import _ from "lodash";
import { EntityDescriptor } from "@crispico/foundation-react";
import { AuditUtils } from "@crispico/foundation-react/pages/Audit/AuditUtils";
import { createTestids } from "@famiprog-foundation/tests-are-demo";

export const filterBarTestids = createTestids("FilterBar", {
    addFilterButton: "",
});

// TODO: cand va fi migrat la RRC, de observat TODO-urile de mai jos
export const sliceFilterBar = createSliceFoundation(class SliceFilterBar {

    initialState = {
        openedFilter: Filter.createForClient(),
        filterFormOpened: false as [number, number] | boolean,
        filterIndex: -1
    }

    nestedSlices = {
        filterForm: sliceFilterForm,
    }

    reducers = {
        ...getBaseReducers<SliceFilterBar>(this),  
        openEditor(state: StateFrom<SliceFilterBar>, data: { rootFilter: Filter, index: number, position: [number, number] | boolean }) {
            if (!(data.index >= 0 && data.rootFilter.filters![data.index].filters)) {
                this.openFilterForm(state, data);
            } // else, in the past, it opened the blockly editor
        },

        openFilterForm(state: StateFrom<SliceFilterBar>, data: { rootFilter: Filter, index: number, position: [number, number] | boolean }) {
            if (state.filterFormOpened) { return; }
            state.filterFormOpened = data.position;
            if (data.index !== -1) {
                state.openedFilter = data.rootFilter.filters![data.index];
            } else {
                state.openedFilter = Filter.createForClient();
            }
            state.filterIndex = data.index;
        },

        openFilterFormWithField(state: StateFrom<SliceFilterBar>, data: { entityName: string, field: string, rootFilter: Filter, position: [number, number] | boolean }) {
            this.openFilterForm(state, { rootFilter: data.rootFilter, index: -1, position: data.position });
            state.openedFilter.field = data.field;
        },

        onCloseFilterForm(state: StateFrom<SliceFilterBar>) {
            state.filterFormOpened = false;
            state.filterIndex = -1;
            state.filterForm = {
                ...this.getSlice().nestedSlices.filterForm.initialState,
                error: ErrorType.NONE,
            }
        }
    }

    impures = {
        ...getBaseImpures<SliceFilterBar>(this),

        onApplyAndAddNew(filter: Filter, entityName: string, rootFilter: Filter, setFilterInCustomQuery?: (filter: Filter) => void) {
            let position = this.getState().filterFormOpened;
            this.onApply(filter, rootFilter, setFilterInCustomQuery);
            this.getDispatchers().setInReduxState({
                filterForm: {
                    ...this.getSlice().nestedSlices.filterForm.initialState,
                }
            })
            this.getDispatchers().openFilterForm({ rootFilter: rootFilter, index: -1, position: position });
        },

        onApplyAndAddNewSameField(filter: Filter, entityName: string, blocklyEditorRef: React.RefObject<BlocklyEditorTab> | undefined, rootFilter: Filter, setFilterInCustomQuery?: (filter: Filter) => void) {
            let position = this.getState().filterFormOpened!;
            if (rootFilter.operator === FilterOperators.forComposedFilter.and.value) {
                let index = this.getState().filterIndex;
                index = index === -1 ? rootFilter.filters!.length : index;
                let newFilter = Filter.createComposedForClient(FilterOperators.forComposedFilter.or, [filter]);
                const rf = this.onApply(newFilter, rootFilter);
                this.getDispatchers().setInReduxState({
                    filterFormOpened: false,
                    filterIndex: -1,
                    filterForm: { ...sliceFilterBar.nestedSlices.filterForm.initialState, error: ErrorType.NONE }
                });
                this.getDispatchers().openEditor({ rootFilter: rf, index: index, position: position });
                blocklyEditorRef?.current?.getBlockFromPath('filters' + Utils.defaultIdSeparator + index, blocklyEditorRef.current!.workspace, blocklyEditorRef.current!.topBlockId).select();
                blocklyEditorRef?.current?.addFilter(Filter.createForClient(filter.field), blocklyEditorRef.current!.workspace, blocklyEditorRef.current!.topBlockId, entityName);
                return;
            }
            this.onApply(filter, rootFilter, setFilterInCustomQuery);
            this.getDispatchers().openFilterFormWithField({ entityName: entityName, field: filter.field!, rootFilter, position: position });
        },

        removeFilter(index: number, rootFilter: Filter, setFilterInCustomQuery?: (filter: Filter) => void) {
            setFilterInCustomQuery?.({ ...rootFilter, ...{ filters: rootFilter.filters!.filter((f, i) => i !== index) } });
        },

        toggleFilterEnabled(index: number, rootFilter: Filter, setFilterInCustomQuery?: (filter: Filter) => void) {
            const rf = lodash.cloneDeep(rootFilter);
            rf.filters![index].enabled = !rootFilter.filters![index].enabled;
            setFilterInCustomQuery?.(rf);
        },

        onApply(filter: Filter, rootFilter: Filter, setFilterInCustomQuery?: (filter: Filter) => void) {
            const rf = lodash.cloneDeep(rootFilter);
            if (this.getState().filterIndex >= 0) {
                rf.filters![this.getState().filterIndex] = filter;
            } else {
                rf.filters!.push(filter);
            }
            this.getDispatchers().setInReduxState({ filterFormOpened: false, filterIndex: -1 })
            setFilterInCustomQuery && setFilterInCustomQuery(rf);
            return rf;
        },

        changeRootFilterOperator(rootFilter: Filter, setFilterInCustomQuery?: (filter: Filter) => void) {
            const rf = lodash.cloneDeep(rootFilter);
            rf.operator = rootFilter.operator === FilterOperators.forComposedFilter.or.value ? FilterOperators.forComposedFilter.and.value : FilterOperators.forComposedFilter.or.value;
            setFilterInCustomQuery?.(rf);
        },

    }

});

interface FilterBarProps {
    entityName: string,
    rootFilter: Filter | undefined,
    setFilterInCustomQuery: (filter: Filter) => void,
    showTooltip?: boolean,
    hide?: boolean
}

export class FilterBar extends React.Component<PropsFrom<typeof sliceFilterBar> & FilterBarProps> {

    onCloseFilterForm = () => {
        this.props.dispatchers.onCloseFilterForm();
    }

    onApply = (filter: Filter) => this.props.dispatchers.onApply(filter, filterOrDefault(this.props.rootFilter), this.props.setFilterInCustomQuery);

    openEditor = (e: any, index: number) => this.props.dispatchers.openEditor({ rootFilter: filterOrDefault(this.props.rootFilter), index: index, position: [e.clientX, e.clientY] });

    openFilterForm = (e: any, index: number) => this.props.dispatchers.openFilterForm({ rootFilter: filterOrDefault(this.props.rootFilter), index: index, position: [e.clientX, e.clientY] });

    onApplyAndAddNew = (filter: Filter) => this.props.dispatchers.onApplyAndAddNew(filter, this.props.entityName, filterOrDefault(this.props.rootFilter), this.props.setFilterInCustomQuery);

    onApplyAndAddNewSameField = (filter: Filter) => this.props.dispatchers.onApplyAndAddNewSameField(filter, this.props.entityName, undefined, filterOrDefault(this.props.rootFilter), this.props.setFilterInCustomQuery);

    render() {
        const rootFilter = filterOrDefault(this.props.rootFilter);
        let parentFilter = lodash.cloneDeep(rootFilter);
        parentFilter.filters = this.props.filterIndex !== -1 ? parentFilter.filters?.filter((f, index) => index !== this.props.filterIndex) : parentFilter.filters;

        const entityMappingIds = AuditUtils.entityMappingIdFromFilters(rootFilter.filters);
        const foundAuditEntityMappingId = entityMappingIds.length === 1 ? entityMappingIds[0] : undefined
        return (
            <>
                {!this.props.hide && <Button data-testid={filterBarTestids.addFilterButton} className="CustomQueryBar_sortFilterAddButton" style={{ height: 38 }} onClick={e => this.openFilterForm(e, -1)} color="green"><Icon name='add'></Icon>{_msg('CustomQueryBar.filterBy')}</Button>}
                {!this.props.hide && !(rootFilter.filters?.length) && this.props.showTooltip && <PopupWithHelpTooltip tooltip={_msg("Filter.infoText")} />}
                {!this.props.hide && rootFilter.filters && rootFilter.filters.length > 1 && <Label className="CustomQueryBar_child" as='a' onClick={() => this.props.dispatchers.changeRootFilterOperator(rootFilter, this.props.setFilterInCustomQuery)} color={rootFilter.operator === FilterOperators.forComposedFilter.or.value ? 'teal' : 'blue'}>{_msg('Filter.operator.' + rootFilter.operator)}</Label>}
                {!this.props.hide && rootFilter.filters && rootFilter.filters.map((filter, index) =>
                    <div className="CustomQueryBar_child" key={index}><FilterBlock onBlockClick={(e: any) => this.openEditor(e, index)} toggleFilterEnabled={() => this.props.dispatchers.toggleFilterEnabled(index, rootFilter, this.props.setFilterInCustomQuery)}
                        removeFilter={() => this.props.dispatchers.removeFilter(index, rootFilter, this.props.setFilterInCustomQuery)} filter={filter} index={index} key={index}
                        entityDescriptorName={this.props.entityName} foundAuditEntityMappingId={foundAuditEntityMappingId} /> </div>)}
                <FilterForm parentFilter={parentFilter} filterFormOpened={this.props.filterFormOpened} {...this.props.filterForm} dispatchers={this.props.dispatchers.filterForm} entityName={this.props.entityName}
                    onClose={this.onCloseFilterForm} onApply={this.onApply} initialFilter={this.props.openedFilter}
                    onApplyAndAddNew={this.onApplyAndAddNew} onApplyAndAddNewSameField={this.onApplyAndAddNewSameField} />
            </>
        );
    }

}

function filterOrDefault(filter: Filter | undefined) {
    return filter ? filter : DEFAULT_COMPOSED_FILTER;
}

export function FilterAsText(props: { filter: Filter | undefined, ed?: EntityDescriptor }) {
    if (!props.filter || _.isEqual(props.filter, Filter.createComposedForClient(FilterOperators.forComposedFilter.and))) {
        return null;
    }
    const entityMappingIds = AuditUtils.entityMappingIdFromFilters([props.filter]);
    const foundAuditEntityMappingId = entityMappingIds.length === 1 ? entityMappingIds[0] : undefined
    return <span style={{ color: "#2fb67e" }}>{_msg("CustomQueryBar.filterBy")}: <span data-testid="FilterBar_filterBy">{Filter.renderAsText(props.filter!, props.ed, foundAuditEntityMappingId)}</span></span>;
}
"../../reduxHelpers""../../utils/Utils""../..""../../pages/Audit/AuditUtils"