import React, { ReactNode } from 'react';
import { Step } from "react-joyride";

// separate files because of issues w/ cross references between files

export type JoyrideDecoratorStep = Partial<Step> & { shortKey?: string } | string;

export type JoyrideDecoratorProps = {
    steps: Step[],
    children?: ReactNode,

    // in ArticlePage: false; in Storybook: true
    sidebar?: boolean

    // if exists: state comes from parent; current state not used; i.e. prod mode
    // if !exists => storybook mode
    stepIndex?: number
    updateStepIndex?: (value: number) => void,
    obtainSetStepIndexOnClickFunction?: (func: (stepIndex: number) => void) => void
    onFinish?: () => void
};

export type JoyrideDecoratorParams = {
    teaser: string,
    steps: Step[]
}

export type ZeroTrainingStory = (() => ReactNode) & {
    story: {
        name: string;
        parameters: {
            joyrideDecorator: JoyrideDecoratorParams;
            renderListenerDecorator: Function;
        }
    }
}

export type ZeroTrainingArticle = {
    key: string;
    title: string;
    stories: ZeroTrainingStory[]
}

export class ZeroTrainingArticlesRegistry {
    articles: { [key: string]: ZeroTrainingArticle } = {};

    add(article: ZeroTrainingArticle) {
        if (!article.title) {
            article.title = _msg(article.key);
        }
        this.articles[article.key] = article;
    }

    addFromModule(mod: any) {
        this.add(ZeroTrainingArticlesRegistry.createArticleFromModule(mod));
    }

    static createArticleFromModule(mod: any) {
        const article: ZeroTrainingArticle = { stories: [] } as any;
        for (let key in mod) {
            const entry = mod[key];
            if (key === "default") {
                if (!entry.key) { throw new Error("In the default export of the module, no 'key' found. Make sure to use 'zeroTrainingArticle()'!")};
                article.key = entry.key;
                article.title = entry.title; // may exist; or probably not
                continue;
            }
            
            if (!entry.story?.parameters?.joyrideDecorator) { continue; }
            article.stories.push(entry);
        }
        return article;
    }

    getAsList() {
        // maybe some sorting by alpha, date, etc?
        return Object.values(this.articles).sort((a, b) => a.title.localeCompare(b.title));
    }
}

export const ZeroTrainingContext = React.createContext<ZeroTrainingArticlesRegistry>(new ZeroTrainingArticlesRegistry());

/**
 * To be used as default export for .stories.ts files.
 */
export function zeroTrainingArticle(categoryKey: string, key: string, otherProps?: any) {
    otherProps = otherProps || {};
    otherProps.key = categoryKey + "-" + key;
    if (!otherProps.title) {
        otherProps.title = _msg({ missingKeyStrategy: "RETURN_KEY" }, categoryKey) + "/" + _msg({ missingKeyStrategy: "RETURN_KEY" }, key);
    }
    return otherProps;
}