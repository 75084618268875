import { SemanticCOLORS } from "semantic-ui-react";
import { tad } from "../TestsAreDemoFunctions";
import { TestsAreDemoSlave } from "../TestsAreDemoSlave";

export interface MiniDbTable {
    [id: number | string]: {
        meta?: {
            color: SemanticCOLORS
        },
        [key: string]: any
    }
};
export interface MiniDbTableSet { [entityName: string]: MiniDbTable };

export interface Highlighted {
    entityName: string,
    id: number | string;
    field: string;
}

export class MiniDb {

    data: MiniDbTableSet = {};

    highlighted?: Highlighted;

    protected addRow(entityName: string, id: number | string, row: any) {
        let table = this.data[entityName];
        if (!table) {
            this.data[entityName] = table = {};
        }
        table[id] = row;
    }

    // TODO experimental: I think clear() and populate() should also do a ...forceUpdate(); currently it works w/o;
    // probably somebody else triggers updates, and they work for us, here, also
    clear() {
        this.data = {};
        this.highlighted = undefined;
        return this;
    }

    async populate(entityName: string, ...ids: any[]): Promise<MiniDb> {
        throw new Error("Not implemented. Please create a subclass and implement this function according to your server backend/DB.");
    }

    get(entityName: string, id: number | string, field: string) {
        const table = this.data[entityName];
        if (!table) {
            throw new Error("Cannot find entityName: " + entityName);
        }
        const row = table[id];
        if (!row) {
            throw new Error(`For entityName = ${entityName}, cannot find row w/ id = ${id}`);
        }
        this.highlighted = { entityName, id, field };
        TestsAreDemoSlave.INSTANCE.master.refMiniDbTableSetComponent.current?.forceUpdate();
        return row[field];
    }

    getDerived(entityName: string, id: number | string, field: string, value: any | ((valueFromDb: any) => any)) {
        // even if we don't use the value in the 2 IF branches, we need to call it to "mark"
        const valueFromDb = this.get(entityName, id, field);
        if (typeof value === "function") {
            return (value as Function).call(null, valueFromDb);
        } else {
            return value;
        }
    }

    removeHighlight() {
        this.highlighted = undefined;
        TestsAreDemoSlave.INSTANCE.master.refMiniDbTableSetComponent.current?.forceUpdate();
    }

}