import "./SyntaxHighlighterExt.css";
import React from "react";
import { FoundationUtils } from "@famiprog-foundation/utils";
import SyntaxHighlighter, { SyntaxHighlighterProps } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const CURRENT_LINE_BACKGROUND = 'rgb(219, 255, 219)';
export const UPPER_LINE_ID = "upperLine";
export const CURRENT_LINE_ID = "currentLine";
export const CODE_ID = "code";

export const syntaxHighlighterExtTestIds = FoundationUtils.createTestids("TestsAreDemoMaster", {
    line: "", code: "", fileName: "", lineNumber: ""
});

export function SyntaxHighlighterExt(props: { highlightSourceLine?: number } & SyntaxHighlighterProps) {
    const { highlightSourceLine, ...otherProps } = props;
    // TODO: the first class name: I don't see it in the DOM. Second: there is, but I don't understand the purpose
    // I tested in ScriptableUi, which uses this component in a limited manner. Let's see in TAD
    return <SyntaxHighlighter id="SyntaxHighlighter" codeTagProps={{ "data-testid": syntaxHighlighterExtTestIds.code, id: CODE_ID } as React.HTMLProps<HTMLElement>} language="javascript" style={docco} wrapLines showLineNumbers lineProps={lineNumber => {
        let style = { background: lineNumber === highlightSourceLine ? CURRENT_LINE_BACKGROUND : undefined };
        return { "data-testid": syntaxHighlighterExtTestIds.line + "_" + lineNumber, style, id: highlightSourceLine ? (lineNumber === highlightSourceLine ? CURRENT_LINE_ID : lineNumber === highlightSourceLine - 1 ? UPPER_LINE_ID : undefined) : undefined };
    }} {...otherProps} />
}