import { PayloadAction } from "@reduxjs/toolkit";
import React, { ReactElement } from "react";
import { Icon, Segment, SegmentGroup } from "semantic-ui-react";
import { AbstractReducers, CompMeta, PropsFromState } from "../../CompMeta";
import { Utils } from "../../utils/Utils";

export type RenderItemParams = { props: Props, linearizedItem: LinearizedItem };

export class TreeAdapter {
    hasChildren(item: any) {
        return true;
    }

    getChildren(item: any): { localId: string, item: any }[] {
        return Object.keys(item).map(key => ({ localId: key, item: item[key] }));
    }
}

export interface OnSelectParams {
    itemId: string,
    prevent: boolean
}

type PropsNotFromState = {
    root: any;
    treeAdapter: TreeAdapter;
    renderItemFunction: (params: RenderItemParams) => ReactElement | null;
    onSelectItem?: (params: OnSelectParams) => void;
    onHoverItem?: (params: OnSelectParams) => void;
    styleItemWrapperFunction?: (props: RenderItemParams) => any;
}

export type Props = PropsFromState<TreeState> & PropsNotFromState & { helper: TreeMeta; };

export interface LinearizedItem {
    index: number;
    itemId: string;
    indent: number;
    expanded: Expanded;
}

export enum Expanded { COLLAPSED, EXPANDED, LEAF };

const initialState = {
    expandedIds: {} as { [key: string]: boolean },
    linearizedItems: [] as Array<LinearizedItem>,
    hoveredId: undefined as string | undefined,
    selectedId: undefined as string | undefined
}

export type TreeState = typeof initialState;

export class TreeReducers extends AbstractReducers<TreeState> {

    treeAdapter!: TreeAdapter;
    root: any;

    expandCollapse(state: TreeState, action: PayloadAction<{ id: string, isExpand: boolean }>) {
        if (action.payload.isExpand) {
            state.expandedIds[action.payload.id] = true;
        } else {
            delete state.expandedIds[action.payload.id];
        }
        this.linearize(state);
    }

    linearize(state: TreeState) {
        state.linearizedItems = [];
        this._linearize(state, this.root, "", -1);
        
        // reset selection/hover if not valid any more on the new data
        if (state.hoveredId && !state.linearizedItems.find(item => item.itemId === state.hoveredId)) {
            state.hoveredId = undefined;
        }
        if (state.selectedId && !state.linearizedItems.find(item => item.itemId === state.selectedId)) {
            state.selectedId = undefined;
        }
    }

    _linearize(state: TreeState, currentItem: any, currentId: string, indent: number) {
        const hasChildren = this.treeAdapter.hasChildren(currentItem);
        if (currentId) {
            const linearizedItem: LinearizedItem = { indent, itemId: currentId, expanded: hasChildren ? Expanded.EXPANDED : Expanded.LEAF, index: state.linearizedItems!.length };
            state.linearizedItems!.push(linearizedItem);
            if (hasChildren && !state.expandedIds[currentId]) {
                linearizedItem.expanded = Expanded.COLLAPSED;
                return;
            }
        } // else is root

        if (!hasChildren) {
            // not usual; may happen if the root has changed and an expanded turned into a leaf
            return;
        }

        this.treeAdapter.getChildren(currentItem)?.forEach(itemWithLocalId => this._linearize(state, itemWithLocalId.item,
            currentId ? currentId + Utils.defaultIdSeparator + itemWithLocalId.localId : itemWithLocalId.localId, indent + 1));
    }

    selectItem(state: TreeState, action: PayloadAction<string>) {
        if (state.selectedId !== action.payload) {
            state.selectedId = action.payload;
        } else {
            // TODO CC: why?
            // LA: maybe to deselect; corresponding test (Tree.test.ts) needs to be edited accordingly
            // state.selectedId = undefined;
        }
    }

    reveal(state: TreeState, action: PayloadAction<{ ids: string[], expandIds: boolean, collapseOthers: boolean, toggle?: boolean }>) {
        if (action.payload.toggle && action.payload.ids.length === 1 && state.expandedIds[action.payload.ids[0]]) {
            state.expandedIds[action.payload.ids[0]] = false;
            this.linearize(state);
            return;
        }

        if (action.payload.collapseOthers) {
            state.expandedIds = {} as any;
        }
        for (let id of action.payload.ids) {
            let first = true;
            while (true) {
                if (!first || action.payload.expandIds) {
                    state.expandedIds[id] = true;
                }
                const nextId = Utils.substringBefore(id, Utils.defaultIdSeparator, true);
                if (nextId === id) {
                    break; // i.e. no more separator
                } else {
                    id = nextId;
                };
                first = false;
            }
        }
        this.linearize(state);
    }

    collapseAll(state: TreeState) {
        state.expandedIds = {} as any;
        this.linearize(state);
    }
}

export class TreeMeta<COMPONENT = React.Component<PropsNotFromState>> extends CompMeta<TreeReducers, any, COMPONENT> {

    constructor(keyInState: string) {
        super(initialState, TreeReducers, null, keyInState);
        this.componentClass = TreePure as any;
        this.setForwardRef();
    }

    navigateToItem(root: any, linearizedItems: LinearizedItem[], index: number) {
        const nextObjectId = linearizedItems[index]?.itemId;
        return nextObjectId && Utils.navigate(root, nextObjectId);
    }
}

export class TreePure extends React.Component<Props> {

    protected rootJustChanged = false;

    paddingLeftBase = 10;
    paddingLeftFactor = 15;
    hoverColor = "rgba(0, 0, 0, 0.03)";
    selectedColor = "rgba(0, 0, 0, 0.15)";

    constructor(props: Props) {
        super(props);

        // we need to invoke here, because shouldComponentUpdate() is not invoked for the first render
        this.shouldComponentUpdateInternal(this.props, true);
    }

    shouldComponentUpdate(nextProps: Props) {
        return this.shouldComponentUpdateInternal(nextProps, false);
    }

    protected setTreeAdapter(treeAdapter: TreeAdapter) {
        if (!treeAdapter) {
            throw new Error("'treeAdapter' is mandatory; was set to null.")
        }

        this.props.helper.reducersInstance.treeAdapter = treeAdapter;
    }

    protected shouldComponentUpdateInternal(nextProps: Props, firstRender: boolean) {
        const that = this.props.helper;

        if (!firstRender) {
            if (nextProps.root === this.props.root && nextProps.treeAdapter === this.props.treeAdapter) {
                this.rootJustChanged = false;
                return true;
            } // else => root OR treeAdapter was changed 
        }

        this.setTreeAdapter(nextProps.treeAdapter);
        that.reducersInstance.root = nextProps.root;
        this.props.dispatch(that.getActionsFactory().linearize(null));

        /** 
         * Initially we had this logic at "componentDidUpdate()"; more precisely "useEffect()" hook, as this was a functional component. BUT:
         * Right now the root is not in sync w/ linearizedItems; if a render() is attempted, ids from linearizedItems may point to
         * objects that don't exist any more. And e.g. the user provided renderItem function will fail. Hence we want to prevent
         * a render right now. The dispatch above will trigger a state change hence this connected component will be notified, and
         * this method will be called again. See also the comment in "render()".
         */
        this.rootJustChanged = !firstRender; // for firstRender, this is true

        return false;
    }

    protected renderMain(mainProps: any, mainChildren: Array<any>): ReactElement {
        return React.createElement(SegmentGroup, mainProps, mainChildren);
    }

    protected createItemWrapperProps(linearizedItem: LinearizedItem) {
        const props = this.props;
        const ac = this.props.helper.getActionsFactory();

        return {
            key: linearizedItem.itemId, "data-cy": linearizedItem.itemId,
            onClick: () => {
                if (props.selectedId !== linearizedItem.itemId) {
                    const params = { itemId: linearizedItem.itemId, prevent: false };
                    props.onSelectItem?.call(null, params);
                    if (params.prevent) {
                        return;
                    }
                    props.dispatch(ac.selectItem(linearizedItem.itemId));
                }
            },
            onMouseOver: () => {
                if (props.hoveredId !== linearizedItem.itemId) {
                    const params = { itemId: linearizedItem.itemId, prevent: false };
                    props.onHoverItem?.call(null, params);
                    if (params.prevent) {
                        return;
                    }
                    props.dispatch(ac.setState({ hoveredId: linearizedItem.itemId }));
                }
            },
            style: Object.assign({
                display: "flex",
                alignItems: "center",
                paddingLeft: (this.paddingLeftBase + linearizedItem.indent * this.paddingLeftFactor) + "px",
                background: linearizedItem.itemId === props.selectedId ? this.selectedColor : linearizedItem.itemId === props.hoveredId ? this.hoverColor : null
            }, props.styleItemWrapperFunction?.call(null, { props, linearizedItem }))
        }
    }

    protected getChildrenIcon(linearizedItem: LinearizedItem, collapsedIcon: string, expandedIcon: string): any {
        switch (linearizedItem.expanded) {
            case Expanded.LEAF: return null;
            case Expanded.COLLAPSED: return collapsedIcon;
            case Expanded.EXPANDED: return expandedIcon;
        }
    }

    protected renderItemWrapper(linearizedItem: LinearizedItem, itemWrapperProps: any) {
        const props = this.props;
        const ac = this.props.helper.getActionsFactory();
        const icon = this.getChildrenIcon(linearizedItem, "plus square outline", "minus square outline");

        return (<Segment {...itemWrapperProps}>
            {icon && <Icon link size="large" name={icon}
                onClick={() => props.dispatch(ac.expandCollapse({ id: linearizedItem.itemId, isExpand: linearizedItem.expanded === Expanded.COLLAPSED }))}
            />}
            {this.renderItem({ props, linearizedItem })}
        </Segment>);
    }

    protected renderItem(params: RenderItemParams) {
        const result = params.props.renderItemFunction?.call(null, params);
        if (result) {
            return result;
        }
        const ids = params.linearizedItem.itemId.split(Utils.defaultIdSeparator)
        return ids[ids.length - 1];
    }

    render() {
        if (this.rootJustChanged) {
            /** 
             * At the moment of writing, if shouldComponentUpdate() returns false => render() is not called. However the docs state that
             * in the future, the result of shouldComponentUpdate() may be taken as a hint; not as a guarantee, and hence render() may still
             * be called. And hence the code will arrive here. Note: that this is also true w/ the functional components / hooks version (i.e. React.memo()).
             * 
             * If this will happen, the only solution I see right now is to "memoize" (or simpler: just cache) the render function; i.e. if the code gets here => 
             * return the previous result.
             */
            throw new Error("An illegal state was detected. This was anticipated, so please follow the instructions and update the code.")
        }
        const props = this.props;
        const that = this.props.helper;

        const mainChildren = props.linearizedItems.map((linearizedItem) => this.renderItemWrapper(linearizedItem, this.createItemWrapperProps(linearizedItem)));

        return this.renderMain({ "data-cy": that.getKeyInState() }, mainChildren);
    }
}