import React from "react";
import { Button, Icon, Segment, Step } from "semantic-ui-react";
import { RRCProps, Reducers, ReduxReusableComponents, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { createTestids } from "@famiprog-foundation/tests-are-demo";

export const wizardTestids = createTestids("Wizard", {
    finish: "",
});

export interface WizardStep {
    title: String;
    description?: String;
    icon: String;
    render: () => React.ReactNode;
    onPreviousClick?: () => boolean;
    onNextClick?: () => boolean;
    previousDisabled?: () => boolean;
    nextDisabled?: () => boolean;
}

export class WizardState extends State {
    currentStepIndex = 0;
}

export class WizardReducers<S extends WizardState = WizardState> extends Reducers<S> {
    reset() {
        this.s.currentStepIndex = 0;
    }
}

type Props = RRCProps<WizardState, WizardReducers> & {
    steps: Array<WizardStep>;
    onFinishClick?: () => void;
    onCancelClick?: () => void;
};

export class Wizard extends React.Component<Props> {

    public getCurrentStepIndex() {
        return this.props.s.currentStepIndex;
    }

    back(currentStepIndex: number, steps: Array<WizardStep>) {
        if (currentStepIndex > 0) {
            const currentStep = steps[currentStepIndex];
            if (currentStep.onPreviousClick && !currentStep.onPreviousClick.apply(null)) {
                return;
            }
            this.props.r.setInReduxState({ currentStepIndex: currentStepIndex - 1 });
        }
    }

    next(currentStepIndex: number, steps: Array<WizardStep>) {
        if (currentStepIndex < steps.length - 1) {
            const currentStep = steps[currentStepIndex];
            if (currentStep.onNextClick && !currentStep.onNextClick.apply(null)) {
                return;
            }
            this.props.r.setInReduxState({ currentStepIndex: currentStepIndex + 1 });
        }
    }

    render() {
        const props = this.props;
        return <React.Fragment>
            <Step.Group widths={props.steps.length as any} size="small">
                {props.steps.map((step, index) => {
                    return (
                        <Step data-cy={"step" + index} active={index === props.s.currentStepIndex} key={"step" + index}>
                            {step.icon && <Icon name={step.icon as any} />}
                            <Step.Content>
                                <Step.Title>{step.title}</Step.Title>
                                <Step.Description>{step.description}</Step.Description>
                            </Step.Content>
                        </Step>
                    );
                })}
            </Step.Group>

            {props.s.currentStepIndex >= 0 && props.steps[props.s.currentStepIndex].render?.apply(null)}

            <Segment className="buttonBar">
                <Button data-cy={"prevBtn"} disabled={props.s.currentStepIndex === 0 || props.steps[props.s.currentStepIndex].previousDisabled?.()} onClick={() => this.back(props.s.currentStepIndex, props.steps)}>
                    <Icon name='arrow left' />
                    {_msg("Wizard.previous")}
                </Button>
                <Button data-cy={"nextBtn"} disabled={props.s.currentStepIndex === props.steps.length - 1 || props.steps[props.s.currentStepIndex].nextDisabled?.()} onClick={() => this.next(props.s.currentStepIndex, props.steps)}>
                    {_msg("Wizard.next")}
                    <Icon name='arrow right' />
                </Button>
                <Button data-cy={"finishBtn"} data-testid={wizardTestids.finish} disabled={props.s.currentStepIndex !== props.steps.length - 1} onClick={() => props.onFinishClick?.apply(null)}>{_msg("Wizard.finish")}</Button>
                <Button data-cy={"cancelBtn"} onClick={() => props.onCancelClick?.apply(null)} >{_msg("general.cancel")}</Button>
            </Segment>
        </React.Fragment>;
    }
}

export const WizardRRC = ReduxReusableComponents.connectRRC(WizardState, WizardReducers, Wizard);


"../../reduxReusableComponents/ReduxReusableComponents"