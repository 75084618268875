import React from "react";
import { Button, Icon, Modal, Segment } from "semantic-ui-react";
import { ModalExt } from "../ModalExt/ModalExt";
import { FallbackProps } from "react-error-boundary";

type ErrorComponentProps = FallbackProps & {hintFixErrorMessage?: string};

export class ErrorComponent extends React.Component<ErrorComponentProps, { errorModal: boolean }> {
    constructor(props: ErrorComponentProps) {
        super(props);
        this.state = { errorModal: false };
    }

    renderStackModal() {
        return <ModalExt closeIcon={true} open={this.state.errorModal} onClose={() => this.setState({ errorModal: false })}>
            <Modal.Header>
                {this.props.error?.message}
            </Modal.Header>
            <Modal.Content>
                {this.props.error?.stack}
            </Modal.Content>
        </ModalExt>
    }

    render() {
        return <Segment style={{overflow: 'auto'}}>
            <h1 style={{ color: 'red' }}>{_msg('general.error')}</h1>
            {this.props.hintFixErrorMessage ? <h3 style={{ color: 'red' }}>{this.props.hintFixErrorMessage}</h3> : null} 
            <p>
                <Icon name="info circle" onClick={() => this.setState({ errorModal: true })} />
                {this.renderStackModal()}
                {this.props.error?.name}: {this.props.error?.message}
            </p>
            <Button floated='right' onClick={() => this.props.resetErrorBoundary()} primary>{_msg("general.tryAgain")}</Button>
        </Segment>
    }
}