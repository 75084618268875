import { DummyToRememberPeopleToCast, EntityDescriptor, FieldDescriptor } from "../EntityDescriptor";
import { castWithHw, ComponentWithScriptableUi, HW, ScriptableUi, ScriptableUiHighlightWrapper, ScriptableUiImpl, WithHW } from "@famiprog-foundation/scriptable-ui";
import React, { ReactElement } from "react";

export interface IFormValuesHolder<V> {
    get values(): V;
    setFieldValue(field: string, value: any, shouldValidate?: boolean): void;
}

/**
 * All these props are also in FieldEditor.propsToExcludeWhenForwarded.
 */
export interface FieldEditorProps<V = any> extends DummyToRememberPeopleToCast {
    formikProps?: IFormValuesHolder<any>;
    fieldDescriptor?: FieldDescriptor;
    autoFocus?: boolean;

    /**
     * Used by the filter form to command the value editor to focus.
     * The "normal" input uses this. It's rather optional for others; some editors may not be focusable.
     * 
     * TODO: not used; to be removed, see #28226
     */
    refForFocus?: React.RefObject<any>,

    /**
     * When used stand alone.
     */
    value?: V;

    /**
     * When used stand alone.
     */
    onChangeValue?: (value: V) => void;
}

/**
 * To be used by editors that don't support the stand alone mode. When creating a new editor,
 * it's good (and quite simple) to make it work in stand alone mode. However, we have some old
 * components for which it's difficult to do this; I mean the effort is not worth, considering
 * the low probability to use them as stand alone.
 * 
 * This is also useful for "inline" editors / anonymous classes directly in entity descriptors.
 * Such editors 1/ are not used stand alone and 2/ using this would avoid unnecessary "!".
 */
export interface FieldEditorNotUsableStandAloneProps<V = any> extends FieldEditorProps<V> {
    formikProps: IFormValuesHolder<any>;
    fieldDescriptor: FieldDescriptor;
}

export namespace ScriptableUiFieldEditor {
    export class Main extends ScriptableUi<Main> {
        getComponentName() { return "ScriptableUiFieldEditor.Main" };

        setFieldValue(value: any) { }
    }
}

export class FieldEditor<V, P = {}, S = {}, SUI extends ScriptableUi<any> = ScriptableUiFieldEditor.Main, SS = any> extends ComponentWithScriptableUi<SUI, P & FieldEditorProps<V>, S, SS> {

    protected propsToExcludeWhenForwarded = ["scriptableUiId" /* from parent class */, "formikProps", "fieldDescriptor", "autoFocus", "refForFocus", "value"];

    // @ts-ignore
    protected scriptableUiClass = ScriptableUiFieldEditor.Main;

    // @ts-ignore
    protected scriptableUiImpl: ScriptableUiImpl<ScriptableUiFieldEditor.Main> = {
        setFieldValue: value => {
            if (this.props.onChangeValue) {
                this.props.onChangeValue(value);
            }
            if (!this.props.formikProps || !this.props.fieldDescriptor) {
                return;
            }
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.getFieldName(), value)
        }
    }

    protected getScriptableUiId() {
        // CS: I think we'll have problems (soon?) in the future. For the moment, using this is "unique" enough for ScriptableUi
        // I think we'll have issues when 1/ maybe composed fields w/ the same last fragment; 2/ if we have multiple editors on
        // the same screen. If this happens and you see this: let's discuss.
        // UPDATE: this happened. It was fixed because we added ScriptableUi also in the parent = CrudForm. But the issue may exist
        // if somehow 2 editors of the same entity exist. However, I didn't see such a case for the moment
        return this.props.fieldDescriptor?.name || super.getScriptableUiId();
    }

    protected getValue(): any {
        return this.props.value !== undefined ? this.props.value : this.props.fieldDescriptor!.getFieldValue((this.props as FieldEditorProps).formikProps!.values);
    }

    /**
     * Many of the field editors will pass their props to the underlying comp, which may be a primitive one (input) or a React
     * comp. In any case, let's remove the props specific from FieldEditorProps, which are for sure not needed, and they might 
     * bother.
     */
    protected getPropsToForward(): Omit<P, keyof FieldEditorProps> {
        const result: any = { ...this.props };
        this.propsToExcludeWhenForwarded.forEach(p => delete result[p]);
        return result;
    }

    renderWithScriptableUi(s: SUI) {
        return <HW id="" children={hw => this.renderEditorComponent(castWithHw(s), hw)} />
    }

    protected renderEditorComponent(s: WithHW<SUI>, hw: ScriptableUiHighlightWrapper): ReactElement {
        throw new Error("Please implement!");
    }

}