import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import React from "react";
import { Dropdown, DropdownProps, StrictDropdownProps } from "semantic-ui-react";
import { entityDescriptors } from "../entityCrudConstants";
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";

export class EntityNameFieldEditor extends FieldEditor<string, FieldEditorProps & StrictDropdownProps> {
    constructor(props: FieldEditorProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        s.setFieldValue(hw, data.value);
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const value = this.getValue();
        let options: any[] = [];
        Object.keys(AppMetaTempGlobals.appMetaInstance.getUIEntityDescriptors()).sort((a, b) => entityDescriptors[a].getLabel() < entityDescriptors[b].getLabel() ? -1 : (entityDescriptors[a].getLabel() > entityDescriptors[b].getLabel() ? 1 : 0)).forEach((name: string) => {
            const ed = entityDescriptors[name];
            options.push({ key: name, text: ed.getLabel(), value: name });
        });
        return <Dropdown data-cy={"entityTypeDropdown"} clearable fluid selection searchInput={{ autoFocus: this.props.autoFocus }} search={true} options={options} onChange={(event, data) => this.handleChange(event, data, s, hw)} value={value ? value : ''} {...this.props} />
    }
}"../../AppMetaTempGlobals"