import { Optional } from "@crispico/foundation-react/CompMeta";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { StatePartialFrom } from "@crispico/foundation-react/reduxHelpers";
import { sliceRoleEditorPage } from "./RoleEditorPage";

const testState0: StatePartialFrom<typeof sliceRoleEditorPage> = {
    entity: {
        id: 500,
        name: "role1",
        permissions: null
    },
    mode: 2,
    defaultCheckedPermissions: {},
    selfCheckedPermissions: {}
}

export const testState1: StatePartialFrom<typeof sliceRoleEditorPage> = {
    ...testState0,
    selfCheckedPermissions: { "global.tablePage.default": true },
}

export const testState2: StatePartialFrom<typeof sliceRoleEditorPage> = {
    ...testState1,
    selfCheckedPermissions: { "ent.Audit.fieldsWrite.default.allow": true },
    defaultCheckedPermissions: { "ent.Audit.tablePage.default.allow": true, "ent.Audit.fieldsWrite.id": true, "ent.Audit.fieldsWrite.entityName": true },
}

export const apolloMock = {
    roleService_findById: { id: 500, name: "role1" }
};

let uiEntityDescriptors2: Optional<{ [entityName: string]: EntityDescriptor }> = {};

Object.values(entityDescriptors).filter(ed => ed.showInUI).map(ed => uiEntityDescriptors2![ed.name] = ed);

export const indexAudit = Object.keys(uiEntityDescriptors2).sort((a, b) => entityDescriptors[a].getLabel() < entityDescriptors[b].getLabel() ? -1 : (entityDescriptors[a].getLabel() > entityDescriptors[b].getLabel() ? 1 : 0)).findIndex(e => e === "Audit");
"../../CompMeta""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../reduxHelpers"