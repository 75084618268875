import { InputProps } from "semantic-ui-react";
import { ScriptableUiFieldEditor } from "./FieldEditor";
import { StringFieldEditor, StringFieldEditorProps } from "./StringFieldEditor";
import { ScriptableUi, ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

export interface NumberFieldEditorProps<V = any> extends StringFieldEditorProps<V> {
}

export class NumberFieldEditor<V = number, P extends NumberFieldEditorProps<V> = NumberFieldEditorProps<V>> extends StringFieldEditor<V, P> {
    
    constructor(props: P) {
        super(props);

        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                original.setFieldValue(Number(value));
            }
        }));

    }

    protected getInputProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper): InputProps | undefined {
        return { type: 'number' };
    }
    
}