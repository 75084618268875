import { Label, Popup } from "semantic-ui-react";
import { Utils } from "../../copied/Utils";

export function TestsClassNameRenderer({ name }: { name: string }) {
    // matches "a.b." in "a.b.Class.Subclass"
    const regex = /^[a-z].*?\.(?=[A-Z])/;
    const simpleName = name.replace(regex, "");
    return <>
        {simpleName !== name && <Popup content={name} on="click" trigger={<Label as="a" horizontal content="..." />} />}
        {simpleName}
    </>;
}