import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { EntityDescriptor } from "./EntityDescriptor";
import { CrudSettings } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { Optional, Utils } from "@crispico/foundation-react";
import { Organization } from "../AppMeta";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "../components/CustomQuery/Filter";
import _ from "lodash";

// TODO by CS: to replace w/ CrudGlobalSettings.fieldId
export const ID = "id";
export const ARCHIVED = "archived";
export const TABLE_PAGE_ICON: SemanticICONS = "search";
export const EDITOR_PAGE_ICON: SemanticICONS = "list alternate outline";

export type EntityGen = {
    name: string,
    fields: FieldGen[]
}

export type FieldGenKind = "SCALAR" | "OBJECT" | "LIST" | "ENUM";
export type FieldGen = {
    name: string,
    type: string,
    kind: FieldGenKind
}

export const sessionGlobalReload = 'global.sessionGlobalReload';

declare global {
    var entitiesGen: { [entityName: string]: EntityGen };
}

var entityDescriptorsWithoutCrudSettingsOverride: { [entityName: string]: EntityDescriptor } | undefined;

export var entityDescriptors: { [entityName: string]: EntityDescriptor } = {};

export const afterStartupRunnables: Array<{ runnable: () => void, orderIndex?: number }> = [];

/**
 * After each organization change, we need to get the `entityDescriptors` image kept before applying the org CrudSettings.
 * It's stored in `entityDescriptorsWithoutCrudSettingsOverride`.
 */
export function resetToEntityDescriptorsWithoutCrudSettingsOverride() {
    if (entityDescriptorsWithoutCrudSettingsOverride === undefined) {
        entityDescriptorsWithoutCrudSettingsOverride = _.cloneDeep(entityDescriptors);
    } else {
        entityDescriptors =_.cloneDeep(entityDescriptorsWithoutCrudSettingsOverride);
    }
}

export function getEntityDescriptor(chain: string[], rootEntity: string) {
    let entityDescriptor: EntityDescriptor = entityDescriptors[rootEntity];

    for (let i = 0; i < chain.length; i++) {
        if (!entityDescriptor) {
            break;
        }
        entityDescriptor = entityDescriptors[entityDescriptor.getField(chain[i]).getType()];
    }
    return entityDescriptor;
}

export function addAfterStartupRunnable(runnable: () => void, orderIndex?: number) {
    if (orderIndex !== undefined) { throw new Error("Implementation not finished. Please look in the code, uncomment and test") };
    afterStartupRunnables.push({ runnable, orderIndex });
}

/**
 * Hack to get away (in some places) of the circular imports issue. 
 */
export function runAfterStartupRunnables() {
    // TODO by CS: uncomment and test when needed; should work; but didn't have time to try
    // afterStartupRunnables.sort((first, second) => first < second ? -1 : first > second ? 1 : 0);
    for (const r of afterStartupRunnables) {
        r.runnable();
    }
}
"./CrudSettings""./.."