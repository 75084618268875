import React from "react";
import { Label } from "semantic-ui-react";
import { MiniDbTableComponent } from "./MiniDbTableComponent";

export class MiniDbTableSetComponent extends React.Component {

    render() {
        const miniDb = window.testsAreDemoMaster!.slave.getTestsAreDemoFunctions()?.miniDb;;
        if (!miniDb) {
            // happens at the very beginning, after app start
            return null;
        }

        return <>
            {Object.getOwnPropertyNames(miniDb.data).sort().map(p => <div key={p}>
                <Label color={miniDb.highlighted?.entityName === p ? "yellow" : "teal"} content={p} />
                <MiniDbTableComponent key={p} table={miniDb.data[p]} highlighted={miniDb.highlighted?.entityName === p ? miniDb.highlighted : undefined} />
            </div>)}
        </>
    }
}