import { FieldEditor, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import moment, { Moment } from "moment";
import { DatePickerReactCalendar, DatePickerReactCalendarProps } from "../../components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Utils } from "@crispico/foundation-react/utils/Utils";

export type DatePickerFieldEditorProps = FieldEditorProps<string> & Partial<Omit<DatePickerReactCalendarProps, "value">>;

// I tried to make it generic, but I got weird errors where FieldDescriptor.castAdditionalFieldEditorProps() was used, so I abandoned
export class DatePickerFieldEditor extends FieldEditor<string, DatePickerFieldEditorProps> {

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const value = this.getValue();
        const valueAsMoment = value ? moment(value) : undefined;
        return <DatePickerReactCalendar fieldName={this.props.fieldDescriptor?.getFieldName()} onChange={(date: Moment | null) => s.setFieldValue(hw, date ? date.toISOString() : null)} value={valueAsMoment} format={Utils.dateTimeFormat} {...this.getPropsToForward()} />
    }
}"./FieldEditor""../fieldRenderersEditors""../../utils/Utils"