import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { SettingsEntityEditorPage, sliceSettingsEntityEditorPage } from "@crispico/foundation-react/pages/SettingsEntity/SettingsEntityEditorPage";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import Dropdown, { } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import React from "react";
import { EntityTablePage, EntityTablePageReducers, EntityTablePageState } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { SettingsEntityDebug } from "./SettingsEntityDebug";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { DropdownItemProps, Input, Popup } from "semantic-ui-react";
import { AppContainerContext, AppContainerContextValue } from "@crispico/foundation-react/AppContainerContext";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { EntityEditorPage, EntityEditorPageProps } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";

// workaround for solving cycle issue
export let settingsEntityDescriptor: EntityDescriptor;
export let organizationEntityDescriptor: EntityDescriptor;

// workaround for solving cycle issue
export let OrganizationManyToOneEditorStandalone: any;

class SettingsTablePage extends EntityTablePage {

    protected getExtraTabPanes() {
        return [{
            routeProps: { path: "/debug" }, menuItemProps: { icon: "bug", content: _msg("SettingsEntityDebug.debug.label") },
            render: () => <SettingsEntityDebug />
        }];
    }
};

const SettingsTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, SettingsTablePage);

export const noOrganization = { key: _msg("Organization.all"), text: _msg("Organization.all"), value: undefined }

addAfterStartupRunnable(() => {
    class SettingsEntityDescriptor extends EntityDescriptor {
        constructor() {
            super({
                name: "SettingsEntity",
                icon: "setting",
                miniFields: ["rootType"],
                defaultSort: { field: "rootType", direction: "ASC" },
                javaIdType: "Long",
                graphQlIdType: "Long",
                hasAttachedDashboards: false
            });
        }

        renderTable() {
            return <SettingsTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
        }
    }

    settingsEntityDescriptor = addEntityDescriptor(new SettingsEntityDescriptor()
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "enabled", type: FieldType.boolean })
        .addFieldDescriptor({ name: "rootType", type: FieldType.string, enabled: false })
        .addFieldDescriptor({ name: "organization", type: "Organization" })
        .addFieldDescriptor({ name: "json", type: FieldType.string, isInDefaultColumnConfigForTable: false })
        .addFieldDescriptor({ name: "importance", type: FieldType.number })
    );

    organizationEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "Organization",
        icon: "building outline",
        miniFields: ["qualifiedName"],
        defaultSort: { field: "qualifiedName", direction: "ASC" },
        javaIdType: "Long",
        graphQlIdType: "Long"
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "name", type: FieldType.string })
        .addFieldDescriptor({ name: "qualifiedName", type: FieldType.string, enabled: false })
        .addFieldDescriptor({ name: "parent", type: "Organization" })
    );

    organizationEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<EntityEditorPageProps> {

        protected getExtraTabPanes(): (TabRouterPane | null)[] {
            return [
                ...super.getExtraTabPanes(),
                this.createOneToManyTabPane("Organization", "parent", { sort: { field: "name", direction: "ASC" } })
            ];
        }
    };

    settingsEntityDescriptor.infoEditor.slice = sliceSettingsEntityEditorPage.setEntityDescriptor(settingsEntityDescriptor);
    settingsEntityDescriptor.infoEditor.wrappedComponentClass = SettingsEntityEditorPage;

    type Props = { value?: any, allOrganizationsAccess?: boolean, onChange: (entity: any) => void };
    OrganizationManyToOneEditorStandalone = class extends React.Component<Props> {

        inputRef = React.createRef<any>();

        static contextType = AppContainerContext;
        context!: AppContainerContextValue;

        state = {
            options: [noOrganization],
            search: '',
            dropdownOpened: false
        }

        protected processOrganizationName(qualifiedName: string) {
            let text;
            if (!qualifiedName) {
                text = qualifiedName;
            } else {
                const dots = qualifiedName.match(/[.]/g)?.length || 0;
                if (dots === 0) {
                    text = qualifiedName;
                } else {
                    const organizations = qualifiedName.split('.');
                    const parents = organizations.slice(0, organizations.length - 1).join(".");
                    text = <>
                        <span style={{ marginLeft: 5 * dots }}>&nbsp;&bull;&nbsp;</span>
                        <>{organizations[organizations.length - 1]}</>
                        <span className="OrganizationDropdown_parent">{parents}</span>
                    </>;
                }
            }
            return <Popup className="OrganizationDropdown_tooltip" trigger={<div className="OrganizationDropdown_item">{text}</div>}>{qualifiedName}</Popup>;
        }

        protected onOpen = async () => {
            this.setState({ dropdownOpened: true })

            setTimeout(() => {
                if (this.inputRef.current) {
                    this.inputRef.current.autocomplete = 'off';
                    this.inputRef.current.focus();
                }
            }, 100);

            this.setState({
                options: (this.props.allOrganizationsAccess ? [noOrganization] : []).concat((this.context.organizations || []).map(entity => ({
                    entity,
                    key: entity.id,
                    value: entity.id,
                    text: entity.qualifiedName
                })))
            });
        }

        protected onChange = (event: any, data: DropdownItemProps) => {
            const organization: any = this.state.options.find(o => o.value === data.organization.value);
            if (organization) {
                this.props.onChange(organization.entity);
            }
        }

        onClose = (e: any) => {
            if (e && e.key === 'Escape') {
                this.setState({ dropdownOpened: false, search: '' })
            }
        }

        onKeyDown(e: any, options: any) {
            if (e.key === 'Enter' && options.length > 0) {
                this.onChange(e, { organization: { value: options[0].value } })
            } else if (e.key === " ") {
                // Input inside Dropdown has a predefined action for `Space`. In order to be able to use
                // `Space` for search, we need to disable default action
                e.stopPropagation();
            }
        }

        onBlur = (e: any) => {
            if (e.relatedTarget && e.relatedTarget.id === 'OrganizationDropdown_inputId') { return; }
            this.setState({ dropdownOpened: false, search: '' })
        }

        onSearchChange = (e: any) => this.setState({ search: e.target.value });

        protected getOptions() {
            return this.state.options.filter(opt => {
                if (!this.state.search) {
                    return true;
                }
                return opt.text.toUpperCase().includes(this.state.search.toUpperCase());
            })
        }

        render() {
            const options = this.getOptions();
            return <Dropdown pointing="top right" scrolling className='link item no-padding-top-bottom' floating compact
                value={this.props.value?.id}
                onOpen={this.onOpen} onClose={this.onClose}
                open={this.state.dropdownOpened}
                onBlur={this.onBlur}
                trigger={<span>
                    <Icon name='building outline' className="AppContainer_menu_icon_without_label" />
                    {this.props.value ? this.props.value.name : noOrganization.text}
                </span>}>
                <Dropdown.Menu className='OrganizationDropdown'>
                    <Dropdown.Header className='OrganizationDropdown_header'>
                        <Input ref={this.inputRef} fluid placeholder={_msg('general.search')} icon='search' iconPosition='left' id="OrganizationDropdown_inputId" onKeyDown={(e: any) => this.onKeyDown(e, options)} value={this.state.search} onChange={this.onSearchChange}></Input>
                    </Dropdown.Header>
                    {options.map(organization => (
                        <Dropdown.Item key={organization.key} {...{ organization, text: this.processOrganizationName(organization.text) }} onClick={this.onChange} />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        }
    }
});"../../entity_crud/EntityDescriptor""./SettingsEntityEditorPage""../../entity_crud/entityCrudConstants""../../entity_crud/EntityTablePage""../../entity_crud/FieldType""../../AppContainerContext""../../reduxReusableComponents/ReduxReusableComponents""../../entity_crud/EntityEditorPage""../../components/TabbedPage/TabbedPage"