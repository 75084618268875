import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import gql from "graphql-tag";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import React from "react";
import { ModalExt, ModalExtOpen } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Dimmer, Icon, Loader, Modal, Table } from "semantic-ui-react";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { FieldDescriptor, EntityDescriptor } from "../../entity_crud/EntityDescriptor";
import { CUSTOM_FIELDS } from "../../entity_crud/FieldType";
import Interweave from "interweave";
import { ActionName, AuditUtils } from "./AuditUtils";
import { NavLink } from "react-router-dom";
import { AuditFieldRenderer } from "./AuditFieldRenderer";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { AppContainerContext, AppContainerContextValue } from "@crispico/foundation-react/AppContainerContext";

var maxRowsForPopup = 20;

export interface AuditRow {
    entityId: number,
    username: string,
    date: Date,
    newValue: string
}

class AuditEntriesPopupState extends State {
    entityName = "";
    fieldName = "";
    entityId = 0;
    open = false as ModalExtOpen;
    auditRows = [] as AuditRow[];
    showSpinner = false;
}

class AuditEntriesPopupReducers<S extends AuditEntriesPopupState = AuditEntriesPopupState> extends Reducers<S> {
}

export class AuditEntriesPopup extends React.Component<RRCProps<AuditEntriesPopupState, AuditEntriesPopupReducers>> {

    static contextType = AppContainerContext;
    context!: AppContainerContextValue;

    async getAuditRows(entityName: string, fieldName: string, entityId: number) {
        const { auditSettings } = this.context.initializationsForClient;
        if (auditSettings) {
            maxRowsForPopup = auditSettings.maxRowsForPopup ? auditSettings.maxRowsForPopup : maxRowsForPopup;
        }

        const query = gql(`query q($params: FindByFilterParamsInput) {
            auditService_findByFilter(params: $params) {
              results {
                username date newValue entityId
              }
            }
        }`);

        const params = FindByFilterParams.create().pageSize(maxRowsForPopup).sorts([{ field: "date", direction: "DESC" }])
            .filter(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                AuditUtils.getFilterForAuditableEntity(entityName), AuditUtils.getFilterForAuditableField(entityName, fieldName),
                Filter.create("entityId", FilterOperators.forNumber.equals, entityId.toString()),
                Filter.createComposed(FilterOperators.forComposedFilter.or, [ 
                    Filter.create("action", FilterOperators.forString.equals, ActionName.UPDATE),
                    Filter.create("action", FilterOperators.forString.equals, ActionName.REFRESH),
                ])
        ]));

        const result: AuditRow[] = (await apolloClientHolder.apolloClient.query({ query, variables: params })).data.auditService_findByFilter.results;
        this.props.r.setInReduxState({ auditRows: result, showSpinner: false })
    }

    async open(entityName: string, fieldName: string, entityId: number, open: ModalExtOpen) {
        this.props.r.setInReduxState({ entityName, fieldName, entityId, open, auditRows: [], showSpinner: true })
        await this.getAuditRows(entityName, fieldName, entityId);
    }

    render() {
        const { props } = this;

        try {
            const ed: EntityDescriptor = entityDescriptors[props.s.entityName];
            const fd: Optional<FieldDescriptor> = props.s.entityName ? ed?.getField(props.s.fieldName) : undefined;

            // accessing the ED this way, to avoid circular imports issue
            const auditUsernameFd = entityDescriptors["Audit"].getField("username");
            const auditDateFd = entityDescriptors["Audit"].getField("date");
            const filterForAuditTable = Filter.createComposedForClient(FilterOperators.forComposedFilter.and, [AuditUtils.getFilterForAuditableEntity(props.s.entityName, true),
                Filter.createForClient("entityId", FilterOperators.forNumber.equals, props.s.entityId.toString())])
            
            return <>
                <ModalExt open={props.s.open} size='tiny' onClose={() => props.r.setInReduxState({ open: false })} >
                    <Modal.Header>
                        <Icon name="chart line"/>
                        <Interweave content={_msg("AuditEntriesPopup.tableHeader", fd?.getLabel())} />
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <Table striped>
                            <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{_msg("Audit.username.label")}</Table.HeaderCell>
                                <Table.HeaderCell>{_msg("Audit.date.label")}</Table.HeaderCell>
                                <Table.HeaderCell>{_msg("Audit.newValue.label")}</Table.HeaderCell>
                            </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {props.s.auditRows.map((auditRow, index) => {
                                        const value = fd && { id: auditRow.entityId, [fd.name]: AuditUtils.convertAuditValue(fd, auditRow.newValue) };
                                        const simulatedEntity = fd ? (fd.isCustomField ? { [CUSTOM_FIELDS]: value } : value) : {};
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>{auditUsernameFd.renderField(auditRow)}</Table.Cell>
                                                <Table.Cell>{auditDateFd.renderField(auditRow)}</Table.Cell>
                                                <Table.Cell><AuditFieldRenderer fd={fd} entity={simulatedEntity}/></Table.Cell>
                                            </Table.Row>
                                        );
                                    }
                                )}
                            </Table.Body>
                        </Table>
                        <Dimmer active={props.s.showSpinner}><Loader size='large'>Loading</Loader></Dimmer>
                        <>
                            {_msg("AuditEntriesPopup.tableFooter", maxRowsForPopup)}&nbsp;
                            {ed?.canAddAuditTabs() ? <NavLink to={ed?.getEntityEditorUrl(props.s.entityId) + "/" + entityDescriptors["Audit"].getLabel()}>{entityDescriptors["Audit"].getLabel(true)}</NavLink> : 
                            <NavLink to={new ShareLinkLogic().createLink(false, entityDescriptors["Audit"], filterForAuditTable, [])}>{_msg("Audit.label")}</NavLink> }
                        </>
                    </Modal.Content>
                </ModalExt>
            </>
        } catch (e) {
            return <>{_msg("general.error")}</>
        }
    }
}

export const AuditEntriesPopupRRC = ReduxReusableComponents.connectRRC(AuditEntriesPopupState, AuditEntriesPopupReducers, AuditEntriesPopup);"../../entity_crud/entityCrudConstants""../../apolloClient""../../entity_crud/FindByFilterParams""../../components/CustomQuery/Filter""../../components/ModalExt/ModalExt""../../CompMeta""../../AppMetaTempGlobals""../../entity_crud/ShareLinkLogic""../../reduxReusableComponents/ReduxReusableComponents""../../AppContainerContext"