import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { ColumnConfigDropdownSource } from "@crispico/foundation-react/components/ColumnConfig/dataStructures";
import { CrudViewer } from "@crispico/foundation-react/entity_crud/CrudViewer";
import { EntityEditorPage, SliceEntityEditorPage } from "@crispico/foundation-react/entity_crud/EntityEditorPage";
import { FIELDS_READ } from "@crispico/foundation-react/utils/Utils";
import React from "react";
import { Header, Icon, Segment } from "semantic-ui-react";
import { AuditEntriesPopup, AuditEntriesPopupRRC } from '../pages/Audit/AuditEntriesPopup';
import { FieldDescriptor } from "./EntityDescriptor";

export interface CrudViewerInEditorProps {
    editor: EntityEditorPage
}

export class CrudViewerInEditor extends React.Component<CrudViewerInEditorProps> {
    refAuditEntriesPopup = React.createRef<AuditEntriesPopup>();

    constructor(props: CrudViewerInEditorProps) {
        super(props);
        this.renderButtonForAuditEntries = this.renderButtonForAuditEntries.bind(this);
    }

    renderHeader() {       
        const { editor } = this.props;
        const { entityDescriptor } = (editor.props.dispatchers as any).getSlice();
        return <Header as="h2" dividing>
            {typeof entityDescriptor.icon === 'string' ? <Icon name={entityDescriptor.icon as any} /> : entityDescriptor.icon}
            <Header.Content>
                {entityDescriptor.toMiniString(editor.props.entity)}
                <Header.Subheader>{_msg("entityCrud.viewer.subheader", entityDescriptor.getLabel())}</Header.Subheader>
            </Header.Content>
        </Header>
    }

    renderButtons() {
        const { editor } = this.props;
        const { entityDescriptor } = (editor.props.dispatchers as any).getSlice();

        // duplicated from EntityEditorPage :(
        // const showBack = AppMetaTempGlobals.locationPathnamePrevious && AppMetaTempGlobals.locationPathnamePrevious !== entityDescriptor.getEntityTableUrl();

        return <Segment className="buttonBar EntityEditorFormSimple_bar">
            {/* {!editor.modalProps && <>
                {showBack && <Button icon="arrow left" content={_msg("dto_crud.back")} onClick={editor.onBack} />}
                <Button icon={!showBack && "arrow left"} content={showBack ? _msg("dto_crud.goToTable") : _msg("dto_crud.backToTable")} onClick={editor.onGotoTable} />
            </>}
            <div className="EntityTablePage_barDivider" /> */}
            {editor.props.columnConfigDropdown && <SliceEntityEditorPage.ColumnConfigDropdown {...editor.props.columnConfigDropdown} dispatchers={(editor.props.dispatchers as any).columnConfigDropdown}
                source={ColumnConfigDropdownSource.EDITOR} entityDescriptor={entityDescriptor} />
            }
        </Segment>;
    }

    protected renderButtonForAuditEntries(fieldDescriptor: FieldDescriptor) {
        const { editor } = this.props;
        const { entityDescriptor } = (editor.props.dispatchers as any).getSlice();

        return <Icon link name="chart line" size="large" onClick={(e: MouseEvent) => this.refAuditEntriesPopup.current!.open(entityDescriptor.name , fieldDescriptor.name, editor.props.entity.id, [e.clientX, e.clientY])} />
    }

    render() {      
        const { editor } = this.props;
        const { entityDescriptor } = (editor.props.dispatchers as any).getSlice();
        
        const columns = editor.props.columnConfigDropdown?.columnConfig?.configObject.columns!.map((column => column.name));
        const fields = Object.keys(entityDescriptor.getAuthorizedFields(FIELDS_READ, columns));

        return <>
            {/* {this.renderHeader()} */}
            {this.renderButtons()}
            {editor.props.entity ? <>
                <CrudViewer entityDescriptor={entityDescriptor} entity={editor.props.entity} fields={fields}
                    renderFunctionsForAdditionalColumns={AppMetaTempGlobals.appMetaInstance.showCrudButtons.showAuditButton ? [this.renderButtonForAuditEntries] : undefined} />
                <AuditEntriesPopupRRC id={"auditEntriesPopup_CrudViewer-" + entityDescriptor.name} ref={this.refAuditEntriesPopup} />
            </> : <p>{_msg("general.loading")}</p>}
        </>
    }
}"../AppMetaTempGlobals""../components/ColumnConfig/dataStructures""./CrudViewer""./EntityEditorPage""../utils/Utils"