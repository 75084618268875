import React from "react";
import { Progress } from "semantic-ui-react";

export class ProgressFieldRenderer extends React.Component<{value: any}> {
    
    render() {
        return <Progress percent={this.props.value && !isNaN(this.props.value) ? this.props.value : 0}
            indicating={(this.props.value && !isNaN(this.props.value) ? this.props.value : 0) < 100}
            progress color={(this.props.value && !isNaN(this.props.value) ? this.props.value : 0) == 100 ? 'green' : undefined}
            success={(this.props.value && !isNaN(this.props.value) ? this.props.value : 0) == 100} />
    }
}