import { ModalExt } from "@famiprog-foundation/scriptable-ui";
import { Modal } from "semantic-ui-react";
import { FeatureBookServer } from "./FeatureBookServer";
import { FeaturebookServer } from "../featurebook/FeaturebookServer";

interface Props {
    parent: FeaturebookServer;
}

export function ModalForTestsServer(props: Props) {
    console.log(props.parent.props.master!)
    return <ModalExt open size="fullscreen" className="ModalForTests_root"
        onClose={() => props.parent.setState({ modalOpen: false })}
        closeIcon closeOnDimmerClick={false} closeOnEscape={false}
    >
        <Modal.Header>Run tests on server</Modal.Header>
        <Modal.Content>
            <FeatureBookServer serverUrlPrefix={props.parent.props.master!.props.serverUrlPrefix} testsToRun={props.parent.state.testsToRun} />
        </Modal.Content>
    </ModalExt>
}