import { StateFrom } from "@crispico/foundation-react";
import { DashboardConfig } from "../DashboardEntityDescriptor";
import { employees } from "@crispico/foundation-react/entity_crud/EntityTablePage.testStates";
import { PIE_COUNT_BY_CRITERIA, DEMO_WIDGET_2, DEMO_WIDGET, LIST_RECORDS_WIDGET, CALCULATE_RECORDS_WIDGET, FIELD_WIDGET } from '../DashboardContants';
import { DashboardTab } from "./DashboardTab";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { categoricalColorSchemes } from "@nivo/colors";
import { WidgetStatus } from "../AbstractWidgetWithFilter";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";

export const dashboardTestState0: StateFrom<DashboardTab> = {
  widgetWrapperConfigs: [
  ]
}

export const configTestState0: DashboardConfig = {
  layouts: {
    lg: [
      { i: 'calculateWidget', x: 0, y: 4, w: 7, h: 12 },
      { i: 'listWidget', x: 14, y: 4, w: 9, h: 12 },
      { i: 'demoWidget1', x: 7, y: 4, w: 7, h: 12 },
      { i: 'demoWidget2', x: 0, y: 16, w: 23, h: 7 },
      { i: 'pieCountByCriteria', x: 23, y: 4, w: 11, h: 12 }
    ]
  },
  widgetWrapperConfigs: {
    listWidget: {
      type: LIST_RECORDS_WIDGET, widgetConfig: {
        title: 'Programmers', nrOfRecords: 0, displayAsTable: true,
        entityType: 'Employee', fields: "firstName,lastName,birthDate,department",
        filter: Filter.createComposed(FilterOperators.forComposedFilter.or, [Filter.createForClient("department.name", FilterOperators.forString.equals, "Programmers")])
      }
    },
    demoWidget1: { type: DEMO_WIDGET, widgetConfig: { title: 'Simple widget', someText: "someText1", someColor: "blue" } },
    demoWidget2: { type: DEMO_WIDGET_2, widgetConfig: { title: 'Widget with additional filter', someText: "someText2", someColor: "yellow", filter: Filter.createComposed(FilterOperators.forComposedFilter.or, [Filter.createForClient("department.name", FilterOperators.forString.equals, "Programmers")]) } },
    pieCountByCriteria: { type: PIE_COUNT_BY_CRITERIA, widgetConfig: { title: 'Employee department', entityType: 'Employee', field: { name: 'department', continuous: false } }},
    calculateWidget: { type: CALCULATE_RECORDS_WIDGET, widgetConfig: { saveIntervalExpression: 1, rollingAverageExpression: 7, valueFontSize: 60, valueColor: "#000000" } }
}}

export const testState0 = {
  listWidget: { entities: employees.filter(x => x.stillEmployed && x.department.name === "Programmers"), count: 3, status: WidgetStatus.DONE },
  demoWidget1: { counter: 100 },
  demoWidget2: { counter: 200 },
  pieCountByCriteria: {
    pieData: [
      {
          id: 'Programmers',
          label: 'Programmers',
          value: 3,
          color: categoricalColorSchemes["category10"][0]
      },
      {
          id: 'Maintenance',
          label: 'Maintenance',
          value: 2,
          color: categoricalColorSchemes["category10"][1]
      },
      {
          id: 'Drivers',
          label: 'Drivers',
          value: 1,
          color: categoricalColorSchemes["category10"][2]
      }
  ],
  tooltipData: {
      'Programmers': ['John Deere', 'Elenora Schowalter', 'Erling Kohler'],
      'Maintenance': ['Daniel Smith', 'Don Walker'],
      'Drivers': ['Jonathan Cash']
  },
  keys: ['Programmers', 'Maintenance', 'Drivers'],
  status: WidgetStatus.DONE
  },
  calculateWidget: { values: {test: [25, 0.25]}, selectedGroupByKey: 'test', averageValues: {test: [24.3]}, status: WidgetStatus.DONE }
}

export const config2: DashboardConfig =
{
  layouts: {
    lg: [
      {
        i: '0',
        x: 0,
        y: 0,
        w: 2,
        h: 2
      },
      {
        i: '1',
        x: 2,
        y: 0,
        w: 2,
        h: 2
      },
      {
        i: '2',
        x: 4,
        y: 0,
        w: 2,
        h: 2
      },
      {
        i: '3',
        x: 6,
        y: 0,
        w: 2,
        h: 2
      }
    ]
  },
  widgetWrapperConfigs: [
    {
      type: 'pieCountByCriteria',
      widgetConfig: {
        title: 'Equipment speed chart',
        equipmentFilter: Filter.create('id', FilterOperators.forNumber.in, '-2000, -2001, -2002, -2003'),
        field: {
          name: 'Speed',
          continuous: true,
          thresholds: [
            {
              name: 'slow',
              value: 10
            },
            {
              name: 'medium',
              value: 20
            },
            {
              name: 'fast',
              value: null
            }
          ]
        }
      }
    },
    {
      type: 'pieCountByCriteria',
      widgetConfig: {
        title: 'Engine state chart',
        equipmentFilter: Filter.create('id', FilterOperators.forNumber.in, '-2000, -2001, -2002, -2003'),
        field: {
          name: 'Engine state',
          continuous: false
        }
      }
    },
    {
      type: 'pieCountByCriteria',
      widgetConfig: {
        title: 'Last detection date chart',
        field: {
          name: 'lastDetectionDate',
          continuous: true,
          thresholds: [
            {
              name: '< 1 min',
              value: 60000
            },
            {
              name: '< 5 min',
              value: 300000
            },
            {
              name: '< 15 min',
              value: 900000
            },
            {
              name: '< 30 min',
              value: 1800000
            },
            {
              name: 'over 30 min',
              value: null
            }
          ]
        }
      }
    },
    {
      type: 'pieCountByCriteria',
      widgetConfig: {
        title: 'Equipment type chart',
        field: {
          name: 'equipmentType',
          continuous: false
        }
      }
    }
  ]
}

export const testState1 = {
  layouts: {
    'lg': [
      { i: '0', x: 0, y: 0, w: 2, h: 2 }
    ]
  },
  numberOfItems: 1,
  widgetWrapperConfigs: [
    { id: 0, value: { type: 'demoWidget', widgetConfig: { counter: 1 } } }
  ],
  widgetWrapperConfigsLastId: 1
}

export const testState2 = {
  layouts: {
    'lg': [
      { i: '0', x: 0, y: 0, w: 2, h: 2 },
      { i: '1', x: 1, y: Infinity, w: 1, h: 2 }
    ]
  },
  numberOfItems: 2,
  widgetWrapperConfigs: [
    { id: 0, value: { type: 'demoWidget', widgetConfig: { counter: 1 } } },
    { id: 1, value: { type: 'demoWidget', widgetConfig: { counter: 1 } } }
  ],
  widgetWrapperConfigsLastId: 2
}

export const testState3 = {
  layouts: {
    'lg': [
      { i: '0', x: 1, y: Infinity, w: 1, h: 2 }
    ]
  },
  numberOfItems: 1,
  widgetWrapperConfigs: [
    { id: 2, value: { type: 'demoWidget', widgetConfig: { counter: 1 } } }
  ],
  widgetWrapperConfigsLastId: 3
}

export const testState4 = {
  layouts: {
    lg: [
      {
        i: '0',
        x: 0,
        y: 0,
        w: 2,
        h: 2
      },
      {
        i: '1',
        x: 2,
        y: 0,
        w: 2,
        h: 2
      },
      {
        i: '2',
        x: 4,
        y: 0,
        w: 2,
        h: 2
      },
      {
        i: '3',
        x: 6,
        y: 0,
        w: 2,
        h: 2
      }
    ]
  },
  widgetWrapperConfigs: [
    {
      id: 0,
      value: {
        type: 'pieCountByCriteria',
        widgetConfig: {
          title: 'Equipment speed chart',
          equipmentFilter: Filter.create('id', FilterOperators.forNumber.in, '-2000, -2001, -2002, -2003'),
          field: {
            name: 'Speed',
            continuous: true,
            thresholds: [
              {
                name: 'slow',
                value: 10
              },
              {
                name: 'medium',
                value: 20
              },
              {
                name: 'fast',
                value: null
              }
            ]
          }
        }
      }
    },
    {
      id: 1,
      value: {
        type: 'pieCountByCriteria',
        widgetConfig: {
          title: 'Engine state chart',
          equipmentFilter: Filter.create('id', FilterOperators.forNumber.in, '-2000, -2001, -2002, -2003'),
          field: {
            name: 'Engine state',
            continuous: false
          }
        }
      }
    },
    {
      id: 2,
      value: {
        type: 'pieCountByCriteria',
        widgetConfig: {
          title: 'Last detection date chart',
          field: {
            name: 'lastDetectionDate',
            continuous: true,
            thresholds: [
              {
                name: '< 1 min',
                value: 60000
              },
              {
                name: '< 5 min',
                value: 300000
              },
              {
                name: '< 15 min',
                value: 900000
              },
              {
                name: '< 30 min',
                value: 1800000
              },
              {
                name: 'over 30 min',
                value: null
              }
            ]
          }
        }
      }
    },
    {
      id: 3,
      value: {
        type: 'pieCountByCriteria',
        widgetConfig: {
          title: 'Equipment type chart',
          field: {
            name: 'equipmentType',
            continuous: false
          }
        }
      }
    }
  ]
}

export const testState5: StateFrom<DashboardTab> = {
  layouts: {} as any,
  widgetWrapperConfigs: {} as any,
  wizardCopyMoveWidgetsSelected: [{id: '0', value: 'widget', widgetConfig: {type: FIELD_WIDGET, widgetConfig: {title: 'RootFilter for Employee', entityType: 'Employee'}}}],
}"../../..""../../../entity_crud/EntityTablePage.testStates""../../../components/CustomQuery/Filter"