import React from "react";
import { ScaleToContainer } from "@crispico/foundation-react/utils/Utils";
import { WidgetConfig, WidgetProps } from "../WidgetWrapper";

export type FreeTextWidgetConfig  = {
    text: string | undefined,
    fontSize?: string
} & WidgetConfig;

export class FreeTextWidget extends React.Component<WidgetProps<FreeTextWidgetConfig>> {
    render() {
        return <div className="flex-container flex-grow  no-margin FieldWidget_lineHeight">
            <ScaleToContainer className="FreeTextWidget flex-center" fixedFontSize={this.props.widgetConfig.fontSize}>{this.props.widgetConfig.text}</ScaleToContainer>
        </div>

    }
}
"../../../../utils/Utils"