import { FormikProps } from "formik";
import { EntityEditorFormSimple, EntityEditorFormSimpleProps, ScriptableUiCrudForm } from "./EntityEditorFormSimple";
import { EntityEditorPage } from "./EntityEditorPage";
import { ID } from "./entityCrudConstants";

export interface CrudFormInEditorProps extends EntityEditorFormSimpleProps {
    editor: EntityEditorPage
}

/**
 * This is a `CrudForm` that is contained by `CrudEditor`. Hence `CrudForm`
 * knows its parent editor, so that it can act accordingly.
 */
export class CrudFormInEditor extends EntityEditorFormSimple<CrudFormInEditorProps> {

    renderFormikChild(s: ScriptableUiCrudForm.Main, formikProps: FormikProps<any>, fieldsToIgnore: string[] = []) {
        if (this.props.editor.props.duplicateFromId !== undefined) {
            fieldsToIgnore.push(ID);
        } 
        return super.renderFormikChild(s, formikProps, fieldsToIgnore);
    }
}