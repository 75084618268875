import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { createTestids } from "@famiprog-foundation/tests-are-demo";
import React from "react";
import { Button, Header, Input, Label, Popup, SemanticCOLORS } from "semantic-ui-react";

export const advancedSelectorTestids = createTestids("AdvancedSelector", {
    item: ""
});

export type AdvancedSelectorItem = {
    id: number | string,
    level: number,
    text: string,
    color: SemanticCOLORS | undefined,
    // Used only by elements on first level
    icon?: string,
    data?: any,
    className?: string,
    // Used only by elements on third level
    disabled?: boolean
    // Used only by disabled elements on third level
    tooltip?: string
}

export class AdvancedSelectorState extends State {
    inputValue: string = "";
}

export class AdvancedSelectorReducers<S extends AdvancedSelectorState = AdvancedSelectorState> extends Reducers<S> {
    update(inputValue: string) {
        this.s.inputValue = inputValue;
    }
}
type PropsNotFromState = {
    data: AdvancedSelectorItem[],
    onItemClick: (data: AdvancedSelectorItem) => void,
    onSearchInputChanged?: (inputSearchValue: string) => void,
    renderItemFunction?: (data: AdvancedSelectorItem, callback: (data: AdvancedSelectorItem) => void) => React.ReactNode
};
type Props = RRCProps<AdvancedSelectorState, AdvancedSelectorReducers> & PropsNotFromState;

export class AdvancedSelector extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
    }

    private renderItem(item: AdvancedSelectorItem, dataTestId: string) {
        if (item.level === 3 && !item.text?.toUpperCase().includes(this.props.s.inputValue.toUpperCase())) {
            return;
        }
        const result = this.props.renderItemFunction?.call(null, item, this.props.onItemClick);
        if (result) {
            return result;
        }

        const extraClassName = item.className ? item.className : "";

        switch (item.level) {
            case 1:
                return <Header icon={item.icon ? item.icon : undefined} data-testid={dataTestId} color={item.color}
                    className={"small-margin-bottom " + extraClassName} as="h4" key={item.id} content={item.text} />;
            case 2:
                return <Label data-testid={dataTestId} className={"small-margin-bottom " + extraClassName} key={item.id} color={item.color}>{item.text}</Label>;
            case 3:
                return <Popup disabled={!item.disabled} content={item.disabled ? item.tooltip : undefined} trigger={
                    <span><Button disabled={item.disabled} color={item.color}
                        data-testid={dataTestId} compact size="tiny" key={item.id} className={"small-margin-bottom " + extraClassName}
                        onClick={() => this.props.onItemClick(item)}>{item.text}</Button></span>
                } />;
            default:
                return <></>;
        }
    }

    private onSearchKeyDown(e: any, value: any) {
        if (e.key === 'Enter') {
            const items = this.props.data.filter(item => item.level === 3 && item.text?.toUpperCase().includes(this.props.s.inputValue.toUpperCase()));
            if (items.length === 1) {
                this.props.onItemClick(items[0]);
                this.props.r.update("");
            }
        }
    }

    render() {
        return <div className="flex-grow-shrink-no-overflow">
            <Input fluid autoFocus action={{ color: "green", icon: "search" }} value={this.props.s.inputValue}
                onKeyDown={this.onSearchKeyDown} onChange={e => {
                    this.props.r.update(e.target.value);
                    if (this.props.onSearchInputChanged) {
                        this.props.onSearchInputChanged(e.target.value);
                    }
                }} />
            {this.props.data.map((item, index) => this.renderItem(item, advancedSelectorTestids.item + "_" + index))}
        </div>
    }
}

export const AdvancedSelectorHOC = ReduxReusableComponents.connectRRC(AdvancedSelectorState, AdvancedSelectorReducers, AdvancedSelector);
"../../reduxReusableComponents/ReduxReusableComponents"