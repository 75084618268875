import { PuppeteerMessage, PuppeteerMessageType, CommonLibAndNodeDefaultExport } from "../common-lib-and-node/common-types";

// @ts-ignore
import d from "../common-lib-and-node/common.mjs";
const { MESSAGE_FOR_PUPPETEER } = (d as CommonLibAndNodeDefaultExport);

export class PuppeteerBridge {

    protected idOfLastMessage: number = 0;
    protected idSent: number = -1;
    protected resolve?: () => void;

    async sendMessageToPuppeteer(messageType: PuppeteerMessageType, message: PuppeteerMessage): Promise<void> {
        if (this.idSent !== -1) {
            // If the error happens, we'll see the message in the log file. Hence 
            // I print the object as JSON, to be readable.
            throw new Error(`A call to puppeteer is already in progress w/ id = ${this.idSent}. Somebody tries to send a new message w/ type = ${messageType} and content = ${JSON.stringify(message, undefined, 2)}`);
        }

        this.idOfLastMessage++;
        this.idSent = this.idOfLastMessage;
        // TODO de trimis si ID-ul
        console.log(`${MESSAGE_FOR_PUPPETEER} ${messageType} ${JSON.stringify(message)}`);
        // TODO de scos acest timeout temporar
        setTimeout(() => this.onPuppeteerFinished(this.idSent), 200);
        return new Promise(resolve => this.resolve = resolve);
    }

    onPuppeteerFinished(id: number) {
        if (id !== this.idSent) {
            throw new Error(`Received a message from puppeteer w/ id = ${id}, but the last message sent to puppeteer had id = ${this.idSent}`);
        }

        this.idSent = -1;
        if (this.resolve) {
            this.resolve();
            this.resolve = undefined;
        }
    }

}