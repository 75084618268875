import { Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { Zoom } from "@crispico/foundation-react/components/Zoom/Zoom";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Datum, Serie } from "@nivo/line";
import lodash from 'lodash';
import moment from "moment";
import React, { ReactNode } from "react";
import { Popup } from "semantic-ui-react";
import { Timeline, Group, BackgroundLayer, Marker } from '@crispico/react-timeline-10000';
import { Column, Table } from "fixed-data-table-2";

interface BarGroup extends Group {
    color?: string | undefined
}

interface BarItem {
    /* Mandatory properties */
    start: any,
    end: any,
    row: number,
    key: number,
    /* Optional properties */
    style?: any,
    value: ReactNode
}

export interface ChartMapping {
    id: number,
    label: ReactNode,
    text?: Optional<string>,
    color: Optional<string | number>,
    start?: number,
    end?: number,
    value?: number | string,
    lineStyle?: Partial<React.CSSProperties>,
    legend?: ReactNode
}

class BarChartState extends State {
    series = [] as Array<Serie>;
    startDate = undefined as number | undefined;
    endDate = undefined as number | undefined;
    chartPaddingLeft = 0 as number;
}

class BarChartReducers<S extends BarChartState = BarChartState> extends Reducers<S> {
    changeSeries(initialSeries: Serie[]) {
        if (!this.s.startDate || !this.s.endDate) {
            return;
        }
        this.s.series = [];
        for (let i = 0; i < initialSeries.length; i++) {
            let newSerie = { ...initialSeries[i] };
            newSerie.data = initialSeries[i].data;
            this.s.series.push(newSerie);
        }
    }
}

type BarChartProps = {
    initialSeries: Serie[],
    initialStartDate: number,
    initialEndDate: number,
    currentStartDate: number,
    currentEndDate: number,
    showCurrentTime?: boolean,
    currentTime?: number,
    mapping: ChartMapping[],
    selectedItems?: (string | number)[],
    onItemClick?: Function,
    hasZoomSlider?: boolean,
    sliderTooltipFormat?: (value: number) => string,
    onRangeChange?: (startDate: number, endDate: number) => void
} & RRCProps<BarChartState, BarChartReducers>;

export class BarChartRaw extends React.Component<BarChartProps> {

    timelineRef = React.createRef<Timeline>();

    constructor(props: BarChartProps) {
        super(props);
    }

    private onSliderBtnResize(width: number | undefined) {
        if (width) {
            this.props.r.setInReduxState({ chartPaddingLeft: width });
        }
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: BarChartProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: BarChartProps) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.initialStartDate, props.initialStartDate)
            || !lodash.isEqual(prevProps?.initialEndDate, props.initialEndDate)) {
            props.r.setInReduxState({ startDate: props.initialStartDate, endDate: props.initialEndDate });
        }
        if (!prevProps || !lodash.isEqual(prevProps.currentStartDate, props.currentStartDate)
            || !lodash.isEqual(prevProps.currentEndDate, props.currentEndDate)) {
            props.r.setInReduxState({ startDate: props.currentStartDate, endDate: props.currentEndDate });
        }
        if (prevProps && (!lodash.isEqual(prevProps.s.startDate, props.s.startDate) || !lodash.isEqual(prevProps?.s.endDate, props.s.endDate))) {
            props.r.changeSeries(props.initialSeries);
            props.onRangeChange?.call(null, props.s.startDate!, props.s.endDate!);
        }
        if (!lodash.isEqual(prevProps?.initialSeries, props.initialSeries)) {
            props.r.changeSeries(props.initialSeries);
        }
        if (this.props.currentTime !== prevProps?.currentTime) {
            this.timelineRef.current?.setState({ cursorTime: moment(this.props.currentTime) });
        }
    }

    computeBarChartData(mapping: ChartMapping[]): { groups: BarGroup[], items: BarItem[] } {
        let barGroups: BarGroup[] = [];
        let barItems: BarItem[] = [];

        if (this.props.s.series.length === 0) {
            return { groups: barGroups, items: barItems };
        }
        let rowNumber = 0;
        this.props.s.series.forEach(serie => {
            let index = 0;
            let currentMapping: any = undefined;
            let section: { id?: string, startTime?: number, endTime?: number, value?: any } = {};
            let sortedData = [...serie.data].sort((a: Datum, b: Datum) => a.x! < b.x! ? -1 : a.x! > b.x! ? 1 : 0);
            for (let i = 0; i < sortedData.length; i++) {
                const point = sortedData[i];
                if (point.y === null) {
                    if (section.id) {
                        section.endTime = point.x as number;
                        barItems.push({ key: serie.id + "." + index as any, row: rowNumber, start: moment(section.startTime!), end: moment(section.endTime!), value: section.value, style: { backgroundColor: AppMetaTempGlobals.appMetaInstance.getColor(currentMapping.color) } });
                        section = {};
                        index++;
                    }
                    currentMapping = undefined;
                    continue;
                }
                let pointMapping = mapping.find(t => t.start !== undefined ? point.y! as number >= t.start! && point.y! as number < t.end! : point.y == t.value);
                if (pointMapping === undefined) {
                    continue;
                }
                if (!currentMapping || pointMapping?.id !== currentMapping.id) {
                    if (section.id) {
                        section.endTime = point.x as number;
                        barItems.push({ key: serie.id + "." + index as any, row: rowNumber, start: moment(section.startTime!), end: moment(section.endTime!), value: section.value, style: { backgroundColor: AppMetaTempGlobals.appMetaInstance.getColor(currentMapping.color) || 'black' } });
                        section = {};
                        index++;
                    }
                    currentMapping = pointMapping;
                    section.id = serie.id + "." + index;
                    section.startTime = point.x as number;
                    section.endTime = point.x as number;
                    section.value = currentMapping.label;
                } else {
                    section.endTime = point.x as number;
                }
            }
            if (section.id) {
                barItems.push({ key: serie.id + "." + index as any, row: rowNumber, start: moment(section.startTime!), end: moment(section.endTime!), value: section.value, style: { backgroundColor: AppMetaTempGlobals.appMetaInstance.getColor(currentMapping?.color) } });
            }
            barGroups.push({ id: rowNumber, title: serie.label, color: AppMetaTempGlobals.appMetaInstance.getColor(currentMapping?.color) });
            rowNumber++;
        })

        return { groups: barGroups, items: barItems };
    }

    public getHeight() {
        return this.props.s.series.length > 5 ? 250 : 140;
    }

    render() {
        const props = this.props;
        const data: { groups: BarGroup[], items: BarItem[] } = this.computeBarChartData(props.mapping);

        // TODO: height of bar chart & item height are hardcoded for now, I think they must be calculated in the future
        return props.s.startDate && props.s.endDate ? <div className="flex-container wh100">
            <div className="flex-container barChart w100" style={{ height: this.getHeight() }}>
                <Timeline ref={this.timelineRef} startDate={moment(props.s.startDate)} endDate={moment(props.s.endDate)}
                    groups={data.groups} table={<Table rowHeight={30} rowsCount={data.groups.length}
                        width={this.props.hasZoomSlider ? this.props.s.chartPaddingLeft : 90} headerHeight={30} >
                        <Column key={0} columnKey={0} width={this.props.hasZoomSlider ? this.props.s.chartPaddingLeft + 10 : 100}
                            cell={({ rowIndex }) => data.groups[rowIndex] && <div>{data.groups[rowIndex].title}</div>}
                        />
                    </Table>}
                    items={data.items} itemHeight={30}
                    onInteraction={() => { }}
                    itemRenderer={(value: any) => <Popup hoverable={false} trigger={<span className='rct9k-items-inner EntityTablePage_contextMenu' style={value.item.style}>&nbsp;</span>}
                        content={<div className="flex-container-row flex-center no-wrap-no-overflow-ellipsis">
                            {value.item.value}&nbsp;{"[" + moment(value.item.start).format(Utils.timeFormat) + " - " + moment(value.item.end).format(Utils.timeFormat) + "]"}
                        </div>}
                    />}
                    interactOptions={{ resizable: {} }} showCursorTime={false}
                    backgroundLayer={<BackgroundLayer markers={props.showCurrentTime && props.currentTime !== undefined ? [
                            <Marker style={{ backgroundColor: "red", zIndex: 1 }} date={props.currentTime} />
                        ] : []} />}
                    selectedItems={this.props.selectedItems as any} onItemClick={this.props.onItemClick}
                />
            </div>
            {props.hasZoomSlider && props.s.startDate && props.s.endDate && props.s.startDate < props.s.endDate &&
                <Zoom
                    initialStartDate={props.initialStartDate} initialEndDate={props.initialEndDate}
                    currentStartDate={this.props.currentStartDate} currentEndDate={this.props.currentEndDate} style={{ rightPadding: 0 }}
                    onRangeChange={(startDate, endDate) => {
                        props.r.setInReduxState({ startDate: startDate, endDate: endDate })
                    }}
                    onButtonAreResize={(width) => this.onSliderBtnResize(width)}
                    sliderTooltipFormat={props.sliderTooltipFormat} />
            }
        </div> : null;
    }
}

export const BarChart = ReduxReusableComponents.connectRRC(BarChartState, BarChartReducers, BarChartRaw);"../..""../../AppMetaTempGlobals""../../CompMeta""../Zoom/Zoom""../../reduxReusableComponents/ReduxReusableComponents"