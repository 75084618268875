import { EntityTablePage, EntityTablePagePartialProps, EntityTablePageReducers, EntityTablePageState, INDEX } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { EntityTableSimpleProps, EntityTableSimple, EntityTableSimpleReducers, EntityTableSimpleState, ITableActionParamForRun } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { DropdownProps, Label } from "semantic-ui-react";
import { FieldEditorProps, FieldRendererProps, fieldRenderers } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import React, { ComponentType, ReactNode } from "react";
import { addAfterStartupRunnable, entityDescriptors, ID } from '@crispico/foundation-react/entity_crud/entityCrudConstants';
import { addEntityDescriptor, EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { AuditedFieldDetails, AuditedFieldDetailsRRC } from "./AuditedFieldDetails/AuditedFieldDetails";
import { AuditFieldsExtendedView } from "./AuditedFieldDetails/AuditFieldsExtendedView";
import { FIELDS_READ, Utils } from "@crispico/foundation-react/utils/Utils";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import {
    ActionName, EXTENDED_VIEW, AUDITABLE_ENTITY_TYPE, AUDITABLE_FIELD_TYPE, AUDIT_ED_NAME,
    AUDITABLE_FIELD, AUDITABLE_ENTITY, AuditUtils, VERSION, LAST_ENTITY_MAPPING_ID_IN_FILTER
} from "./AuditUtils";
import { FieldType, fieldTypeToPrimitiveFieldTypeMapping } from "@crispico/foundation-react/entity_crud/FieldType";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { SelectExtOption } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { getMessageForField } from "@crispico/foundation-react/components/fieldNameContentAssist/FieldNameContentAssist";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { EntityFieldsFieldEditor } from "../../entity_crud/fieldEditors/EntityFieldsFieldEditor";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { EntityNameFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/EntityNameFieldEditor";
import { ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";

export let auditEntityDescriptor: EntityDescriptor;
// It is necessary because we want to reuse table with audit in editors 
export let AuditTablePageRRC: ComponentType<Omit<Readonly<AuditTablePageProps> & Readonly<{ children?: ReactNode | undefined; }>, "s" | "r"> & { id: string; ref?: React.Ref<any>; }>;

function getLastEntityMappingIdInFilter() {
    const lastEntityMappingIdInFilter = window.sessionStorage.getItem(LAST_ENTITY_MAPPING_ID_IN_FILTER);
    return lastEntityMappingIdInFilter ? lastEntityMappingIdInFilter : "0";
}

export class AuditTablePageState extends EntityTablePageState {
    extendedViewForEntity: string = "";
    extendedViewEntity: ReactNode = null;
}

class AuditTablePageReducers<S extends AuditTablePageState = AuditTablePageState> extends EntityTablePageReducers<S> {
}

export type AuditTablePageProps = RRCProps<AuditTablePageState, AuditTablePageReducers> & EntityTablePagePartialProps;

class AuditableEntitySelector extends EntityNameFieldEditor {
    handleChange(event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        s.setFieldValue(hw, EntityDescriptorForServerUtils.getEntityId(String(data.value)));
    }
}

class AuditableFieldSelector extends EntityFieldsFieldEditor {
    protected getEntityName() {
        return EntityDescriptorForServerUtils.getEntityDescriptor(getLastEntityMappingIdInFilter())!.name;
    }

    protected convertField(field: string) {
        return String(EntityDescriptorForServerUtils.getFieldId(getLastEntityMappingIdInFilter(), field));
    }

    protected getOptions() {
        const entityName = this.getEntityName();
        const fields = entityDescriptors[entityName].getAuthorizedFields(FIELDS_READ);
        return Object.keys(fields).filter(field => this.convertField(field)).map(field => { return { value: this.convertField(field), label: fields[field].getLabel() } });
    }

    protected getDropdownOptionFromItem(field: any, entityName: string): SelectExtOption {
        return { value: this.convertField(field), label: getMessageForField(field, entityName) }
    }

    protected getItemsFromValue(value: any): any[] {
        return value ? value.split(',').map((fieldId: string) => {
            const fd = EntityDescriptorForServerUtils.getFieldDescriptor(getLastEntityMappingIdInFilter(), fieldId);
            return fd ? fd.name : fieldId;
        }) : [];
    }
}

addAfterStartupRunnable(() => {

    const entityFieldDescriptor = new class extends FieldDescriptor {
        constructor() {
            super();
            this.name = AUDITABLE_ENTITY;
            this.type = AUDITABLE_ENTITY_TYPE;
            this.sortable = false;
        }

        getFieldValue(values: any) {
            return EntityDescriptorForServerUtils.getEntityDescriptor(super.getFieldValue(values))?.name;
        }

        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
            props.value = entityDescriptors[props.value].getLabel();
            return super.renderFieldInternal(RendererClass, props, entity);
        }

        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            return React.createElement(AuditableEntitySelector as any, props);
        }
    }();

    const actionFieldDescriptor = new class extends FieldDescriptor {
        constructor() {
            super();
            this.name = "action";
            this.type = FieldType.dropdown;
        }

        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
            const key = `actionColumn-${props.entity.index}-${props.entity.auditableEntity}`;
            const color = this.fieldDescriptorSettings?.fieldIntervals?.find(x => x.from === props.entity.action)?.color;
            const semanticColor = color != null && color != undefined ? color as SemanticCOLORS : undefined;
            return <span key={key} className={key}><Label basic horizontal color={semanticColor}>{props.entity.action}</Label></span>
        }
    }();

    const fieldsFieldDescriptor = new class extends FieldDescriptor {
        auditedFieldDetailsRef!: React.RefObject<AuditedFieldDetails>;

        constructor() {
            super();
            this.name = AUDITABLE_FIELD;
            this.type = AUDITABLE_FIELD_TYPE;
            this.sortable = false;
            this.noComposedFields = true;
            //this.allowMultiple = false;
        }

        handleFieldClick = (entity: any, fieldName: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
            e.stopPropagation();
            e.preventDefault();
            // @ts-ignore
            this.auditedFieldDetailsRef.current!.props.r.setInReduxState({ openForSelector: e.currentTarget.className.split(" ")[0], entity, fieldName, secondaryPopupValues: undefined })
        }

        handleExtendedViewClick = (props: FieldRendererProps) => (e: React.MouseEvent<HTMLSpanElement>) => {
            e.stopPropagation();
            e.preventDefault();
            // @ts-ignore
            this.auditedFieldDetailsRef.current!.props.r.setInReduxState({ extendedViewEntity: this.getAuditFields(props, EXTENDED_VIEW), extendedViewForEntity: e.currentTarget.className.split(" ")[0] })
        }

        protected getAuditFields(props: FieldRendererProps, additionalKey: string) {
            return (
                <span className="AuditTablePage_fieldsColumn_displayFlex">{
                    (props.entity.auditableField as number[]).map((fieldId, fieldPosition) => {
                        const fieldDescriptor = EntityDescriptorForServerUtils.getFieldDescriptor(props.entity.auditableEntity, fieldId);
                        const fieldName = fieldDescriptor ? fieldDescriptor.name : EntityDescriptorForServerUtils.getFieldName(props.entity.auditableEntity, fieldId);
                        const fieldType = fieldDescriptor ? fieldDescriptor.type : FieldType.defaultScalar;

                        if ([ID, VERSION].includes(fieldName)) {
                            return null;
                        }

                        let innerEntityDescriptor;
                        if (!fieldRenderers[fieldType]) {
                            innerEntityDescriptor = entityDescriptors[fieldType];
                        }

                        let value = props.entity.action === ActionName.DELETE ? props.entity.oldValue[fieldPosition] : props.entity.newValue[fieldPosition];

                        if (!value && [ActionName.ADD, ActionName.DELETE].includes(props.entity.action)) {
                            return null;
                        }

                        if (fieldDescriptor) {
                            value = AuditUtils.convertAuditValue(fieldDescriptor, value);
                        }

                        const className = `${additionalKey}fieldColumn-${props.entity.index}-${props.entity.auditableEntity}-${fieldName}-${fieldPosition}`;

                        return <span key={className} className={`${className} AuditTablePage_fieldsColumn_paddingBetweenValues`} onClick={this.handleFieldClick(props.entity, fieldName)}>
                            <a href="/#" className="OldSchoolLink">{fieldDescriptor ? fieldDescriptor.getLabel() : fieldName}: {(innerEntityDescriptor ? innerEntityDescriptor.name + "/" : "") + value}</a>
                        </span>
                    })
                }</span>
            )
        }

        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
            const key = EXTENDED_VIEW + props.entity.auditableEntity + '-' + props.entity.index
            return <>
                <span key={key} className={key} style={{ float: "right" }} onClick={this.handleExtendedViewClick(props)}>
                    <a href="/#">...</a>
                </span>
                {this.getAuditFields(props, "")}
            </>
        }

        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            return React.createElement(AuditableFieldSelector as any, props);
        }
    }();

    class AuditEntityDescriptor extends EntityDescriptor {
        getGraphQlFieldsToRequest(fields: string[]) {
            return "index auditableEntity entityId date username sourceCode auditableField oldValue newValue action version";
        }

        renderTable() {
            return <AuditTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} itemsHidedFromCell={["add"]} />;
        }
    }

    fieldTypeToPrimitiveFieldTypeMapping[AUDITABLE_ENTITY_TYPE] = FieldType.number;
    fieldTypeToPrimitiveFieldTypeMapping[AUDITABLE_FIELD_TYPE] = FieldType.number;

    auditEntityDescriptor = addEntityDescriptor(new AuditEntityDescriptor({
        name: AUDIT_ED_NAME,
        icon: "database",
        miniFields: [AUDITABLE_ENTITY, "index"],
        defaultSort: { field: "date", direction: "DESC" },
        defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today)
    }, false)
        .addFieldDescriptor({ name: "index", type: FieldType.number })
        .addFieldDescriptor(entityFieldDescriptor)
        .addFieldDescriptor({ name: "entityId", type: FieldType.number })
        .addFieldDescriptor({ name: "username", type: FieldType.string })
        .addFieldDescriptor({ name: "date", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }), additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat }) })
        .addFieldDescriptor({ name: "version", type: FieldType.string, sortable: false })
        .addFieldDescriptor(actionFieldDescriptor)
        .addFieldDescriptor(fieldsFieldDescriptor)
        .isInDefaultColumnConfig(false, "index", "version")
    );

    class AuditTablePage<P extends AuditTablePageProps = AuditTablePageProps> extends EntityTablePage<P> {
        private auditedFieldDetailsRef = React.createRef<AuditedFieldDetails>();

        static defaultProps = {
            ...EntityTablePage.defaultProps,
            itemsHidedFromCell: ["add"]
        }

        addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
            super.addOneToManyModeFilters(filters, oneToManyModeField, oneToManyModeEntityDescriptor, oneToManyModeCachedId);
            filters.push(AuditUtils.getFilterForAuditableEntity(oneToManyModeEntityDescriptor.name));
        }

        processAuditInsertedDeletedValue(entity: any): any {
            let entityValue: any = {};
            if (entity.oldValue !== "") {
                entityValue = JSON.parse(entity.oldValue);

                entity.oldValue = Object.values(entityValue);
                entity.newValue = [];
            } else {
                entityValue = JSON.parse(entity.newValue);

                entity.oldValue = [];
                entity.newValue = Object.values(entityValue);
            }

            entity.auditableField = Object.keys(entityValue);

            return entity;
        }

        getFieldsToRequestAsString(fieldsToRequest: string[]) {
            return this.props.entityDescriptor.getGraphQlFieldsToRequest(fieldsToRequest);
        }

        getId(entity: any) {
            return entity.auditableEntity + "-" + entity.index;
        }

        constructor(props: P) {
            super(props);
            fieldsFieldDescriptor.auditedFieldDetailsRef = this.auditedFieldDetailsRef;

            const that = this;
            this.tableSimpleClass = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, class extends EntityTableSimple<EntityTableSimpleProps> {
                openFilterFormWithField(entityDescriptor: EntityDescriptor, field: string, position: boolean | [number, number]) {
                    const entityMappingIds = AuditUtils.entityMappingIdFromFilters(Array.of(that.getFilterForLoad()));
                    if (field === AUDITABLE_FIELD && entityMappingIds.length !== 1) {
                        Utils.showGlobalAlert({ message: _msg('Audit.auditableField.notFilterable'), severity: Severity.INFO });
                    } else {
                        window.sessionStorage.setItem(LAST_ENTITY_MAPPING_ID_IN_FILTER, entityMappingIds[0]);
                        super.openFilterFormWithField(entityDescriptor, field, position, this.filterFormRef);
                    }
                }
            });

            this.setOptions({ countMode: undefined });

            this.processAuditInsertedDeletedValue = this.processAuditInsertedDeletedValue.bind(this);
        }

        protected getScriptableUiId() {
            return "inAuditTablePage";
        }

        adjustEntities(entities: any[]): any[] {
            let newEntities: any[] = [];

            this.addIndexOnEntity(entities);

            for (const result of entities) {
                let entity = { ...result };

                if ([ActionName.ADD, ActionName.DELETE].includes(entity.action)) {
                    entity = this.processAuditInsertedDeletedValue(entity);
                    newEntities.push(entity);
                } else if (entity.action === ActionName.COMBINE) {
                    const oldValue = JSON.parse(entity.oldValue);
                    const newValue = JSON.parse(entity.newValue);

                    entity.auditableField = [];
                    entity.oldValue = [];
                    entity.newValue = [];

                    Object.keys(newValue).forEach(key => {
                        entity.auditableField.push(key);
                        entity.oldValue.push(oldValue[key]);
                        entity.newValue.push(newValue[key]);
                    });

                    newEntities.push(entity);
                } else {
                    //if already exists an entity with same primary information then we will update it
                    const existingEntity = newEntities.find(x => x.date === entity.date && x.auditableEntity === entity.auditableEntity && x.entityId === entity.entityId && x.action === entity.action)

                    if (existingEntity) {
                        existingEntity.auditableField.push(entity.auditableField);
                        existingEntity.oldValue.push(entity.oldValue);
                        existingEntity.newValue.push(entity.newValue);

                        existingEntity[INDEX] = entity[INDEX];
                    } else {
                        entity.auditableField = [entity.auditableField];
                        entity.oldValue = [entity.oldValue];
                        entity.newValue = [entity.newValue];

                        newEntities.push(entity);
                    }
                }
            }
            return newEntities;
        }

        onDoubleClickItem(entity: any) {
            //restrict edit mode
        }

        protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] {
            return [];
        }

        protected preRenderButtons(params: any): Array<OverrideableElement> {
            return super.preRenderButtons(params).filter((x: any) => (!x.props || x.props.key !== "add") && (!x.element || !["fileImporter", "fileExporter"].includes(x.element.key)));
        }

        // disable options for cells
        protected provideActionsForCell(actionParam: ITableActionParamForRun): IAction[] {
            return [];
        }

        renderMain() {
            return (
                <>
                    {super.renderMain()}
                    <AuditedFieldDetailsRRC id="auditedFieldDetails" ref={this.auditedFieldDetailsRef} processAuditInsertedDeletedValue={this.processAuditInsertedDeletedValue} />
                    <AuditFieldsExtendedView content={this.props.s.extendedViewEntity} openFor={this.props.s.extendedViewForEntity}
                        onPopupClose={() => this.props.r.setInReduxState({ extendedViewForEntity: "", extendedViewEntity: null })} />
                </>
            );
        }
    }

    AuditTablePageRRC = ReduxReusableComponents.connectRRC(AuditTablePageState, AuditTablePageReducers, AuditTablePage);

});
"../../entity_crud/EntityTablePage""../../entity_crud/EntityTableSimple""../../entity_crud/fieldRenderersEditors""../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../utils/Utils""../../components/TabbedPage/TabbedPage""../../components/CustomQuery/Filter""../../entity_crud/FieldType""../../components/selectExt/SelectExt""../../components/fieldNameContentAssist/FieldNameContentAssist""../../components/ModalExt/ModalExt""../../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils""../../reduxReusableComponents/ReduxReusableComponents""../../entity_crud/fieldRenderers/DateFieldRenderer""../../entity_crud/fieldEditors/DatePickerFieldEditor""../../entity_crud/fieldEditors/EntityNameFieldEditor""../../entity_crud/fieldEditors/FieldEditor"