import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { InputProps } from "semantic-ui-react";
import { ScriptableUiFieldEditor } from "./FieldEditor";
import { NumberFieldEditor, NumberFieldEditorProps } from "./NumberFieldEditor";

export interface DoubleFieldEditorProps<V = any> extends NumberFieldEditorProps<V> {
}

export class DoubleFieldEditor<V = number, P extends DoubleFieldEditorProps<V> = DoubleFieldEditorProps<V>> extends NumberFieldEditor<V, P> {
    protected getInputProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper): InputProps | undefined {
        return { ...super.getInputProps(s, hw), step: "any" };
    }
}