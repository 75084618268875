import React from "react";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt";
import { MarkdownExt } from "../MarkdownExt";
import { LOCAL_STORAGE_CURRENT_DIR_WITH_SCREENSHOTS, TestsAreDemoMaster } from "../TestsAreDemoMaster";
import { FeaturesList } from "../featurebook/FeatureList";
import { Button } from "semantic-ui-react";

// @ts-ignore
import d from "../../common-lib-and-node/common.mjs";
const { RECORDED_TESTS_INDEX, RECORDED_TESTS_DIR } = (d as CommonLibAndNodeDefaultExport);
import { CommonLibAndNodeDefaultExport } from "../../common-lib-and-node/common-types";

interface Props {
    master: TestsAreDemoMaster
}

interface State {
    dirs?: string[]
    currentDir?: string
    markdown?: string
}

export class ScreenshotsFromTests extends React.Component<Props, State> {

    state: State = {};

    async componentDidMount() {
        const result = await fetch(RECORDED_TESTS_DIR + RECORDED_TESTS_INDEX);
        if (!result.ok) {
            return;
        }
        const indexJson = await result.text();
        this.setState({ dirs: JSON.parse(indexJson) });
    }

    async loadDir(dir: string) {
        try {
            const result = await fetch(RECORDED_TESTS_DIR + dir + "/report.md");
            if (!result.ok) {
                throw new Error();
            }
            const markdown = await result.text()
            this.setState({ currentDir: dir, markdown });
        } catch {
            this.setState({ markdown: undefined });
        }
    }

    render() {
        return (
            <SplitPaneExt size={"25%"} split="vertical">
                <div className="w100">
                    <FeaturesList items={this.state.dirs} localStorageKey={LOCAL_STORAGE_CURRENT_DIR_WITH_SCREENSHOTS}
                        onSelect={(dir: string) => this.loadDir(dir)}
                        renderItem={(item: string) => <>{item}</>}
                    />
                </div>
                <MarkdownExt baseUrlForImages={RECORDED_TESTS_DIR + this.state.currentDir}>{this.state.markdown}</MarkdownExt>;
            </SplitPaneExt>)
    }
}