import { FilterOperators } from '@crispico/foundation-gwt-js';
import { FieldDescriptor } from '@crispico/foundation-react/entity_crud/EntityDescriptor';
import { FieldType } from '@crispico/foundation-react/entity_crud/FieldType';
import { Filter } from '../../components/CustomQuery/Filter';
import { EntityDescriptorForServerUtils } from '../../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils';

export const AUDIT_ED_NAME = "Audit";

export enum ActionName { ADD = "ADD", UPDATE = "UPDATE", DELETE = "DELETE", REFRESH = "REFRESH", COMBINE = "COMBINE" }

export const TRUE_VALUES = ["true", "t", "1"]
export const VERSION: string = "version";
export const EXTENDED_VIEW = "extended-view-";

export const AUDITABLE_ENTITY = "auditableEntity";
export const AUDITABLE_FIELD = "auditableField";
export const AUDITABLE_ENTITY_TYPE = `${AUDITABLE_ENTITY}Type`;
export const AUDITABLE_FIELD_TYPE = `${AUDITABLE_FIELD}Type`;
export const LAST_ENTITY_MAPPING_ID_IN_FILTER = "lastEntityMappingIdInFilter";

export class AuditUtils {

    static getFilterForAuditableEntity(entityName: string, enabled?: boolean) {
        if (enabled) {
            Filter.createForClient(AUDITABLE_ENTITY, FilterOperators.forNumber.equals, String(EntityDescriptorForServerUtils.getEntityId(entityName)), enabled);
        }
        return Filter.create(AUDITABLE_ENTITY, FilterOperators.forNumber.equals, String(EntityDescriptorForServerUtils.getEntityId(entityName)));
    }

    static getFilterForAuditableField(entityName: string, fieldName: string, enabled?: boolean) {
        if (enabled) {
            Filter.createForClient(AUDITABLE_FIELD, FilterOperators.forNumber.equals, String(EntityDescriptorForServerUtils.getFieldId(entityName, fieldName)), enabled)
        }
        return Filter.create(AUDITABLE_FIELD, FilterOperators.forNumber.equals, String(EntityDescriptorForServerUtils.getFieldId(entityName, fieldName)));
    }

    static getFieldLabelsFromIds(entityMappingId: string, fieldMappingIds: string) {
        if (!fieldMappingIds) {
            return "";
        }
        return fieldMappingIds.split(',').map(fieldMappingId => {
            const fd = EntityDescriptorForServerUtils.getFieldDescriptor(entityMappingId, fieldMappingId);
            if (fd) {
                return fd.getLabel();
            }
            return String(fieldMappingId);
        }).join(',')
    }

    static entityMappingIdFromFilters(filters: Filter[] | undefined): string[] {
        let entityMappingIds: string[] = []
        if (filters) {
            for (const filter of filters) {
                if (filter.field === AUDITABLE_ENTITY && filter.value) {
                    entityMappingIds.push(filter.value);
                }
                if (filter.filters) {
                    entityMappingIds = entityMappingIds.concat(AuditUtils.entityMappingIdFromFilters(filter.filters))
                }
            }
        }
        return entityMappingIds;
    }

    static convertAuditValue(fd: FieldDescriptor, value: any) {
        switch (fd.type) {
            case FieldType.boolean:
                const valueAsString = String(value);
                return TRUE_VALUES.includes(valueAsString);
            case FieldType.dropdown:
                return Number(value);
            default:
                return value;
        }
    }
}"../../entity_crud/EntityDescriptor""../../entity_crud/FieldType"