import React, { ReactNode } from "react";
import SplitPane, { SplitPaneProps } from "react-split-pane";
import "./styles.css";

export function SplitPaneExt(props: SplitPaneProps & { className?: string, children: ReactNode[] }) {
    let className = (props.split === 'horizontal' ? "flex-container-row" : "flex-container") + " flex-grow";
    if (props.className) { className += " " + props.className }

    // 1/ That "static" was found after heavy research; I don't remember exactly what it does; but in at least one
    // case; at that time the trick w/ the additional div below was not yet there
    // 2/ The component comes w/ flex: 1 1 0%. This differs from 1 1 0. I didn't really understand the difference.
    // In fact the last digit, about redistributing the space, is quite a mistery to me. But 1 1 0 was mandatory
    // for usage within a fullscreen modal. Otherwise the modal would be "pushed" out of the screen.
    const splitPane = (<SplitPane {...props} className={className} style={{ position: "static", ...props.style, flex: "1 1 0" }}>
        {props.children}
    </SplitPane>);

    if (props.split === "horizontal") {
        // for this case, SplitPane adds minHeight=100%, which is not overrideable (like some other styles that it adds)
        // I think the percentage reffers to the width of the parent; so if the parent div has other divs besides the split pane => 
        // a scroll appear, equal with the size of the other div; adding an intermediate div solves the issue; in it: the split pane
        // is the only child; hence it can take all the 100% w/o problems
        return <div className="flex-grow">{splitPane}</div >;
    } else {
        return splitPane;
    }
}
