import { ConnectedPageHelper } from "./ConnectedPageHelper";
import React from "react";
import { Utils } from "../utils/Utils";
import { reducer_setInReduxState } from "../stateAndLogic/StateAndLogic";

/**
 * Holds all the ConnectedPageHelpers. When a page is mounted/unmounted, this is notified, so that it
 * can keep track of "mounted" helpers (i.e. the 'mounted' state of the component that corresponds to a helper). 
 * 'reducer()' calls only the reducers of the mounted helpers. Usually only une helper is mounted at a given
 * moment; but more are supported as well; more are possible via route config.
 */
export class RootReducerForPages {

    private static REMOVE_SLEEPING_KEYS_FROM_ROOT_STATE = "REMOVE_SLEEPING_KEYS_FROM_ROOT_STATE";

    static Context = React.createContext<RootReducerForPages>(undefined as any);

    static actionRemoveSleepingKeysFromRootState(keys: string[]) {
        return { type: RootReducerForPages.REMOVE_SLEEPING_KEYS_FROM_ROOT_STATE, keys };
    }

    protected mountedHelpers: { [sliceName: string]: ConnectedPageHelper } = {};

    constructor(helpers?: Array<ConnectedPageHelper>) {
        this.reducer = this.reducer.bind(this);
        if (!helpers) { return; }
        for (let helper of helpers) {
            this.add(helper);
        }
    }

    isHelperMounted(sliceName: string | undefined): boolean {
        return sliceName && this.mountedHelpers[sliceName] ? true : false;
    }

    getMountedHelper<SLICE>(sliceName: string): ConnectedPageHelper<SLICE> {
        const helper = this.mountedHelpers[sliceName];
        if (!helper) { throw new Error("Cannot find helper for sliceName = " + sliceName + "; available slices in the root state: " + Object.keys(this.mountedHelpers).join()) }
        return helper;
    }

    add(helper: ConnectedPageHelper) {
        helper.initHOCs(this);
    }

    onHelperMounted(helper: ConnectedPageHelper) {
        if (this.mountedHelpers[helper.sliceName]) {
            throw new Error(`More than one helper w/ the same key = '${helper.sliceName}' were mounted.`);
        }
        this.mountedHelpers[helper.sliceName] = helper;
    }

    onHelperUnmounted(helper: ConnectedPageHelper) {
        delete this.mountedHelpers[helper.sliceName]
    }

    reducer(state: any, action: { type: string, [key: string]: any }) {
        if (action.type === "replaceState") {
            return action.payload;
        }

        const newStateMaybe = reducer_setInReduxState(state, action);
        if (newStateMaybe !== state) {
            return newStateMaybe;
        } // else other type of action; so let's continue

        // we want to remove the keys of mounted reducers, in order to have at the end
        // the keys of "sleeping" reducers
        if (state) {
            state = { ...state };
        }

        if (action.type === RootReducerForPages.REMOVE_SLEEPING_KEYS_FROM_ROOT_STATE) {
            for (let key of action.keys) {
                delete state[key];
            }
            return state;
        }

        const result: any = {};
        for (let sliceName in this.mountedHelpers) {
            this.invokeReducerInHelper(state, action, result, this.mountedHelpers[sliceName]);
        }

        if (state) {
            // now state may contain state slices of components that are (sleeping)
            // which is also the case for initial display in storybook
            Object.assign(result, state);
        }
        return result;
    }

    invokeReducerInHelper(state: any, action: any, result: any, helper: ConnectedPageHelper) {
        result[helper.sliceName] = helper.reducer(state?.[helper.sliceName], action);
        if (state) {
            // this reducer is not sleeping; we just got fresh info from it's reducer
            // so get rid of the sleeping state
            delete state[helper.sliceName]
        }
    }

}