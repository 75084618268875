import React from "react";
import { NavLink, Route } from "react-router-dom";
import { Card, Label, Icon, Header, Segment, Message } from "semantic-ui-react";
import { TabbedPage } from "../components/TabbedPage/TabbedPage";
import { getZeroTrainingArticleUrl } from "./ZeroTrainingArticlePage";
import { ZeroTrainingContext, ZeroTrainingArticle } from "./dataStructures";
import Interweave from 'interweave';

const colors = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'];

export class ZeroTrainingIndexPage extends TabbedPage<any> {

    protected getTitle() {
        return { icon: "leanpub", title: _msg("ZeroTraining.title") };
    }

    renderMain() {
        return (
            <ZeroTrainingContext.Consumer>
                {registry => {
                    // I don't know why the card group has some negative margins; and It's not the only one I saw it like this
                    // hence I override w/ 0. Probably because the SUIR containers have all padding; so maybe to cancel a bit
                    // of the padding
                    return (
                            <Segment>
                                <Header as="h2" dividing>
                                    <Icon name={this.getTitle().icon as any} />
                                    <Header.Content>
                                        {this.getTitle().title}
                                        <Header.Subheader></Header.Subheader>

                                    </Header.Content>
                                </Header>
                                <Message color="blue"><Interweave content={_msg("ZeroTraining.description")} /></Message>
                                <Card.Group centered>
                                    {registry.getAsList().map((article, i) => <Article key={i} article={article} />)}
                                </Card.Group>
                            </Segment>
                    )
                }}
            </ZeroTrainingContext.Consumer>
        );
    }
}

export const zeroTrainingIndexPageUrl = "/ZeroTrainingIndex";
export const ZeroTrainingIndexPageRoute = () => <Route key="ztipr" exact path={zeroTrainingIndexPageUrl} component={ZeroTrainingIndexPage} />
export const zeroTrainingIndexPageMenuEntry = () => { return { content: _msg("ZeroTraining.title"), to: "/ZeroTrainingIndex", icon: "leanpub", exact: true, color: "orange" } };

export function getColor(str: string): any {
    let sum = 0;
    for (let i = 0; i < str.length && i < 15; i++) {
        sum += str.charCodeAt(i);
    }
    return colors[sum % colors.length];
}

function getInitials(str: string) {
    const spl = str.split(/ |\//);
    if (spl.length >= 2) {
        return (spl[0].length ? spl[0].charAt(0) : "?").toUpperCase() + (spl[1].length ? spl[1].charAt(0) : "?").toUpperCase();
    } else if (spl.length === 1) {
        return spl[0].substr(0, 2);
    } else {
        return "?";
    }
}

function Article(props: { article: ZeroTrainingArticle }) {
    return (<Card as={NavLink} to={getZeroTrainingArticleUrl(props.article.key)}>
        <Card.Content className="ZeroTraining_cardInitials">
            <Label circular size="massive" color={getColor(props.article.title!)}>{getInitials(props.article.title!)}</Label>
        </Card.Content>
        <Card.Content>
            <Card.Header>{props.article.title}</Card.Header>
            <Card.Meta>
                {_msg("ZeroTraining.contains", props.article.stories.length)}
            </Card.Meta>
            <Card.Description>
                {props.article.stories[0]?.story.parameters.joyrideDecorator.teaser}
            </Card.Description>
        </Card.Content>
    </Card>);
}
