import { TestClassDescriptor, TestsToRun } from "../TestsAreDemoMaster";
import "./styles.css";

export function getTestsToRunCount(testsToRun?: TestsToRun) {
    let classesChecked = 0, functionsChecked = 0;
    if (testsToRun) {
        for (let clazz of Object.values(testsToRun)) {
            const f = Object.getOwnPropertyNames(clazz).length;
            if (f === 0) { continue; } // no functions selected, so we shouldn't increment
            classesChecked++;
            functionsChecked += f;
        }
    }

    return { classesChecked, functionsChecked };
}

export function getTestToRunCountLabel(testsToRun?: TestsToRun) {
    const r = getTestsToRunCount(testsToRun);
    return `${r.functionsChecked}t (${r.classesChecked}c)`;
}

export function getTestsCountLabel(testClassDescriptors: TestClassDescriptor[]) {
    let classes = 0, functions = 0;
    testClassDescriptors?.forEach(d => {
        classes++;
        functions += d.functions.length;
    });
    return `${functions}t (${classes}c)`;
}

export function getLinkToCurrentPage(appendQueryParam: string, value: string) {
    const search = new URLSearchParams(window.location.search);
    search.delete(appendQueryParam);
    search.append(appendQueryParam, value); // in the spec they don't mention that they encode the value; but I observed that they do
    return window.location.href + "?" + search.toString();
}

export async function fetchSafe(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const result = await fetch(input, init);
    if (!result.ok) {
        throw new Error("Request failed. See the browser's network tab for details. URL: " + input);
    }
    return result;
}

export async function fetchJsonSafe<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return (await fetchSafe(input, init)).json();
}

export async function fetchJsonCredentials<T>(url: string, includeCredentialsInJsonRequest: boolean | undefined): Promise<T> {
    return fetchJsonSafe(url, {
        // "include" = e.g. to transmit the cookies for a request to a private GitLab repo
        // however, this is not a passe-par-tout solution; it works ONLY if access-control-allow-origin IS NOT *
        // and * is the default
        credentials: includeCredentialsInJsonRequest ? "include" : undefined,
    });
}
