import gql from "graphql-tag";

export const LOAD_NOTIFICATIONS = gql`query notificationService_findByFilter($params: CustomFindByFilterParamsInput) {
  notificationService_findByFilter(params: $params) {
    totalCount
    results {
        id creationDate entityName entityId code severity description deletionDate
    }
  }
}`;

export const SAVE_NOTIFICATION_AND_USER = gql`mutation notificationAndUserService_save($params: SaveParams_LongInput) {
  notificationAndUserService_save(params: $params) {
    id
  }
}`;