import React, { ReactElement, ReactNode, createRef } from "react";
import { ScriptableUiContext } from "../ScriptableUiContext";
import { Spotlight } from "../../components/Spotlight";
import ReactFloater from "react-floater";
import { Button } from "semantic-ui-react";
import { ScriptableUi } from "../ScriptableUi";
import { FoundationUtils } from "@famiprog-foundation/utils";
import { SyntaxHighlighterExt } from "../../components/SyntaxHighlighterExt";
import { ScriptableUiInterceptor } from "../ScriptableUiInterceptor";
import { Capture, CaptureV2 } from "../Capture";
import { ScriptableContainerContext } from "./ScriptableContainerContext";

interface State {
    highlight?: boolean,
    floaterContent?: JSX.Element,
    resolveConfirmRecordedInstruction?: (value: boolean) => void
}

interface Props {
    // I try to have only Functions, but it forces also id and children to be a function
    [key: string]: any,
    id: string | number,
    children: ReactElement | ((scriptable: Scriptable) => ReactElement),
};

export class Scriptable extends React.Component<Props, State> {

    static ownPropsToExclude: Record<string, boolean> = { id: true, children: true };

    static contextType = ScriptableContainerContext;
    declare context: React.ContextType<typeof ScriptableContainerContext>

    state: State = {};

    wrapperRef = createRef<HTMLDivElement>();

    proxy: any;

    constructor(props: Props, context: React.ContextType<typeof ScriptableContainerContext>) {
        super(props);
        const that = this;
        this.proxy = new Proxy({}, {

            get(target: any, p: string, receiver) {
                if (p === "then") {
                    // This proxy is returned as a result of an async method (in ScriptableUiObtainer). I observed that when 
                    // the method is called (probably via await), an attempt is made to obtain property "then". I think this
                    // is the way of JS to test if the result is a promise or not. 
                    return undefined;
                }
                return async (...args: any) => {
                    // heads up: we need to use that.props (instead of props), to access the latest props
                    // (and not the ones passed at construction)
                    const originalFunction: Function = that.props[p];
                    if (typeof originalFunction !== "function") {
                        throw new Error("Someone calls the proxy for function: " + p + "; but such a function is not defined (or the property exists, but it's not a function)");
                    }

                    if (!ScriptableUiInterceptor.INSTANCE) {
                        originalFunction.apply(null, args);
                        return;
                    }

                    const capture = new CaptureV2(context!.qualifiedId, that.props.id, p, args);
                    if (await ScriptableUiInterceptor.INSTANCE.onIntercept(capture, undefined, that)) {
                        originalFunction.apply(null, args);
                    }
                }
            }
        });
    }

    getDomElement() {
        return this.wrapperRef.current?.nextElementSibling as HTMLElement;
    }

    componentDidMount(): void {
        if (!this.context) {
            throw new Error("A Scriptable must be an (direct or indirect) child of a ScriptableContainer");
        }
        this.context.addScriptable(this);
    }

    componentWillUnmount(): void {
        this.context!.removeScriptable(this);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.id !== prevProps.id) {
            throw new Error("Changing 'id' is not supported");
        }
    }

    protected getFloaterContent() {
        return this.state.floaterContent;
    }

    async highlightAndConfirmRecordedInstruction(recordedInstruction: string) {
        if (!await this.highlight(<>
            <SyntaxHighlighterExt showLineNumbers={false}>{recordedInstruction}</SyntaxHighlighterExt>
            <Button positive content="Continue" onClick={() => this.state.resolveConfirmRecordedInstruction!(true)} />
            <Button negative content="Cancel" onClick={() => this.state.resolveConfirmRecordedInstruction!(false)} />
        </>)) {
            // if here => we are not able to display the UI w/ buttons. So consider that the user pressed OK
            console.log("Cannot get user input. Continuing.");
            return true;
        }
        const result = await new Promise<boolean>(resolveConfirmRecordedInstruction => {
            this.setState({ resolveConfirmRecordedInstruction });
        });
        this.cancelHighlight();
        return result;
    }

    /**
     * @returns `false` if the highlight cannot be performed.
     */
    async highlight(floaterContent?: JSX.Element) {
        this.setState({ highlight: true });
        // wait for a render so that the ref to the div is created
        await FoundationUtils.setTimeoutAsync();
        if (!this.wrapperRef.current) {
            console.log("Cannot highlight the target component. It is unmounted. E.g. clicked on a context menu that quickly disappeared.");
            return false;
        }
        if (!this.getDomElement()) {
            console.log("Cannot highlight the target component. Cannot find the corresponding DOMElement. E.g. it uses a portal (such as a modal, a popup), so the DOMElement is not a child in the natural place, but somewhere else. ");
            return false;
        }
        this.setState({ floaterContent });
        return true;
    }

    cancelHighlight() {
        this.setState({ highlight: false, floaterContent: undefined, resolveConfirmRecordedInstruction: undefined });
    }

    /**
     * @see ScriptableUi.play()
     */
    render() {
        let child: ReactElement;
        if (typeof this.props.children === "function") {
            // in this case, the user should declare a callback in the child element that invokes the proxy
            child = this.props.children(this);
        } else {
            child = this.props.children as ReactElement;

            const newChildProps = { ...child.props };
            for (const prop in this.props) {
                if (Scriptable.ownPropsToExclude[prop]) {
                    continue;
                }
                if (typeof this.props[prop] !== "function") {
                    throw new Error("You can only add properties that are callbacks/functions. The prop is not a function: " + prop);
                }
                if (!child.props[prop]) {
                    // it's like the user had written e.g. onClick={() => scriptable.proxy.onClick()}
                    // it's important to add an intermediate function (and not call this.proxy[prop]() directly)
                    // otherwise, the callee would "see" the original args of the component (e.g. event, etc); he
                    // might be tempted to use them. And this is not compatible w/ the call coming from the script
                    newChildProps[prop] = () => this.proxy[prop]();
                } // else there is already a callback; probably the user will is calling the proxy by himself, in order to adjust params
            }

            // @ts-ignore
            if (child.ref) {
                // the ref is not present in props
                // @ts-ignore
                newChildProps.ref = child.ref;
            }
            child = React.createElement(child.type, newChildProps);
        }

        const floaterContent = this.getFloaterContent();
        const domElement = this.getDomElement();
        // the div is a sibbling and not a parent because as a parent, it may break styling
        // initially cf. highlight = true/false we would display this or the original child; the 
        // disadvantage is that when toggling, the component would loose it's state. We didn't actually
        // see this, but it may be annoying in some cases (e.g. for a complex component)
        return <>
            {this.state.highlight && <div ref={this.wrapperRef} style={{ display: "none" }} />}
            {child}
            {this.state.highlight && domElement && <>
                <Spotlight element={domElement} />
                {floaterContent && <ReactFloater open styles={{ options: { zIndex: 10001 } /* TestsAreDemo_overlay + 1 */, container: { borderRadius: "4px" }, floater: { maxWidth: "1000px" } }}
                    target={domElement} content={floaterContent}
                />}
            </>}
        </>
    }
}
