import { settingsEntityService_getSettingsRootTypes } from "@crispico/foundation-react/apollo-gen-foundation/settingsEntityService_getSettingsRootTypes";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import React, { useState, useEffect } from "react";
import { Button, Segment, Dropdown, TextArea, Form, Input } from "semantic-ui-react";
import { GET_SETTINGS_ROOT_TYPES, SETTINGS_ENTITY_DESCRIPTOR_DTO } from "./queries";
import gql from "graphql-tag";

export function SettingsEntityDebug() {
    const [rootTypes, setRootTypes] = useState([] as { key: string, text: string, value: string }[]);
    const [json, setJson] = useState("");
    const [org, setOrg] = useState("");
    const [rootType, setRootType] = useState("");

    // separate function, because "async" => result = Promise; not accepted by "useEffect()"
    async function getRootTypes() {
        var rootTypes = (await apolloClient.query<settingsEntityService_getSettingsRootTypes>({ query: GET_SETTINGS_ROOT_TYPES })).data.settingsEntityService_settingsRootTypes;
        setRootTypes(rootTypes.map(rt => ({ key: rt, text: rt, value: rt })));
    }

    useEffect(() => { getRootTypes() }, []);
    return (<Segment>
        <Button onClick={async () => {
            const result = (await apolloClient.query({
                query: gql`
                    query settingsDebug ($organization: String, $rootType: String) { settingsEntityService_settingsDebugJson(organization: $organization, rootType: $rootType) { a b { ...SettingsEntityDescriptorDto } } }
                    ${SETTINGS_ENTITY_DESCRIPTOR_DTO}
                `,
                variables: { organization: org, rootType: rootType }
            })).data.settingsEntityService_settingsDebugJson;
            setJson(result.a);
        }
        }>{_msg("SettingsEntityDebug.getJson.label")}</Button>

        <Form>
            <Form.Field>
                <label>{_msg("SettingsEntity.organization.label")}</label>
                <Input value={org} onChange={(e, d) => setOrg(d.value)} />
            </Form.Field>
            <Form.Field>
                <label>{_msg("SettingsEntity.rootType.label")}</label>
                <Dropdown search selection options={rootTypes} value={rootType} onChange={(e, d) => setRootType(d.value as string)} />
            </Form.Field>
            <TextArea rows={30} value={json} />
        </Form>
    </Segment>);
}
"../../apollo-gen-foundation/settingsEntityService_getSettingsRootTypes""../../apolloClient"