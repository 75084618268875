import React from "react";
import { MarkdownExt } from "../MarkdownExt";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt";
import { FeaturesList } from "../featurebook/FeatureList";
import { LOCAL_STORAGE_CURRENT_DOC } from "../TestsAreDemoMaster";

// @ts-ignore
import d from "../../common-lib-and-node/common.mjs";
import { DocDescriptor, CommonLibAndNodeDefaultExport } from "../../common-lib-and-node/common-types";
const { generateMarkdownDoc } = (d as CommonLibAndNodeDefaultExport);

export class DocFromTests extends React.Component {

    state = {} as {
        descriptors?: string[]
        markdown?: string
    };

    async componentDidMount() {
        const response = await fetch("docDescriptors/index.json");
        if (!response.ok) {
            return;
        }
        const indexJson = await response.text();
        this.setState({ descriptors: JSON.parse(indexJson) });
    }

    async loadDescriptor(file: string) {
        try {
            const response = await fetch("docDescriptors/" + file);
            if (!response.ok) {
                throw new Error();
            }
            const json = await response.text()
            const docDescriptor: DocDescriptor = JSON.parse(json);
            this.setState({ markdown: await generateMarkdownDoc(docDescriptor) });
        } catch {
            this.setState({ markdown: undefined });
        }
    }

    render() {
        return (
            <SplitPaneExt size={"25%"} split="vertical">
                <div className="w100">
                    <FeaturesList items={this.state.descriptors} localStorageKey={LOCAL_STORAGE_CURRENT_DOC}
                        renderItem={item => item}
                        onSelect={(descriptor: string) => this.loadDescriptor(descriptor)} />
                </div>
                <MarkdownExt>{this.state.markdown}</MarkdownExt>;
            </SplitPaneExt>)
    }
}