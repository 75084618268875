import { FilterOperators } from "@crispico/foundation-gwt-js";
import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { BlocklyEditorTab, BlocklyEditorTabRRC, DEFAULT_COMPOSED_FILTER } from "@crispico/foundation-react/components/CustomQuery/BlocklyEditorTab";
import { createFilterBlock } from "@crispico/foundation-react/components/CustomQuery/createFilterBlock";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterPreview } from "@crispico/foundation-react/components/CustomQuery/FilterPreview";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import _ from "lodash";
import React from "react";
import { Button, Checkbox, Label, Modal } from "semantic-ui-react";
import { FieldEditor, FieldEditorNotUsableStandAloneProps, ScriptableUiFieldEditor } from "./FieldEditor";
import { castWithHw, HW, ScriptableUi } from "@famiprog-foundation/scriptable-ui";

export enum EntityFilterMode {
    JSON, OBJECT
}

export class FilterFieldEditor extends FieldEditor<any, FieldEditorNotUsableStandAloneProps> {

    blocklyEditorTabRef = React.createRef<BlocklyEditorTab>();

    constructor(props: FieldEditorNotUsableStandAloneProps) {
        super(props);

        // For this editor, we also need to close the modal for editor here. With closing outside of this method,
        // modal that display filter editor is closed before the spotlight appear on it
        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                original.setFieldValue(value);
                this.closeEditor();
            }
        }));
    }

    state = {
        editorOpened: false,
        entityName: undefined
    }

    componentDidUpdate(prevProps: FieldEditorNotUsableStandAloneProps) {
        if (prevProps.formikProps.values[this.props.fieldDescriptor.fieldForEntityName] !== this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName]) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, undefined);
        }
    }

    closeEditor = () => this.setState({ editorOpened: false })

    openEditor = () => {
        this.setState({ editorOpened: true });
    }

    renderWithScriptableUi(s: ScriptableUiFieldEditor.Main) {
        const entityName: string = this.props.fieldDescriptor.entityName ? this.props.fieldDescriptor.entityName : this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName];
        const mode: EntityFilterMode = this.props.fieldDescriptor.mode ? this.props.fieldDescriptor.mode : EntityFilterMode.JSON;
        let filter = DEFAULT_COMPOSED_FILTER;
        if (this.state.entityName !== entityName) {
            this.setState({ entityName: entityName });
        } else if (this.props.formikProps.values[this.props.fieldDescriptor.name]) {
            let f = this.props.formikProps.values[this.props.fieldDescriptor.name]!;
            if (mode === EntityFilterMode.JSON) {
                f = JSON.parse(f)!;
            }
            filter = Filter.enableAllFilters(f)!;
        }
        return (
            <>
                <div className='FilterBar_div'>
                    {filter.filters && filter.filters.length > 1 && <Label className="tiny-margin-right" size="small" content={filter.operator.toUpperCase()} color={filter.operator === FilterOperators.forComposedFilter.and.value ? "blue" : "teal"} />}
                    {filter?.filters?.map((f: Filter, index: number) => {
                        return (
                            <Label key={index} className={'OnlyFilterBar_filterDiv'}>
                                <Checkbox checked={f.enabled ? true : false} />
                                <BlocklyReadOnly key={index}>{createFilterBlock(f, { entityDescriptorName: entityName }, false, false, true)}</BlocklyReadOnly>
                            </Label>
                        );
                    })}
                    <Button type='button' compact onClick={() => this.openEditor()}>...</Button>
                    {this.props.fieldDescriptor.showPreviewButton !== false ? <FilterPreview entityName={entityName} getFilter={() => filter} /> : null}
                    <ModalExt onClose={this.closeEditor} open={this.state.editorOpened} size='small' centered={false}>
                        <Modal.Header>{_msg('FilterFieldEditor.header')}</Modal.Header>
                        <Modal.Content scrolling>
                            <HW id="blocklyEditorTab" children={hw => <BlocklyEditorTabRRC id="blocklyEditorTab"
                                initialFilter={filter} 
                                ref={this.blocklyEditorTabRef} 
                                onCancel={this.closeEditor} 
                                apply={
                                    (filter: Filter) => {
                                        const f = Filter.eliminateDisabledFilters(filter);
                                        castWithHw(s).setFieldValue(hw, mode === EntityFilterMode.JSON ? (JSON.stringify(f) || "") : f);
                                    }}
                                entityDescriptor={entityName}
                                ownRef={this.blocklyEditorTabRef}
                            />} />
                        </Modal.Content>
                    </ModalExt>
                </div>
            </>
        );
    }
}"../../blockly/BlocklyReadOnly""../../components/CustomQuery/BlocklyEditorTab""../../components/CustomQuery/createFilterBlock""../../components/CustomQuery/Filter""../../components/CustomQuery/FilterPreview""../../components/ModalExt/ModalExt"