import React from "react";
import Blockly from "blockly";
import { TestUtils } from "../utils/TestUtils";
import { Utils } from "../utils/Utils";

interface Props {
    children?: any;
    openEditor?: (event: any) => void
}

export class BlocklyReadOnly extends React.Component<Props, any> {
    blocklyReadonlyDivRef = React.createRef<any>();
    workspace!: Blockly.WorkspaceSvg;

    componentDidMount() {
        if (Utils.isTest()) {
            return;
        }
        this.workspace = Blockly.inject(this.blocklyReadonlyDivRef.current, {
            comments: false,
            trashcan: false,
            readOnly: true,
            move: {
                scrollbars: false
            },
            zoom: {
                controls: false,
                wheel: false
            },
            renderer: 'custom_renderer'
        });
        this.updateWorkspace();
    }

    componentDidUpdate(prevProps: any) {
        this.updateWorkspace(prevProps);
    }

    updateWorkspace(prevProps?: any) {
        if (Utils.isTest()) {
            return;
        }
        if (this.props.children === prevProps?.children) {
            return;
        }
        this.workspace.clear();
        const xmlString = `<xml>${this.props.children as string}</xml>`;
        Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xmlString), this.workspace);
        var metrics: any = this.workspace.getMetrics();
        const div = this.blocklyReadonlyDivRef.current;
        div.style.height = metrics.contentHeight + "px";
        div.style.width = metrics.contentWidth + "px";
        Blockly.svgResize(this.workspace);
    }

    render() {
        return (
            <div onClick={this.props.openEditor} ref={this.blocklyReadonlyDivRef} className='BlocklyReadOnly_elementMargins' />
        );
    }
}