import { ReactElement, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./Spotlight.css";

export function Spotlight({ element, additionalClassNames, children }: { element: Element, additionalClassNames?: string, children?: ReactElement }) {
    const [_, setDummy] = useState(false);

    // When running a recorded test, sometimes the spotlight position is not good.
    // e.g. 1/ if the element is outside the viewport (so a scroll is needed). In this case the element is not visible
    // e.g. 2/ if there is an animation, e.g. drawer slide. In this case, the spotlight is not above the element
    // A trivial solution is to have a timer that repositions the spotlight, to make sure it's above the element.
    useEffect(() => {
        const handle = setInterval(() => {
            // changing this dummy state value to force a rerender of this component
            setDummy(currentValue => !currentValue);
        }, 1000);

        return function cleanup() {
            clearInterval(handle);
        }
    }, []);

    const spotlightRect = element.getBoundingClientRect();
    const OFFSET = 5;
    spotlightRect.x -= OFFSET;
    spotlightRect.y -= OFFSET;
    spotlightRect.width += 2 * OFFSET;
    spotlightRect.height += 2 * OFFSET;
    
    // the CSS uses "position: fixed", which in theory would make the div to be positioned cf. viewport. But in practice, there are some styles that ruin the game:
    // will-change: transform. If a parent has this style => it "steals" this div from the viewport, and puts it inside it. Hence the need to put the div directly in <body>
    return ReactDOM.createPortal(<div className="Spotlight_overlay">
        <div className={"Spotlight_spotlight " + additionalClassNames} style={{ left: `${spotlightRect!.x}px`, top: `${spotlightRect!.y}px`, width: `${spotlightRect!.width}px`, height: `${spotlightRect!.height}px` }} />
        {children}
    </div>, document.body);
}