export const HistogramWithDetailsMeta = {
    scenarios: {
        givenProps: "GIVEN props to HistogramWithDetails, THEN is displyed more data",
        givenSerieLabel: "GIVEN label for serie, THEN in list is show the label",
        whenCheckbox: "WHEN click on checkbox, THEN toogle the show serie on lineChart",
        whenMouseOverChartLine: "WHEN I move the mouse over a chart line, THEN the point closest to the mouse cursor is displayed with a bolder style AND a tooltip is shown with more info",
        pinButton: "WHEN the point on lineChar is pinned, THEN the button is active and hover on lineChar is locked",
        exportButton: "WHEN click on export button, THEN download the file with data of series",
    },

    testids: {
        seriesList: "",
        seriePoint: "",
        serieSelectedPoint: "",
        serieCheckbox: "",
        pinButton: "",
        exportButton: "",
        selectedSerie: "",
        infoLabel: "",
        gridSerieLabel: ""
    }
};