import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { createSortBlock, Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { SortForm } from "@crispico/foundation-react/components/CustomQuery/SortForm";
import { Formik } from "formik";
import React from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import lodash from 'lodash'
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";
import { castWithHw, HW, ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

export class SortFieldEditor extends FieldEditor<any, FieldEditorProps> {

    state = {
        sorts: [] as Array<Sort>,
        indexForEditor: -1,
        error: false,
        editorOpened: false,
        entityName: undefined
    }

    constructor(props: FieldEditorProps) {
        super(props);
        this.renderForm = this.renderForm.bind(this);
        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                original.setFieldValue(value);
                this.setState({ sorts: value })
            }
        }));
    }

    componentDidMount() {
        if (this.props.formikProps && this.props.fieldDescriptor && this.props.formikProps.values[this.props.fieldDescriptor.name] && this.props.formikProps.values[this.props.fieldDescriptor.name].length > 0) {
            this.setState({ sorts: this.props.formikProps.values[this.props.fieldDescriptor.name] });
        }
    }

    componentDidUpdate(prevProps: FieldEditorProps) {
        if (this.props.formikProps && this.props.fieldDescriptor && prevProps.formikProps && prevProps.formikProps.values[this.props.fieldDescriptor.fieldForEntityName] !== this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName]) {
            this.props.formikProps.setFieldValue(this.props.fieldDescriptor.name, []);
            this.setState({ sorts: [] })
        }
    }

    closeEditor = () => {
        this.setState({
            editorOpened: false,
            indexForEditor: -1,
            error: false,
            entityType: ''
        })
    }

    openEditor = (sortIndex: number) => {
        if (this.state.editorOpened) { return; }
        this.setState({
            indexForEditor: sortIndex,
            editorOpened: true
        })
    }

    onSave = (sort: Sort, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        if (!sort.field) {
            this.setState({ error: true })
            return;
        }
        this.setState({ error: false })
        let sorts = lodash.cloneDeep(this.state.sorts);
        if (this.state.indexForEditor === -1) {
            sorts.push(sort);
        } else {
            sorts[this.state.indexForEditor] = sort;
        }
        this.setState({ sorts: sorts })
        this.closeEditor();
        s.setFieldValue(hw, sorts);
    }

    moveLeft = () => {
        if (this.state.indexForEditor <= 0) { return; }
        let indexForEditor = this.state.indexForEditor;
        let sorts = lodash.cloneDeep(this.state.sorts);
        let sort = sorts[indexForEditor];
        sorts[indexForEditor] = sorts[indexForEditor - 1];
        sorts[indexForEditor - 1] = sort;
        this.setState({ indexForEditor: indexForEditor - 1, sorts: sorts })
    }

    moveRight = () => {
        if (this.state.indexForEditor >= this.state.sorts.length - 1) { return; }
        let indexForEditor = this.state.indexForEditor;
        let sorts = lodash.cloneDeep(this.state.sorts);
        let sort = sorts[indexForEditor];
        sorts[indexForEditor] = sorts[indexForEditor + 1];
        sorts[indexForEditor + 1] = sort;
        this.setState({ indexForEditor: indexForEditor + 1, sorts: sorts })
    }

    remove = (s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        let sorts = lodash.cloneDeep(this.state.sorts);
        sorts = sorts.filter((f, index) => {
            return index !== this.state.indexForEditor;
        });
        this.setState({ sorts: sorts })
        s.setFieldValue(hw, sorts);
        this.closeEditor();
    }

    renderForm(formikProps: any, entityType: string, s: ScriptableUiFieldEditor.Main) {
        // we need to add a <div> here to find a component to focus on for ScriptableUi, in recording/playing mode
        return <HW id="form" children={hw => <div>
            <SortForm closeSortEditor={this.closeEditor} onSave={(sort: Sort) => this.onSave(sort, castWithHw(s), hw)} embeddedMode={false} remove={() => this.remove(castWithHw(s), hw)} moveLeft={this.moveLeft} moveRight={this.moveRight}
                entityName={entityType} indexForEditor={this.state.indexForEditor} formOpened={this.state.editorOpened} error={this.state.error} sorts={this.state.sorts} />
        </div>} />
    }

    renderWithScriptableUi(s: ScriptableUiFieldEditor.Main) {
        const entityName = this.props.fieldDescriptor && this.props.fieldDescriptor.entityName ? this.props.fieldDescriptor.entityName :
                this.props.formikProps && this.props.fieldDescriptor ? this.props.formikProps.values[this.props.fieldDescriptor.fieldForEntityName] : '';
        let sorts = this.state.sorts;
        if (this.state.entityName !== entityName) {
            this.setState({ entityName: entityName })
            sorts = [];
        }
        const initialValues = this.state.indexForEditor >= 0 ? this.state.sorts[this.state.indexForEditor] : { field: '', direction: 'ASC' };
        return (
            <>
                <div className='FilterBar_div'>
                    {sorts?.map((sort: Sort, index: number) =>
                        <Label key={index} size='mini' className='SortBar_sortDiv'>
                            <div>
                                <Icon className='SortBar_sortIcon' size='large' name={sort.direction === 'ASC' ? 'sort up' : 'sort down'} />
                            </div>
                            <BlocklyReadOnly openEditor={(e: any) => this.openEditor(index)} key={index}>{createSortBlock(sort, entityName)}</BlocklyReadOnly>
                        </Label>)}
                    <Button type='button' onClick={e => this.openEditor(-1)}>...</Button>
                    <Formik initialValues={initialValues} onSubmit={() => { }}>
                        {(formikProps: any) => this.renderForm(formikProps, entityName, s)}
                    </Formik>
                </div>
            </>
        );
    }
}"../../blockly/BlocklyReadOnly""../../components/CustomQuery/SortBar""../../components/CustomQuery/SortForm"