import lodash from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Optional } from "../CompMeta";
import useFitText from "use-fit-text";
import _ from "lodash";
import { Severity } from "../components/ModalExt/ModalExt";

import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import filenamify from "filenamify";
import { FoundationUtils } from "@famiprog-foundation/utils";

// @ts-ignore
momentDurationFormatSetup(moment);

export const FAIL_IMAGE_KEY: string = "failImage";

export const GLOBAL: string = "global";
export const ENTITY: string = "ent";
export const DEFAULT: string = "default";
// TODO cp:users-security: nu sar in ochi ca astea sunt permisiuni; tr sa fie undeva clar repertoriate

export const ALLOW: string = "allow";
export const DENY: string = "deny";

export const FIELDS_READ: string = "fieldsRead";
export const FIELDS_WRITE: string = "fieldsWrite";

export const AUDIT_FULL: string = "AUDIT_FULL";
export const SCHEDULED_TASK_FULL: string = "SCHEDULED_TASK_FULL";
export const SETTINGS_FULL: string = "SETTINGS_FULL";
export const DATABASE_MENU_ENTRY: string = "DATABASE_MENU_ENTRY";
export const DELETE_ALL_FILTERED_ROWS_MENU_ENTRY: string = "deleteAllFilteredRowsMenuEntry";
export const FILE_BROWSER: string = "fileBrowser";
export const FILE_BROWSER_FULL: string = "fileBrowserFull";
export const CUSTOM_QUERY_COLUMN_CONFIG_ALLOW_APPLY: string = "customQueryColumnConfigAllowApply";

export const ENT_READ: string = "ENT_READ";
export const ENT_SAVE: string = "ENT_SAVE";
export const ENT_DELETE: string = "ENT_DELETE";
export const ENT_ADD: string = "ENT_ADD";
export const ENT_TABLE: string = "ENT_TABLE";
export const ENT_IMPORT: string = "ENT_IMPORT";
export const ENT_EXPORT: string = "ENT_EXPORT";
export const ENT_AUDIT: string = "ENT_AUDIT";
export const ENT_EXTERNAL_LINK: string = "ENT_EXTERNAL_LINK";
export const LOGGED_IN: string = "LOGGED_IN";

type ObserverProps = { value: any, didUpdate: any };
export type ShowGlobalAlertParams = { message?: string, title?: string, severity?: Severity };

/**
 * Initially I was using a simpler version, inspired from the internet, that used
 * useEffect(). However, the === equality it uses posed problems when the listened value
 * is an object, e.g. a router match. Often the object instance differs, but it's keys (i.e
 * match, e.g. id = 123) are the same. This triggered additional events => calls to server on
 * render and/or infinite render loops.
 * 
 * Using a class component, allows us to to a deep equality.
 */
class Observer extends React.Component<ObserverProps> {
    render() { return null; }
    
    componentDidMount() { 
        // this works as following: the first render of the owning component (of this) happens; this component is mounted;
        // this is called. If we call the handler right now, and if in the handler we use a ref => it is not yet populated.
        // Hence we calling the callback later, and we give time to the ref to populate.
        // I don't see a reason why not to call later always. If such reason will appear, we should add a prop for controlling this.
        // NOTE: when this is used in a component that's tested w/ React Testing Library, this "async" may pose an issue.
        // @see ReduxReusableComponentsTest > renderAndWaitABit()
        setTimeout(() => this.props.didUpdate(this.props.value)); 
    }

    componentDidUpdate(prevProps: ObserverProps) {
        if (!lodash.isEqual(prevProps.value, this.props.value)) {
            this.props.didUpdate(this.props.value);
        }
    }
}

export function ScaleToContainer(props: { className?: string, children: ReactNode, divStyle?: any, fixedFontSize?: string }) {
    const [overflow, setOverflow] = useState("hidden");
    if (props.divStyle?.overflow && props.divStyle?.overflow !== overflow) {
        setOverflow(props.divStyle?.overflow ? props.divStyle?.overflow : "visible");
    }

    // the default set for resolution, 5, is not enough, in some cases the text exceeds the layout
    const { fontSize, ref } = useFitText({ maxFontSize: 10000, minFontSize: 10, resolution: 20 });
    return <div ref={ref} className={props.className} style={{ ...{...props.divStyle, ...{overflow: overflow}}, fontSize: (Utils.isNullOrEmpty(props.fixedFontSize) ? fontSize : Utils.getFontSize(props.fixedFontSize!)) }} >
        {props.children}
    </div>;
}

/**
 * @author Cristian Spiescu
 */
export class Utils {

    /**
     * The default color to use for text, in hex format.
     * Used in code, not css ot less.
     * Maybe in the future will find a way to get it from less file.
     */
    static DEFAULT_TEXT_COLOR = '#000000'; // black

    static constantNow?: Date;

    /**
     * @see TestState.replacePipeWithSeparator() - an utility function only for tests
     */
    static defaultIdSeparator = "|/|";

    /**
     * I'm sorry, but I don't see another (better) solution instead of these globals.
     * Maybe w/ context?
     * 
     * DateTimeFormat.momentJsFormat
     */
    static dateFormatShorter = "DD/MM";   
    static dateFormat = "DD/MM/YYYY";
    /**
    * timeFormatShorter from DateTimeFormat.momentJsFormatShorter
    * Wasn't renamed because it is used in multiple places.
    */
    static timeFormat = "HH:mm";
    static dateTimeFormat = Utils.dateFormat + " " + Utils.timeFormat;
    static dateTimeFormatShorter = Utils.dateFormatShorter + " " + Utils.timeFormat;
    /**
     * timeFormat from DateTimeFormat.momentJsFormat
     * Wasn't renamed because it is used in multiple places.
     */
    static timeWithSecFormat = Utils.timeFormat + ":ss";
    static dateTimeWithSecFormat = Utils.dateFormat + " " + Utils.timeWithSecFormat;

    static H_IN_MS = 60 * 60 * 1000;

    static LOCAL_TIMEZONE_OFFSET = -1 * new Date().getTimezoneOffset() / 60;

    static defaultOrganizationId = -50000;

    static Observer = Observer;

    static MatchObserver = (props: { onMatchChanged: (match: any) => void }) => {
        // Functional components as static functions are not recommended, cf. https://github.com/facebook/react/issues/20342#issuecomment-1082564319
        // Hence silencing the error. Maybe we could have used somehow "namespaces"?
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return <Observer value={useParams()} didUpdate={props.onMatchChanged} />
    }

    static LocationObserver = (props: { onLocationChanged: (match: any) => void }) => {
        // Functional components as static functions are not recommended, cf. https://github.com/facebook/react/issues/20342#issuecomment-1082564319
        // Hence silencing the error. Maybe we could have used somehow "namespaces"?
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return <Observer value={useLocation()} didUpdate={props.onLocationChanged} />
    }

    /**
     * Also exported as "jd()", for easy use while building testState.
     * 
     * Returns a string with the date in ISO8601 format, i.e. the one used in JSON. I.e. something like: str = "2014-01-01T23:28:56.782Z".
     * Note that such a string can be easily used in new Date(str).
     * 
     * When the arg "format" is given, we obviously use it. Otherwise we accept as input e.g.: "2019-06-04", "2019-06-04 10:45", or
     * 2019-06-04 10:45:00.
     * 
     * We decided to use this format to represent dates in Redux states/actions. Reason: human readable while debugging.  Using
     * "Date" in Redux state/actions, although human readable, is a no go, because it's not serializable. And we'd like to keep the state/actions
     * serializable to take advantage of things such as undo/redo, reply, etc.
     */
    static convertToJsonDate(date: string, format?: string | string[]) {
        const dateTime = moment(date, format);
        if (dateTime.get("millisecond") % 1000 === 0) {
            return dateTime.utc().format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        }
        else {
            return dateTime.toISOString();
        }
    }

    static formatDuration(duration: number) {
        if (!duration || duration < 3600000) {
            return "00:" + moment.duration(duration).format("mm");
        } else {
            return moment.duration(duration).format("HH:mm");
        }
    }

    /**
     * @param expression If it's an array, then the members can be:
     *  * string => we'll use that property;
     *  * `RegExp` => we'll apply the RegExp to search for the property. The result should contain exactly one match. If 0 or 2+ => error;
     *  * a function => we'll pass the current object obtained via navigation. It's up to the function to select a property and return its value (not property!).
     */
    static navigate(object: any, expression: Array<string | RegExp | ((object: any) => any)> | string, throwError = true, separator = Utils.defaultIdSeparator, prefix?: string) {
        if (typeof expression === "string") {
            expression = expression.split(separator);
        }
        const originalObject = object;
        for (let field of expression) {
            if (!object) {
                break;
            }
            object = prefix ? object[prefix] : object
            if (typeof field === "string") {
                object = object[field];
            } else if (field instanceof RegExp) {
                let properties = Object.keys(object).filter((key) => key.match(field as RegExp));
                if (properties.length !== 1) {
                    object = undefined;
                    break;
                }
                object = object[properties[0]];
            } else {
                object = field(object);
            }
        }
        if (object === undefined && throwError) {
            console.log(`Error during navigation for expression: ${expression}; printing object: `, originalObject)
            throw new Error(`Error during navigation for expression: ${expression} for object ${originalObject}. Please check the console for more info. If you are seing this in the console: look above. Some additional hints were printed.`);
        }
        return object;
    }

    static isTest() {
        // "process?.relea..." doesn't work; throws error in vite
        return typeof process !== 'undefined' && process.release?.name === 'node';
    }

    static now(): Date {
        if (this.constantNow) {
            return this.constantNow;
        } else if (Utils.isTest()) {
            throw new Error("In test mode, 'Utils.constantNow' should be specified.");
        } else {
            // not test, not predefined now => return the current date
            return new Date();
        }
    }

    static getTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    static hexToRGB = (hex: any) => {
        hex = '0x' + hex.replace('#', '').replace('0x', '')
        let r = (hex >> 16) & 0xFF
        let g = (hex >> 8) & 0xFF
        let b = hex & 0xFF
        return `rgb(${r}, ${g}, ${b})`
    }

    static consoleLogJson(object: any, invokeLog = true) {
        // encountered the circular case when printing dom elements
        // cf. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#examples
        const getCircularReplacer = () => {
            const seen = new WeakSet();
            return (key: string, value: any) => {
                if (key.startsWith("__react")) {
                    // this is a very long object and pollutes the output
                    return;
                }
                if (typeof value === "object" && value !== null) {
                    if (seen.has(value)) {
                        return;
                    }
                    seen.add(value);
                }
                return value;
            };
        };

        const str = JSON.stringify(object, getCircularReplacer(), 2);
        invokeLog && console.log(str);
        return str;
    }

    /**
     * Access the 'children' field or 'props', in a way meant for code reuse / override.
     * E.g. the super class has already populated 'props', and the subclass wants to add
     * stuff there.
     */
    static getOrCreateChildrenInProps(props: any): Array<any> {
        if (!props.children) {
            // super class didn't add children
            props.children = [];
        } else if (!(props.children instanceof Array)) {
            // super class has children of other types; e.g. a single value, a string
            // if we see this error message, then we should modify this function (e.g. 
            // create 'children' and add the existing element)
            throw new Error("Only pre-existing 'children' of type Array are supported; current children = " + props.children);
        }
        return props.children;
    }

    static substringAfter(str: string, sep: string, afterLast?: boolean) {
        const i = afterLast ? str.lastIndexOf(sep) : str.indexOf(sep);
        if (i < 0) {
            return str;
        } else {
            return str.substring(i + sep.length);
        }

    }

    static substringBefore(str: string, sep: string, beforeLast?: boolean) {
        const i = beforeLast ? str.lastIndexOf(sep) : str.indexOf(sep);
        if (i < 0) {
            return str;
        } else {
            return str.substring(0, i);
        }

    }

    static createFailImage(callback: (url: string, image: HTMLImageElement) => void, width: number = 24, height: number = 24) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        if (ctx == null) {
            return null;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.beginPath(); ctx.moveTo(0, 0); ctx.lineTo(width, height); ctx.lineWidth = 4; ctx.strokeStyle = '#ff0000'; ctx.stroke();
        ctx.beginPath(); ctx.moveTo(width, 0); ctx.lineTo(0, height); ctx.lineWidth = 4; ctx.strokeStyle = '#ff0000'; ctx.stroke();

        var image = new Image();
        image.width = width;
        image.height = height;
        image.src = canvas.toDataURL();
        image.onload = e => callback(image.src, e.target as HTMLImageElement);
        return image;
    }

    static loadImages(images: { [key: string]: string }, callback: (loadedImages: { [key: string]: { url: string, image: HTMLImageElement } }) => void) {
        const loadedImages = {} as { [key: string]: { url: string, image: HTMLImageElement } };
        const imagesLength = Object.keys(images).length;
        var resources = imagesLength;

        Utils.createFailImage((url: string, failImage: any) => {
            loadedImages[FAIL_IMAGE_KEY] = { url: url, image: failImage };

            if (imagesLength === 0) {
                callback(loadedImages);
                return;
            }
            const onImageLoaded = (key: string, image: any) => {
                loadedImages[key] = { url: images[key], image: image };
                resources = resources - 1;
                if (resources === 0) { // all images were loaded
                    callback(loadedImages);
                }
            };

            Object.keys(images).forEach(imageName => {
                var img = new Image();
                img.onload = e => onImageLoaded(imageName, e.target);
                // img.onerror = e => onImageLoaded(imageName, failImage);
                img.src = images[imageName];
            })
        })
    }

    static getRGBValuesColor(color: number): number[] {
        var result: Array<number> = [];
        let r: number = color & 0xFF0000; // apply mask to select red channel
        r = r >> 16; // shift
        let g: number = color & 0x00FF00; // apply mask to select green channel
        g = g >> 8;
        let b: number = color & 0x0000FF; // apply mask to select blue channel
        result[0] = r;
        result[1] = g;
        result[2] = b;
        return result;
    }

    static getRGBAColor(color: number | string, d?: number): string {
        color = typeof color === "string" ? Utils.convertColorFromHex(color) : color; 
        var rgb: Array<number> = Utils.getRGBValuesColor(color);
        if (d === undefined) {
            return "rgb(" + rgb[0] + ", " + rgb[1] + ", " + rgb[2] + ")";
        }
        return "rgba(" + rgb[0] + ", " + rgb[1] + ", " + rgb[2] + ", " + d + ")";
    }

    static convertColorToHex(color: number): string {
        // Color such as #00FF00 was not converted properly into #FF00
        // An integer => hex, so adding "0" before the number (0123 = 123) is not automatically
        // in order to have 6 characters
        let result: string = color.toString(16);
        let addingZero: number = 6 - result.length;
        if (addingZero === 0) {
            return "#" + result;
        } else {
            let zero: string = "";
            for (let i: number = 0; i < addingZero; i++) {
                zero = zero + "0";
            }
            return "#" + zero + result;
        }
    }

    static convertColorFromHex(colorAsHex: string): number {
        colorAsHex = colorAsHex.trim();
        if (!colorAsHex.startsWith("#")) {
            throw new Error("Color should start with '#'");
        }
        colorAsHex = colorAsHex.substring(1);
        return parseInt(colorAsHex, 16);
    }

    static convertToGrayscale(color: number): number {
        return (Utils.getRGBValuesColor(color)[0] * 0.299) + (Utils.getRGBValuesColor(color)[1] * 0.587) + (Utils.getRGBValuesColor(color)[2] * 0.114);
    }

    /**
     * E.g. if the color of the button comes from the BD: what should be the color of the text?
     * White or black? E.g. for dark blue => white; for light yellow => black. 
     */
    static getContrastingForegroundColor(color: number): number {
        return (Utils.convertToGrayscale(color) < 128 ? 0xFFFFFF : this.convertColorFromHex(Utils.DEFAULT_TEXT_COLOR));
    }

    static runOnlyInBrowser(f: Function) {
        if (!(process?.release?.name === 'node')) {
            f();
        }
    }
    
    /**
     * Not sure if this should stay here.
     * Used by mobile app to provide the server URL.
     */
    static forcedURL?: string;

    /**
     * @see associated test for the 3 cases it handles.
     */
    static adjustUrlToServerContext(url: string | undefined, pathname?: string, remove2ndToken: boolean = true) {
        if (url === undefined) { return url; }
        if (Utils.forcedURL) { return Utils.forcedURL + "/" + url; };
        if (!pathname) { pathname = window.location.pathname; }

        let spl = pathname.split("/");
        let deleteStart = spl.length;
        if (spl[spl.length - 1].includes(".")) {
            // found e.g. index.html
            deleteStart--;
        }        
        if (remove2ndToken) {
            deleteStart--; // e.g. html5
        }       
        
        spl.splice(deleteStart);
        const splJoined = spl.join("/");
        // attention, we need absolute path in case of domain/html5/index.html, otherwise it will 
        // be domein/html5/graphql instead of domain/graphql
        return splJoined + "/" + url;
    }

    static join(s: string[]): string {
        return s.join(".");
    }

    static pipeJoin(s: string[]): string {
        return s.join("|");
    }

    static hasPermission(permission: string, currentPermissions: Optional<any>) {
        if (!currentPermissions || !permission) {
            return true;
        }
        // TODO cp:users-security: plecand de la AppMeta.hasPermissions(), lantul e lung pana aici; si de multe ori se fac gen "if not null => pass"

        return currentPermissions[permission] !== undefined;
    }

    static setTimeoutAsync = FoundationUtils.setTimeoutAsync;

    static showGlobalAlert(params: ShowGlobalAlertParams) {
        // nop; this function will be replaced in AppMeta; because of circularity prevention
    }

    static spinnerVisible = false;
    
    static showSpinner() {
        // nop;
    }

    static hideSpinner() {
        // nop;
    }

    /**
     * https://stackoverflow.com/a/24922761
     * @param filename - will be automatically converted to a valid file name by filenamify and csv extension will be added to it.
     * @param rows - an array of arrays.
     * @param withoutTimestamp - if true current timestamp will don't be added to the filename.
     * @example 
     * Utils.exportToCsv("MyExport", [["department", "count"], ["programmers", 2], ["sales", 4]])
     * // => MyExport_2024-02-01T14_00_00Z.csv with the next content:
     * // department,count
     * // programmers,2
     * // sales,4
     */
    static exportToCsv(filename: string, rows: any[], withoutTimestamp?: boolean) {
        let filenameCsv = !this.isNullOrEmpty(filename.trim()) ? filenamify(filename.trim(), { replacement: '_' }) : "CSV_EXPORT";
        filenameCsv = !withoutTimestamp ? filenameCsv + "_" + Utils.now().toISOString() + ".csv" : filenameCsv + ".csv";
        var processRow = function (row: any) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                // TODO: second condition (row[j] === undefined) should be removed after exportCsv is finished
                var innerValue = (row[j] === null || row[j] ===  undefined) ? '' : row[j].toString();
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, filenameCsv);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filenameCsv);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    static isNullOrEmpty(value: any) {
        return value === undefined || value === null || value === "" || value instanceof Array && value.length == 0;
    }

    static getFontSize(fontSize: string) {
        switch (fontSize) {
            case "mini":
                return "8pt";
            case "tiny":
                return "16pt";
            case "small":
                return "24pt";
            case "medium":
                return "32pt";
            case "large":
                return "48pt";
            case "big":
                return "64pt";
            case "huge":
                return "72pt";
            case "massive":
                return "88pt";
            default:
                return fontSize + "pt";
        }
    }

    // Community solution of this problem: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
    static formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    static replaceCharAt(str: string, index: number, replacement: string) {
        if (index >= str.length || replacement.length != 1) {
            return str;
        }
        return str.substring(0, index) + replacement + str.substring(index + 1);
    }
}

export const jd = Utils.convertToJsonDate;
