import { addEntityDescriptor, EntityDescriptor, FieldDescriptorGen } from "./EntityDescriptor";
import { CrudGlobalSettings } from "./CrudGlobalSettings";
import { entityDescriptors, FieldGen } from "./entityCrudConstants";
import { FieldType, graphQlToFieldTypeMapping } from "./FieldType";

export class EntityDescriptorPopulatorFromEntityGen {

    static INSTANCE = new EntityDescriptorPopulatorFromEntityGen();
    remainingEntitiesGenToIgnore: string[] = [];

    populate(ed: EntityDescriptor) {
        ed.miniFields = [CrudGlobalSettings.INSTANCE.fieldId];
        const entityGen = globalThis.entitiesGen[ed.name];
        if (!entityGen) { return; }

        for (let fieldGen of entityGen.fields) {
            this.processField(ed, fieldGen);
        }
    }

    protected processField(ed: EntityDescriptor, fieldGen: FieldGen) {
        const cgs = CrudGlobalSettings.INSTANCE;
        const fieldDescriptor = new FieldDescriptorGen();
        fieldDescriptor.name = fieldGen.name;
        if (fieldGen.kind === "SCALAR") {
            fieldDescriptor.type = graphQlToFieldTypeMapping[fieldGen.type] || FieldType.defaultScalar;
        } else if (fieldGen.kind === "OBJECT") {
            fieldDescriptor.type = fieldGen.type;
        } else if (fieldGen.kind === "ENUM") {
            fieldDescriptor.type = FieldType.dropdown;
        } else {
            // for "LIST" don't do anything
            return;
        }
        if (cgs.fieldId === fieldDescriptor.name || cgs.fieldVersion === fieldDescriptor.name || cgs.fieldsToSemiHide.indexOf(fieldDescriptor.name) >= 0) {
            fieldDescriptor.isInDefaultColumnConfig = false;
            fieldDescriptor.enabled = false;
        }
        ed.addFieldDescriptor(fieldDescriptor);

        if (cgs.fieldsLikeName.indexOf(fieldDescriptor.name) >= 0) {
            ed.miniFields = [fieldDescriptor.name];
            ed.defaultSort = { field: fieldDescriptor.name, direction: "ASC" };
        }
    }

    processRemainingEntitiesGen() {
        for (let name in globalThis.entitiesGen) {
            this.processEntities(name);
        }
    }

    protected processEntities(name: string){
        if (!entityDescriptors[name] && !this.remainingEntitiesGenToIgnore.includes(name)) { 
            addEntityDescriptor(new EntityDescriptor({ name }));
        } 
    }
}