import { Header, Label, Table } from "semantic-ui-react";
import { Highlighted, MiniDbTable } from "./MiniDb";

export function MiniDbTableComponent(props: { table: MiniDbTable, highlighted?: Highlighted }) {
    const fields: { [key: string]: boolean } = {};
    for (let id in props.table) {
        const row = props.table[id];
        for (let field in row) {
            fields[field] = true;
        }
    }
    return <>
        <Table celled color={props.highlighted && "yellow"}>
            <Table.Header>
                <Table.Row>
                    {Object.getOwnPropertyNames(fields).map(p => <Table.HeaderCell key={p}>{p}</Table.HeaderCell>)}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.getOwnPropertyNames(props.table).map(id => <Table.Row key={id}>
                    {Object.getOwnPropertyNames(fields).map((p, i) => <Table.Cell key={p} className={props.highlighted?.id == id && props.highlighted?.field === p ? "MiniDbTableSetComponent_highlightedCell" : ""} >
                        {props.highlighted?.id == id // == and not === on purpose
                            ? (
                                i === 0
                                    ? <Label ribbon color="yellow">{String(props.table[id][p])}</Label>
                                    : String(props.table[id][p])
                            )
                            : String(props.table[id][p])}
                    </Table.Cell>)}
                </Table.Row>)}
            </Table.Body>
        </Table>
    </>
}