import React, { FC, ReactElement } from 'react'
import { render, RenderOptions, configure } from '@testing-library/react/pure'

// This file is not currently really needed. It's inspired from https://testing-library.com/docs/react-testing-library/setup#custom-render
// But we might need it in the future.
// NOTE: DON'T use this file from the app. Consider it internal to this dir. Imports should use "instrumentedFunctions".
const AllTheProviders: FC = ({ children }) => {
  return (<>
    {children}
  </>);
}

export type CustomRenderOptions = Omit<RenderOptions, 'wrapper'>;

function customRender(ui: ReactElement, options?: CustomRenderOptions) {
  const container = document.getElementById("rtl");
  return render(ui, { wrapper: AllTheProviders, container: container!, ...options })
}

configure({
  getElementError(message) {
    // by default the whole DOM node is printed (which is mostly <body>, and is huge)
    // hence we replace this behavior, relaying only the message
    return new Error(message || "n/a"); // I observed that message is null (at least) for the case where multiple elements are found, and only one was expected
  },
})

// TODO CS: this was not accepted by vite v4. For the moment I didn't see any impact.
// export * from '@testing-library/react/pure'
export { customRender as render }