import { AppContainerContext, AppContainerContextValue } from "@crispico/foundation-react/AppContainerContext";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import React from "react";
import { Icon, Label, List, Message, Popup } from 'semantic-ui-react';

type PasswordStrengthBarComponentProps = {
  password: string,
  onStrengthChanged?: (isStrongPassword: boolean) => void,
  conditionsForPassword?: ConditionsForPassword
}

type State = {
  strength: { idx: number, col: string }
}

export type ConditionsForPassword = {
  PASSWORD_MIN_LENGTH?: number,
  MAXIMUM_STRENGTH?: number,
  PASSWORD_HAS_LOWER_CASE_CONSTRAINT?: boolean,
  PASSWORD_HAS_UPPER_CASE_CONSTRAINT?: boolean,
  PASSWORD_HAS_NUMBER_CONSTRAINT?: boolean,
  PASSWORD_HAS_SPECIAL_CHARACTER_CONSTRAINT?: boolean
}
/**
 * Inspired from password-strength-bar.tsx of a default react app generated by jhipster
 * We replaced the initial strength algorithm with a simpler one that colors progressively each of the (5) segment of the strength bar indicator
 * if each one of the 5 pasword strength condition are met:
 * minimum number of characters
 * one upper case
 * one lower case
 * one number
 * one special character 
 * 
 * The logic for computing the force was changed because : 
 *  the initial logic applies a big penality if the password length was not satisfied,
 *  so if you inserted the correct characters variation from the begining e.g. aA1@ the password strength remains minimun till 
 *  you satisfy the password lenth condition (i.e. till it becomes aA1@aaaaaaaa when it shows maximum strength indicator) 
 *  This algorithm works smoothly when you first satisfy the length condition (with for e.g. aaaaaaaaaaaa) 
 *  and after that enters the rest of conditions (e.g. aaaaaaaaaaaaA1@) in this case showing a progresive strength indicator 
 * 
 * @author Daniela Buzatu
 */
export class PasswordStrengthBarComponent extends React.Component<PasswordStrengthBarComponentProps, State> {
  
  static contextType = AppContainerContext;
  context!: AppContainerContextValue;

  static colors = ['#F00', '#F90', '#ffc400' , '#FF0', '#9F0', '#0F0'];
  static PASSWORD_MIN_LENGTH: number = 6;
  static MAXIMUM_STRENGTH: number = 5;
  static PASSWORD_HAS_LOWER_CASE_CONSTRAINT = true;
  static PASSWORD_HAS_UPPER_CASE_CONSTRAINT = true;
  static PASSWORD_HAS_NUMBER_CONSTRAINT = true;
  static PASSWORD_HAS_SPECIAL_CHARACTER_CONSTRAINT = true;

  constructor(props: PasswordStrengthBarComponentProps) {
    super(props);
    this.state = { strength: PasswordStrengthBarComponent.getColor(PasswordStrengthBarComponent.measureStrength(props.password)) };
  }

  componentDidMount(): void {
    if (this.props.conditionsForPassword !== undefined) {
      this.getConditionsFromProps(this.props.conditionsForPassword);
    }
    this.getConditionsFromUserSettings();
  }

  protected getConditionsFromUserSettings() {
    const { userSettings } = this.context.initializationsForClient;
    if (userSettings) {
      // get password constraints
      PasswordStrengthBarComponent.PASSWORD_HAS_LOWER_CASE_CONSTRAINT = userSettings.passwordHasLowerCaseConstraint;
      PasswordStrengthBarComponent.PASSWORD_HAS_UPPER_CASE_CONSTRAINT = userSettings.passwordHasUpperCaseConstraint;
      PasswordStrengthBarComponent.PASSWORD_HAS_NUMBER_CONSTRAINT = userSettings.passwordHasNumberConstraint;
      PasswordStrengthBarComponent.PASSWORD_HAS_SPECIAL_CHARACTER_CONSTRAINT = userSettings.passwordHasSpecialCharacterConstraint;

      // compute MAXIMUM_STRENGTH
      PasswordStrengthBarComponent.MAXIMUM_STRENGTH = 1;
      if (userSettings.passwordHasLowerCaseConstraint) {
        PasswordStrengthBarComponent.MAXIMUM_STRENGTH ++;
      }
      if (userSettings.passwordHasUpperCaseConstraint) {
        PasswordStrengthBarComponent.MAXIMUM_STRENGTH ++;
      }
      if (userSettings.passwordHasNumberConstraint) {
        PasswordStrengthBarComponent.MAXIMUM_STRENGTH ++;
      }
      if (userSettings.passwordHasSpecialCharacterConstraint) {
        PasswordStrengthBarComponent.MAXIMUM_STRENGTH ++;
      }

      // get password PASSWORD_MIN_LENGTH
      PasswordStrengthBarComponent.PASSWORD_MIN_LENGTH = userSettings.passwordMinLength;
    }
  }

  protected getConditionsFromProps(conditions: ConditionsForPassword) {
    if (conditions === undefined || conditions === null) {
      return;
    }

    if (conditions.MAXIMUM_STRENGTH !== undefined) {
      PasswordStrengthBarComponent.MAXIMUM_STRENGTH = conditions.MAXIMUM_STRENGTH;
    }
    if (conditions.PASSWORD_HAS_LOWER_CASE_CONSTRAINT !== undefined) {
      PasswordStrengthBarComponent.PASSWORD_HAS_LOWER_CASE_CONSTRAINT = conditions.PASSWORD_HAS_LOWER_CASE_CONSTRAINT;
    }
    if (conditions.PASSWORD_HAS_NUMBER_CONSTRAINT !== undefined) {
      PasswordStrengthBarComponent.PASSWORD_HAS_NUMBER_CONSTRAINT = conditions.PASSWORD_HAS_NUMBER_CONSTRAINT;
    }
    if (conditions.PASSWORD_HAS_UPPER_CASE_CONSTRAINT !== undefined) {
      PasswordStrengthBarComponent.PASSWORD_HAS_UPPER_CASE_CONSTRAINT = conditions.PASSWORD_HAS_UPPER_CASE_CONSTRAINT;
    }
    if (conditions.PASSWORD_HAS_SPECIAL_CHARACTER_CONSTRAINT !== undefined) {
      PasswordStrengthBarComponent.PASSWORD_HAS_SPECIAL_CHARACTER_CONSTRAINT = conditions.PASSWORD_HAS_SPECIAL_CHARACTER_CONSTRAINT;
    }
    if (conditions.PASSWORD_MIN_LENGTH !== undefined) {
      PasswordStrengthBarComponent.PASSWORD_MIN_LENGTH = conditions.PASSWORD_MIN_LENGTH;
    }
  }

  static getDerivedStateFromProps(props: PasswordStrengthBarComponentProps, state: any): State {
    const newStrength = PasswordStrengthBarComponent.getColor(PasswordStrengthBarComponent.measureStrength(props.password));
    const isNewStrong = newStrength.idx == PasswordStrengthBarComponent.MAXIMUM_STRENGTH;
    const isOldStrong = state.strength.idx == PasswordStrengthBarComponent.MAXIMUM_STRENGTH;
    if (isNewStrong != isOldStrong && props.onStrengthChanged) {
      props.onStrengthChanged(isNewStrong);
    }
    return { strength: newStrength };
  }

  static measureStrength(p: string): number {
    let force = 0;
    const regex = /[!-\/:-@{-~^_`[\]]/g;
    // CUSTOM LOGIC DIFFERENT FROM JHIPSTER GENERATED APP: 
    type Flags = { [key: string]: boolean };
    var flags:Flags = {};
    if (PasswordStrengthBarComponent.PASSWORD_HAS_LOWER_CASE_CONSTRAINT) {
      flags.lowerLetters = /[a-z]+/.test(p);
    }

    if (PasswordStrengthBarComponent.PASSWORD_HAS_UPPER_CASE_CONSTRAINT) {
      flags.upperLetters = /[A-Z]+/.test(p);
    }
    if (PasswordStrengthBarComponent.PASSWORD_HAS_NUMBER_CONSTRAINT) {
      flags.numbers = /[0-9]+/.test(p);
    }
    if (PasswordStrengthBarComponent.PASSWORD_HAS_SPECIAL_CHARACTER_CONSTRAINT) {
      flags.symbols = regex.test(p);
    }

    var  passedMatches = Object.values(flags).filter((isMatchedFlag: boolean) => !!isMatchedFlag).length;
    // Chech if the condition for password strength is passed
    if (p.length >= PasswordStrengthBarComponent.PASSWORD_MIN_LENGTH) {
      passedMatches += 1;
    }
    
    force = passedMatches;

    // INITIAL LOGIC FROM JHIPSTER GENERATED APP:
    // const flags = {
    //   lowerLetters: /[a-z]+/.test(p),
    //   upperLetters: /[A-Z]+/.test(p),
    //   numbers: /[0-9]+/.test(p),
    //   symbols: regex.test(p),
    // };
    // var  passedMatches = Object.values(flags).filter((isMatchedFlag: boolean) => !!isMatchedFlag).length;
    // force += 2 * p.length + (p.length >= 10 ? 1 : 0);
    // force += passedMatches * 10;

    // // penalty (short password)
    // force = p.length <= 11 ? Math.min(force, 10) : force;

    // // penalty (poor variety of characters)
    // force = passedMatches === 1 ? Math.min(force, 10) : force;
    // force = passedMatches === 2 ? Math.min(force, 20) : force;
    // force = passedMatches === 3 ? Math.min(force, 40) : force;

    return force;
  };

  static getColor(s: number): any {
    // CUSTOM LOGIC DIFFERENT FROM JHIPSTER GENERATED APP: 
    let idx = s == 0 ? 0 : s - 1;
    return { idx: idx + 1, col: PasswordStrengthBarComponent.colors[idx] };
    // INITIAL LOGIC FROM JHIPSTER GENERATED APP:  
    // let idx = 0;
    // if (s <= 10) {
    //   idx = 0;
    // } else if (s <= 20) {
    //   idx = 1;
    // } else if (s <= 30) {
    //   idx = 2;
    // } else if (s <= 40) {
    //   idx = 3;
    // } else {
    //   idx = 4;
    // }
    // return { idx: idx + 1, col: colors[idx] };
  };

  protected getPoints(force: any) {
    const pts = [] as any[];
    var color = force.idx < PasswordStrengthBarComponent.MAXIMUM_STRENGTH ? force.col : PasswordStrengthBarComponent.colors[PasswordStrengthBarComponent.colors.length - 1];
    for (let i = 0; i < PasswordStrengthBarComponent.MAXIMUM_STRENGTH; i++) {
      // CUSTOM LOGIC DIFFERENT FROM JHIPSTER GENERATED APP:
      pts.push(<li key={i} className="PasswordStrengthBarComponent_point" style={i < force.idx ? { backgroundColor: color } : { backgroundColor: '#DDD' }} />);
      // INITIAL LOGIC FROM JHIPSTER GENERATED APP: 
      // pts.push(<li key={i} className="PasswordStrengthBarComponent_point" style={i < force.idx ? { backgroundColor: force.col } : { backgroundColor: '#DDD' }} />);
    }
    return pts;
  };

  render() {
    const points = this.getPoints(this.state.strength);
    return (
      <div id="strength">
        <label>
          {_msg("PasswordStrengthBarComponent.strength")}
          <Popup position='right center' flowing
            trigger={<Label basic size='mini' circular color='orange' horizontal style={{ marginLeft: 5 }}>
              <Icon name="info" style={{ marginRight: 0 }}></Icon>
            </Label>}>
            <Message warning>
              <List bulleted>
                <List.Item key="item1">
                  <List.Content>{_msg("PasswordStrengthBarComponent.hint.minNumberOfCharacters", PasswordStrengthBarComponent.PASSWORD_MIN_LENGTH)}</List.Content>
                </List.Item>
                { PasswordStrengthBarComponent.PASSWORD_HAS_UPPER_CASE_CONSTRAINT ?
                <List.Item key="item2">
                  <List.Content>{_msg("PasswordStrengthBarComponent.hint.oneUpperCase")}</List.Content>
                </List.Item> : null}
                { PasswordStrengthBarComponent.PASSWORD_HAS_LOWER_CASE_CONSTRAINT ?
                <List.Item key="item3">
                  <List.Content>{_msg("PasswordStrengthBarComponent.hint.oneLowerCase")}</List.Content>
                </List.Item> : null}
                { PasswordStrengthBarComponent.PASSWORD_HAS_NUMBER_CONSTRAINT ?
                <List.Item key="item4">
                  <List.Content>{_msg("PasswordStrengthBarComponent.hint.oneNumber")}</List.Content>
                </List.Item> : null}
                { PasswordStrengthBarComponent.PASSWORD_HAS_SPECIAL_CHARACTER_CONSTRAINT ?
                <List.Item key="item5">
                  <List.Content>{_msg("PasswordStrengthBarComponent.hint.oneSpecialCharacter")}</List.Content>
                </List.Item> : null}
              </List>
            </Message>
          </Popup>

        </label>
        <ul id="strengthBar">{points}</ul>
      </div>
    );
  }
}"../../AppContainerContext""../../AppMetaTempGlobals"