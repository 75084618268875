import React, { ReactNode } from "react";
import { Icon, Label } from "semantic-ui-react";
import { ChartCurrentSelectionMeta } from "./ChartCurrentSelectionMeta";
import { MessageExt } from "../semanticUiReactExt";

const { testids } = ChartCurrentSelectionMeta;

type ChartCurrentSelectionProps = {
    date?: string,
    contentHeader?: ReactNode,
    contentMain?: ReactNode,
    currentPointDataCyAttribute?: string,
    contentHeaderRight?: ReactNode,
}

export class ChartCurrentSelection extends React.Component<ChartCurrentSelectionProps> {

    render() {
        return <>
            <MessageExt>
                {this.props.contentHeader}
                <span className="less-margin-top-bottom small-margin-left tiny-margin-right" style={{ float: "left" }}>{_msg("ChartCurrentSelection.currentPoint")}: <Label data-testid={testids.date} basic size="large" data-cy={this.props.currentPointDataCyAttribute}><Icon name='calendar alternate outline' /> {this.props.date}</Label></span>
                <span className="float-right">{this.props.contentHeaderRight}</span>
            </MessageExt>
            {this.props.contentMain}
        </>;
    }
}