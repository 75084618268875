import React from "react";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { PrivateRoute, PrivateRouteProps } from "@crispico/foundation-react/reduxHelpers/ConnectedPageHelper";
import { FILE_BROWSER, FILE_BROWSER_FULL, Utils } from "@crispico/foundation-react/utils/Utils";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { DELETE_FILE, DOWNLOAD_FILES, GET_CONSTRAINTS, GET_FILES, GET_FILES_SIMPLE, GET_IS_DIRECTORY, SEARCH_GREP, SEARCH_JAVA } from "./queries";
import { Breadcrumb, Button, Header, Icon, Input, List, Message, Modal, ModalActions, ModalContent, Segment } from "semantic-ui-react";
import moment from "moment-timezone";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { List as ListVirtualized, ListRowProps } from 'react-virtualized';
import Measure from "react-measure";
import { v4 as uuid } from 'uuid';
import { Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import _, { uniqueId } from "lodash";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import { PeriodPicker, PeriodPickerRRC, PeriodType } from "@crispico/foundation-react/components/periodPicker/PeriodPicker";
import { SelectExt } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { AppContainerContext, AppContainerContextValue } from "@crispico/foundation-react/AppContainerContext";

const ROOT = "root";
enum SEARCH_METHOD { GREP = "grep", JAVA = "java", JAVA_REGEX = "javaRegex" };

type File = {
    alias: string,
    isDirectory: boolean,
    date: number,
    size: number
}

type Search = {
    name: string,
    keyword: string,
    startDate: string,
    endDate: string,
    periodType: PeriodType
}

export class FileBrowserPageState extends State {
    search: Search = { name: "", keyword: "", startDate: "", endDate: "", periodType: PeriodType.LOADING };
    lastSearch: Search | undefined = undefined;
    searchMethod = SEARCH_METHOD.GREP;
    alias: string = ROOT;
    files: File[] = [];
    filesSimple: string[] = [];
    getFilesUID: string | undefined = undefined;
    filesNotDownloaded: File[] = [];
    downloadMaximumSize: number = 0;
    uploadMaximumSize: number = 0;
    fileTypes: string = "";
    uploadModal: boolean = false;
    sizeReject: boolean = true;
    fileToUpload: string = "";
    hasFullAccess: boolean = false;
    filterModalOpen: boolean = false;
    datePickerWasUpdated: boolean = false;
    initialStartDate: string | undefined = undefined;
    initialEndDate: string | undefined = undefined;
}

export class FileBrowserPageReducers<S extends FileBrowserPageState = FileBrowserPageState> extends Reducers<S> {
    updateSearchName(name: string) {
        this.s.search.name = name;
    }
    updateSearchKeyword(keyword: string) {
        this.s.search.keyword = keyword;
    }
}

type LocalState = {
    measuredWidth: number, measuredHeight: number
}

export class FileBrowserPage extends React.Component<RRCProps<FileBrowserPageState, FileBrowserPageReducers>, LocalState> {

    static contextType = AppContainerContext;
    context!: AppContainerContextValue;

    private listRef = React.createRef<ListVirtualized>();
    protected periodPickerRef = React.createRef<PeriodPicker>();

    constructor(props: RRCProps<FileBrowserPageState, FileBrowserPageReducers>) {
        super(props);
        this.state = { measuredWidth: 0, measuredHeight: 0 };
    }

    componentDidMount() {
        this.loadForAlias(ROOT);
    }

    private async loadForAlias(alias: string) {
        let result;
        let constraints = (await apolloClient.query({ query: GET_CONSTRAINTS })).data["fileBrowserService_constraints"];
        let startDate = this.periodPickerRef.current?.getStartDate();
        let endDate = this.periodPickerRef.current?.getEndDate();
        if (alias === ROOT) {
            result = (await apolloClient.query({ query: GET_FILES, variables: { alias: null, startDate, endDate } })).data;
        } else {
            result = (await apolloClient.query({ query: GET_FILES_SIMPLE, variables: { alias, startDate, endDate } })).data;
            const getFilesUID = uuid();
            this.props.r.setInReduxState({
                alias,
                files: [],
                filesSimple: (result["fileBrowserService_filesSimple"]),
                getFilesUID,
                lastSearch: undefined,
                downloadMaximumSize: constraints[0],
                uploadMaximumSize: constraints[1],
                fileTypes: constraints[2],
                hasFullAccess: AppMetaTempGlobals.appMetaInstance.hasPermission(FILE_BROWSER_FULL)
            });
            result = (await apolloClient.query({ query: GET_FILES, variables: { alias: alias, startDate, endDate }, context: { showSpinner: false } })).data;
            if (getFilesUID !== this.props.s.getFilesUID) {
                return;
            }
        }
        this.props.r.setInReduxState({
            alias,
            files: (result["fileBrowserService_files"]),
            filesSimple: [],
            getFilesUID: undefined,
            lastSearch: undefined,
            downloadMaximumSize: constraints[0],
            uploadMaximumSize: constraints[1],
            fileTypes: constraints[2],
            datePickerWasUpdated: false,
            searchMethod: SEARCH_METHOD.GREP,
            hasFullAccess: AppMetaTempGlobals.appMetaInstance.hasPermission(FILE_BROWSER_FULL)
        });
        this.props.r.updateSearchKeyword("");
        this.props.r.updateSearchName("");
    }

    private async goUpOneLevel() {
        const alias = this.props.s.alias;
        const lastForwardSlash = alias.lastIndexOf("/");
        if (lastForwardSlash < 0) {
            this.loadForAlias(ROOT);
        } else {
            this.loadForAlias(alias.slice(0, lastForwardSlash));
        }
    }

    private async search(alias: string, name: string, keyword: string) {
        const files = this.props.s.searchMethod === SEARCH_METHOD.GREP
            ? (await apolloClient.query({ query: SEARCH_GREP, variables: { alias, name, keyword, startDate: this.periodPickerRef.current!.getStartDate(), endDate: this.periodPickerRef.current!.getEndDate() } })).data["fileBrowserService_searchWordIntoFolderCommandLine"]
            : (await apolloClient.query({ query: SEARCH_JAVA, variables: { alias, name, keyword, isRegex: this.props.s.searchMethod === SEARCH_METHOD.JAVA_REGEX, startDate: this.periodPickerRef.current!.getStartDate(), endDate: this.periodPickerRef.current!.getEndDate() } })).data["fileBrowserService_searchKeywordIntoFolder"]
            ;
        this.props.r.setInReduxState({ alias, files, filesSimple: [], getFilesUID: undefined, lastSearch: { name, keyword, startDate: this.periodPickerRef.current?.getStartDate() || "", endDate: this.periodPickerRef.current?.getEndDate() || "", periodType: this.periodPickerRef.current?.props.s.periodType || PeriodType.LOADING } });
    }

    private downloadFile(alias: string) {
        // window.open("http://localhost:8080/file-browser-servlet/" + alias, "_blank");
        window.open(Utils.adjustUrlToServerContext("file-browser-servlet/") + alias, "_blank");
    }

    private async deleteFile(alias: string) {
        await apolloClient.query({ query: DELETE_FILE, variables: { alias: alias } });
        this.loadForAlias(this.props.s.alias);
    }

    private async downloadFiles() {
        const filesToDownload: string[] = [];
        const filesNotDownloaded: File[] = [];
        this.props.s.files.forEach(file => {
            if (file.size < this.props.s.downloadMaximumSize * 1024 * 1024) {
                filesToDownload.push(file.alias);
            } else {
                filesNotDownloaded.push(file);
            }
        });
        const token = (await apolloClient.query({ query: DOWNLOAD_FILES, variables: { filesToDownload } })).data["fileBrowserService_registerFilesToDownload"];
        this.props.r.setInReduxState({ filesNotDownloaded });
        // window.open("http://localhost:8080/file-browser-servlet?token=" + token, "_blank");
        window.open(Utils.adjustUrlToServerContext("file-browser-servlet?token=") + token, "_blank");
    }

    private async onSearchKeyDown(e: any) {
        if (e.key === 'Enter' && this.props.s.alias !== ROOT && (this.props.s.search.name.length || this.props.s.search.keyword.length)) {
            this.search(this.props.s.alias, this.props.s.search.name, this.props.s.search.keyword);
        }
    }

    private renderAlias() {
        const path = this.props.s.alias.split("/");
        return <Breadcrumb size="large" style={{ display: "flex", flexWrap: "nowrap" }} >{path.map((p, index) => {
            const active = index === path.length - 1;
            return <>
                <Breadcrumb.Divider>{index > 0 ? <>/</> : <Icon name="folder open" />}</Breadcrumb.Divider>
                <Breadcrumb.Section onClick={!active ? () => this.loadForAlias(path.slice(0, index + 1).join("/")) : undefined} link={!active} active={active}>{p}</Breadcrumb.Section>
            </>;
        })}</Breadcrumb>;
    }

    private renderMessage() {
        const content = [];
        const lastSearch = this.props.s.lastSearch;
        if (lastSearch) {
            const found = this.props.s.files.length;
            if (lastSearch.name.length && lastSearch.keyword.length && lastSearch.periodType !== PeriodType.ALL) {
                content.push(<>{_msg("fileBrowser.result.nameAndKeywordAndInterval", this.props.s.files.length, lastSearch.name, lastSearch.keyword, moment(lastSearch.startDate).format(Utils.dateTimeFormat), moment(lastSearch.endDate).format(Utils.dateTimeFormat))}</>);
            } else if (lastSearch.name.length && lastSearch.keyword.length) {
                content.push(<>{_msg("fileBrowser.result.nameAndKeyword", this.props.s.files.length, lastSearch.name, lastSearch.keyword)}</>);
            } else if (lastSearch.name.length && lastSearch.periodType !== PeriodType.ALL) {
                content.push(<>{_msg("fileBrowser.result.nameAndInterval", this.props.s.files.length, lastSearch.name, moment(lastSearch.startDate).format(Utils.dateTimeFormat), moment(lastSearch.endDate).format(Utils.dateTimeFormat))}</>);
            } else if (lastSearch.name.length) {
                content.push(<>{_msg("fileBrowser.result.name", this.props.s.files.length, lastSearch.name)}</>);
            } else if (lastSearch.keyword.length && lastSearch.periodType !== PeriodType.ALL) {
                content.push(<>{_msg("fileBrowser.result.keywordAndInterval", this.props.s.files.length, lastSearch.keyword, moment(lastSearch.startDate).format(Utils.dateTimeFormat), moment(lastSearch.endDate).format(Utils.dateTimeFormat))}</>);
            } else if (lastSearch.keyword.length) {
                content.push(<>{_msg("fileBrowser.result.keyword", this.props.s.files.length, lastSearch.keyword)}</>);
            } else if (lastSearch.periodType !== PeriodType.ALL) {
                content.push(<>{_msg("fileBrowser.result.interval", this.props.s.files.length, moment(lastSearch.startDate).format(Utils.dateTimeFormat), moment(lastSearch.endDate).format(Utils.dateTimeFormat))}</>)
            } else {
                content.push(<>{_msg("fileBrowser.result.all", this.props.s.files.length)}</>)
            }
            if (found) {
                content.push(<><span className="small-margin-right" /><Button primary compact onClick={() => this.downloadFiles()}><Icon name="download" />{_msg("fileBrowser.download")}</Button></>);
            }
        } else if (this.props.s.files.length + this.props.s.filesSimple.length === 0) {
            content.push(<>{_msg("fileBrowser.emptyFolder")}</>);
        }
        return content.length > 0 ? <Message>{content}</Message> : null;
    }

    private uploadCheck = (info: UploadChangeParam) => {
        this.props.r.setInReduxState({ fileToUpload: info.file.name })

        if (info.file.size! > this.props.s.uploadMaximumSize * 1024 * 1024) {
            this.props.r.setInReduxState({ uploadModal: true, sizeReject: true });
            return;
        }

        const typeParts = info.file.type!.split("/")

        if (typeParts[0] !== "image" || !this.props.s.fileTypes.includes(typeParts[1])) {
            this.props.r.setInReduxState({ uploadModal: true, sizeReject: false });
            return;
        }

        this.loadForAlias(this.props.s.alias);
    }

    private disableSearchButtons() {
        return this.props.s.alias === ROOT || (!this.props.s.search.name && !this.props.s.search.keyword && !this.periodPickerRef.current!.getStartDate() && !this.periodPickerRef.current!.getEndDate());
    }

    private renderFilterModal() {
        return <ModalExt size="tiny" closeIcon={true}
            header={<div> {_msg("fileBrowser.filterModal.header")}</div>}
            content={
                <ModalContent className="flex-container FileBrowser_FilterModal_Content">
                    <div className="flex-container">
                        <div className="FileBrowser_FilterModal_Label">{_msg("fileBrowser.searchFor")}</div>
                        <Input value={this.props.s.search.name}
                            onChange={(e) => this.props.r.updateSearchName(e.target.value)}
                            onKeyDown={(e: any) => this.onSearchKeyDown(e)} placeholder={_msg("fileBrowser.searchFor.placeholder") + "..."} />
                    </div>
                    <div className="flex-container">
                        <div className="FileBrowser_FilterModal_Label">{_msg("fileBrowser.findText")}</div>
                        <Input value={this.props.s.search.keyword}
                            onChange={(e) => this.props.r.updateSearchKeyword(e.target.value)}
                            onKeyDown={(e: any) => this.onSearchKeyDown(e)} placeholder={_msg("fileBrowser.findText.placeholder") + "..."} />
                    </div>
                    <div className="flex-container">
                        <div className="FileBrowser_FilterModal_Label">{_msg("fileBrowser.searchMethod")}</div>
                        <SelectExt isMulti={false} isDisabled={this.disableSearchButtons()} closeMenuOnSelect value={{ value: this.props.s.searchMethod, label: _msg("fileBrowser." + this.props.s.searchMethod) }}
                            onChange={(data: any, e: any) => this.props.r.setInReduxState({ searchMethod: data.value as SEARCH_METHOD })}
                            options={Object.values(SEARCH_METHOD).map(method => { return { label: _msg("fileBrowser." + method), value: method } })} />
                    </div>
                </ModalContent>
            }
            open={this.props.s.filterModalOpen} onClose={() => this.props.r.setInReduxState({ filterModalOpen: false })}
            actions={<ModalActions>
                <Button primary onClick={() => {
                    this.search(this.props.s.alias, this.props.s.search.name, this.props.s.search.keyword);
                    this.props.r.setInReduxState({ filterModalOpen: false });
                }} content={_msg("general.search")} />
                <Button onClick={() => this.props.r.setInReduxState({ filterModalOpen: false })} content={_msg("general.cancel")} />
            </ModalActions>}
        />
    }

    private datesAreChanged(startDate: string, endDate: string) {
        return ((this.props.s.initialStartDate && this.props.s.initialEndDate &&
            this.props.s.initialStartDate !== startDate && this.props.s.initialEndDate !== endDate) ||
            (startDate !== moment().startOf("day").toISOString() || endDate !== moment().endOf("day").toISOString()));
    }

    render() {
        return <div className="flex-container flex-grow" style={{ margin: "1em" }}>
            <Header as="h3" style={{ marginBottom: 0 }}><Icon name="folder open" /> {_msg("FileBrowserPage.title")}</Header>
            <Segment className="FileBrowserPage_Bar less-margin-top-bottom">
                <div>
                    <Button icon="home" onClick={() => this.loadForAlias(ROOT)} />
                    <Button icon="arrow alternate circle up" onClick={() => this.goUpOneLevel()} />
                    <div className="FileBrowser_Breadcrumb mini-margin-right">{this.renderAlias()}</div>
                    {(this.props.s.hasFullAccess && this.props.s.alias !== "root")
                        ? <Upload showUploadList={false} onChange={this.uploadCheck} action={Utils.adjustUrlToServerContext("file-browser-servlet/") + this.props.s.alias} >
                            <Button icon='upload' style={{ marginRight: 0 }} primary />
                        </Upload>
                        : null}
                </div>
            </Segment>
            <Segment className="less-margin-top-bottom">
                <div className="flex-container-row flex-center less-margin-top-bottom">
                    <MessageExt className="margin-auto flex-grow mini-margin-right">
                        <PeriodPickerRRC id={uniqueId('periodPickerRRC-FileBrowser-')} ref={this.periodPickerRef}
                            optionAllEnabled onChange={(startDate, endDate) => {
                                if (this.datesAreChanged(startDate, endDate)) {
                                    this.props.r.setInReduxState({
                                        datePickerWasUpdated: true,
                                        initialStartDate: startDate,
                                        initialEndDate: startDate,
                                    });
                                }
                            }} />
                    </MessageExt>
                    <Button disabled={this.disableSearchButtons()} primary icon="filter" content={_msg("general.filter")}
                        onClick={() => this.props.r.setInReduxState({ filterModalOpen: true })} />
                    <Button icon={this.props.s.lastSearch ? "close" : "refresh"} color={this.props.s.lastSearch ? "red" : (this.props.s.datePickerWasUpdated ? "purple" :"green")}
                        onClick={(e: any) => {
                            this.loadForAlias(this.props.s.alias);
                        }} />
                </div>
            </Segment>
            <Segment className="flex-container flex-grow less-margin-top-bottom">
                {this.renderMessage()}
                <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width || 0, measuredHeight: contentRect.bounds?.height || 0 })}>
                    {({ measureRef }) => (<div ref={measureRef} className="flex-grow">
                        <ListVirtualized ref={this.listRef}
                            width={this.state.measuredWidth}
                            height={this.state.measuredHeight}
                            rowCount={this.props.s.filesSimple.length > this.props.s.files.length ? this.props.s.filesSimple.length + 1 : this.props.s.files.length + 1}
                            rowHeight={25}
                            rowRenderer={(props: ListRowProps) => {
                                if (props.index === 0) {
                                    return <div style={{ position: 'relative' }}><span>{_msg("fileBrowser.name")}
                                        <div className="FileBrowser_SizeAndDate">{_msg("fileBrowser.size")}
                                            <div className="FileBrowser_Column">{_msg("general.date")}</div>
                                            <div className="FileBrowser_Column"></div></div></span></div>;
                                }
                                if (this.props.s.filesSimple.length > 0 && this.props.s.files.length === 0) {
                                    const file = this.props.s.filesSimple[props.index - 1];
                                    return <div key={props.key} style={props.style}>
                                        {file ? <>
                                            <Icon name="spinner" loading />
                                            <a onClick={async () => {
                                                const result = (await apolloClient.query({ query: GET_IS_DIRECTORY, variables: { alias: file } })).data;
                                                if (result["fileBrowserService_isDirectory"]) {
                                                    this.loadForAlias(file);
                                                } else {
                                                    this.downloadFile(file);
                                                }
                                            }}>{file}</a>
                                        </> : null}
                                    </div>;
                                } else {
                                    const file = this.props.s.files[props.index - 1];
                                    return <div key={props.key} style={props.style}>
                                        {file ? <>
                                            <Icon name={file.isDirectory ? "folder" : "file"} />
                                            <a onClick={() => file.isDirectory ? this.loadForAlias(file.alias) : this.downloadFile(file.alias)}>{file.alias}</a>
                                            {file.date > 0 && !file.isDirectory
                                                ? <div className="FileBrowser_SizeAndDate">{Utils.formatBytes(file.size)}
                                                    <div className="FileBrowser_Column" >{moment(file.date).format(Utils.dateTimeWithSecFormat)}</div>
                                                    <div className="FileBrowser_Column" >
                                                        {this.props.s.hasFullAccess
                                                            ? <Button style={{ 'font-size': 8 }} icon="delete" negative onClick={() => this.deleteFile(file.alias)} />
                                                            : null}
                                                        <Button style={{ 'font-size': 8 }} icon="file outline" primary onClick={() => this.downloadFile(file.alias)} />
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </> : null}
                                    </div>;
                                }
                            }}
                        />
                    </div>)}
                </Measure>
            </Segment>
            <ModalExt open={this.props.s.filesNotDownloaded.length > 0} closeIcon onClose={() => this.props.r.setInReduxState({ filesNotDownloaded: [] })} >
                <Modal.Header>{_msg("fileBrowser.donwload.notDownloaded.title")}</Modal.Header>
                <Modal.Content>
                    <p>{_msg("fileBrowser.donwload.notDownloaded.message.1", this.props.s.filesNotDownloaded.length, this.context.initializationsForClient.fileBrowserBulkDownloadMaximumFileSize)}</p>
                    <List bulleted>{this.props.s.filesNotDownloaded.map(file => <List.Item><a onClick={() => this.downloadFile(file.alias)}>{file.alias}</a> - {Utils.formatBytes(file.size)}</List.Item>)}</List>
                    <p>{_msg("fileBrowser.donwload.notDownloaded.message.2")}</p>
                </Modal.Content>
                <Modal.Actions><Button primary onClick={() => this.props.r.setInReduxState({ filesNotDownloaded: [] })}>{_msg("general.ok")}</Button></Modal.Actions>
            </ModalExt>
            <ModalExt open={this.props.s.uploadModal} closeIcon onClose={() => this.props.r.setInReduxState({ uploadModal: false })} >
                <Modal.Header>{_msg("fileBrowser.upload.failed.title")}</Modal.Header>
                <Modal.Content>
                    <p>{this.props.s.sizeReject === true
                        ? _msg("fileBrowser.upload.failed.message.1.size", this.props.s.fileToUpload, this.props.s.uploadMaximumSize)
                        : _msg("fileBrowser.upload.failed.message.1.type", this.props.s.fileToUpload, this.props.s.fileTypes)}
                    </p>
                    <p>{_msg("fileBrowser.upload.failed.message.2")}</p>
                </Modal.Content>
                <Modal.Actions><Button primary onClick={() => this.props.r.setInReduxState({ uploadModal: false })}>{_msg("general.ok")}</Button></Modal.Actions>
            </ModalExt>
            {this.renderFilterModal()}
        </div>;
    }
}

export const FileBrowserPageHOC = ReduxReusableComponents.connectRRC(FileBrowserPageState, FileBrowserPageReducers, FileBrowserPage);

export const fileBrowserPageUrl = "/FileBrowser";
export const fileBrowserPageRoute = () =>
    <PrivateRoute key="fileBrowserPage"
        path={fileBrowserPageUrl}
        render={(props) => <FileBrowserPageHOC {...props} id="fileBrowserPage" />}
        permission={FILE_BROWSER}
    />

export const fileBrowserPageMenuEntry = () => {
    return {
        id: "fileBrowserPage",
        content: _msg("FileBrowserPage.title"),
        to: fileBrowserPageUrl, exact: true, icon: "folder open",
        permission: FILE_BROWSER
    }
};"../../reduxReusableComponents/ReduxReusableComponents""../../reduxHelpers/ConnectedPageHelper""../../utils/Utils""../../apolloClient""../../components/ModalExt/ModalExt""../../AppMetaTempGlobals""../../components/semanticUiReactExt""../../components/periodPicker/PeriodPicker""../../components/selectExt/SelectExt""../../AppContainerContext"