import React from "react";
import { Button, Icon, Message, Modal, Menu } from "semantic-ui-react";
import { Upload } from 'antd';
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import lodash from "lodash";
import gql from "graphql-tag";
import { UploadProps, UploadChangeParam } from "antd/lib/upload";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { ModalExt, Severity } from "../ModalExt/ModalExt";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export enum FILE_IMPORTER_MODE {
    NORMAL, MENU_ITEM
}

export class FileImportButtonState extends State {
    message = "";
    status = undefined as string | undefined;
}

export class FileImportButtonReducers<S extends FileImportButtonState = FileImportButtonState> extends Reducers<S> {
}

// TODO by CS: see remark in storybook
export class FileImportButton extends React.Component<RRCProps<FileImportButtonState, FileImportButtonReducers> & { entityName: string, refresh: () => void, mode?: FILE_IMPORTER_MODE }> {

    protected async preUploadFileForImport(name: string, originFileObj: any, refresh: () => void) {
        const preUploadFileForImportOperationName = `${lodash.lowerFirst(name)}Service_preUploadFileForImport`;
        const preUploadFileForImportQuery = gql(`query q { ${preUploadFileForImportOperationName} }`);

        const token = (await apolloClient.query({ query: preUploadFileForImportQuery })).data[preUploadFileForImportOperationName];

        const fd = new FormData();
        fd.append('entitiesFile', originFileObj);
        const url = Utils.adjustUrlToServerContext('upload/upload-file?token=' + token);

        await fetch(url!, { method: 'POST', body: fd })
            .then(response => {
                if (response.statusText === 'OK') {
                    return response.text();
                } else {
                    return response.statusText;
                }
            })
            .then(text => this.props.r.setInReduxState({ message: text, status: "done" }));

        refresh();
    }

    state = {
        fileList: [] as any[]
    };

    handleChange = (info: UploadChangeParam) => {
        if (info.file.status !== undefined) {
            return;
        }
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files (1)
        fileList = fileList.slice(-1);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url:link
                file.url = file.response.url;
            }
            return file;
        });

        this.setState({ fileList });
        this.props.r.setInReduxState({ status: "inProgress" });
        this.preUploadFileForImport(this.props.entityName, fileList[0]?.originFileObj, this.props.refresh);
    };

    renderModal() {
        return <ModalExt severity={Severity.INFO} open={this.props.s.message.length > 0} closeIcon onClose={() => this.props.r.setInReduxState({ message: "" })}>
            <Modal.Header>{_msg("FileImporter.results")}</Modal.Header>
            <Modal.Content>
                <Message>{this.props.s.message}</Message>
            </Modal.Content>
        </ModalExt>
    }

    render() {
        const props: UploadProps = {
            onChange: this.handleChange,
            multiple: false,
            accept: ".xls, .xlsx",
            showUploadList: false
        };

        if (this.props.mode === FILE_IMPORTER_MODE.MENU_ITEM) {
            return <><Menu.Item onClick={() => null}>
                <Upload {...props} fileList={this.state.fileList} beforeUpload={() => false}>
                    <div className="FileImporter_menu_div">
                        <span>{this.props.s.status === "inProgress" ? _msg("FileImporter.spinner") : _msg("dto_crud.import")}</span>
                        <span className="FileImporter_menu_div_icon"><Icon loading={this.props.s.status === "inProgress"} name={this.props.s.status === "inProgress" ? 'spinner' : 'upload'} /></span>
                    </div>
                </Upload>
            </Menu.Item>
                {this.renderModal()}
            </>
        } else {
            return <div className="FileImporter_div">
                <Upload {...props} fileList={this.state.fileList} beforeUpload={() => false} className={"FileImporter_upload"}>
                    <Button color="blue"><Icon loading={this.props.s.status === "inProgress"} name={this.props.s.status === "inProgress" ? 'spinner' : 'upload'} />
                        {this.props.s.status === "inProgress" ? _msg("FileImporter.spinner") : _msg("dto_crud.import")}
                    </Button>
                </Upload>
                {this.renderModal()}
            </div>
        }
    }
}

export const FileImportButtonRRC = ReduxReusableComponents.connectRRC(FileImportButtonState, FileImportButtonReducers, FileImportButton);
"../../apolloClient""../../utils/Utils""../../reduxReusableComponents/ReduxReusableComponents"