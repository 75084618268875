import { FilterOperators } from "@crispico/foundation-gwt-js";

export const CUSTOM_FIELDS = "customFields";

/**
 * @see TypeMappings.java
 */
export class FieldType {
    // JS primitive types
    static string = "string";
    static number = "number";
    static boolean = "boolean";
    static date = "date";
    static rangeDate = 'rangeDate';
    static byte = "byte";

    // derived types; maybe they are a primitive type, but we want a custom renderer or editor
    static color = "color";
    static password = "password";
    static double = "double";
    static text = "text";
    static timeZone = "timeZone";
    static progress = "progress";
    static cron = "cron";

    static defaultScalar = FieldType.string;
    static defaultManyToOne = "default-many-to-one";
    static defaultOneToMany = "default-one-to-many";

    static oneToMany(type: string): string { return "[" + type + "]" };

    static filter = 'filter';
    static sort = 'sort';
    static entityName = 'entity-name';
    static entityFields = 'entityFields';
    static dropdown = "dropdown";
    static pieCountColorPalette = "pieCountColorPalette";
    static enum = "enum";
    static daysOfTheWeek = "daysOfTheWeek";
}

export const graphQlToFieldTypeMapping: { [graphQlType: string]: string } = {
    Boolean: FieldType.boolean,
    String: FieldType.string,
    Date: FieldType.date,
    Int: FieldType.number,
    Byte: FieldType.number,
    Double: FieldType.double,
    Float: FieldType.double,
    Long: FieldType.double
}

export const fieldTypeToPrimitiveFieldTypeMapping = {
    // string
    [FieldType.string]: FieldType.string,
    [FieldType.defaultScalar]: FieldType.string,
    [FieldType.filter]: FieldType.string,
    [FieldType.sort]: FieldType.string,
    [FieldType.text]: FieldType.string,

    // number
    [FieldType.number]: FieldType.number,
    [FieldType.color]: FieldType.number,
    [FieldType.double]: FieldType.number,
    [FieldType.byte]: FieldType.number,

    [FieldType.date]: FieldType.date,
    [FieldType.boolean]: FieldType.boolean,
    [FieldType.defaultManyToOne]: FilterOperators.TYPE_MANY_TO_ONE,
    [FieldType.defaultOneToMany]: FilterOperators.TYPE_ONE_TO_MANY,

    [FieldType.entityName]: FieldType.string,
    [FieldType.dropdown]: FieldType.enum,
    [FieldType.daysOfTheWeek]: FieldType.string
}