
export enum DashboardMode {
    VIEW, EDIT
}

export const COPY = 'copy'
export const MOVE = 'move'

export const DEMO_WIDGET = "demoWidget";
export const DEMO_WIDGET_2 = "demoWidget2";
export const LIST_RECORDS_WIDGET = "listOfRecordsWidget";
export const PIE_COUNT_BY_CRITERIA = "pieCountByCriteria"
export const CALCULATE_RECORDS_WIDGET = "calculateForRecordsWidget";

export const FIELD_WIDGET = "fieldWidget";
export const FREE_TEXT_WIDGET = "freeTextWidget";
export const MULTIPLE_FIELDS_WIDGET = "multipleFieldsWidget";