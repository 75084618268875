import { FilterOperators, Messages } from "@crispico/foundation-gwt-js";
import { ConnectedPageInfo, createSliceFoundation, PrivateRoute } from "@crispico/foundation-react/reduxHelpers";
import _ from "lodash";
import React, { ReactElement, ReactNode } from "react";
import { Icon, Label } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";
import { OmitFieldsOfTypeFunction, Optional } from "../CompMeta";
import { ClientColumnConfig } from "../components/ColumnConfig/ClientColumnConfig";
import { COLUMN_DEFAULT_WIDTH } from "../components/ColumnConfig/dataStructures";
import { ClientCustomQuery } from "../components/CustomQuery/ClientCustomQuery";
import { Filter } from "../components/CustomQuery/Filter";
import { NavLinkWithPermission } from "../components/NavLinkWithPermission";
import { EntityDescriptorForServerUtils } from "../flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { TestUtils } from "../utils/TestUtils";
import { ALLOW, DEFAULT, ENTITY, ENT_TABLE, FIELDS_READ, FIELDS_WRITE, Utils } from "../utils/Utils";
import { FieldDescriptorSettings, FieldInterval, FieldIntervalColoring, ForEntity, MeasurementUnit, PhysicalQuantity } from "./CrudSettings";
import { ARCHIVED, entityDescriptors, ID } from "./entityCrudConstants";
import { EntityDescriptorPopulatorFromEntityGen } from "./EntityDescriptorPopulatorFromEntityGen";
import { EntityEditorPage, SliceEntityEditorPage } from "./EntityEditorPage";
import { EntityTablePage, EntityTablePageRRC } from "./EntityTablePage";
import { ColumnConfigConfig, ColumnDefinition } from "./EntityTableSimple";
import { FieldEditorProps, fieldEditors, FieldRendererProps, fieldRenderers } from "./fieldRenderersEditors";
import { CUSTOM_FIELDS, FieldType } from "./FieldType";
import { CrudGlobalSettings } from "./CrudGlobalSettings";
import { IFormValuesHolder } from "./fieldEditors/FieldEditor";
import { Sort } from "../components/CustomQuery/SortBar";
import { AppContainer } from "@crispico/foundation-react/AppMeta";

type EntityDescriptorForConstructor = Omit<OmitFieldsOfTypeFunction<Partial<EntityDescriptor>>, "fields">;
export const FIELDS_LAST_UPDATE = "fieldsLastUpdate";
export type FieldsSettings = {
    name?: string | undefined
    fields?: string | undefined
    field?: string | undefined
}

export class EntityDescriptor {
    name!: string;
    miniFields!: string[];
    icon: SemanticICONS = "file outline";

    javaIdType?: string;
    graphQlIdType?: string;
    /**
     * Don't add elements here directly. Use "addFieldDescriptor()"
     */
    fields: { [fieldName: string]: FieldDescriptor } = {};

    protected _orderedFields: string[] = [];
    protected orderedFieldsNeedSort: boolean = true;

    /**
     * This is lazy initialized (sorted) on first access. Used primarily when generating the default column
     * config. For the moment, besides this place, there is no reason to use this for iteration (instead of "fields"
     * directly).
     * 
     * ## A few words about the order of the fields
     * 
     * First of all, appear physical fields. And then custom fields. The order of physical fields should be 
     * specified in the Java entity. I.e. the order in which the fields are defined. If a new order is 
     * needed => reorder there (using also @AuditableField if needed). It cannot be altered through CrudSettings. 
     * More precisely the order from the Java entities is used by GraphQL, which is used by "entitiesGen.js", 
     * which is used when EntityDescriptors are created in React.
     * 
     * The custom fields are defined in CrudSettings. The order in CrudSettings.forEntities defines the order 
     * of the custom fields. 
     * 
     * Note: we are talking about the "default" order, for the default (programmatic) column config. An user can 
     * always create a new column config in which he can mix custom fields w/ physical fields. And he may choose 
     * this as default for an org.
     */
    get orderedFields(): string[] {
        if (this.orderedFieldsNeedSort) {
            let fieldToIndexMap: { [key: string]: number } = {};
            this.entityDescriptorSettings?.fieldDescriptorSettings.map((fds, index) => {
                if (this.fields[fds.fieldRef]) {
                    fieldToIndexMap[fds.fieldRef] = index;
                }
            });

            const fields = this.getAuthorizedFields(FIELDS_READ);
            this._orderedFields = Object.keys(fields).sort((a, b) => {
                if (!fields[a].isCustomField && !fields[b].isCustomField) { // a & b aren't custom fields -> don't interfere
                    return 0;
                }
                if (!fields[a].isCustomField && fields[b].isCustomField) {
                    return -1;
                }
                if (fields[a].isCustomField && !fields[b].isCustomField) {
                    return 1;
                }
                if (fieldToIndexMap[a] === undefined && fieldToIndexMap[b] === undefined) {
                    // if no criteria, order them alphabetically
                    return this.getField(a).getLabel().toLowerCase().localeCompare(this.getField(b).getLabel().toLowerCase());
                }
                if (fieldToIndexMap[a] === undefined && fieldToIndexMap[b] !== undefined) {
                    return 1;
                }
                if (fieldToIndexMap[a] !== undefined && fieldToIndexMap[b] === undefined) {
                    return -1;
                }
                return fieldToIndexMap[a] < fieldToIndexMap[b] ? -1 : fieldToIndexMap[a] > fieldToIndexMap[b] ? 1 : 0;
            });
            this.orderedFieldsNeedSort = false;
        }
        return this._orderedFields;
    }

    showInUI: boolean = true;
    hasDuplicateButton: boolean = true;
    hasAttachedDashboards: boolean = true;

    pluralized: boolean = true;

    protected _infoEditor!: ConnectedPageInfo;

    defaultSort?: Sort | Sort[] = { field: "id", direction: "ASC" };
    defaultFilter?: Filter;

    entityDescriptorSettings?: ForEntity;

    scrollOnlyContentInEditor?: boolean;

    protected _entityTablePageRef = React.createRef<EntityTablePage>();

    // TODO by CS: the code supposed that entity.id = the field. We introduced this. But the code hasn't been adjusted.
    // At least new code should take this into account. And we'll refactor old code as well
    idFields = ["id"];

    get infoEditor() {
        return this.getInfoEditor();
    }

    protected getInfoEditor() {
        if (!this._infoEditor) {
            this._infoEditor = new ConnectedPageInfo(createSliceFoundation(SliceEntityEditorPage).setEntityDescriptor(this), EntityEditorPage, this.name + "Editor", { scrollOnlyContentInEditor: this.scrollOnlyContentInEditor });
            this._infoEditor.routeProps = { path: this.getEntityEditorUrl(":id"), routeIsModal: true, routeEntityName: this.name };
        }
        return this._infoEditor;
    }

    constructor(ed: Optional<EntityDescriptorForConstructor>, prepopulateFromEntitiesGen: boolean = true) {
        // TODO CS: cum facem ca din cauza asta apar erori pe la teste
        // if (!globalThis.entitiesGen) { throw new Error("Issue in order of imports (probably). The 'entitiesGen' file was not yet imported, and someone tries to create an EntityDescriptor. 'entitiesGen' should be imported very soon, so that people can override generated descriptors. ED name: " + ed?.name) }
        if (globalThis.entitiesGen && prepopulateFromEntitiesGen) {
            if (!ed?.name) { throw new Error("'name' is a mandatory param") }
            this.name = ed.name;
            EntityDescriptorPopulatorFromEntityGen.INSTANCE.populate(this);
        }

        if (ed) {
            Object.assign(this, ed);
        }
        this.customize();
    }

    protected customize() {
        // no op
    }

    get entityTablePage() {
        return this._entityTablePageRef;
    }

    /**
     * @param fieldDescriptor Is a PLAIN object that will be copied into the FieldDescriptor. For legacy compatibility, this may also be an instance of
     *  FieldDescriptor. But this is not any more recommended. The next param is for this.
     * @param fieldDescriptorInstance An instance of FieldDescriptor. If you want to override the FieldDescriptor (e.g. for a custom renderer), this
     *  is the way to go. All the props of `fieldDescriptor` are copied to `fieldDescriptorInstance`.
     */
    addFieldDescriptor(fieldDescriptor: OmitFieldsOfTypeFunction<FieldDescriptor>, fieldDescriptorInstance?: FieldDescriptor) {
        this.orderedFieldsNeedSort = true;
        let existingFD: FieldDescriptor | undefined = this.fields[fieldDescriptor.name];
        let existingFDGen;
        if (existingFD instanceof FieldDescriptorGen) {
            existingFDGen = existingFD;
            existingFD = undefined;

        }
        if (existingFD) {
            if (fieldDescriptorInstance ||
                fieldDescriptor instanceof FieldDescriptor) { // as mentioned in the doc, we accept this for legacy comp
                throw new Error("A FieldDescriptor already exists. In this case, the parameter should be an Object, not a FieldDescriptor; for field name = " + existingFD.name);
            }
        } else {
            // no existingFD; let's make one
            if (fieldDescriptorInstance) {
                if (fieldDescriptor instanceof FieldDescriptor) {
                    throw new Error("'fieldDescriptor' and 'fieldDescriptorInstance' cannot be both instances of FieldDescriptor. You want maybe to pass a 'fieldDescriptor' = plain object and 'fieldDescriptorInstance' = an actual FieldDescriptor?");
                }
                existingFD = fieldDescriptorInstance;
            } else if (fieldDescriptor instanceof FieldDescriptor) {
                existingFD = fieldDescriptor;
            } else {
                // no FD instance provided; we create it here
                existingFD = new FieldDescriptor();
            }
            existingFD.parent = this;
        }
        if (existingFDGen) {
            Object.assign(existingFD, existingFDGen);
        }
        Object.assign(existingFD, fieldDescriptor);

        this.fields[fieldDescriptor.name] = existingFD;
        return this;
    }

    removeFieldDescriptors(...fields: string[]) {
        for (let field of fields) {
            delete this.fields[field];
        }
        return this;
    }

    toMiniString(entityWithMiniFields: any): string {
        if (this.miniFields.length === 0) {
            return entityWithMiniFields[ID];
        }
        return this.miniFields.map(field => {
            let e = entityWithMiniFields;
            field.split(".").forEach(s => {
                if (e) { e = e[s]; }
            });
            return e;
        }).join(" ");
    }

    getEntityEditorUrl(id: any) {
        return `/${this.name}Editor/${id}`;
    }

    getEntityTableUrl() {
        return `/${this.name}Table`;
    }

    getLabelKey() {
        return this.name + ".label";
    }

    getLabel(pluralized: boolean = false) {
        return _msg({ missingKeyStrategy: "RETURN_KEY", pluralized }, this.getLabelKey());
    }

    getIcon(iconProps?: any) {
        return typeof this.icon === 'string' ? <Icon {...iconProps} name={this.icon as any} /> : this.icon;
    }

    getLabelWithIcon() {
        return <Label>{this.getIcon()}{this.getLabel()}</Label>;
    }

    getMenuEntry(): any | null {
        return {
            key: this.name, content: this.getLabel(), to: this.getEntityTableUrl(), icon: this.icon, permission: Utils.pipeJoin([ENT_TABLE, this.name]),
            as: EntityMenu, entityMenuProps: { show: this.showInUI, labelKey: this.getLabelKey() }
        };
    }

    getHelpers(): ConnectedPageInfo[] {
        return [this.infoEditor];
    }

    getGraphQlFieldsToRequest(fields?: string[]) {
        if (!fields) {
            fields = Object.keys(this.fields);
        }

        let selection = "";
        const customFields: { [key: string]: string[] } = {}

        for (const field of fields) {
            const fieldSplit = field.split('.');

            // keeps track of the entityDescriptor & fieldDescriptor when navigating composed field selections
            let entityDescriptor: EntityDescriptor = this
            let fieldDescriptor: Optional<FieldDescriptor> = null;
            let fieldSelection: string = "";

            let goToNextField = false;
            for (let index = 0; index < fieldSplit.length; index++) {
                fieldDescriptor = entityDescriptor && entityDescriptor.fields[fieldSplit[index]];

                // check if one of the fields is marked as clientOnly, because if this field reaches the request, 
                // it will give an error, as it does not exist on the server
                if (!fieldDescriptor || fieldDescriptor.clientOnly) {
                    goToNextField = true;
                    break;
                }
                entityDescriptor = entityDescriptors[fieldDescriptor.getType()];

                // I start composing the request here so I don't need another loop below
                // We need to query also the id (for inline editor on table page) of the current entity if it has a parent (index > 0)
                // e.g. if we have department.name (department is an entity with id), the query will be department { id name } 
                fieldSelection += (index ? ' { ' : ' ') + (index > 0 && fieldDescriptor.parent.fields["id"] ? " id " : "") + fieldSplit[index] + ' '
            }

            if (goToNextField) {
                continue;
            }
            // using fieldDescriptor because it should be for the last field in the field sequence
            if (fieldDescriptor && (fieldDescriptor.isCustomField || fieldDescriptor.isAggregateField)) {
                let key = field.includes(".") ? Utils.substringBefore(field, '.', true) : "";
                if (!customFields[key]) {
                    customFields[key] = [];
                }
                customFields[key].push(Utils.substringAfter(field, '.', true))
                continue;
            }

            selection += fieldSelection;

            // if last field in field sequence is an entity then we need to request the miniFields
            if (entityDescriptor) {
                selection += ` { ${ID} ${(entityDescriptor.hasArchivedField() ? ARCHIVED : "")} ${entityDescriptor.getGraphQlFieldsToRequest(entityDescriptor.miniFields)} } `;
            }

            // closing the brackets opened in the loop for fieldSelection
            selection += ' } '.repeat(fieldSplit.length - 1);
        }

        // adding customFields to the request
        Object.keys(customFields).forEach(field => {
            const fieldSplit = field.split('.');

            fieldSplit.forEach((word, index) => selection += (index ? ' { ' : ' ') + word + ' ');

            selection += (fieldSplit.length > 0 && (field !== "") ? ' { id ' : ' ');
            selection += `${CUSTOM_FIELDS}(whichFields: [${customFields[field].map(name => '"' + name + '"').join(',')}])`;
            selection += (fieldSplit.length > 0 && (field !== "") ? ' } ' : ' ');

            selection += ' } '.repeat(fieldSplit.length - 1);
        });

        // removes duplicate/leading/trailing whitespace
        return selection.replace(/\s+/g, ' ').trim();
    }

    getField(name: string) {
        const fds = this.getFieldDescriptorChain(name);
        const result = fds[fds.length - 1];
        if (!result) {
            throw new Error("Expected field not found: " + this.name + "." + name);
        }
        return result;
    }

    getParentField(name: string) {
        if (!name.includes(".")) {
            // If field is not composed, then it won't have a parent field
            throw new Error("The argument is not a composed field name (i.e. it doesn't contain '.')");
        }

        const fds = this.getFieldDescriptorChain(name);
        const result = fds[fds.length - 2];
        
        if (!result) {
            throw new Error("Expected field not found: " + this.name + "." + name);
        }

        return result;
    }

    getFieldDescriptorChain(name: string): FieldDescriptor[] {
        if (!this.fields[name] && name.includes(".")) {
            const fieldSplit = name.split(".");
            let fieldDescriptorChain: FieldDescriptor[] = []
            let entityDescriptor: EntityDescriptor = this;
            for (const word of fieldSplit) {
                fieldDescriptorChain.push(entityDescriptor.fields[word]);
                entityDescriptor = entityDescriptors[entityDescriptor.fields[word].getType()];
            }
            return fieldDescriptorChain;
        }
        return [this.fields[name]];
    }

    getComposedFieldLabel(fieldDescriptorChain: FieldDescriptor[]) {
        const simpleField = fieldDescriptorChain.length === 1 ? fieldDescriptorChain[0] : undefined;
        let composedFieldLabel = '';
        if (fieldDescriptorChain.length > 1) {
            for (const fd of fieldDescriptorChain) {
                composedFieldLabel += fd.getLabel() + '.';
            }
            composedFieldLabel = composedFieldLabel.substring(0, composedFieldLabel.length - 1);
        }
        return simpleField ? simpleField.getLabel() : composedFieldLabel
    }

    getLabelMaybeAbbreviated(fieldDescriptorChain: FieldDescriptor[], propsColumns: ColumnDefinition[]) {
        let iterator = fieldDescriptorChain.length - 1;
        let label = "";
        while (iterator >= 0) {
            let fieldDescriptor = fieldDescriptorChain[iterator--];
            let found = false;
            if (label === "") {
                label = fieldDescriptor.getLabel();
            } else {
                label = fieldDescriptor.getLabel() + "." + label;
            }
            for (let column of propsColumns) {
                let columnLabel = "";
                this.getFieldDescriptorChain(column.name).map((fd) => {
                    if (columnLabel === "") {
                        columnLabel = fd.getLabel();
                    } else {
                        columnLabel += "." + fd.getLabel();
                    }
                })
                if (columnLabel.endsWith(label)) {
                    found = true;
                    break;
                }
            }
            if (found) {
                continue;
            }
            break;
        }
        return { fieldLabel: label, abbreviated: iterator < 0 ? false : true };
    }

    getAuthorizedFields(type: string, columns?: string[]): { [fieldName: string]: FieldDescriptor } {
        let fields = {} as { [fieldName: string]: FieldDescriptor };
        if (!entityDescriptors[this.name]) {
            return this.fields
        }
        // ex: columnName = [normalField, composedField.composedField1.field]
        let columnNames = columns ? columns : Object.keys(this.fields);
        columnNames?.forEach((columnName) => {
            let columnNameSplit = columnName.split(".");
            // recursion -> fd[composedField] -> fd[composedField1] -> fd[field]
            let fieldDescriptor = this.getFieldDescriptorChain(columnName)[0];
            // ignore permissions if field is admin only and user is not admin

            // TODO: There is a problem with the following condition in storybook mode; !TestUtils.storybookMode
            // was added into if to resolve problem temporary; should be deleted when the problem is solved
            if (!TestUtils.storybookMode && fieldDescriptor.adminOnly && !AppContainer.INSTANCE.getAppContainerContextValue().initializationsForClient.currentUser?.isAdmin) {
                return;
            }
            // if field is authorized
            if (AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.join([ENTITY, this.name, type, fieldDescriptor.getId()])) ||
                ((fieldDescriptor.clientOnly || fieldDescriptor.isAggregateField) &&
                    AppMetaTempGlobals.appMetaInstance.hasPermission(Utils.join([ENTITY, this.name, FIELDS_WRITE, DEFAULT, ALLOW])))) {
                if (columnNameSplit.length > 1 && fieldDescriptor.typeIsEntity()) {
                    // composedField1.field -> field
                    let remainingColumnName = columnName.replace(columnNameSplit[0] + ".", "");
                    // fields [composedField.composedField1.field] = fields[composedField1.field] = fields[field]
                    fields[columnName] = entityDescriptors[fieldDescriptor.getType()].getAuthorizedFields(type, [remainingColumnName])[remainingColumnName];
                } else {
                    // fields[normalField] && fields[field]
                    fields[columnName] = fieldDescriptor;
                }
            }
        })

        // end -> { composedField.composedField1.field : fd[field, normalField : fd[normalField] }
        return fields;
    }

    /**
     * @param fieldNames If `null` => operates on all fields
     */
    doForFields(fieldNames: string[] | null, callback: (fieldDescriptor: FieldDescriptor) => void) {
        if (!fieldNames) {
            fieldNames = Object.keys(this.fields);
        }
        for (let field of fieldNames) {
            const fd = this.fields[field];
            if (!fd) { throw new Error("Field not found: " + field + " among the fields of " + this.name) }
            callback(fd);
        }
        return this;
    }

    /**
     * The app fails to initialize if we try to add audit to CustomQuery. It is stuck in some loop where the page is blank and there is no console ouput.
     * I had no error suggesting this is caused by adding audit to CQ. I thought CQ could cause this and tried w/o audit and it worked.
     * Task for this issue: #29245 https://redmine.xops-online.com/issues/29245
     */
    public canAddAuditTabs() {
        return !["Audit", "CustomQuery"].includes(this.name);
    }

    isInDefaultColumnConfig(value: boolean, ...fields: string[]) {
        if (value) {
            this.doForFields(null, fd => fd.isInDefaultColumnConfig = false);
        }
        this.doForFields(fields, fd => fd.isInDefaultColumnConfig = value);
        return this;
    }

    hasArchivedField() {
        return this.fields[CrudGlobalSettings.INSTANCE.fieldArchived] !== undefined &&
            this.fields[CrudGlobalSettings.INSTANCE.fieldArchived] !== null;
    }

    getDefaultCustomQuery() {
        let filters: Filter[] = [];
        if (this.defaultFilter) {
            filters.push(this.defaultFilter);
        }
        if (this.hasArchivedField()) {
            filters.push(Filter.createForClient(CrudGlobalSettings.INSTANCE.fieldArchived, FilterOperators.forBoolean.equals, "false"))
        }

        let sorts = this.defaultSort ? Array.isArray(this.defaultSort) ? this.defaultSort : [this.defaultSort] : [];
        return {
            name: _msg('entityCrud.table.cq.programDefault'),
            screen: this.name,
            enabled: true,
            color: '',
            id: -1,
            customQueryDefinitionObject: {
                filter: Filter.createComposedForClient(FilterOperators.forComposedFilter.and, filters),
                sorts: sorts as Array<Sort>
            },
            dirty: false,
            preferredColumnConfig: undefined,
            fromCrudSettings: false,
            emailScheduleRecipientList: undefined,
            emailScheduleCron: ''
        } as ClientCustomQuery;
    }

    getDefaultColumnConfig(forEditor?: boolean) {
        let columnConfigDefinition: ColumnConfigConfig = {
            columns: []
        };
        let priorityFields = [];
        let defaultColumnConfigFields = [];
        for (let field of this.orderedFields) {
            const fd = this.getField(field);
            if (!forEditor && !fd.getAppearsInUi()) { continue; }
            const flag = forEditor ? fd.isInDefaultColumnConfigForEditor : fd.isInDefaultColumnConfigForTable;
            if (flag !== undefined) {
                if (flag) {
                    priorityFields.push({ name: field, width: COLUMN_DEFAULT_WIDTH });
                }
            } else if (fd.isInDefaultColumnConfig) {
                defaultColumnConfigFields.push({ name: field, width: COLUMN_DEFAULT_WIDTH })
            }
        }

        columnConfigDefinition.columns = defaultColumnConfigFields.concat(priorityFields);

        let cc: ClientColumnConfig = {
            dirty: false,
            id: -1,
            name: _msg('entityCrud.table.cc.programDefault'),
            entityName: this.name,
            configObject: columnConfigDefinition,
            fromCrudSettings: false,
            organization: null,
            displayAsCards: false,
            autoRefreshInterval: 0
        };
        return cc;
    }

    createNewEntity(): any {
        return {};
    }

    getPrefixForCsvExport() {
        return '//' + `//{"entity":"${this.name}"}`
    }

    renderTable() {
        return <EntityTablePageRRC ref={this.entityTablePage} id={this.name + "-entityTablePage"} currentLocation={AppMetaTempGlobals.history.location} entityDescriptor={this} />
    }

    renderTableRoute() {
        return <PrivateRoute path={this.getEntityTableUrl()} key={this.getEntityTableUrl()} render={(props) => this.renderTable()} />
    }

    /* TODO: temporary code, should be replaced with filtersMatchesEntity when GWT livrary will be implemented */
    protected checkCondition(condition: string, entity: any) {
        // see java doc for FieldInHeader
        // '||' - OR, '&&' - AND, '=' - equals, '!=' - not equals
        // use OR or AND between all conditions, nested case is not treated
        // format template for OR: field1=value1||field2!=value2
        // format template for AND: field1=value1&&field2!=value2
        // e.g. organization.name=OR||type.name!=T
        // e.g. organization.name=OR&&type.name!=T
        if (Utils.isNullOrEmpty(condition) || condition.indexOf("=") < 0) {
            return false;
        }

        let conditionSeparator = condition.indexOf("||") > 0 ? "||" : "&&";
        for (const group of condition.split(conditionSeparator!)) {
            // equals or not equals
            let groupSeparator = group.indexOf("!=") > 0 ? "!=" : "=";
            const fieldAndValue = group.split(groupSeparator);
            const fd = this.getFieldDescriptorChain(fieldAndValue[0])?.pop();
            const value = Utils.navigate(entity, fieldAndValue[0], false, '.');
            let found = false;
            if (FieldType.boolean === fd?.type) { // custom treatment for boolean 
                if (String(Boolean(value)) === fieldAndValue[1]) {
                    found = true;
                }
            } else if (value === fieldAndValue[1] || String(value) === fieldAndValue[1]) {
                found = true;
            }
            // add not to result if not equals is used
            if (groupSeparator == "!=") {
                found = !found;
            }
            if (found && conditionSeparator == "||") {
                return true;
            }
            if (!found && conditionSeparator == "&&") {
                return false;
            }
        }
        
        // if OR and no group of condition was true means all condition is false, if AND and all groups of condition were true means all condition is true
        return conditionSeparator == '||' ? false : true;
    }

    /**
     * return all fields (no duplicates) from all fields settings which pass the condition and default fields
    **/
    getFieldsFromSettings(templates: FieldsSettings[] | undefined, entity: any): { defaultFields: string[], fields: string[] } {
        let fields: string[] = [];
        let defaultFields: string[] = [];
        if (Utils.isNullOrEmpty(entity) || Utils.isNullOrEmpty(templates)) {
            return { defaultFields, fields };
        }
        for (let i = 0; i < templates!.length; i++) {
            const template = templates![i];
            if (!template.name) {
                if (template.fields) {
                    defaultFields = _.union(defaultFields, template.fields.split(",").map((f: string) => f.trim()) || []);
                } else if (template.field && !defaultFields.includes(template.field.trim())) {
                    defaultFields.push(template.field.trim());
                }
            } else {
                if (this.checkCondition(template.name, entity)) {
                    if (template.fields) {
                        fields = _.union(fields, template.fields.split(",").map((f: string) => f.trim()) || []);
                    } else if (template.field && !fields.includes(template.field.trim())) {
                        fields.push(template.field.trim());
                    }
                }
            }
        }

        return { defaultFields, fields }
    }
}

function EntityMenu(props: any) {
    const { entityMenuProps, ...menuProps } = props;

    // Dana: I think that this code for disabling the menu entry (when showInUi is false) is useless
    // because if showInUi is false this menuEntry is never added to the app menu because of the code in the AppMeta
    if (!entityMenuProps.show) {
        const disabledStyle = { pointerEvents: "none", opacity: 0.5 };
        menuProps.style = menuProps.style ? { ...menuProps.style, ...disabledStyle } : disabledStyle;
    }

    return <NavLinkWithPermission {...menuProps} />;
}

export interface DummyToRememberPeopleToCast {
    
    /**
     * Don't use this field. It is the solution to force the TS compiler to do the "excess property check". 
     * On an empty type, this [doesn't happen](https://github.com/Microsoft/TypeScript/wiki/FAQ#why-are-all-types-assignable-to-empty-interfaces).
     * 
     * And because the attribute is optional, it doesn't bother the people.
     */
    _dummyToRememberPeopleToCast?: number;
}

// cf. https://stackoverflow.com/a/69299668
// It's usual to expose as props, the props of the underlying component. E.g. Select. It
// may have [key: string]: any, which practically cancels our efforts to force typing. Hence
// we exclude such a construct.
// However, if possible, field renderers/editors should avoid to expose props that accept anything.
type NoStringIndex<T> = { [K in keyof T as string extends K ? never : K]: T[K] };
type OmitParentFields<PARENT, CHILD> = Partial<Omit<NoStringIndex<CHILD>, keyof PARENT>> & DummyToRememberPeopleToCast;

export class FieldDescriptor {
    [key: string]: any;

    parent!: EntityDescriptor;
    name!: string;
    type!: string;
    enabled: boolean = true;
    filterable: boolean = true;
    sortable: boolean = true;
    isCustomField: boolean = false;
    allowMultiple: boolean = true;

    /**
     * Allow the creation of NavLinks for a many-to-one field in a CRUD table, otherwise show only the label
     */
    showManyToOneCellAsLink: boolean = true;

    /**
     * @see getIcon()
     */
    icon?: SemanticICONS;

    // used for tags (automatically selectes tags.tag when creating filter); name was kept to correspond with java side
    // NOTE: for the moment this is set manually in fd, but it is intended to be automatically configured when gen entities
    filterAsManyToMany: string | undefined;

    isInDefaultColumnConfig: boolean = true;

    /**
     * If set, it has priority over `isInDefaultColumnConfig`.
     */
    isInDefaultColumnConfigForTable?: boolean = undefined;

    /**
     * If set, it has priority over `isInDefaultColumnConfig`.
     */
    isInDefaultColumnConfigForEditor?: boolean = undefined;

    /**
     * To work w/ types, please cast using the following example/pattern:
     * 
     * ```ts
     * .addFieldDescriptor({
     *      name: "birthDate",
     *      // ...
     *      additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { utcMode: true })
     * })
     *  ```
     */
    additionalFieldRendererProps?: DummyToRememberPeopleToCast;

    /**
     * To work w/ types, please cast using the following example/pattern:
     * 
     * ```ts
     * .addFieldDescriptor({
     *      name: "birthDate",
     *      // ...
     *      additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldRendererProps(DatePickerFieldEditor, { utcMode: true })
     * })
     *  ```
     */
    additionalFieldEditorProps?: DummyToRememberPeopleToCast;

    initialValue?: any;

    fieldDescriptorSettings?: FieldDescriptorSettings;

    /**
     * Currently this is meant for fields that are not shown in UI. Because currently the load of the entity is separated in table and editor. It is not central.
     * Usually the editor has a lot of logic that may populate such a field. But when the entity is loaded in the table, the field wouldn't be there => issue.
     * And the fact that it's not central, isn't maybe a bad thing. The table may load lots of records (hundreds). And I don't think it makes sense to to the additional
     * processing for all the records displayed in the table. If new needs appear => maybe we may change things around here.
     */
    clientOnly = false;

    // aggregate field, see aggFunc_count_baggages_id in FlightEntityDescriptor
    isAggregateField = false;

    // only user.isAdmin = true reads/writes this column
    adminOnly = false;

    /**
     * In our current TS version, there seems to be a glitch. If the component is generic, the compiler complains.
     * Workaround: in your generic component, please add an explicit constructor.
     * 
     * @see additionalFieldRendererProps
     */
    static castAdditionalFieldRendererProps<T extends FieldRendererProps>(rendererComponent: React.ElementType<T>, props: OmitParentFields<FieldRendererProps, T>): OmitParentFields<FieldRendererProps, T> {
        return props as any;
    }

    /**
     * In our current TS version, there seems to be a glitch. If the component is generic, the compiler complains.
     * Workaround: in your generic component, please add an explicit constructor.
     * 
     * @see additionalFieldEditorProps
     */
    static castAdditionalFieldEditorProps<T extends FieldEditorProps>(editorComponent: React.ElementType<T>, props: OmitParentFields<FieldEditorProps, T>): OmitParentFields<FieldEditorProps, T> {
        return props as any;
    }

    /**
     * I.e. in the default column configs for table, and in the field selector of the col config dropdown. This is meant to be overridden, if e.g. you want a calculated field 
     * in the table.
     * 
     * Regarding: "the col config dropdown". We are talking also about the one in the editor. So currently the user cannot create a CC w/ such a field. It may appear only in
     * the default CC.
     */
    getAppearsInUi(): boolean {
        return !this.clientOnly;
    }

    getType(): string {
        return this.typeIsOneToMany() ? this.type.slice(1, this.type.length - 1) : this.type;
    }

    // temporary used until app knows how to work 100% with field.fid 
    getId(): string {
        if (this.isCustomField) {
            return String(EntityDescriptorForServerUtils.getFieldId(this.parent.name, this.name));
        }
        return this.name;
    }

    getLabel(getDefaultMeasurementUnitSymbol: boolean = false, withMeasurementUnitSymbol: boolean = true): string {
        let muLabel;
        if (withMeasurementUnitSymbol) {
            muLabel = this.getMeasurementUnitLabel(getDefaultMeasurementUnitSymbol);
        }
        const fieldLabel = this.isCustomField
            ? Messages.getInstance().maybeTranslateByUser(EntityDescriptorForServerUtils.entityDescriptorsForServer[this.parent?.name].fields[this.name].fieldLabel)
            : _msg({ missingKeyStrategy: "RETURN_KEY" }, this.parent?.name + "." + this.name + ".label");
        return fieldLabel + (muLabel ? " (" + Messages.getInstance().maybeTranslateByUser(muLabel) + ")" : "");
    }

    getIcon(): ReactNode {
        let iconName = this.icon;
        if (this.typeIsEntity() && !iconName) {
            iconName = entityDescriptors[this.getType()].icon;
        }
        return iconName ? <Icon name={iconName} /> : null;
    }

    getMeasurementUnitLabel(getDefaultMeasurementUnitSymbol?: boolean) {
        const physicalQuantityName = this.fieldDescriptorSettings?.physicalQuantity;
        const physicalQuantity: Optional<PhysicalQuantity> = AppContainer.INSTANCE.getAppContainerContextValue().initializationsForClient.crudSettings?.physicalQuantities.find(pq => pq.name === physicalQuantityName);
        const muName = this.fieldDescriptorSettings?.measurementUnit;
        const mu: Optional<MeasurementUnit> = physicalQuantity?.measurementUnits.find(mu => mu.name === muName);

        return physicalQuantity ? (getDefaultMeasurementUnitSymbol
            ? physicalQuantity.defaultMeasurementUnitSymbol
            : mu && physicalQuantity.defaultMeasurementUnitSymbol !== mu.symbol ? mu.symbol : physicalQuantity.defaultMeasurementUnitSymbol) : undefined;
    }

    typeIsEntity() {
        return entityDescriptors[this.getType()] !== undefined;
    }

    typeIsOneToMany() {
        // TODO CS: le-am pus rapid caci crapau in serie f multe storybooks
        if (!this.type) { return false; }
        return this.type.startsWith("[") && this.type.endsWith("]");
    }

    /**
     * Reused function used in 2 places.
     * 
     * @param whereToLook May be `fieldRenderers` or `fieldEditors`.
     */
    getForFieldType<T>(whereToLook: { [key: string]: T }, props: any) {
        let innerType = this.getType();
        let item: any = whereToLook[innerType];

        if (!item) {
            // no descriptor found; either A) we are dealing with an unknown type e.g. "myUnknownType"
            // or B) we are dealing with a an entity type, e.g. "Department"
            // so for these cases, get the default one (e.g. the default renderer or default editor)

            if (this.typeIsEntity()) {
                // case B), i.e. a many-to-one field or one-to-many

                item = whereToLook[this.typeIsOneToMany() ? FieldType.defaultOneToMany : FieldType.defaultManyToOne];
                if (props) {
                    props.innerEntityDescriptor = entityDescriptors[innerType];
                }
            } else {
                // case A) i.e. normal field
                item = whereToLook[FieldType.defaultScalar];
            }
        }

        return item;
    }

    renderField(entity: any, additionalFieldRendererProps?: DummyToRememberPeopleToCast) {
        const props: FieldRendererProps = { ...this.additionalFieldRendererProps, ...additionalFieldRendererProps, entity: entity, value: this.getFieldValue(entity), fieldDescriptor: this };
        const RendererClass: any = this.getForFieldType(fieldRenderers, props);
        return this.renderFieldInternal(RendererClass, props, entity);
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
        return React.createElement(RendererClass, props);
    }

    /**
     * Use this to wrap the FD value with additional UI components.
     * NOTE: Maybe this must stay in other  place or have other name. Open to suggestions.
     */
    wrapComponent(value: any, component: any) {
        const fi = this.getFieldInterval(value);
        const fieldColors = this.getFieldColors(value);
        return <div className="flex-container-row flex-center" data-testid="fieldRendererWrapper">
            {fi?.applyColorTo === FieldIntervalColoring.BULLET ? <div><Icon name="circle" style={{ color: fieldColors?.bulletColor }} /></div> : <></>}
            {component}
        </div>;
    }

    /**
     * @param values Can be the entity itself, or may be "values" from formik. Takes into
     *  account the nested case (e.g. mySubEntity.myField).
     */
    getFieldValue(values: any) {
        let value: any = undefined;
        if (values) {
            value = Utils.navigate(values, this.getFieldName().split("."), false);
        }

        return value;
    }

    getFieldValueConvertedToMeasurementUnit(value: any, truncateValueToXDecimals?: number) {
        if (value === undefined || value === null) {
            return value;
        }
        const physicalQuantityName = this.fieldDescriptorSettings?.physicalQuantity;
        const physicalQuantity: Optional<PhysicalQuantity> = AppContainer.INSTANCE.getAppContainerContextValue().initializationsForClient.crudSettings?.physicalQuantities.find(pq => pq.name === physicalQuantityName);

        const muName = this.fieldDescriptorSettings?.measurementUnit;
        const mu: Optional<MeasurementUnit> = physicalQuantity?.measurementUnits.find(mu => mu.name === muName);
        if (!physicalQuantity || !mu || physicalQuantity.defaultMeasurementUnitSymbol === mu.symbol) {
            return this.getFieldValueForDisplay(value, truncateValueToXDecimals);
        }
        return this.getFieldValueForDisplay((mu.scaleFactorTowardsDefaultMeasurementUnit || 1) * value + (mu.translationFactorTowardsDefaultMeasurementUnit || 0), truncateValueToXDecimals);
    }

    getFieldInterval(value: any): Optional<FieldInterval> {
        var fieldInterval = undefined;
        this.fieldDescriptorSettings?.fieldIntervals?.forEach(fi => {
            if (fi.to && value >= Number(fi.from) && value < Number(fi.to)) {
                fieldInterval = fi;
                return;
            }
            // for Enums, try if value equals orderIndex or from
            if (!fi.to && ((fi.enumOrderIndex !== undefined && fi.enumOrderIndex !== null ? value == fi.enumOrderIndex : false) || value == fi.from)) {
                fieldInterval = fi;
                return;
            }
        });
        return fieldInterval;
    }

    getFieldColors(value: any): any {
        const fi: Optional<FieldInterval> = this.getFieldInterval(value);
        let color, backgroundColor, bulletColor = undefined;
        if (fi && fi.color) {
            const colorAsHex = AppMetaTempGlobals.appMetaInstance.getColor(fi.color);
            const contrastingColorAsHex = Utils.convertColorToHex(Utils.getContrastingForegroundColor(Utils.convertColorFromHex(colorAsHex)));
            if (fi.applyColorTo === FieldIntervalColoring.TEXT) {
                color = colorAsHex;
            } else if (fi.applyColorTo === FieldIntervalColoring.BACKGROUND) {
                color = contrastingColorAsHex;
                backgroundColor = colorAsHex;
            } else if (fi.applyColorTo === FieldIntervalColoring.BULLET) {
                bulletColor = colorAsHex;
            }
        }
        return { backgroundColor: backgroundColor, color: color, bulletColor: bulletColor };
    }

    getFieldValueForDisplay(value: any, truncateValueToXDecimals?: number) {
        if (this.fieldDescriptorSettings?.numberOfDecimals !== null && this.fieldDescriptorSettings?.numberOfDecimals !== undefined) {
            truncateValueToXDecimals = this.fieldDescriptorSettings!.numberOfDecimals!;
        }
        if (value === undefined || value === null || truncateValueToXDecimals === undefined) {
            return value;
        }
        return Math.trunc(value * Math.pow(10, truncateValueToXDecimals)) / Math.pow(10, truncateValueToXDecimals);
    }

    renderFieldEditor(formikProps: IFormValuesHolder<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast): ReactElement {
        let props: FieldEditorProps = {...this.additionalFieldEditorProps, ...additionalFieldEditorProps, formikProps, fieldDescriptor: this };
        let EditorClass: any = this.getForFieldType(fieldEditors, props);
        return this.renderFieldEditorInternal(EditorClass, props);
    }

    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps): ReactElement {
        return React.createElement(EditorClass, props);
    }

    getFieldName() {
        return (this.isCustomField || this.isAggregateField ? CUSTOM_FIELDS + "." : "") + this.name;
    }

}

export class FieldDescriptorGen extends FieldDescriptor {
}

export function addEntityDescriptor(ed: EntityDescriptor) {
    entityDescriptors[ed.name] = ed.removeFieldDescriptors(CUSTOM_FIELDS);
    return ed;
}

export function copyFieldDescriptorSettings(fields: string[], from: EntityDescriptor, to: EntityDescriptor) {
    fields.forEach(field => {
        to.getField(field).fieldDescriptorSettings = from.getField(field).fieldDescriptorSettings;
    });
}

export function getFieldLastUpdateDate(entity: any, field: string): string | undefined {
    let lastUpdateDate = undefined;
    if (entity && entity[FIELDS_LAST_UPDATE]) {
        lastUpdateDate = entity[FIELDS_LAST_UPDATE][field];
        // example: '2011-12-03T10:15:30+01:00[Europe/Paris]', in this case needs to remove the zone
        if (typeof lastUpdateDate === "string" && lastUpdateDate.includes('[')) {
            lastUpdateDate = lastUpdateDate.substring(0, lastUpdateDate.indexOf('['));
        }
    }

    return lastUpdateDate; 
}
"../reduxHelpers""../AppMeta"