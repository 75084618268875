import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { createSortBlock, Sort } from "@crispico/foundation-react/components/CustomQuery/SortBar";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { Button, Icon, Label, Popup } from "semantic-ui-react";

export const SortFieldRenderer = (props: FieldRendererProps) => {
    const { value, entity, fieldDescriptor } = props;
    const entityName = fieldDescriptor.entityName ? fieldDescriptor.entityName : entity[fieldDescriptor.fieldForEntityName];
    return <>
        <Popup flowing on='click' trigger={<Button size='mini' content='...' />}>
            <div className='FilterBar_div'>
                {value?.map((sort: Sort, index: number) =>
                    <Label key={index} size='mini' className='SortBar_sortDiv'>
                        <div>
                            <Icon className='SortBar_sortIcon' size='large' name={sort.direction === 'ASC' ? 'sort up' : 'sort down'} />
                        </div>
                        <BlocklyReadOnly key={index}>{createSortBlock(sort, entityName)}</BlocklyReadOnly>
                    </Label>)}
            </div>
        </Popup>
    </>
}"../../blockly/BlocklyReadOnly""../../components/CustomQuery/SortBar""../fieldRenderersEditors"