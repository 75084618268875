import gql from "graphql-tag";

export const GET_FILES = gql(`query q($alias: String, $startDate: String, $endDate: String) { 
    fileBrowserService_files(alias: $alias, startDate: $startDate, endDate: $endDate) {
        alias isDirectory date size
    }
}`);

export const GET_FILES_SIMPLE = gql(`query q($alias: String, $startDate: String, $endDate: String) { 
    fileBrowserService_filesSimple(alias: $alias, startDate: $startDate, endDate: $endDate)
}`);

export const GET_IS_DIRECTORY = gql(`query q($alias: String) { 
    fileBrowserService_isDirectory(alias: $alias)
}`);

export const SEARCH_JAVA = gql(`query q($alias: String, $name: String, $keyword: String, $isRegex: Boolean!,  $startDate: String, $endDate: String) {
    fileBrowserService_searchKeywordIntoFolder(alias: $alias, name: $name, keyword: $keyword, isRegex: $isRegex, startDate: $startDate, endDate: $endDate) {
        alias isDirectory date size
    }
}`);

export const SEARCH_GREP = gql(`query q($alias: String, $name: String, $keyword: String,  $startDate: String, $endDate: String) {
    fileBrowserService_searchWordIntoFolderCommandLine(alias: $alias, name: $name, keyword: $keyword, startDate: $startDate, endDate: $endDate) {
        alias isDirectory date size
    }
}`);

export const DOWNLOAD_FILES = gql(`query q($filesToDownload: [String]) {
    fileBrowserService_registerFilesToDownload(filesToDownload: $filesToDownload)
}`)

export const DELETE_FILE = gql(`query q($alias: String) { 
    fileBrowserService_deleteFile(alias: $alias)
}`);

export const GET_CONSTRAINTS = gql(`query fileBrowserService_getConstraints {
    fileBrowserService_constraints
}`);
