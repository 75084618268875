import moment from "moment";
import React from "react";
import Calendar, { Detail } from "react-calendar";
import { DatePicker, DatePickerProps } from "../DatePicker";
import 'react-calendar/dist/Calendar.css';

export interface DatePickerReactCalendarProps extends DatePickerProps {
    view?: Detail
}

export class DatePickerReactCalendar extends DatePicker<DatePickerReactCalendarProps> {

    onDateSelected(value: Date) {
        let currentValue = moment();
        if (this.isValidExternal(this.state.value)) {
            currentValue = moment(this.state.value, this.props.format);

        }
        const selectedValue = moment(value, this.props.format);
        
        selectedValue.hour(currentValue.hour());
        selectedValue.minute(currentValue.minute());
        selectedValue.second(currentValue.second());
        this.updateValue(selectedValue.format(this.props.format));
        this.setState({ modalOpen: false })
        this.focusTime();
        this.applyOnChange(selectedValue);
    }

    getCalendarComponent(): any {
        const date = this.state.lastValidValue ? moment(this.state.lastValidValue, this.props.format).toDate() : moment().toDate();
        return <Calendar tileDisabled={({activeStartDate, date, view }) => this.props.disabledDate ? this.props.disabledDate(moment(date)) : false} className="DatePickerReactCalendar" value={date} onChange={(value: Date) => this.onDateSelected(value)} maxDetail={this.props.view} />
    }

}