import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { ColorResult, SketchPicker } from "react-color";
import { Optional, Utils } from "../..";
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";

interface Props extends FieldEditorProps<string | number> {
    dataType?: "string" | "number"
}

export class ColorFieldEditor extends FieldEditor<string | number, Props> {
    state = {
        displayColorPicker: false
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    onChange = (color: ColorResult, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        s.setFieldValue(hw, this.prepareValueToSave(color.hex, this.props.fieldDescriptor?.colorType || this.props.dataType))
    };

    getColor = (): string => {
        return this.prepareValueToDisplay(this.getValue());
    }

    prepareValueToDisplay(color: Optional<string | number>): string {
        if (!color) {
            return "#FFFFFF"; // white, if not defined
        }
        if (typeof color === 'number') {
            return Utils.convertColorToHex(color);
        }
        return color;
    }

    prepareValueToSave(color: string | number, requestedType: string | undefined) {
        if (!requestedType) {
            requestedType = "string";
        }
        if (typeof color === 'number' && requestedType === 'string') {
            return Utils.convertColorToHex(color);
        }
        if (typeof color === 'string' && requestedType === 'number') {
            return Utils.convertColorFromHex(color);
        }
        return color;
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        return (
            <div>
                <div className="ColorPicker_swatch" onClick={this.handleClick}>
                    <div className="ColorPicker_color" style={{ backgroundColor: this.getColor() }} />
                </div>
                {this.state.displayColorPicker ? <div className="ColorPicker_popover">
                    <div className="ColorPicker_cover" onClick={this.handleClose} />
                    <SketchPicker disableAlpha color={this.getColor()} onChange={(color) => this.onChange(color, s, hw)} />
                </div> : null}
            </div>
        )
    }
}

export default ColorFieldEditor;