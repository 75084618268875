import React, { ReactNode, createRef } from "react";
import { ScriptableUiContext } from "./ScriptableUiContext";
import { Spotlight } from "../components/Spotlight";
import ReactFloater from "react-floater";
import { Button } from "semantic-ui-react";
import { ScriptableUi } from "./ScriptableUi";
import { FoundationUtils } from "@famiprog-foundation/utils";
import { SyntaxHighlighterExt } from "../components/SyntaxHighlighterExt";

interface State {
    highlight?: boolean,
    floaterContent?: JSX.Element,
    resolveConfirmRecordedInstruction?: (value: boolean) => void
}

export class ScriptableUiHighlightWrapper extends React.Component<{ id: string | number, children: JSX.Element | ((hw: ScriptableUiHighlightWrapper) => ReactNode) }, State> {

    static contextType = ScriptableUiContext;
    declare context: React.ContextType<typeof ScriptableUiContext>

    protected static current?: ScriptableUiHighlightWrapper;

    static getCurrent(): ScriptableUiHighlightWrapper {
        if (!ScriptableUiHighlightWrapper.current) {
            throw new Error("Trying to access ScriptableUiHighlightWrapper.current, but it's not set! Did you use <HW ...>...</HW>?");
        }
        return ScriptableUiHighlightWrapper.current;
    }

    state: State = {};

    wrapperRef = createRef<HTMLDivElement>();

    getDomElement() {
        return this.wrapperRef.current?.nextElementSibling as HTMLElement;
    }

    componentDidMount(): void {
        if (!this.context) {
            throw new Error("HighlighWrapper must be an (direct or indirect) child of a ScriptableUi");
        }
        this.context.addHighlighWrapper(this);
    }

    componentWillUnmount(): void {
        this.context!.removeHighlighWrapper(this);
    }

    componentDidUpdate(prevProps: Readonly<{ id: string | number; children: JSX.Element; }>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.id !== prevProps.id) {
            throw new Error("Changing 'id' is not supported");
        }
    }

    protected createPartialErrorMessage() {
        return "For 'highlightWrapper id' = " + ScriptableUiHighlightWrapper.current?.props.id
            + ", 'scriptableUi id'" + ScriptableUiHighlightWrapper.current?.context?.qualifiedId;
    }

    setCurrent() {
        if (ScriptableUiHighlightWrapper.current) {
            throw new Error("Invoking a handler in a child of a HighlighWrapper. But a current invocation seems to be in progress. " + this.createPartialErrorMessage());
        }
        ScriptableUiHighlightWrapper.current = this;
        return true;
    }

    static unsetCurrent() {
        ScriptableUiHighlightWrapper.current = undefined;
    }

    protected getFloaterContent() {
        return this.state.floaterContent;
    }

    async highlightAndConfirmRecordedInstruction(recordedInstruction: string) {
        if (!await this.highlight(<>
            <SyntaxHighlighterExt showLineNumbers={false}>{recordedInstruction}</SyntaxHighlighterExt>
            <Button positive content="Continue" onClick={() => this.state.resolveConfirmRecordedInstruction!(true)} />
            <Button negative content="Cancel" onClick={() => this.state.resolveConfirmRecordedInstruction!(false)} />
        </>)) {
            // if here => we are not able to display the UI w/ buttons. So consider that the user pressed OK
            console.log("Cannot get user input. Continuing.");
            return true;
        }
        const result = await new Promise<boolean>(resolveConfirmRecordedInstruction => {
            this.setState({ resolveConfirmRecordedInstruction });
        });
        this.cancelHighlight();
        return result;
    }

    /**
     * @returns `false` if the highlight cannot be performed.
     */
    async highlight(floaterContent?: JSX.Element) {
        this.setState({ highlight: true });
        // wait for a render so that the ref to the div is created
        await FoundationUtils.setTimeoutAsync();
        if (!this.wrapperRef.current) {
            console.log("Cannot highlight the target component. It is unmounted. E.g. clicked on a context menu that quickly disappeared.");
            return false;
        }
        if (!this.getDomElement()) {
            console.log("Cannot highlight the target component. Cannot find the corresponding DOMElement. E.g. it uses a portal (such as a modal, a popup), so the DOMElement is not a child in the natural place, but somewhere else. ");
            return false;
        }
        this.setState({ floaterContent });
        return true;
    }

    cancelHighlight() {
        this.setState({ highlight: false, floaterContent: undefined, resolveConfirmRecordedInstruction: undefined });
    }

    /**
     * @see ScriptableUi.play()
     */
    render() {
        const newProps: any = {};
        let element: ReactNode;
        
        if ("props" in this.props.children) {
            // TODO so it's element; will be removed later
            for (let key in this.props.children.props) {
                const prop = this.props.children.props[key];
                if (prop instanceof Function) {
                    const newFunction = (...args: any) => {
                        try {
                            if (ScriptableUi.playing) {
                                console.warn("Probably not an issue, but noteworthy though. @see ScriptableUi.play().\nScriptableUi script is playing, and an UI handler was invoked. Ignoring.", this.createPartialErrorMessage());
                                return;
                            }

                            // we make available the HW; maybe the interceptor in RECORD mode wants
                            // to highlight
                            this.setCurrent();

                            (prop as Function).apply(null, args);
                        } finally {
                            ScriptableUiHighlightWrapper.unsetCurrent();
                        }
                    };
                    newProps[key] = newFunction;
                    // newProps[key] = prop;
                } else {
                    newProps[key] = prop;
                }
            }
            element = React.createElement(this.props.children.type, newProps);
            // @ts-ignore
            if (this.props.children.ref) {
                // the ref is not present props
                // @ts-ignore
                newProps.ref = this.props.children.ref;
            }
        } else {
            element = this.props.children.call(null, this);
        }

        const floaterContent = this.getFloaterContent();
        const domElement = this.getDomElement();
        // the div is a sibbling and not a parent because as a parent, it may break styling
        // initially cf. highlight = true/false we would display this or the original child; the 
        // disadvantage is that when toggling, the component would loose it's state. We didn't actually
        // see this, but it may be annoying in some cases (e.g. for a complex component)
        return <>
            {this.state.highlight && <div ref={this.wrapperRef} style={{ display: "none" }} />}
            {element}
            {this.state.highlight && domElement && <>
                <Spotlight element={domElement} />
                {floaterContent && <ReactFloater open styles={{ options: { zIndex: 10001 } /* TestsAreDemo_overlay + 1 */, container: { borderRadius: "4px" }, floater: { maxWidth: "1000px" } }}
                    target={domElement} content={floaterContent}
                />}
            </>}
        </>
    }
}

export const HW = ScriptableUiHighlightWrapper;
