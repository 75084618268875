import Measure from "react-measure";
import { TestClassDescriptor, TestsAreDemoMaster } from "../TestsAreDemoMaster";
import React from "react";
import { ModalForTestsOpenMode } from "../ModalForTests";

interface Props {
    master: TestsAreDemoMaster;
    testClassDescriptor: TestClassDescriptor;
}

/**
 * Normally we'd want this component to hold the demo component (chosen from the tree on the left). However,
 * we are in the "master". And the function that renders the component comes from the "slave" / iframe. It's kind
 * of illegal to do this. And if the demo component is (or has as children) functional components: they will give
 * that error message: "you are breaking the rules of hooks... maybe you have more than one time React included?".
 * 
 * This component will "call" the iframe, put it exactly on top of it (+ update
 * position/size on resize thanks to `Measure`) and tell the slave to display the demo component. Hence we call this 
 * component a "pseudo portal". And the iframe is "pseudo included".
 */
export class DemoComponentPseudoPortal extends React.Component<Props> {

    componentDidMount(): void {
        this.componentDidUpdate();
    }

    componentDidUpdate(prevProps?: Readonly<Props>) {
        if (this.props.testClassDescriptor !== prevProps?.testClassDescriptor) {
            this.props.master.setState({
                modalModePseudoIncludedTestClassDescriptor: this.props.testClassDescriptor
            });
            this.props.master.setModalOpenMode(ModalForTestsOpenMode.PSEUDO_INCLUDED_FOR_COMPONENT_DEMO);

            this.props.master.slave.setTestClassDescriptorForDemoComponent(this.props.testClassDescriptor);
        }
    }

    componentWillUnmount(): void {
        if (this.props.master.state.modalOpenMode === ModalForTestsOpenMode.PSEUDO_INCLUDED_FOR_COMPONENT_DEMO) {
            this.props.master.setModalOpenMode(ModalForTestsOpenMode.CLOSED);
        }
        // else it means it wasn't last open by us, in mode "pseudo include"; so it was (re)opened by "run tests"
        // so we don't want to close it; however we need the line below, i.e. remove the "demo component holder" and
        // add the "test holder"

        this.props.master.slave.setState({ testClassDescriptorForDemoComponent: undefined })
    }

    render() {
        return <Measure bounds
            onResize={contentRect => {
                this.props.master.setState({ modalModePseudoIncludedBounds: contentRect.bounds })
            }}>
            {({ measureRef }) => (
                <div ref={measureRef} style={{ width: "100%" }}>On top of this component should "float" the IFrame.</div>
            )}
        </ Measure>
    }
}