import React, { DragEventHandler, ReactNode } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

declare type Identifier = string | symbol;

type CommonProps = {
  key?: number | string;
  className?: string;
  height?: number;
  item?: any;
  children?: ReactNode;
}

export type DraggableProps = CommonProps & {
  type?: Identifier;
  onDraggingStyle?: React.CSSProperties;
  onDragStart?: DragEventHandler<any>;
}

export const Draggable = (props: DraggableProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: props.type ? props.type : 'draggable',
    item: props.item,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: {
      dropEffect: "copy"
    }
  }));
  const style = isDragging ? props.onDraggingStyle : undefined;

  return <div ref={drag} style={{ ...style, height: props.height, opacity: isDragging ? 0.5 : 1, cursor: 'grab' }} key={props.key} className={props.className}
    onDragStart={props.onDragStart}>
    {props.children}
  </div >

}
export type DroppableProps = CommonProps & {
  accept?: Identifier | Identifier[];
  item?: any;
  children?: JSX.Element | JSX.Element[];
  onOverStyle?: React.CSSProperties;
  canDrop?: (source: any, dest: any) => boolean;
  drop?: (source: any, dest: any) => void;
}
// KI: https://github.com/react-dnd/react-dnd/issues/3530
export const Droppable = (props: DroppableProps) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: props.accept ? props.accept : 'draggable',
      item: props.item,
      drop: (item: any, monitor: any) => props.drop && props.drop(item, props.item),
      canDrop: (item: any, monitor: any) => {
        return props.canDrop ? props.canDrop(item, props.item) : true;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    })
  );

  const style = isOver ? props.onOverStyle : undefined;
  return <div ref={drop} style={{ ...style, height: props.height, transition: '0.1s' }} key={props.key} className={props.className}>
    {props.children}
  </div >;
}

export class DragAndDropArea extends React.Component<{}> {
  render() {
    return <DndProvider backend={HTML5Backend} >{this.props.children}</DndProvider>
  }
}