import { Optional, Utils } from "@crispico/foundation-react";
import { ResponsiveLineExt, ResponsiveLineExtProps } from "@crispico/foundation-react/components/nivoExt";
//@ts-ignore
import { Serie, Datum, DatumValue, PointSymbolProps, PointMouseHandler, PointTooltipProps, useSlices } from "@nivo/line";
import lodash from 'lodash';
import React, { ReactElement } from "react";
import { Zoom } from "@crispico/foundation-react/components/Zoom/Zoom";
import { LineProps } from "@nivo/line";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { ChartMapping } from "../BarChart/BarChart";
import moment from "moment";
import { CartesianMarkerProps } from "@nivo/core";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { ResponsiveLineChartMeta } from "./ResponsiveLineChartMeta";
import Measure from "react-measure";

const { testids } = ResponsiveLineChartMeta;

export class ResponsiveLineChartState extends State {
    // calculated based on this.props.initialSeries (min, max)
    // initialStartTime: 0 as number,
    // initialEndTime: 0 as number,
    // calculated each time a zoom in/out or change is done
    series = [] as Serie[];
    startDate = undefined as number | undefined;
    endDate = undefined as number | undefined;

    chartPaddingLeft = 0 as number;

    minYScale = undefined as number | undefined;
    maxYScale = undefined as number | undefined;
}

export class ResponsiveLineChartReducers<S extends ResponsiveLineChartState = ResponsiveLineChartState> extends Reducers<S> {
    changeSeries(initialSeries: Serie[], scaleYField?: string | number, curve?: LineProps["curve"]) {
        if (!this.s.startDate || !this.s.endDate) {
            return;
        }
        this.s.series = [];
        for (let i = 0; i < initialSeries.length; i++) {
            const newSerie = { ...initialSeries[i] };
            const data: Datum[] = [];

            initialSeries[i].data.forEach(value => {
                if (value.x! >= this.s.startDate! && value.x! <= this.s.endDate!) {
                    data.push(value);
                }
            });

            let startDateChart = undefined as unknown as Datum;
            let endDateChart = undefined as unknown as Datum;
            for (let j = 0; j < initialSeries[i].data.length; j++) {
                if (this.s.startDate >= Number(initialSeries[i].data[j].x)) {
                    startDateChart = initialSeries[i].data[j];
                }
                if ((this.s.endDate <= Number(initialSeries[i].data[j].x)) && (!endDateChart) && (initialSeries[i].data[j] !== startDateChart)) {
                    endDateChart = initialSeries[i].data[j];
                }
            }
            data.unshift({
                id: "start",
                x: this.s.startDate,
                y: !startDateChart ? null : startDateChart.y,
                serieId: newSerie.id
            });
            data.push({
                id: "end",
                x: this.s.endDate,
                y: !endDateChart ? null : endDateChart.y,
                serieId: newSerie.id
            });

            newSerie.data = data;
            this.s.series.push(newSerie);
        }
        if (scaleYField) {
            this.setYScaleMinMax(scaleYField);
        }
    }

    setYScaleMinMax(serieId: string | number): { min: number, max: number } | undefined {
        let serieFound = undefined;
        for (const serie of this.s.series) {
            if (serie.id === serieId) {
                serieFound = serie;
                break;
            }
        }
        if (!serieFound || serieFound.data.length === 0) {
            return;
        }
        // find min max
        let max: Optional<DatumValue> = serieFound?.data[0].y;
        let min: Optional<DatumValue> = serieFound?.data[0].y;
        for (const dat of serieFound?.data!) {
            if (dat.y === undefined || dat.y === null) {
                continue;
            }
            const y = Number(dat.y);
            if (max === undefined || max === null || (max < y)) {
                max = y;
            }
            if (min === undefined || min === null || (min > y)) {
                min = y;
            }
        }
        if (min === undefined || min === null || max === undefined || max === null) {
            return;
        }
        this.s.minYScale = Number(min);
        this.s.maxYScale = Number(max);
    }
}

export type ResponsiveLineChartProps = {
    series: Serie[],
    startDate: number,
    endDate: number,
    currentStartDate?: number,
    currentEndDate?: number,
    showCurrentTime?: boolean,
    currentTime?: number,
    hasZoomSlider?: boolean,
    scaleYField?: string, // the serie.id that will be used as reference to set the min & max for Y scale
    curve?: LineProps["curve"],
    axisDateFormat?: string,
    sliderTooltipFormat?: (value: number) => string,
    onRangeChange?: (startDate: number, endDate: number) => void,
    pointSymbol?: (props: Readonly<PointSymbolProps>) => ReactElement,
    onClick?: PointMouseHandler,
} & ResponsiveLineExtProps;

type Props = RRCProps<ResponsiveLineChartState, ResponsiveLineChartReducers> & Omit<ResponsiveLineChartProps, "data"> & { mapping?: ChartMapping[], parentHeight?: number };

type LocalState = {
    measuredWidth: number | undefined,
    measuredHeight: number | undefined
}

export class ResponsiveLineChart extends React.Component<Props, LocalState> {

    static defaultProps = {
        hasZoomSlider: true,
    }

    slices = [] as any[];
    protected localDataToRecompute: any = { width: undefined, height: undefined, data: undefined }

    constructor(props: Props) {
        super(props);

        this.areaLayer = this.areaLayer.bind(this);
        this.lineLayer = this.lineLayer.bind(this);
        this.renderLineChartTooltip = this.renderLineChartTooltip.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentWillUnmount() {
        this.slices = [];
        this.localDataToRecompute = {};
    }

    private componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.startDate, props.startDate)
            || !lodash.isEqual(prevProps?.endDate, props.endDate)) {
            props.r.setInReduxState({ startDate: props.startDate, endDate: props.endDate });
        }
        if (props.currentStartDate !== undefined) {
            if (!prevProps || !lodash.isEqual(prevProps.currentStartDate, props.currentStartDate)
                || !lodash.isEqual(prevProps.currentEndDate, props.currentEndDate)) {
                props.r.setInReduxState({ startDate: props.currentStartDate, endDate: props.currentEndDate });
            }
        }
        if (prevProps && (!lodash.isEqual(prevProps.s.startDate, props.s.startDate) || !lodash.isEqual(prevProps?.s.endDate, props.s.endDate))) {
            props.r.changeSeries(props.series, props.scaleYField, props.curve);
            props.onRangeChange?.call(null, props.s.startDate!, props.s.endDate!);
        }
        if (!lodash.isEqual(prevProps?.series, props.series)) {
            props.r.changeSeries(props.series, props.scaleYField, props.curve);
        }
    }

    private onSliderBtnResize(width: number | undefined) {
        if (width) {
            this.props.r.setInReduxState({ chartPaddingLeft: width });
        }
    }

    public getHeightBasedOnParent() {
        if (!this.props.parentHeight) {
            return 150; // default height
        }
        if (this.props.hasZoomSlider) {
            return this.props.parentHeight - 64; // subtracting the height of the zoom
        }
        return this.props.parentHeight;
    }

    protected renderLineChartTooltip(value: PointTooltipProps): React.ReactNode {
        const point = value.point;
        const pointMapping = this.props.mapping?.find(t => t.start !== undefined ? point.data.y! >= t.start! && point.data.y! < t.end! : point.data.y == t.value);

        return <>
            <span style={{ color: this.props.series.find(serie => serie.id == point.serieId)?.color as any }}><strong>{this.props.series.find(serie => serie.id == point.serieId)?.label}</strong></span>
            <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={point.serieId + "." + point.id}>
                <span style={{ color: pointMapping?.color as any }}><strong>{point.data.y}</strong></span>
                <span> [{_msg("general.at", (point.data as Datum).x_label ? (point.data as Datum).x_label : moment(point.data.x).format(Utils.dateTimeWithSecFormat))}]</span>
            </div>
            {pointMapping ? <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={point.serieId + "." + pointMapping.id}>
                <span style={{ color: pointMapping.color as any }}>{pointMapping.text}</span>
            </div> : null}
        </>;
    }

    protected computeSlicesIfNeeded(p: any) {
        if (this.localDataToRecompute.width !== p.innerWidth
            || this.localDataToRecompute.height !== p.innerHeight
            || this.localDataToRecompute.data !== p.data) {
            this.localDataToRecompute = {
                width: p.innerWidth,
                height: p.innerHeight,
                data: p.data
            }
            // TODO use of hooks is not allowed in class components. It's odd that the code actually works. This is the error message:
            // React Hook "useSlices" cannot be called in a class component. React Hooks must be called in a React function component or a custom React Hook function  react-hooks/rules-of-hooks
            // I silenced the error temporarily below:
            // eslint-disable-next-line react-hooks/rules-of-hooks
            this.slices = useSlices({ enableSlices: "x", points: p.points, width: p.innerWidth, height: p.innerHeight });
        } else {
            // need this bogus call to avoid "Rendered fewer hooks than expected. This may be caused by an accidental early return statement." error
            // UPDATE: I think that the comment above is somehow a fix for the already bad practice of using the hook in a class component
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useSlices({ enableSlices: false, points: [], width: p.innerWidth, height: p.innerHeight });
        }
    }

    protected areaLayer(p: any): JSX.Element | undefined {
        this.computeSlicesIfNeeded(p);
        return;
    }

    protected lineLayer(p: any) {
        const { series, lineGenerator, xScale, yScale } = p;
        return series.map((p1: any) => (
            <path data-testid={testids.line + "_" + p1.id}
                key={p1.id}
                d={lineGenerator(
                    p1.data.map((d: any) => ({
                        x: xScale(d.data.x),
                        y: d.data.y != null ? yScale(d.data.y) : null,
                    }))
                )}
                fill="none"
                stroke={p1.color}
                strokeWidth={2}
            />
        ))
    }

    protected renderAnimationLine() {
        const props = this.props;
        const width = this.state?.measuredWidth;
        const height = this.state?.measuredHeight;
        const chartSideMargin = 109;
        if (!props.showCurrentTime || props.currentTime === undefined || props.currentStartDate == undefined
            || props.currentEndDate == undefined || width === undefined || height === undefined) {
            return null;
        }
        let offset = 0;
        const ratio = (width - chartSideMargin) / (props.currentEndDate - props.currentStartDate);
        offset = ratio * (props.currentTime - props.currentStartDate);
        return <div style={{ width: 2, height: height, backgroundColor: "red", opacity: 0.9,   
            position: "absolute", margin: "0px 0 0 " + (chartSideMargin + offset) + "px", zIndex: 1 }}
        />;
    }

    render() {
        const props = this.props;
        let markers: CartesianMarkerProps[] = [];
        if (props.mapping) {
            props.mapping?.forEach(m => {
                if (m.color) {
                    markers.push({ axis: "y", value: m.start !== undefined ? m.start! : m.value!, lineStyle: { stroke: AppMetaTempGlobals.appMetaInstance.getColor(m.color), strokeWidth: 2 } });
                }
            });
        }

        return <div className="wh100">
            <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width, measuredHeight: contentRect.bounds?.height })}>
                {({ measureRef }) => (
                    <div className="flex-container w100" ref={measureRef} style={{ height: Math.floor(this.getHeightBasedOnParent()) }}>
                        {this.renderAnimationLine()}
                        <div className="wh100" >
                            <ResponsiveLineExt renderTooltipContent={this.renderLineChartTooltip}
                                animate={false}
                                layers={[
                                    'grid',
                                    'markers',
                                    'axes',
                                    'areas',
                                    this.areaLayer,
                                    'lines',
                                    this.lineLayer,
                                    'slices',
                                    'points',
                                    'mesh',
                                    'legends'
                                ]}
                                axisBottom={{
                                    legend: "",
                                    tickPadding: 3,
                                    format: value => `${moment(value).format(props.axisDateFormat ? props.axisDateFormat : Utils.dateFormatShorter + " " + Utils.timeFormat)}`
                                }}
                                colors={{ datum: 'color' }}
                                curve={props.curve !== undefined ? props.curve : "monotoneX"}
                                markers={markers} {...props}
                                margin={{ top: 0, bottom: 20, left: props.hasZoomSlider ? props.s.chartPaddingLeft + 10 : 0, right: 5 }}
                                data={props.s.series}
                                pointBorderWidth={undefined}
                            />
                        </div>
                    </div>)}
            </Measure>
            {props.hasZoomSlider && props.s.startDate && props.s.endDate && props.s.startDate < props.s.endDate &&
                <Zoom style={{ rightPadding: 5 }}
                    sliderTooltipFormat={value => moment(value).format(Utils.dateFormatShorter + " " + Utils.timeFormat)}
                    currentStartDate={props.currentStartDate ? props.currentStartDate : props.startDate}
                    currentEndDate={props.currentEndDate ? props.currentEndDate : props.endDate}
                    initialStartDate={props.startDate}
                    initialEndDate={props.endDate}
                    onRangeChange={props.onRangeChange ? props.onRangeChange : (startDate, endDate) => {
                        props.r.setInReduxState({ startDate: startDate, endDate: endDate })
                    }}
                    onButtonAreResize={(width) => this.onSliderBtnResize(width)}
                />
            }
        </div>;
    }
}

export const ResponsiveLineChartRRC = ReduxReusableComponents.connectRRC(ResponsiveLineChartState, ResponsiveLineChartReducers, ResponsiveLineChart);"../..""../nivoExt""../Zoom/Zoom""../../reduxReusableComponents/ReduxReusableComponents""../../AppMetaTempGlobals"