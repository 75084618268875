import React from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { Label } from 'semantic-ui-react';
import { Optional } from '../CompMeta';
import { JoyrideDecoratorProps, JoyrideDecoratorStep, JoyrideDecoratorParams } from './dataStructures';
import { makeDecorator } from '@storybook/addons';

export default makeDecorator({
    // I don't understand why we need this; the type requires it anyway
    name: 'JoyrideDecorator',
    parameterName: 'joyrideDecorator',
    // This means don't run this decorator if the notes decorator is not set
    skipIfNoParametersOrOptions: true,
    wrapper: (getStory, context, po) => {
        const params: JoyrideDecoratorParams = po.parameters as any;
        return <JoyrideDecorator sidebar steps={params.steps}>{getStory(context)}</JoyrideDecorator>
    }
})

export function joyrideDecoratorParams(prefix: string, inputSteps: JoyrideDecoratorStep[]) {
    let teaser = typeof inputSteps[0] === "string" ? inputSteps[0] : "";
    if (teaser) {
        teaser = _msg({ missingKeyStrategy: "RETURN_NULL" }, prefix + "." + teaser);
        if (!teaser) {
            teaser = "";
        }
    }
    teaser = teaser.substr(0, 80);
    teaser += " ...";

    return {
        joyrideDecorator: {
            teaser,
            steps: convertToJoyrideSteps(prefix, inputSteps)
        }
    }

}

/**
 * Convert the input steps to normal joyride steps.
 */
function convertToJoyrideSteps(prefix: string, inputSteps: JoyrideDecoratorStep[]) {
    let steps = [] as Step[];
    for (let inputStep of inputSteps) {
        let step: Step = {} as any;
        steps.push(step);
        let shortKey: Optional<String> = undefined;
        if (typeof inputStep === "string") {
            shortKey = inputStep;
        } else {
            shortKey = inputStep.shortKey; // may exist or not
        }

        // put these here; if the inputStep has one already, it will overwrite
        step.disableBeacon = true;
        step.hideCloseButton = true;

        if (shortKey) {
            step.target = `[data-cy='${prefix}.${shortKey}']`;
            step.content = _msg({ missingKeyStrategy: "RETURN_KEY" }, prefix + "." + shortKey);
        }

        // do this now (and not in the above if) because maybe the user wants to specify dataCy
        // and then override .target or .content
        Object.assign(step, inputStep);
    }
    return steps;
}

/**
 * Problems (work arounded):
 * 
 * 1) first step opens in beacon mode; user must click on beacon to see popup => 'disableBeacon' for each step
 * 2) because of 1), the first step on close => the system exists, but the popup remains "stray" => 'hideCloseButton' for each step
 * 3) on "skip", the internal state is "run = false", even if the controlled "run" = true; hence need to update it to false in callback
 * 4) 'updateStepIndexForced()' speaks for itself.
 */
export class JoyrideDecorator extends React.Component<JoyrideDecoratorProps> {

    state = {
        run: false,
        // is overriden when used in the article page
        localStepIndex: 0,
    }

    protected onJoyrideCallback = (data: CallBackProps) => {
        const { action, index, status, type } = data;

        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            this.setState({ run: false });

            // the commented line below was for close; to stop it to advance; but since we don't
            // use it any more because on the issue w/ the first step => disabled
            // this.updateStepIndex(-1);

            //     // this.props.onFinish?.call(null);
        } else if (action !== ACTIONS.CLOSE && (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND)) {
            const newIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            this.updateStepIndex(newIndex);
            setTimeout(() => {
                const joyrideStillOpen = document.getElementById("react-joyride-portal");
                if (!joyrideStillOpen) {
                    // workaround for ugly bug: in nivo chart, only in yarn start, joyride closes itself when focusing a point in graph
                    this.updateStepIndexForced(newIndex);
                }
            });
        }
    }

    componentDidMount() {
        // for storybook mode, i.e. local index, we want by default to focus the last step
        // to help us when adding anchors
        this.setState({ localStepIndex: this.props.steps.length - 1 });

        // needed beacause tabulator isn't ready
        // CC: I didn't found other solution for this
        // CS: now we don't have tabulator any more; but maybe other components will be "picky" like Tabulator; hence we leve the "timeout"
        setTimeout(() => { this.setState({ run: true }); });
    }

    componentDidUpdate(oldProps: JoyrideDecoratorProps) {
        if (oldProps.steps === this.props.steps) {
            return;
        } // else changed towards another screen => other steps
        this.updateStepIndex(0);
    }

    updateStepIndexForced = (stepIndex: number) => {
        this.setState({ run: false });
        // the first timeout is needed because even if I set the step now, we receive callback
        // which would modify +/-1; so give the system time to "shut down"
        setTimeout(() => {
            this.updateStepIndex(stepIndex);
            // this was needed when we had only 1 timeout; now: I don't know;
            // but anyway, it also helps for the above comment @CC
            setTimeout(() => this.setState({ run: true }));
        });
    }

    protected updateStepIndex = (value: number) => {
        if (this.props.stepIndex !== undefined) {
            this.props.updateStepIndex!(value);
        } else {
            this.setState({ localStepIndex: value });
        }
    }

    render() {
        const props = this.props;
        const stepIndex = props.stepIndex !== undefined ? props.stepIndex : this.state.localStepIndex;

        // give this function to the article page
        // inspired from Joyride.getHelpers()
        props.obtainSetStepIndexOnClickFunction?.(this.updateStepIndexForced);

        const mainContent = <><Joyride
            stepIndex={stepIndex}
            steps={this.props.steps} callback={this.onJoyrideCallback} disableScrollParentFix // w/o this, the histogram chart would increase it's height in an endless loop
            showProgress showSkipButton continuous scrollToFirstStep disableOverlayClose run={this.state.run} spotlightClicks
            styles={{
                options: {
                    primaryColor: '#0E6EB8',
                },
                overlay: { pointerEvents: "none" } // pass through for mouse events
            }}
        /><div className="flex-grow flex-container">{props.children}</div></>;
        if (!props.sidebar) {
            return mainContent;
        } else {
            return (<div className="JoyrideDecorator">
                <div className="JoyrideDecorator_sidebar">{this.props.steps.map((e, i) => (
                    <Label key={i} as="a" circular color={i === stepIndex ? "green" : undefined} onClick={() => this.updateStepIndexForced(i)}>{i + 1}</Label>)
                )}
                </div>
                {mainContent}
            </div>)
        }
    }
}