import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { TextArea } from "semantic-ui-react";
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";

export class TextFieldEditor extends FieldEditor<string, FieldEditorProps> {

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        return <TextArea autoFocus={this.props.autoFocus} ref={this.props.refForFocus} rows={5}
            name={this.props.fieldDescriptor?.getFieldName()} value={this.getValue() || ""}
            onChange={(event, data) => s.setFieldValue(hw, data.value)} {...this.getPropsToForward()} />
    }
}

export default TextFieldEditor;