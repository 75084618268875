import { Formik, FormikConsumer, FormikContextType, FormikErrors, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { Form, Icon, InputOnChangeData } from "semantic-ui-react";
import { ConditionsForPassword, PasswordStrengthBarComponent } from "./PasswordStrengthBarComponent";

type PasswordEditorProps = {
    disabled?: boolean,
    hasInputForCurrentPassword?: boolean,
    ref?: React.RefObject<any>,
    className?: string
    conditionsForPassword?: ConditionsForPassword
}

export type FormValues = {
    currentPassword: string,
    newPassword: string,
    isStrongPassword: boolean,
    newPasswordConfirmation: string
}

export class PasswordEditor extends React.Component<PasswordEditorProps, {showNewPassword: boolean, showConfirmPassword: boolean}> {
    static STRENGTH_ERROR: string = "Strength";
    formikContext!: FormikContextType<any>;

    constructor(props:PasswordEditorProps) {
        super(props);
        this.validate = this.validate.bind(this);
        this.state = { showNewPassword: false, showConfirmPassword: false};
    }

    /**
     * Submit form triggers all the validations. 
     * There could be form inputs invalid (expl: password confirmation) that don't show errors
     * because they haven't been touched by the user yet. So if the user entered a valid new password
     * but didn't touched the password confirmation field yet, the form does't show any errors
     * On submit, Formik mark all the fields as touched  by the user in order for all the validations to be visible 
     * So the parent components should call this, before submitimg if they want to see all the validation errors
     */
    async submitForm() {
        await this.formikContext.submitForm();
    }

    hasErrors(): boolean {
        return Object.keys(this.formikContext.errors).length > 0;
    }

    // Not used. Needed because Formik gives a compile error if we don't pas a submit handler
    onSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    }

    validate(values: FormValues): FormikErrors<FormValues> {
        const errors: any = {};
        
        if (this.props.hasInputForCurrentPassword) {
            if (!values.currentPassword || values.currentPassword == '') {
                errors.currentPassword = _msg("PasswordEditor.requiredCurrentPasswordsError");
            }
        }
        
        if (!values.isStrongPassword) {
            errors.newPassword = PasswordEditor.STRENGTH_ERROR;
        }

        if (values.newPassword != values.newPasswordConfirmation) {
            errors.newPasswordConfirmation = _msg("PasswordEditor.notMatchingPasswordsError");
        }
        return errors;
    }

    showNewPassword(prevShowNewPassword: boolean){
        this.setState({showNewPassword: !prevShowNewPassword})
    }

    showConfirmPassword(prevShowConfirmPassword: boolean){
        this.setState({showConfirmPassword: !prevShowConfirmPassword})
    }

    render() {
        var initialValues:any = {newPassword: "", newPasswordConfirmation: "", isStrongPassword: false };
        if (this.props.hasInputForCurrentPassword) {
            initialValues.currentPassword = "";
        } 
        return (<div className={this.props.className}>
            <Formik<FormValues> initialValues={initialValues}
                onSubmit={this.onSubmit.bind(this)} validate={this.validate} validateOnChange={true}>
                {(props: FormikProps<FormValues>) => {
                    return (<>
                        <Form>
                            {this.props.hasInputForCurrentPassword ?
                            <Form.Input required label={_msg("PasswordEditor.currentPassword.label")} type='password'
                                placeholder={_msg("PasswordEditor.currentPassword.label")}
                                value={props.values.currentPassword}
                                onChange={(event: any, data: InputOnChangeData) => { props.setFieldValue("currentPassword", data.value); }}
                                onFocus={(event: any) => { if (!props.touched.newPassword) { props.setFieldTouched("currentPassword", true) } }}
                                error={props.touched.currentPassword && props.errors.currentPassword ? props.errors.currentPassword : null}
                                disabled={this.props.disabled} /> : null
                            }
                            <Form.Input icon required label={_msg("PasswordEditor.newPassword.label")} type={this.state.showNewPassword ? 'text' : 'password'}
                                placeholder={_msg("PasswordEditor.newPassword.label")}
                                value={props.values.newPassword}
                                onChange={(event: any, data: InputOnChangeData) => { props.setFieldValue("newPassword", data.value); }}
                                onFocus={(event: any) => { if (!props.touched.newPassword) { props.setFieldTouched("newPassword", true) } }}
                                // In case is an error (STRENGTH_ERROR), mark the input as having errors, but don't show an explicit message. 
                                // The strength bar indicator component is already explaining what is the problem with the password 
                                error={props.touched.newPassword && props.errors.newPassword ? true : false}
                                disabled={this.props.disabled} >
                                <input autoComplete='off'/>
                                <Icon name={this.state.showNewPassword ? 'eye slash' : 'eye'} link onClick={()=>this.showNewPassword(this.state.showNewPassword)}/>
                            </Form.Input>

                            <Form.Field disabled={this.props.disabled}>
                                <PasswordStrengthBarComponent password={props.values.newPassword} onStrengthChanged={(isStrongPassword: boolean) => 
                                    { props.setFieldValue("isStrongPassword", isStrongPassword); }} conditionsForPassword={this.props.conditionsForPassword}/>
                            </Form.Field>

                            <Form.Input icon required label={_msg("PasswordEditor.newPasswordConfirmation.label")} type={this.state.showConfirmPassword ? 'text' : 'password'}
                                placeholder={_msg("PasswordEditor.newPasswordConfirmation.placeholder")}
                                value={props.values.newPasswordConfirmation}
                                onChange={(event: any, data: InputOnChangeData) => { props.setFieldValue("newPasswordConfirmation", data.value); }}
                                onFocus={(event: any) => { if (!props.touched.newPasswordConfirmation) { props.setFieldTouched("newPasswordConfirmation", true) } }}
                                error={props.touched.newPasswordConfirmation && props.errors.newPasswordConfirmation ? props.errors.newPasswordConfirmation : null}
                                disabled={this.props.disabled}>
                                <input/>
                                <Icon name={this.state.showConfirmPassword ? 'eye slash' : 'eye'} link onClick={()=>this.showConfirmPassword(this.state.showConfirmPassword)}/>
                            </Form.Input>
                        </Form>
                        <FormikConsumer>{context => { this.formikContext = context; return null; }}</FormikConsumer>
                    </>);
                }}
            </Formik>
        </div>);
    }
}