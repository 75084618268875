import React, { ReactNode } from "react";
import { ScriptableUiHighlightWrapper } from "./ScriptableUiHighlightWrapper";
import { ScriptableUiInterceptor } from "./ScriptableUiInterceptor";
import { ScriptableUiContext } from "./ScriptableUiContext";
import ReactDOM from 'react-dom';
import { ERROR_MESSAGE_LIB_INCLUDED_MULTIPLE_TIMES, RecordPlayModal } from "./RecordPlayModal";
import { Capture } from "./Capture";
import { ConvertReturnToPromiseForAll, FoundationUtils } from "@famiprog-foundation/utils";
import { waitFor_options } from "@famiprog-foundation/utils/src/lib";
import { Scriptable } from "./v2/Scriptable";
import { ScriptableContainer } from "./v2/ScriptableContainer";

export type ScriptableUiImpl<O> = Omit<O, keyof ScriptableUi<any>>;

export interface ScriptableUiProps<O> {
    id: string;
    children: (o: ScriptableUiProxy<O>) => ReactNode;
    implementation: ScriptableUiImpl<O>;
}

type ScriptableUiProxy<O> = ConvertReturnToPromiseForAll<Omit<O, keyof ScriptableUi<any>>>

export type WithHW<A> = {
    [K in keyof A]: WithHWForOne<A[K]>
}

type WithHWForOne<T> = T extends (...args: infer P) => infer R ? (inserted: ScriptableUiHighlightWrapper, ...args: P) => R : never;

export function castWithHw<A>(a: A) {
    return a as unknown as WithHW<A>;
}

interface ScriptableUi_get_options {
    waitForOptions: waitFor_options;
}

class ScriptableUiObtainer {

    protected async getScriptableUi(key: string, options?: ScriptableUi_get_options) {
        let result!: ScriptableUi<any>;
        await FoundationUtils.waitForWhile(() => !(result = ScriptableUi.instances[key]),
            { ...options?.waitForOptions, onTimeoutReached: originalMessage => { throw new Error(originalMessage + ` Cannot find instance w/ key = ${key}. It is not (yet?) mounted.`); } });
        return result;
    }

    get<T extends ScriptableUi<any>>(clazz: abstract new (...s: any) => T, id: string, highlighWrapperId?: string | number, options?: ScriptableUi_get_options): ConvertReturnToPromiseForAll<T> {
        // @ts-ignore
        const key = ScriptableUi.getKey(new clazz().getComponentName(), id);
        const promise = this.getScriptableUi(key, options);

        // we use this proxy to wrap the function call w/ setting/unsetting the corresponding
        // HighlightWrapper global
        return new Proxy({}, {
            get(target: any, p, receiver) {
                return async (...args: any) => {
                    // if the SUI is mounted, this async result comes pretty quickly (but not sync; so at least one/a few render cycles are needed)
                    // if not yet mounted, the waiting logic wil apply
                    const scriptableUi = await promise;
                    // @ts-ignore
                    if (!(scriptableUi[p] instanceof Function)) {
                        const message = `Attempting to access the property = ${String(p)} that is not a function, for scriptableUi w/ id = ${scriptableUi.qualifiedId}`;
                        // it took me a bit to understand where "then" comes from. So a hint in the error is worth.
                        if ("then" === p) {
                            throw new Error(message + `
Heads up: the function being called is 'then'. This may come from an 'await' because + a recorded instruction of type:

await s.get(...).doSomething()

was edited by hand into something like:

await s.get(...)
`);
                        }
                        throw new Error(message);
                    }
                    // @ts-ignore
                    const f = scriptableUi[p] as Function;
                    try {
                        if (highlighWrapperId !== undefined) {
                            // we make available the HW; maybe the interceptor in PLAY mode will want
                            // to highlight it
                            scriptableUi.getHighlighWrapper(highlighWrapperId).setCurrent();
                        }
                        // In theory, people will access only the functions defined by the user; i.e. not e.g. setState()
                        // And they are async. Let's add a fail safe, which is not a bullet proof solution
                        const result = f.apply(target, args);
                        if (!(result instanceof Promise)) {
                            throw new Error(`Attempting to call the function = ${String(p)}. Because this function doesn't return a Promise, it doesn't seem to be OK.`);
                        }
                        return await result;
                    } finally {
                        if (highlighWrapperId !== undefined) {
                            // was converted to static because 1/ it doesn't need the instance and
                            // 2/ the ScriptableUi may have disappeared in the mean type (e.g. a context menu closed)
                            ScriptableUiHighlightWrapper.unsetCurrent();
                        }
                    }
                }
            }
        });
    }

    /**
     * The name `p` comes from "proxy". Its a short one, to reduce pollution in tests, where there are tenths and tenths of
     * such lines.
     */
    async p(qualifiedId: string, scriptableId: string | number, options?: ScriptableUi_get_options) {
        let scriptableContainer!: ScriptableContainer;
        await FoundationUtils.waitForWhile(() => !(scriptableContainer = ScriptableContainer.instances[qualifiedId]),
            { ...options?.waitForOptions, onTimeoutReached: originalMessage => { throw new Error(originalMessage + ` Cannot find ScriptableContainer w/ qualifiedId = ${qualifiedId}. It is not (yet?) mounted.`); } });
        return scriptableContainer.getScriptable(scriptableId).proxy;
    }

}

export class ScriptableUi<C, P extends ScriptableUiProps<C> = ScriptableUiProps<C>, S = {}> extends React.Component<P, S> {

    static contextType = ScriptableUiContext;
    declare context: React.ContextType<typeof ScriptableUiContext>

    static SINGLETON = "$";
    static instances: { [componentNameAndKey: string]: ScriptableUi<any> } = {};

    static playing = false;

    /**
     * We force the user to execute script instructions in a callback, in order to be able to set
     * `playing` to `true` at the beginning, and to `false` at end. We need this flag because of the following
     * example: `<Dropdown onOpen={() s.onOpen()} />. 1/ The script invokes `s.onOpen()`. But the dropdown 2/ emits 
     * again the "onOpen" event. Hence some kind of a loop is created. 
     * 
     * We use `playing` to ignore calls of type 2/.
     * 
     * @see ScriptableUiHighlightWrapper.render()
     */
    static async play(cb: (s: ScriptableUiObtainer) => Promise<void>) {
        try {
            ScriptableUi.playing = true;
            await cb(new ScriptableUiObtainer());
        } finally {
            ScriptableUi.playing = false;
        }
    }

    // CS: since we've introduced qualified ids, this is not any more needed; we should maybe remove it
    static getKey(componentName: string, id: string) {
        return id;
    }

    static extendImpl<T, U>(original: T, callback: (original: T) => U): T & U {
        return { ...original, ...callback(original) };
    }

    static openRecordPlayUserInterface() {
        let hostDiv = document.getElementById("scriptableUiHostDiv");
        if (!hostDiv) {
            hostDiv = document.createElement("div");
            hostDiv.id = "scriptableUiHostDiv";
            document.body.appendChild(hostDiv);
        }

        ReactDOM.render(<RecordPlayModal onClose={() => ReactDOM.render(<></>, hostDiv)} />, hostDiv);
    }

    static showErrorMessageRegardingMultipleLibs() {
        console.error(ERROR_MESSAGE_LIB_INCLUDED_MULTIPLE_TIMES);
        globalThis.scriptableUiErrorMessageLibIncludedMultipleTimes = true;
    }

    qualifiedId!: string;
    protected highlighWrappers: { [id: string | number]: ScriptableUiHighlightWrapper } = {};

    constructor(props: P, context: React.ContextType<typeof ScriptableUiContext>) {
        super(props);
        if (!props) {
            // this class is also instantiated in a non-React context (in order to .getComponentName())
            return;
        }
        const localId = `[${this.props.id}:${this.getComponentName()}]`
        this.qualifiedId = context ? context.qualifiedId + "/" + localId : localId;
    }

    addHighlighWrapper(highlighWrapper: ScriptableUiHighlightWrapper) {
        if (this.highlighWrappers[highlighWrapper.props.id]) {
            throw new Error(`For id = ${highlighWrapper.props.id}, there is already another HighlighWrapper.`);
        }
        this.highlighWrappers[highlighWrapper.props.id] = highlighWrapper;
    }

    removeHighlighWrapper(highlighWrapper: ScriptableUiHighlightWrapper) {
        if (!this.highlighWrappers[highlighWrapper.props.id]) {
            throw new Error(`For id = ${highlighWrapper.props.id}, there nothing to remove.`);
        }
        delete this.highlighWrappers[highlighWrapper.props.id];
    }

    getHighlighWrapper(id: string | number) {
        const result = this.highlighWrappers[id];
        if (!result) {
            throw new Error(`For id = ${id}, we didn't find anything.`);
        }
        return result;
    }

    getComponentName(): string {
        throw new Error("Please implement.")
    }

    protected addClassToGlobalScope() {
        const spl = this.getComponentName().split(".");
        // create the namespace
        // @ts-ignore
        if (!globalThis[spl[0]]) {
            // @ts-ignore
            globalThis[spl[0]] = {};
        }

        // add the class to the namespace
        // @ts-ignore
        if (!globalThis[spl[0]][spl[1]]) {
            // @ts-ignore
            globalThis[spl[0]][spl[1]] = this.constructor;
        }
    }

    componentDidMount(): void {
        this.addClassToGlobalScope();
        const key = ScriptableUi.getKey(this.getComponentName(), this.qualifiedId);
        if (ScriptableUi.instances[key]) {
            throw new Error(`For key = ${key}, a component instance already exists. Did you provide an 'id'? If yes, please review the code to understand the cause of the collision.`);
        }
        ScriptableUi.instances[key] = this;
        this.wrapFunctions();
    }

    protected wrapFunctions(): void {
        for (let prop in this.props.implementation) {
            const newFunctionAtComponentMount = this.props.implementation[prop] as unknown as Function;
            if (!(newFunctionAtComponentMount instanceof Function)) {
                throw new Error("The 'implementation' object should contain only functions. Property '" + prop + "' is: " + newFunctionAtComponentMount);
            }

            // initially, there was a check that looked if this[prop] existed. However, since we started to recommend abstract
            // functions, such a check is no more possible at runtime.

            // newFunctionAtComponentMount is not the same for the whole lifecycle of the component. Practically it changes on each
            // render. Hence it's important to call the latest one
            const wrappedFunction = (...args: any[]) => {
                if (args[0] instanceof ScriptableUiHighlightWrapper) {
                    args = args.slice(1);
                }
                // @ts-ignore
                this.props.implementation[prop].apply(null, args);
            };
            // @ts-ignore
            this[prop] = this.wrapFunction(prop, wrappedFunction);
        }
    }

    protected wrapFunction(functionName: string, originalFunction: Function): Function {
        return async (...args: any[]) => {
            if (!ScriptableUiInterceptor.INSTANCE) {
                originalFunction.apply(null, args);
                return;
            }
            let hw: ScriptableUiHighlightWrapper;
            if (args[0] instanceof ScriptableUiHighlightWrapper) {
                hw = args[0];
                args = args.slice(1);
            } else {
                hw = ScriptableUiHighlightWrapper.getCurrent();
            }
            const capture = new Capture(this.getComponentName(), this.qualifiedId, functionName, args, hw.props.id);
            if (await ScriptableUiInterceptor.INSTANCE.onIntercept(capture, this, hw)) {
                originalFunction.apply(null, args);
            }
        }
    }

    componentWillUnmount(): void {
        delete ScriptableUi.instances[ScriptableUi.getKey(this.getComponentName(), this.qualifiedId)];
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any): void {
        if (prevProps.id !== this.props.id) {
            throw new Error(`Attempting to change the id (from ${prevProps.id} to ${this.props.id}). The id cannot be changed!`);
        }
    }

    render() {
        return <ScriptableUiContext.Provider value={this}>
            {/* @ts-ignore */}
            {this.props.children(this)}
        </ScriptableUiContext.Provider>
    }

}

type ScriptableUiWithAnotherName = typeof ScriptableUi;

declare global {
    var ScriptableUi: ScriptableUiWithAnotherName;
    var scriptableUiOpen: () => void;
    var scriptableUiErrorMessageLibIncludedMultipleTimes: boolean | undefined;
    var scriptableUiInterceptor: ScriptableUiInterceptor | undefined;
}

if (globalThis.ScriptableUi) {
    // We delegate to the already existing class on purpose. For @famiprog-foundation/scriptable-ui-app (the demo + tests app), the message
    // is displayed, because scriptable-ui is also included in tests-are-demo. But the case is known/controlled/validated; no bad
    // things will happen. So we want to cancel the message. Especially because it will be shown in the demo app, so the first things that our
    // users will see.
    globalThis.ScriptableUi.showErrorMessageRegardingMultipleLibs();
} else {
    console.log("ScriptableUi lib is included in this project. From the Dev tools > Console, type to open the UI: scriptableUiOpen()");
    globalThis.scriptableUiOpen = ScriptableUi.openRecordPlayUserInterface;
    globalThis.ScriptableUi = ScriptableUi;
}