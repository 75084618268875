import React from "react";
import { Scriptable } from "./Scriptable";
import { ScriptableUiContext } from "../ScriptableUiContext";
import { ScriptableContainerContext } from "./ScriptableContainerContext";

interface Props {
    id: string | number;
}

export class ScriptableContainer extends React.Component<Props> {

    static contextType = ScriptableContainerContext;
    declare context: React.ContextType<typeof ScriptableContainerContext>

    static instances: { [qualifiedId: string]: ScriptableContainer } = {};

    qualifiedId!: string;
    protected scriptables: { [id: string | number]: Scriptable } = {};

    constructor(props: Props, context: React.ContextType<typeof ScriptableUiContext>) {
        super(props);
        this.qualifiedId = context ? (context.qualifiedId + "/" + props.id) : String(props.id);
    }

    addScriptable(scriptable: Scriptable) {
        if (this.scriptables[scriptable.props.id]) {
            throw new Error(`In ScriptableContainer w/ qualifiedId = ${this.qualifiedId}, there is already another Scriptable for id = ${scriptable.props.id}`);
        }
        this.scriptables[scriptable.props.id] = scriptable;
    }

    removeScriptable(scriptable: Scriptable) {
        if (!this.scriptables[scriptable.props.id]) {
            throw new Error(`For id = ${scriptable.props.id}, there nothing to remove.`);
        }
        delete this.scriptables[scriptable.props.id];
    }

    getScriptable(id: string | number) {
        const result = this.scriptables[id];
        if (!result) {
            throw new Error(`For id = ${id}, we didn't find anything.`);
        }
        return result;
    }

    componentDidMount(): void {
        if (ScriptableContainer.instances[this.qualifiedId]) {
            throw new Error(`For qualified id = ${this.qualifiedId}, another ScriptableContainer instance already exists. This usually happens because this component is used several times from parents that don't have <ScriptableContainer>. ` 
                + `Review/debug your code in order to understand who uses this component. And then add <ScriptableContainer> to those "whos" (and if needed, to their parents, parents of parents, etc.).`);
        }
        ScriptableContainer.instances[this.qualifiedId] = this;
    }

    componentWillUnmount(): void {
        delete ScriptableContainer.instances[this.qualifiedId];
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.id !== this.props.id) {
            throw new Error(`Attempting to change the id (from ${prevProps.id} to ${this.props.id}). The id cannot be changed!
If you think that changing the id is needed/normal, please discuss. The code could be adjusted to support this use case.`);
        }
    }

    render() {
        return <ScriptableContainerContext.Provider value={this}>
            {this.props.children}
        </ScriptableContainerContext.Provider>
    }

}