import gql from "graphql-tag"

export const CALCULATE_CURRENT_VALUE = gql`
    query dashboardService_calculateForRecordsGetValue($dashboardId: Long!, $widgetIdIfReferenced: String!, $filter: FilterInput) {
        dashboardService_calculateForRecordsGetValue(dashboardId:$dashboardId, widgetIdIfReferenced:$widgetIdIfReferenced, filter:$filter) {
            a b
        }
    }`

export const GET_SAVED_VALUES = gql`
    query dashboardCalculateForRecordsValueService_findByFilter1($params: FindByFilterParamsInput) {
        dashboardCalculateForRecordsValueService_findByFilter(params: $params) {
            results {
                savedValues
            }
        }
    }`

export const GET_SAVED_DATA = gql`
    query dashboardCalculateForRecordsValueService_findByFilter2($params: FindByFilterParamsInput) {
        dashboardCalculateForRecordsValueService_findByFilter(params: $params) {
            results {
                id savedValues records timestamp
            }
        }
    }`