import { ReactNode, useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";

export interface FeatureListProps {
    items?: any[];
    onSelect: (item: any) => void;
    renderItem: (item: any) => ReactNode;
    getIdForLocalStorage?: (item: any) => string;
    localStorageKey?: string;
    selectedImposed?: any;
}

export const FeaturesList = (props: FeatureListProps) => {
    const [hovered, setHovered] = useState(-1);
    const [selected, setSelected] = useState(-1);

    const getLabel = (item: any) => {
        if (props.getIdForLocalStorage) {
            return props.getIdForLocalStorage(item);
        } else if (typeof item === "string") {
            return item;
        } else {
            throw new Error("item is not string; please provide getIdForLocalStorage");
        }
    }

    const select = (item: any, i: number, firstCall = false) => {
        setSelected(i);
        props.onSelect(item);
        if (!firstCall && props.localStorageKey) {
            localStorage.setItem(props.localStorageKey, getLabel(item));
        }
    }

    useEffect(() => {
        if (props.items && props.localStorageKey) {
            const ls = props.selectedImposed || localStorage.getItem(props.localStorageKey);
            if (!ls) { return; }
            for (let i = 0; i < props.items?.length; i++) {
                if (ls === getLabel(props.items[i])) {
                    select(props.items[i], i, true);
                    return;
                }
            }
        }
    // because items is filled in after call to server
    }, [props.items]);

    return (<Segment.Group>
        {props.items?.map((item, i) => (
            <Segment key={i} secondary={i === hovered} tertiary={i === selected}
                onMouseEnter={() => setHovered(i)} onMouseLeave={() => setHovered(-1)} onClick={() => select(item, i)}>{props.renderItem(item)}</Segment>)
        )}
    </Segment.Group>)
}