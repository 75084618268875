import { relative } from "path";
import React, { ReactNode, useRef, useState } from "react";

type ContainerWithHeaderContextProps = {
    titleAreaDiv: HTMLDivElement;
    titleAreaDivAlreadyFilledIn?: boolean    
}

export const ContainerWithHeaderContext = React.createContext<ContainerWithHeaderContextProps>({} as any);

export function ContainerWithHeader(props: { children: ReactNode, childTitleAreaLeft?: ReactNode, childTitleAreaRight?: ReactNode, titleAreaClassName?: string, displayChildrenInRow?: boolean, headerClassName?: string}) {
    const [div, setDiv] = useState<HTMLDivElement | null>();

    return (<>
        <div className={`ContainerWithHeader_header ${props.headerClassName}`}>
            {props.childTitleAreaLeft && props.childTitleAreaLeft}
            <div ref={div => setDiv(div)} style={{ flex: "1 1"}} className={props.titleAreaClassName} />
            {props.childTitleAreaRight && props.childTitleAreaRight}
        </div>
        {div && <ContainerWithHeaderContext.Provider value={{ titleAreaDiv: div }}>
            {props.displayChildrenInRow ? <div className="flex-container-row flex-grow">{props.children}</div> : props.children}            
        </ContainerWithHeaderContext.Provider>}
    </>);
}