import React from "react";
import { Popup, Segment } from "semantic-ui-react";

type AuditFieldsExtendedViewType = { content: any, openFor: string, onPopupClose: () => void };

export class AuditFieldsExtendedView extends React.Component<AuditFieldsExtendedViewType> {
    constructor(props: AuditFieldsExtendedViewType) {
        super(props);
        this.onPopupClose = this.onPopupClose.bind(this);
    }

    onPopupClose() {
        this.props.onPopupClose();
    }

    render() {
        if (this.props.openFor === "") {
            return null;
        }

        const element = document.getElementsByClassName(this.props.openFor)[0] as HTMLElement;

        if (!element) {
            return null;
        }

        return (
            <Popup
                on="click"
                position="top right"
                wide="very"
                content={<Segment>{this.props.content}</Segment>}
                open={this.props.openFor !== ""}
                context={element}
                onClose={this.onPopupClose}
                closeOnEscape />
        );
    }
}