import { TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { TestsAreDemoSlave } from "./TestsAreDemoSlave";

const LAST_SLIDE_INDEX = -1;
const SLIDE_REGEX = /{s([0-9]+(?:\.[0-9]+)?)+}/; 

export class TestsAreDemoSlideShow {
    text: string;
    lastSlideIndex: number | null = null;
    nextSlideIndex: number | null = null;
    protected master!: TestsAreDemoMaster;

    constructor(text: string) {
        this.text = text;
        if (!window.parent?.testsAreDemoMaster) {
            throw new Error("Looking at the parent of this IFrame to find TestsAreDemoMaster. But was not found.");
        }
        this.master = window.parent.testsAreDemoMaster;
    }

    slide(index: number) {
        // enable slideshow only in step by step mode
        if (!this.master.state.stepByStepMode) {
            return;
        }

        // For the first slide: find the index of the slide and exclude the first slide from the text.
        // We will always keep the index of the next slide and use it to retrieve the text until the next slide.
        // E.g: text: '{s1} content 1 {s2} content 2 {s3} ...' => nextSlideIndex: 1, text: content 1 {s2} content 2 {s3} ...'
        if (!this.nextSlideIndex) {
            let nextMatchingSlide = this.text.match(SLIDE_REGEX);
            if (!nextMatchingSlide) {
                throw new Error("Could not find any slides.");
            }
            this.nextSlideIndex = Number(nextMatchingSlide[1]).valueOf();
            this.text = this.splitTextBySlidePattern(nextMatchingSlide)[1];
        }

        if (index !== this.nextSlideIndex) {
            // it is possible that the wanted slide does not exist
            if (!this.text.match(`{s${index}`) && index !== this.lastSlideIndex) {
                throw new Error(`You are trying to access slide: ${index}, but it does not exist.`)
            } else {
                throw new Error(`Last accesed slide: ${this.lastSlideIndex}. You should be now accesing slide: ${this.nextSlideIndex}. But you are accesing slide: ${index}.`);
            }
        }

        // find the next slide
        let nextMatchingSlide = this.text.match(SLIDE_REGEX);
        if (!nextMatchingSlide) {
            // this is the last slide
            this.updateSlideProperties(this.text, this.nextSlideIndex, "", LAST_SLIDE_INDEX);
            return;
        }

        const matchingSlideIndex = Number(nextMatchingSlide[1]).valueOf();
        if (matchingSlideIndex < this.nextSlideIndex) {
            throw new Error(`You are trying to access slide: ${index}. But we have found slide ${matchingSlideIndex} after slide ${index}. The slides should be in ascending order.`);
        }

        // Split the text using the next matching slide.
        // The string before the next slide is the content of the slide.
        // The remaining string after slide is the new text.
        let tokens = this.splitTextBySlidePattern(nextMatchingSlide);
        this.updateSlideProperties(tokens[0], this.nextSlideIndex, tokens[1], matchingSlideIndex);
    }

    splitTextBySlidePattern(slidePattern: RegExpMatchArray) {
        const tokens = this.text.split(slidePattern[0]);
        if (tokens.length > 2) {
            throw new Error(`Slide ${slidePattern[1]} appears multiple times.`);
        }
        return tokens;
    }

    updateSlideProperties(lastSlideCaptured: string, lastSlideIndex: number, text: string, nextSlideIndex: number ) {
        TestsAreDemoSlave.INSTANCE.currentComment = lastSlideCaptured;
        TestsAreDemoSlave.INSTANCE.currentCommentSlideIndex = this.lastSlideIndex = lastSlideIndex;
        this.text = text;
        this.nextSlideIndex = nextSlideIndex;
    }

}