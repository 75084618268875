import { getFieldLastUpdateDate, ScaleToContainer, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { AuditEntriesPopup, AuditEntriesPopupRRC } from "@crispico/foundation-react/pages/Audit/AuditEntriesPopup";
import { uniqueId } from "lodash";
import moment from "moment";
import React, { RefObject } from "react";
import ReactDOM from 'react-dom';
import { Header, Icon, Popup, Segment } from "semantic-ui-react";
import { WidgetConfig, WidgetProps } from "../../WidgetWrapper";

export type FieldWidgetConfig  = {
    entityName: string,
    field: string,
    fontSize?: string,    
    titleHasFieldName?: boolean
} & WidgetConfig;

export type FieldWidgetProps = WidgetProps<FieldWidgetConfig>;

export class FieldWidget extends React.Component<FieldWidgetProps> {
    fieldWidgetValueRef: RefObject<any>;
    refAuditEntriesPopup = React.createRef<AuditEntriesPopup>();

    constructor(props: FieldWidgetProps) {
        super(props);
        this.fieldWidgetValueRef = React.createRef();
    }

    protected createButtonForAuditEntries(entityName: string, fieldName: string, entityId: number) {
        return <Icon link name="chart line" size="large" onClick={(e: MouseEvent) => this.refAuditEntriesPopup.current!.open(entityName, fieldName, entityId, [e.clientX, e.clientY])} />;
    }

    render() {
        const props = this.props;
        try {
            let lastUpdateDate;
            let fd = null;
            let fdc = null;
            let value = null;
            if (props.entityForAttachedDashboard && props.widgetConfig.entityName) {
                fdc = entityDescriptors[props.widgetConfig.entityName]?.getFieldDescriptorChain(props.widgetConfig.field);
                fd = fdc[fdc.length - 1];
                lastUpdateDate = getFieldLastUpdateDate(props.entityForAttachedDashboard, props.widgetConfig.field);
            }
            
            let result: any = _msg("general.error");
            let muLabel: string | undefined;
            let textColor: string | undefined;
            let backgroundColor: string | undefined;
            let isObject: boolean = false;
            if (fd && fdc) {
                value = props.entityForAttachedDashboard;
                for (let i = 0; i < fdc.length - 1; i++) {
                    value = value[fdc[i].getFieldName()];
                    if (!value) {
                        break;
                    }
                }
                if (value) {
                    value = (fd?.renderField(value) as any).props.value;
                }
                if (value !== null && value !== undefined) {
                    result = value;
                    if (typeof value === "string") {
                        if (value.length === 0 || value === "null") {
                            result = _msg("general.notAvailable");
                        }
                    } else if (typeof value === "number") {
                        result = fd.getFieldValueConvertedToMeasurementUnit(value, 1);
                    } else if (typeof value === "object") {
                        isObject = true;
                    }
                    const fieldColors = fd.getFieldColors(value);
                    if (!fieldColors?.bulletColor) {
                        textColor = fieldColors?.color;
                        backgroundColor = fieldColors?.backgroundColor;
                    }
                } else {
                    result = _msg("general.notAvailable");
                }
                muLabel = fd.getMeasurementUnitLabel();
            }

            return <><Segment basic className="flex-container flex-grow no-padding-top-bottom" style={{ padding: '5px' }}>
                <Popup position="top center"
                    popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                    trigger={<div className="flex-container-row flex-grow FieldWidget_lineHeight" ref={this.fieldWidgetValueRef} style={{ backgroundColor: backgroundColor, alignItems: 'baseline' }}>
                        <ScaleToContainer className="FieldWidget_bigText flex-center" fixedFontSize={props.widgetConfig.fontSize}><div style={{color: textColor, backgroundColor: backgroundColor}}>
                            {isObject ? fd?.renderField(props.entityForAttachedDashboard) : fd ? fd.wrapComponent(value, result) : result}
                        </div></ScaleToContainer>
                        {muLabel ? <Header size="small" className="no-margin">{muLabel}</Header> : null}
                    </div>}
                    content={result + (muLabel ? (' ' + muLabel) : '')}>
                </Popup>
                {lastUpdateDate ? <Popup position="top center"
                    popperModifiers={[{ name: "preventOverflow", options: { boundariesElement: "offsetParent" } }]}
                    trigger={<Header size="tiny" className="no-margin white-space-normal" color='grey' style={{ fontWeight: 'normal' }}>{moment(lastUpdateDate).fromNow()}</Header>}
                    content={moment(lastUpdateDate).format(Utils.dateTimeFormat)}>
                </Popup> : null}
            </Segment>
                {fd && props.buttonBarRef?.current && ReactDOM.createPortal(this.createButtonForAuditEntries(props.widgetConfig.entityName, props.widgetConfig.field, props.entityForAttachedDashboard.id), props.buttonBarRef.current)}
                <AuditEntriesPopupRRC id={uniqueId("auditEntriesPopup_FieldWidget")} ref={this.refAuditEntriesPopup} />
            </>
        } catch (e) {
            return <>{_msg("general.error")}</>
        }

    }
}
"../../../../..""../../../../../entity_crud/entityCrudConstants""../../../../Audit/AuditEntriesPopup"