import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTablePage, EntityTablePageProps, EntityTablePageReducers, EntityTablePageState } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { RoleEditorPage, sliceRoleEditorPage } from "@crispico/foundation-react/pages/role/RoleEditorPage";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export let roleEntityDescriptor: EntityDescriptor;
export let roleToUserEntityDescriptor: EntityDescriptor;

const ROLE_TABLE_ROW_HEIGHT = 90;

class RoleTablePage extends EntityTablePage<EntityTablePageProps> {

    protected getTableProps() {
        return { rowHeight: ROLE_TABLE_ROW_HEIGHT };
    }
}

const RoleTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, RoleTablePage);

addAfterStartupRunnable(() => {
    class RoleEntityDescriptor extends EntityDescriptor {
        constructor() {
            super({
                name: "Role",
                icon: "bookmark",
                miniFields: ["name"]
            });
        }

        renderTable() {
            return <RoleTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
        }
    }

    // ROLE ENTITY DESCRIPTOR
    roleEntityDescriptor = addEntityDescriptor(new RoleEntityDescriptor()
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "name", type: FieldType.string })
        .addFieldDescriptor({ name: "permissions", type: FieldType.string, enabled: false })
    );

    roleEntityDescriptor.infoEditor.slice = sliceRoleEditorPage.setEntityDescriptor(roleEntityDescriptor);
    roleEntityDescriptor.infoEditor.wrappedComponentClass = RoleEditorPage;

    // ROLE TO USER ENTITY DESCRIPTOR
    roleToUserEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
        name: "UserToOrganization",
        icon: "bookmark",
        miniFields: ["id"]
    })
        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "user", type: "User" })
        .addFieldDescriptor({ name: "role", type: "Role" })
        .addFieldDescriptor({ name: "organization", type: "Organization" })
    );
})"../../entity_crud/entityCrudConstants""../../entity_crud/EntityDescriptor""../../entity_crud/EntityTablePage""../../entity_crud/FieldType""./RoleEditorPage""../../reduxReusableComponents/ReduxReusableComponents"