import { EntityDescriptor, EntityEditorPage, EntityEditorPageProps } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { TabRouterPane } from "../TabbedPage/TabbedPage";

export class DashboardCalculateForRecordsWidgetEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({name: "DashboardCalculateForRecordsWidget"});
    }

    protected customize() {
        this.infoEditor.wrappedComponentClass = class extends EntityEditorPage<EntityEditorPageProps> {
            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                return [...super.getExtraTabPanes(), this.createOneToManyTabPane("DashboardCalculateForRecordsValue", "widget")];
            }
        }
    }
}

export class DashboardCalculateForRecordsValueEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({name: "DashboardCalculateForRecordsValue"});
    }

    protected customize() {
        this.addFieldDescriptor({ name: "records", type: FieldType.string, prepareValue: (value:string) => value.replace(/,(?=[^\s])/g, ", ") });
    }
}
"../..""../../entity_crud/FieldType"