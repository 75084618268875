import { InputProps } from "semantic-ui-react";
import { ScriptableUiFieldEditor } from "./FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { NumberFieldEditor, NumberFieldEditorProps } from "./NumberFieldEditor";

export type ProgressFieldEditorProps = NumberFieldEditorProps;

export class ProgressFieldEditor<P extends ProgressFieldEditorProps = ProgressFieldEditorProps> extends NumberFieldEditor<number, P> {

    constructor(props: P) {
        super(props);

        this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
            setFieldValue: (value: any) => {
                let percentValue = Number(value) > 100 ? 100 : Number(value) < 0 ? 0 : Number(value);
                original.setFieldValue(Number(percentValue));
            }
        }));

    }

    protected getInputProps(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper): InputProps | undefined {
        return { ...super.getInputProps(s, hw), min: 0, max: 100, icon: "percent", iconPosition: "left" };
    }
    
}