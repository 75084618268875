import React from "react";
import { Utils } from "../..";
import { FieldRendererProps } from "../fieldRenderersEditors";

export type ColorFieldRendererProps = FieldRendererProps & {
    style?: React.CSSProperties
};

export class ColorFieldRenderer<P extends ColorFieldRendererProps, S = {}> extends React.Component<P, S> {
    
    /**
     * @see FieldDescriptor.castAdditionalFieldRendererProps An explicit constructor is needed because of the glitch.
     */
    constructor(props: P) {
        super(props);
    }
    
    render() {
        return <div className="ColorRenderer" style={{
            width: "100%",
            height: "100%",
            ...this.props.style,
            backgroundColor: this.props.value ?
                (this.props.fieldDescriptor.colorType === "number" ? Utils.convertColorToHex(this.props.value) : this.props.value) :
                undefined
        }} />
    }
}

export default ColorFieldRenderer;