import { getByTestId } from "@testing-library/react/pure";
import { tad } from "../TestsAreDemoFunctions";
import { TestsAreDemoSlave } from "../TestsAreDemoSlave";
import { Capture, UiApi } from "./UiApi";

export class UiApiHelper {

    protected static _INSTANCE: UiApiHelper;

    static get INSTANCE(): UiApiHelper {
        if (window.testsAreDemoMaster) {
            throw new Error("UiApis should only exist in TADSlave. Trying to access it from TADMaster.");
        }
        if (!UiApiHelper._INSTANCE) {
            UiApiHelper._INSTANCE = new UiApiHelper();
        }
        return UiApiHelper._INSTANCE;
    }

    create<P>(componentName: string) {
        return (id?: any) => {
            id = id || "";
            return new UiApi<P>(this, componentName, id!)
        }
    }

    async onBeforeCallImplementation(fromWithinComponent: boolean, capture: Capture): Promise<boolean> {
        if (!TestsAreDemoSlave._INSTANCE) {
            return true;
        }
        let domElement: HTMLElement;
        try {
            domElement = getByTestId(document.body, capture.getTestId());
        } catch (e) {
            const message = e + `\n\nThe component that emitted the event: does it have e.g. \ndata-testid={api.testids.${capture.functionName}}\n?`
            alert(message);
            throw e;
        }
        if (TestsAreDemoSlave.INSTANCE.master.state.running) {
            if (!fromWithinComponent) {
                TestsAreDemoSlave.INSTANCE.lastElementCaptured = domElement;
                await tad.showSpotlight({ focusOnLastElementCaptured: true, message: capture.getTestId() });
            } // else, e.g. the test dispatched an event; the component caught it and called the API. But in this case we don't want a spotlight
            // because the test will continue running anyway, w/o waiting for this spotlight. Probably there will be an error on the next "await tad..."
            // that will want to issue a spotlight also, while a spotlight is in progress
        } else {
            if (TestsAreDemoSlave.INSTANCE.master.refCaptureUiApiEvents.current) {
                return await TestsAreDemoSlave.INSTANCE.master.refCaptureUiApiEvents.current.onCapture(domElement, capture.getGeneratedSourceCode());
            }
        }

        // During the first experiments, this was needed. E.g. sometimes, for 2 successive API calls, call #1 wouldn't have time to finish render & co, and
        // call #2 would throw an error. However, now, I observe that's not needed any more. Maybe the current impl has by design some embedded "set timeout"s?
        // await Utils.setTimeoutPromise();
        return true;
    }
}