import { TraceMap, originalPositionFor, sourceContentFor } from '@jridgewell/trace-mapping';
import ErrorStackParser from "error-stack-parser";
import { parse } from 'stack-trace';
import { Utils } from '../copied/Utils';

export interface SourceCodeInfo {
    sourceFile: string;
    sourceLine: number;
    sourceColumn: number;
    sourceCode: string;
}
export interface SourceCodeInfoFull extends SourceCodeInfo {
    cacheEntry: TraceMapCacheEntry;
}

export interface TraceMapCacheEntry {
    traceMap: TraceMap;
    [additionalKey: string]: any;
}

/**
 * When the program suspends, we see a stack trace e.g.:
 * * file1.function1()
 * * file2.function2()
 * * File3TestsAreDemo.someTest()
 * * ...
 * 
 * We are interested in the ...TestsAreDemo file. But in order to retrieve it (its source code + line number), we always (at each suspend) need
 * to decode file1 and file2 as well. Meaning loading the corresponding .map file + decoding.
 * 
 * Hence we want to cache. In "step by step" mode performed by a human, this optimization is not really needed. However, when we are in the 
 * "puppeteer" mode, when puppeteer is making screenshots at each stop: we need this optimization.
 */
export class TraceMapCache {

    static INSTANCE = new TraceMapCache();

    protected cache: { [fileName: string]: TraceMapCacheEntry } = {};

    async getSourceCodeState(fileName: string, line: number, column: number): Promise<SourceCodeInfoFull | undefined> {
        let cacheEntry: TraceMapCacheEntry = this.cache[fileName];
        if (!cacheEntry) {
            const sourceMapFile = this.prepareFileUrl(fileName, ".map");
            try {
                const response = await fetch(sourceMapFile);
                if (!response.ok) {
                    throw new Error("Server returned code " + response.status + " - " + response.statusText)
                }
                const mapFile = await (response).text();
                const traceMap = new TraceMap(JSON.parse(mapFile), sourceMapFile);
                cacheEntry = this.cache[fileName] = { traceMap };
            } catch (e) {
                // CS: let's see if this message makes sense, or we should hide it
                console.error(`Error while get file ${sourceMapFile} from server: `, e);
                return undefined;
            }
        }
        const position = originalPositionFor(cacheEntry.traceMap, { line, column });
        if (!position.source
            || !position.line) {  // this is equivalent of instanceof InvalidOriginalMapping
            return undefined;
        }
        const sourceCode = sourceContentFor(cacheEntry.traceMap, position.source);
        if (!sourceCode) {
            // CS: idem cf. above
            // VI: this error make sens because if we have the original position, need to have source content for this position too
            console.error("Error while processing source-map: sourceContentFor()", this.prepareFileUrl(position.source));
            return undefined;
        }

        return { sourceCode, sourceFile: this.prepareFileUrl(position.source), sourceLine: position.line, sourceColumn: position.column, cacheEntry }
    }

    /**
     * Initially this function didn't use a cache. Now it does. As any cache, there is the dilemma: memory vs speed optimization.
     * 
     * Disadvantage: if there is a single error w/ 10 lines, we'll download and store maybe 6 or 7 source maps.
     * And then we won't use them any more, because the error was very rare.
     * 
     * Advantage: if an error appears several times, then we gain time by using the cache. 
     * 
     * Initially, this was for TestsAreDemo. So speed was not that crucial. But since adding the feature that sends the (decoded)
     * error to server, speed becomes important. If we have a recurring error that maybe is not blocking, we don't want it to
     * generate a lot of network traffic.
     */
    async decodeStackTrace(err: Error): Promise<string> {
        if (!err) {
            return "Cannot decode null error";
        }
        let stackLog!: string;
        let erroOnStringify = false;
        try {
            // try to stringify the object without stack
            stackLog = Utils.consoleLogJson({ ...err, stack: undefined }, false);
        } catch (e) {
            console.error("Displaying object w/ JSON.stringify(): an error was thrown", e);
            erroOnStringify = true;
        }
        if (erroOnStringify || !Object.keys(JSON.parse(stackLog)).length) {
            let indexOfNl: number;
            if (!err.stack || (indexOfNl = err.stack.indexOf("\n")) < 0) {
                stackLog = err.message;
            } else {
                stackLog = err.stack.substring(0, indexOfNl);
            }
        }
        if (!err.stack) {
            return stackLog + " - No stacktrace available.";
        }
        const stackFrameFunctions = parse(err).map(stackFrame => stackFrame.getFunctionName()); // for show funtion of error
        const stackFrames = ErrorStackParser.parse(err);
        for await (const [idx, stackFrame] of stackFrames.entries()) {
            // if the file name, line number or column number cannot be determined skip this stackFrame
            if (!stackFrame.fileName || !stackFrame.lineNumber || !stackFrame.columnNumber) {
                continue;
            }

            const sourceCodeState = await this.getSourceCodeState(stackFrame.fileName, stackFrame.lineNumber, stackFrame.columnNumber);
            if (!sourceCodeState) {
                continue;
            }

            // conf. "The column number is 0-based." (column + 1) and "The line number is 1-based." (line - 1) (https://github.com/mozilla/source-map)
            // the source is only path and she can be relative or absolute
            // create from source an url to source line, column where is error
            // ex. ../../test.js => http://localhost:3000/test.js:1:1
            const sourceUrl = this.prepareFileUrl(sourceCodeState.sourceFile) + ":" + sourceCodeState.sourceLine + ":" + (sourceCodeState.sourceColumn + 1);
            const functionName = stackFrameFunctions[idx] ? (" at " + stackFrameFunctions[idx]) : "";
            let sourceLineCode = sourceCodeState.sourceCode.split(/\n/g)[sourceCodeState.sourceLine - 1]?.trim();
            stackLog += "\n\t " + functionName + " (" + sourceUrl + ") " + (sourceLineCode ? "[" + sourceLineCode + "]" : "");
        }
        return stackLog;
    }

    private prepareFileUrl(fileName: string, extension?: string) {
        let url = fileName;
        if (fileName.includes("async")) {
            // this was a workaround for issue when we have a async anonymous function in Chrome
            // ex. (stack trace line `at async TestsAreDemoSlave.showSpotlight`, the parser return filename `async TestsAreDemoSlave.showSpotlight`)
            // REGEX does not treat this case https://github.com/stacktracejs/error-stack-parser/blob/9f33c224b5d7b607755eb277f9d51fcdb7287e24/error-stack-parser.js#L17
            url = fileName.replace(/.*http/g, "http");
        }
        if (!url.startsWith("http")) {
            // if the file name is only path, create the url
            // I observed in lib dev/preview mode the fileName is only path
            url = window.location.origin + "/" + url.split("..").pop();
        }
        return url.split("?")[0] + (extension || "");
    }
}