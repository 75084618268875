import gql from "graphql-tag"

export const COLUMN_CONFIG = gql`
    fragment ColumnConfig on ColumnConfig {
        id entityName name configJson organization { id qualifiedName } displayAsCards autoRefreshInterval
    }
`

export const FIND_BY_FILTER = gql`
    query columnConfigService_findByFilter ($params: FindByFilterParamsInput) {
        columnConfigService_findByFilter(params: $params) { 
            results { ...ColumnConfig }
        }
    }
    ${COLUMN_CONFIG}
`

export const FIND_BY_ID = gql`
    query columnConfigService_findById ($id: Long) {
        columnConfigService_findById(id: $id) { 
            ...ColumnConfig
        }
    }
    ${COLUMN_CONFIG}
`