import { FindByFilterParamsInput } from '../apollo-gen-foundation/globalTypes';
import { FindByFilterParams } from './FindByFilterParams';

export interface  FindByStringParamsInput extends FindByFilterParamsInput {
    string: string;
}

export class FindByStringParams extends FindByFilterParams {
    params: FindByStringParamsInput = { string: '', startIndex: 0, pageSize: 10 };

    string(string: string) {
        this.params.string = string;
        return this;
    }

    static create() {
        return new FindByStringParams();
    }
}