import { produce } from "immer";
import React, { ReactNode } from "react";
import { Button, Checkbox, Label, Message, Popup } from "semantic-ui-react";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt";
import { LOCAL_STORAGE_CURRENT_TEST_CLASS, LOCAL_STORAGE_TESTS_TO_RUN_SERVER, QUERY_STRING_TESTS_TO_RUN, TestClassDescriptor, TestsAreDemoMaster, TestsToRun } from "../TestsAreDemoMaster";
import { FeatureListProps, FeaturesList } from "./FeatureList";
import { TestClassComponent, TestClassComponentProps } from "./TestClassComponent";
import "./styles.css";
import { getLinkToCurrentPage, getTestToRunCountLabel, getTestsCountLabel, getTestsToRunCount } from "./functions";
import { TestsClassNameRenderer } from "./TestClassNameRenderer";
import { DemoComponentPseudoPortal } from "./DemoComponentPseudoPortal";
import { ModalForTestsOpenMode } from "../ModalForTests";

export interface FeaturebookCommonProps {
    master?: TestsAreDemoMaster;
    running?: boolean;
    testsToRun?: TestsToRun;
}

export interface FeaturebookUiProps extends FeaturebookCommonProps {
    testClassDescriptors?: TestClassDescriptor[];
}

export interface FeaturebookUiState {
    selectedDescriptor?: TestClassDescriptor;
}

/**
 * Featurebook started as a component used via composition. W/o much state, because its parent (i.e. `TestsAreDemoMaster`) would store the state.
 * 
 * Meanwhile, there were needs to reuse it: `FeaturebookRecorded`, `FeaturebookServer`. For these cases, there is no more a "master"/parent component. Hence
 * we extracted access to the state in overrideable functions. E.g. `getTestClassDescriptors()`, `getTestsToRun()`, `updateTestsToRun()`.
 */
export class Featurebook<P extends FeaturebookUiProps = FeaturebookUiProps, S extends FeaturebookUiState = FeaturebookUiState> extends React.Component<P, S> {

    // @ts-ignore
    state: S = {};

    constructor(props: P) {
        super(props);
        this.componentDidUpdate();
    }

    protected getTestClassDescriptors() {
        return this.props.testClassDescriptors;
    }

    protected getTestsToRun(): TestsToRun | undefined {
        return this.props.testsToRun;
    }

    updateTestsToRun(testsToRun: TestsToRun) {
        this.props.master!.updateTestsToRun(testsToRun);
    }

    protected hasCheckboxes() {
        return true;
    }

    componentDidUpdate(prevProps?: Readonly<P>): void {
        const props = this.props;

        if (props.testClassDescriptors === prevProps?.testClassDescriptors || !this.getTestsToRun()) {
            return;
        } // else, descriptors were modified

        // NOTE: this doesn't happen for FeaturebookServer
        this.onTestClassDescriptorsChanged();
    }

    /**
     * Maybe some classes/functions stored in LocalStorage don't exist anymore; so let's remove them.
     * W/o this, the counters would be wrong.
     */
    protected onTestClassDescriptorsChanged() {
        let wasUpdated = false;
        const testsToRunUpdated = produce(this.getTestsToRun()!, draft => {
            for (let testClass of Object.getOwnPropertyNames(draft)) { // I use .getOwnPropertyNames() because I'm deleting properties. I don't know if this would impact the "normal" iteration
                const testClassDescriptor = this.getTestClassDescriptors()!.find(d => d.name === testClass);
                if (!testClassDescriptor) {
                    delete draft[testClass];
                    wasUpdated = true;
                    continue;
                }
                const functionsToRun = draft[testClass];
                for (let functionName of Object.getOwnPropertyNames(functionsToRun)) {
                    if (!testClassDescriptor.functions.find(f => f.functionName === functionName)) {
                        delete functionsToRun[functionName];
                        wasUpdated = true;
                    }
                }
            }
        });
        if (wasUpdated) {
            this.updateTestsToRun(testsToRunUpdated);
        }
    }

    protected getShowLeftPane(): boolean | undefined {
        return true;
    }

    protected getAdditionalPropsForFeatureList(): Partial<FeatureListProps> {
        return { localStorageKey: LOCAL_STORAGE_CURRENT_TEST_CLASS };
    }

    /**
     * Also used as condition to know if this is FeaturebookUi or FeaturebookUiRecorded
     */
    protected getAdditionalPropsForTestClassComponent(): Partial<TestClassComponentProps> | undefined {
        return undefined;
    }

    protected renderTestClassComponent() {
        let { master } = this.props;

        if (this.state.selectedDescriptor?.demoComponent) {
            if (this.props.master?.state.modalOpenMode === ModalForTestsOpenMode.POPUP_FOR_TEST) {
                // seeing a demo component (i.e. DemoComponentPseudoPortal is mounted => ModalForTests open in "pseudo include" mode), 
                // and test run has started. In this case I want to unmount DemoComponentPseudoPortal
                // or the case when the app starts in "auto run test" && "at last test, the popup w/ tests was shown"
                // NOTE: I double the IF by looking at the open state also; w/o it when the test has finished running AND the following message
                // is still displayed => the demo will show; i.e. the test popup will close; this is unexpected; hence I look also at the state
                // I don't care that, not being a prop, I won't be notified when the open state changes
                return "Cannot display while tests are running.";
            } else {
                return <DemoComponentPseudoPortal master={master!} testClassDescriptor={this.state.selectedDescriptor} />
            }
        } else {
            return <TestClassComponent parentFeaturebook={this} master={master} testsToRun={this.getTestsToRun()} testClassDescriptor={this.state.selectedDescriptor} {...this.getAdditionalPropsForTestClassComponent()} />;
        }
    }

    protected setSelectedDescriptor(selectedDescriptor: TestClassDescriptor) {
        this.setState({ selectedDescriptor });
    }

    protected renderInHeader(): ReactNode {
        const testsToRun = this.getTestsToRun();

        let linkFragment = testsToRun && JSON.stringify(testsToRun);
        let link: string | undefined;
        if (linkFragment) {
            link = getLinkToCurrentPage(QUERY_STRING_TESTS_TO_RUN, linkFragment);
            const search = new URLSearchParams();
            search.append(QUERY_STRING_TESTS_TO_RUN, linkFragment)
            linkFragment = "\\&" + search.toString();
        }
        const checked = getTestsToRunCount(testsToRun);
        return <>
            <Button disabled={this.props.running} icon="play" content={"Run " + getTestToRunCountLabel(testsToRun)} onClick={e => this.props.master!.run(true)} />
            <Popup flowing on="click" trigger={<Button icon="linkify" content="Link" />}>
                {!linkFragment ? "No test is checked to run" : <>
                    <p>
                        {getTestToRunCountLabel(testsToRun)}: there are {checked.functionsChecked} test function(s) (in {checked.classesChecked} class(es)) checked to be ran.
                    </p>
                    <p>
                        <Label circular content="1" /> This <a href={link} target="_blank">shareable link</a> contains in its search string the JSON for the checked tests to run.
                    </p>
                    <p>
                        <Label circular content="2" /> When launched from <b>puppeteer</b>, all the tests are ran. If you want to <b>filter</b>, then you can append a command line fragment to the puppeteer command line e.g. <Label horizontal><code>tad-puppeteer \&testsToRun=...</code></Label>:
                    </p>
                    <Message><code>{linkFragment}</code></Message>
                    <Button content="Copy to clipboard" onClick={() => {
                        navigator.clipboard.writeText(linkFragment!);
                        alert("Copied to clipboard!");
                    }} />
                </>}
            </Popup>
        </>
    }

    render() {
        let testsToRun = this.getTestsToRun();

        if (!this.getShowLeftPane()) {
            return this.renderTestClassComponent();
        }

        return (<>
            <SplitPaneExt size={"25%"} split="vertical">
                <div className="w100">

                    {this.getTestClassDescriptors() && <Label basic horizontal color="grey">
                        {getTestsCountLabel(this.getTestClassDescriptors()!)}
                    </Label>}
                    {this.renderInHeader()}
                    <FeaturesList items={this.getTestClassDescriptors()}
                        getIdForLocalStorage={(descriptor: TestClassDescriptor) => descriptor.name}
                        onSelect={(selectedDescriptor: TestClassDescriptor) => this.setSelectedDescriptor(selectedDescriptor)}
                        renderItem={(descriptor: TestClassDescriptor) => {
                            const checked = testsToRun && testsToRun[descriptor.name] && Object.getOwnPropertyNames(testsToRun[descriptor.name]).length > 0;
                            return <>
                                {/* no checkbox for entry for demo component */}
                                {!descriptor.demoComponent && this.hasCheckboxes() && <Checkbox checked={checked} label="&nbsp;" onChange={() => {
                                    if (!testsToRun) {
                                        testsToRun = {};
                                    }
                                    this.updateTestsToRun(produce(testsToRun!, draft => {
                                        if (checked) {
                                            delete draft[descriptor.name];
                                        } else {
                                            // @ts-ignore
                                            const clazz = draft[descriptor.name] = {} as TestsToRun["something"];
                                            for (let f of descriptor.functions) {
                                                clazz[f.functionName] = true;
                                            }
                                        }
                                    }));
                                }} />}
                                <TestsClassNameRenderer name={descriptor.name} />
                            </>
                        }} {...this.getAdditionalPropsForFeatureList()} />
                </div>
                {this.renderTestClassComponent()}
            </SplitPaneExt>
        </>)
    }
}