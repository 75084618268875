import { History } from "history";
import { AppMeta } from "./AppMeta";

export const AppMetaTempGlobals = {
} as {
    history: History,
    locationPathnameCurrent: string,
    locationPathnamePrevious?: string

    // previously this was AppMeta.INSTANCE; but referencing this would often produce cyclic dep issues
    // when using this, please ask for permission, describing why you need this; as its name implies, this
    // is a bad practice; but we didn't quickly find a correct solution
    appMetaInstance: AppMeta
};