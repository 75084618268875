import React from "react";
import { Button, Label, Modal } from "semantic-ui-react";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt";
import { SourceCode } from "../SourceCode";
import { SourceCodeInfo } from "../TraceMapCache";
import { TestFunctionComponent, TestFunctionComponentInternal } from "../featurebook/TestFunctionComponent";
import { RecordedTest } from "./RecordedTest";
import "./styles.css";

interface Props {
    parent: TestFunctionComponent;
    testPath: string;
    currentSlide?: number;
    onClose: () => void;
    recordedTest: RecordedTest;
}

interface State {
    currentSlide: number;
}

export class RecordedTestModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const state: State = { currentSlide: 0 };
        this.state = state;
        state.currentSlide = props.currentSlide || 0;
    }

    renderComponent() {
        let sourceCodeInfo: SourceCodeInfo | undefined;
        let img: string | undefined;
        const { recordedTest } = this.props;
        if (recordedTest && recordedTest.slides.length > 0) {
            const slide = recordedTest.slides[this.state.currentSlide];
            sourceCodeInfo = {
                sourceCode: recordedTest.sourceFiles[slide.sourceFile],
                sourceFile: slide.sourceFile,
                sourceLine: slide.sourceLine,
                sourceColumn: 0
            }
            img = this.props.testPath + "/" + recordedTest.slides[this.state.currentSlide].screenshot + ".png";
        }
        return <div className="RecordedTestModal">
            <div>
                <Button.Group basic>
                    <Button icon='step backward' disabled={this.state.currentSlide <= 0} onClick={() => this.setState({ currentSlide: this.state.currentSlide - 1 })} />
                    <Button icon='step forward' disabled={this.state.currentSlide >= recordedTest.slides.length - 1}
                        onClick={() => this.setState({ currentSlide: this.state.currentSlide + 1 })} />
                </Button.Group>
                {recordedTest && recordedTest.slides.map((slide, i) =>
                    <Label key={i} as="a" circular content={i + 1} color={i === this.state.currentSlide ? "blue" : undefined} size={i === this.state.currentSlide ? "huge" : undefined}
                        onClick={() => this.setState({ currentSlide: i })} />)}

            </div>
            {recordedTest &&
                <SplitPaneExt size={"25%"} split="vertical">
                    <div className="flex-grow flex-container no-padding-margin">
                        <SourceCode sourceCodeInfo={sourceCodeInfo} />
                    </div>
                    <div>
                        <img src={img} />
                    </div>
                </SplitPaneExt>
            }
        </div>
    }

    render() {
        return <Modal className="RecordedTestModalRoot" open size="fullscreen" onClose={this.props.onClose}>
            <TestFunctionComponentInternal {...this.props} parent={undefined} testFunctionDescriptor={this.props.parent.props.testFunctionDescriptor} recordedTest={this.props.recordedTest}
                additionalForSegment2={<>Recorded test URL: {this.props.testPath}</>} />
            {this.renderComponent()}
            <Modal.Actions>
                <Button onClick={this.props.onClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    }
}