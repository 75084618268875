import { fireEvent } from '@testing-library/dom'

// This file is taken from https://testing-library.com/docs/example-drag/.
// NOTE 1 (from the link above): This example only works with a real browser (not with jsdom, as it does not support getBoundingClientRect).
// NOTE 2: I would have like such functionnality directly in the lib. But the lib's author doesn't seem to like very much the "drag" approach, 
// probably because of "flakyness". Cf. https://github.com/testing-library/user-event/issues/440
// NOTE 3: If the user moves the mouse during the drag operation => the test will probably break. Because we expect a drag of e.g. 30px, but (because
// of the mouse move), the actual drag will have e.g. 27px.
function isElement(obj: any) {
    if (typeof obj !== 'object') {
        return false
    }
    let prototypeStr, prototype
    do {
        prototype = Object.getPrototypeOf(obj)
        // to work in iframe
        prototypeStr = Object.prototype.toString.call(prototype)
        // '[object Document]' is used to detect document
        if (
            prototypeStr === '[object Element]' ||
            prototypeStr === '[object Document]'
        ) {
            return true
        }
        obj = prototype
        // null is the terminal of object
    } while (prototype !== null)
    return false
}

function getElementClientCenter(element: any) {
    const { left, top, width, height } = element.getBoundingClientRect()
    return {
        x: left + width / 2,
        y: top + height / 2,
    }
}

const getCoords = (charlie: any) =>
    isElement(charlie) ? getElementClientCenter(charlie) : charlie

const sleep = (ms: any) =>
    new Promise(resolve => {
        setTimeout(resolve, ms)
    })

export async function dragRaw(
    element: any,
    { from: inFrom, to: inTo, delta, steps = 20, duration = 500, options = {} }: any,
) {
    const from = inFrom ? inFrom : getElementClientCenter(element);
    const to = delta
        ? {
            x: from.x + delta.x,
            y: from.y + delta.y,
        }
        : getCoords(inTo)

    const step = {
        x: (to.x - from.x) / steps,
        y: (to.y - from.y) / steps,
    }

    const current = {
        clientX: from.x,
        clientY: from.y,
        ...options
    }

    fireEvent.mouseEnter(element, current)
    fireEvent.mouseOver(element, current)
    fireEvent.mouseMove(element, current)
    fireEvent.mouseDown(element, current)
    for (let i = 0; i < steps; i++) {
        current.clientX += step.x
        current.clientY += step.y
        await sleep(duration / steps)
        fireEvent.mouseMove(element, current)
    }
    fireEvent.mouseUp(element, current)
}