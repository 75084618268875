import React from "react";
import { Icon, Message } from "semantic-ui-react";
import { zeroTrainingArticle } from "../dataStructures";
import { joyrideDecoratorParams } from "../JoyrideDecorator";

// If we want to expose a .stories file as ZeroTraining (ZT) article, then this would be the first step, cf. the line below.
// message keys for: category, article
// NOTE: .label; by convention every entity has this key for it's "official" name; we'll often use them. But if needed, 
// any message key can be used
export default zeroTrainingArticle("ZeroTraining.label", "JoyrideDecorator.demo");

const colors = ["blue", "orange", "green", "red"];

export const Screen0 = () => Screen(0);
Screen0.story = {
    name: "Screen number 0",
    // to assign messages to a story:
    // use this way, if no other params
    parameters: joyrideDecoratorParams("JoyrideDecorator.demo", [
        // simple mode: shorthands; you need to have e.g. JoyrideDecorator.demo.message both as "data-cy='...'" + as message key
        "message", "icon",

        // advanced mode 1: override something (not all); in this case, I provide another content, but reuse the calculated target
        // i.e. JoyrideDecorator.demo.icon; "shortKey" to the rescue to reuse + override
        // PS: we'll probably use from times to times this technique: i.e. same target, but 2 popups. Maybe when we have a lot of content
        { shortKey: "icon", content: _msg("JoyrideDecorator.demo.icon2") },

        // advanced mode 2: here I didn't override anything; I wanted to change stuff for the Joyride step
        { shortKey: "header", disableOverlay: true },

        // more simple messages
        "blabla1", "blabla2", "full",

        // advanced mode 3 / "ultimate": if more control is needed, a full decorator step can also be provided; here I didn't provide "shortKey"
        // because I didn't want to override anything
        {
            content: <><h2>Last but not least:</h2><p>You can add a full fledged Joyride step</p></>,
            locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
            placement: 'center' as any, target: 'body', disableOverlay: true
        },
    ])

    // NOTE: most of the times, you will use "parameters" only for ZeroTraining/Joyride; hence cf. above. If you have a special setup
    // where you need to provide additional params, use the "spread" / ... operator:
    // parameters: { myParamForAnotherDecorator: 123, ...joyrideDecoratorParams("JoyrideDecorator.demo", [...]) }
}

// end of demo; below: utility stuff

const parameters = Screen0.story.parameters;

export const Screen1 = () => Screen(1);
Screen1.story = { name: "Screen number 1", parameters }

export const Screen2 = () => Screen(2);
Screen2.story = { name: "Screen number 2", parameters }

const Screen = (i: number) => (<Message data-cy="JoyrideDecorator.demo.full" style={{ height: "100%", marginTop: "0" }}>
    <Message data-cy="JoyrideDecorator.demo.message" color={colors[i % colors.length] as any} icon>
        <Icon data-cy="JoyrideDecorator.demo.icon" name="smile" />
        <Message.Content>
            <Message.Header data-cy="JoyrideDecorator.demo.header">I'm a screen</Message.Header>
            <span data-cy="JoyrideDecorator.demo.blabla1">With number:</span> <span data-cy="JoyrideDecorator.demo.blabla2">{i}</span>
        </Message.Content>
    </Message>
</Message>);