import React from "react";
import { Button, Label, Popup } from "semantic-ui-react";
import { Utils } from "../copied/Utils";
import { createTestids } from "./TestsAreDemoFunctions";
import { SourceCodeInfo } from "./TraceMapCache";
import { CODE_ID, CURRENT_LINE_ID, SyntaxHighlighterExt, UPPER_LINE_ID } from "@famiprog-foundation/scriptable-ui";

export const sourceCodeTestIds = createTestids("TestsAreDemoMaster", {
    fileName: "", lineNumber: ""
});

interface Props {
    sourceCodeInfo?: SourceCodeInfo;
}

export class SourceCode extends React.Component<Props> {

    private adjustScrollbarsIfNeededToHaveCurrentLineElementInViewport() {
        const line = document.getElementById(CURRENT_LINE_ID);
        if (!line) {
            return;
        }
        const parent = document.getElementById(CODE_ID) as HTMLElement;
        var pageTop = parent.scrollTop;
        var pageBottom = pageTop + parent.offsetHeight;
        let scrollBarHeight = parent.offsetHeight - parent.clientHeight;
        var elementTop = line.offsetTop;
        var elementBottom = elementTop + line.offsetHeight + scrollBarHeight;

        if (pageTop > elementTop || pageBottom < elementBottom) {
            document.getElementById(UPPER_LINE_ID)!.scrollIntoView();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const state = this.props.sourceCodeInfo;
        if (state && prevProps.sourceCodeInfo != this.props.sourceCodeInfo) {
            this.adjustScrollbarsIfNeededToHaveCurrentLineElementInViewport();
        }
    }

    render() {
        const state = this.props.sourceCodeInfo;
        return state?.sourceCode
            ? <>
                <span><div className="flex-container-row">File@line: <b data-testid={sourceCodeTestIds.fileName}>{Utils.substringAfter(state.sourceFile!, "/", true)}</b>@<div data-testid={sourceCodeTestIds.lineNumber}>{state.sourceLine}</div>  &nbsp;
                    <Popup trigger={<Button icon="bars" size="mini" />}
                        flowing on='click' pinned
                        content={<>
                            Full file name: <b>{state.sourceFile}</b>
                            <p />
                            <Button size="mini" content="Print it to console" onClick={() => {
                                console.log("Printing the file name, in a clickable form:");
                                if (state.sourceFile) {
                                    console.log(state.sourceFile + ":" + state.sourceLine);
                                } else {
                                    console.log("The source file is undefined");
                                }
                            }
                            } />
                            Then open DevTools (e.g. via <Label size="mini" content="F12" />) and click on the file name (to open it in DevTools).
                        </>}
                    />
                </div></span>
                <SyntaxHighlighterExt highlightSourceLine={state.sourceLine} >{state.sourceCode}</SyntaxHighlighterExt>
            </>
            : <>SourceCode not yet loaded</>;
    }
}