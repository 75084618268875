import React from "react";
import { Button, Icon, Input, Popup } from "semantic-ui-react";

export type TreeMenuSearchProps = {
    value?: string,
    onChange: (value: any) => void,
    onFocus?: (value: any) => void,
    style?: { minWidth?: any, maxWidth?: any, width?: any; },
    focus?: boolean;
};
export class TreeMenuSearch extends React.Component<TreeMenuSearchProps> {

    inputRef = React.createRef<any>();

    state = {
        value: ""
    }

    static defaultProps = {
        focus: true
    };

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdateInternal(prevProps?: TreeMenuSearchProps) {
        if ((!prevProps || prevProps.focus !== this.props.focus) && this.props.focus) {
            setTimeout(() => {
                this.inputRef.current?.focus();
            });
        }
        if (this.props.value !== undefined && prevProps && prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        } 
    }

    componentDidUpdate(prevProps: TreeMenuSearchProps) {
        this.componentDidUpdateInternal(prevProps);
    }

    render() {
        return <div className="TreeMenu_searchDiv" style={this.props.style}>
            <Input className="TreeMenu_searchInput" data-cy="inputSearch" id="TreeMenu_searchDiv_inputID" 
                ref={this.inputRef} fluid placeholder={_msg("TreeMenu.quickSearch")} iconPosition="left" value={this.state.value} 
                onChange={e => { this.setState({ value: e.target.value }); this.props.onChange(e.target.value) }}
                onFocus={(e: any) => {
                    if (e.currentTarget && e.currentTarget.id !== 'TreeMenu_searchDiv_inputID') { return; }
                    this.props.onFocus?.call(null, true);
                }}
                onBlur={(e: any) => {
                    if (e.relatedTarget && e.relatedTarget.id !== 'TreeMenu_searchDiv_inputID') { return; }
                    this.props.onFocus?.call(null, false);
                }}
            >
                <Icon name="search" />
                <input autoComplete="off" />
            </Input>
            {(!this.state.value || this.state.value === "") ? "" :
                <Popup
                    trigger={<Button data-cy="deleteBtn" className="TreeMenu_removeButton" size='mini' color='red' icon='delete' onClick={() => {
                        this.setState({ value: "" });
                        this.props.onChange("");
                        this.inputRef.current.focus();
                    }} />}
                    content={_msg("TreeMenu.clearSearch")}
                    basic size='mini'
                />}
        </div>;
    }
}
