import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";
//@ts-ignore
import TimezonePicker from 'react-timezone-select';
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

export class TimezoneFieldEditor extends FieldEditor<any, FieldEditorProps> {

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        if (!this.getValue()) {
            s.setFieldValue(hw, { value: "GMT", label: "(GMT+0:00) Dublin, Edinburgh, Lisbon, London \n            (GMT)", abbrev: "GMT", altName: "GMT" });
        }
        return <TimezonePicker fluid value={this.getValue()}
            onChange={(tz: any) => s.setFieldValue(hw, tz)} labelStyle={'abbrev'} />
    }
}

export default TimezoneFieldEditor;