import { Messages } from "@crispico/foundation-gwt-js";
import { ConnectedPageInfo, PropsFrom, createSliceFoundation, getBaseImpures, getBaseReducers } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import React, { ReactNode } from "react";
import { Container, Divider, Header, Message } from "semantic-ui-react";
import { Utils } from "../utils/Utils";

export const sliceHomePage = createSliceFoundation(class SliceHomePage {
    initialState = {     
    }
    reducers = {
        ...getBaseReducers<SliceHomePage>(this),
    }
    impures = {
        ...getBaseImpures<SliceHomePage>(this),
    }
})

export type HomePageProps = PropsFrom<typeof sliceHomePage>;

export class HomePage<P = HomePageProps, S = {}> extends TabbedPage<P, S> {

    constructor(props:P) {
        super(props);
    }

    protected getTitle() {
        return { icon: "home", title: _msg("HomePage.title") };
    }

    protected renderUnderJumbotron(): ReactNode {
        return null;
    }

    protected renderFooterBar(): ReactNode {
        const { homePageSettings } = this.context.initializationsForClient;
        if (homePageSettings.footerBarText) {
            return <div className="footerBar">{homePageSettings.footerBarText}</div>;
        }
        return null;
    }

    protected get castedProps(): HomePageProps {
        // "as unknown" needed because of 
        //"Conversion of type 'Readonly<P> & Readonly<{ children?: ReactNode; }>' to type 'Props' may be a mistake"
        // I don't know why in tabbebPage.propsCasted this problem doesn't appear
        return this.props as unknown as HomePageProps;
    }

    protected backgroundImageURL() {      
        const { homeBackgroundImage } = this.context.initializationsForClient.visualStyleSettings;
        return homeBackgroundImage && "url(" + Utils.adjustUrlToServerContext(homeBackgroundImage) + ")";
    }

    protected renderMain() {    
        const { visualStyleSettings, version } = this.context.initializationsForClient;
        return (<div className="HomePage" style={{ backgroundImage: this.backgroundImageURL() }}>
            <Container>
                <Message>
                    <div className="flex-container flex-center">
                        {visualStyleSettings?.homeLogo2 ?
                            <div>
                                <img className="HomePage_clientLogo" src={Utils.adjustUrlToServerContext(visualStyleSettings?.homeLogo2)} alt='homeLogo2' />
                                <Divider className="less-margin-top-bottom" />
                            </div>
                            : null}
                        <div>
                            <img className="HomePage_homeLogo" src={Utils.adjustUrlToServerContext(visualStyleSettings?.homeLogo)} alt='homeLogo' />
                        </div>
                    </div>
                    <Divider className="less-margin-top-bottom mobile-hide" />
                    <Header as="h2" color="grey" className="mobile-hide">
                        <Header.Content>{Messages.getInstance().maybeTranslateByUser(visualStyleSettings?.homeTitle)}</Header.Content>
                        <Header.Subheader>{_msg("HomePage.version", version?.[0], version?.[1])}</Header.Subheader>
                    </Header>
                </Message>
                {this.renderUnderJumbotron()}
            </Container>
            {this.renderFooterBar()}
        </div>);
    }
}

export const infoHomePage = new ConnectedPageInfo(sliceHomePage, HomePage, "HomePage");
infoHomePage.routeProps = { path: "/", exact: true, homePageType: "normal" };
"./..""../components/TabbedPage/TabbedPage"