import { DashboardCalculateForRecordsWidgetEntityDescriptor, DashboardCalculateForRecordsValueEntityDescriptor } from "./components/CalculateForRecords/DashboardCalculateForRecordsWidget";
import { addEntityDescriptor, EntityDescriptor } from "./entity_crud/EntityDescriptor";
import { DashboardEntityDescriptor } from "./pages/dashboard/DashboardEntityDescriptor";
import { RecipientListEntityDescriptor } from "./pages/RecipientList/RecipientListEntityDescriptor";

export let dashboardCalculateForRecordsWidgetEntityDescriptor!: EntityDescriptor;
export let dashboardCalculateForRecordsValueEntityDescriptor!: EntityDescriptor;
export let dashboardEntityDescriptor!: EntityDescriptor;
export let recipientListEntityDescriptor!: EntityDescriptor;

export abstract class FoundationEntityDescriptors {
    init() {
        // please keep sorted alphabetically
        dashboardCalculateForRecordsWidgetEntityDescriptor = addEntityDescriptor(new DashboardCalculateForRecordsWidgetEntityDescriptor());
        dashboardCalculateForRecordsValueEntityDescriptor = addEntityDescriptor(new DashboardCalculateForRecordsValueEntityDescriptor());
        dashboardEntityDescriptor = addEntityDescriptor(new DashboardEntityDescriptor());
        recipientListEntityDescriptor = addEntityDescriptor(new RecipientListEntityDescriptor());
    }
}