import { FilterInput, SortInput } from "../apollo-gen-foundation/globalTypes";

export interface ExportParamsInput {
    columns?: string[];
    filter?: FilterInput | null;
    sorts?: SortInput[];
    timezone?: string;
  }

export class ExportParams {
    params: ExportParamsInput = {};

    filter(filter?: FilterInput) {
        this.params.filter = filter;
        return this;
    }
    sorts(sorts: SortInput[]) {
        this.params.sorts = sorts;
        return this;
    }
    columns(columns: string[]) {
        this.params.columns = columns;
        return this;
    }
    timezone(timezone: string) {
        this.params.timezone = timezone;
        return this;
    }
    static create() {
        return new ExportParams();
    }
}