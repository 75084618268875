import { ID } from "./entityCrudConstants";

export class CrudGlobalSettings {
    static INSTANCE = new CrudGlobalSettings;

    fieldId = ID;
    fieldVersion = "version";
    fieldsLikeName = ["name"];
    fieldsToSemiHide: string[] = [];
    defaultGraphQlIdType = "Long";
    defaultJavaIdType = "Long";
    fieldArchived = "archived";
}