import { FieldRendererProps } from "../fieldRenderersEditors";
import StringFieldRenderer from "./StringFieldRenderer";
import { categoricalColorSchemes } from "@nivo/colors";
import React from "react";

export class PieCountColorPaletteFieldRenderer extends StringFieldRenderer<FieldRendererProps> {
    render() {
        const value = this.props.value;
        if (!value || value.length === 0) {
            return <></>;
        }
        const schema: string[] = (categoricalColorSchemes as any)[value as any];
        return <div>
            <div className="small-margin-right">{value}</div>
            <div>{schema.map(color => <div className="SelectNivoColor_ColorBlock" style={{backgroundColor: color}} />)}</div>
        </div>;
    }
}

export default PieCountColorPaletteFieldRenderer;