import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityEditorFormSimple } from "@crispico/foundation-react/entity_crud/EntityEditorFormSimple";
import { NumberFieldEditor, NumberFieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldEditors/NumberFieldEditor";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ShortcutRefForTest } from "@famiprog-foundation/tests-are-demo";
import { FormikProps } from "formik";
import React from "react";
import { Button, Icon, Message, Modal, Popup } from "semantic-ui-react";
import { BooleanFieldEditor, BooleanFieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldEditors/BooleanFieldEditor";
import { ModalExt } from "@famiprog-foundation/semantic-ui-react-ext";
import { FieldEditorNotUsableStandAloneProps } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { RRCProps, Reducers, ReduxReusableComponents, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export const DEFAULT_REFRESH_RATE = 0;
export const MIN_REFRESH_RATE = 15;
export const REFRESH_RATE_ON_ENABLED = 60;
export const MAX_REFRESH_RATE = 60 * 60 * 24;

class RefreshButtonState extends State {
    refreshModal: boolean | [number, number] = false;
}

type RefreshButtonProps = {
    refreshRate: number | undefined,
    refresh: () => any,
    automaticRefresh?: boolean;
    defaultRefreshRate?: number;
    className?: string;
    onChange?: (params: { refreshRate?: number }) => void;
    openColumnConfig?: () => void;
} & RRCProps<RefreshButtonState, Reducers<RefreshButtonState>>;

interface RefreshButtonLocalState {
    // we use the local state instead of the Redux state, because, although it is serializable as data type (= number)
    // it is not serializable "as meaning". I.e. deserializing such a value doesn't' have any meaning.
    timerId: number | undefined
}

export class RefreshButton extends React.Component<RefreshButtonProps, RefreshButtonLocalState> {
    
    state = {
        timerId: undefined
    }

    editorRef = React.createRef<EntityEditorFormSimple>();

    entityDescriptor = new EntityDescriptor({ name: "refreshButtonModalEditor" })
        .addFieldDescriptor({ name: "enabled", type: FieldType.boolean })
        .addFieldDescriptor({ name: "interval", type: FieldType.number });
    entityDescriptorInit = false;

    /**
     * Arrow function because it is passed as function pointer.
     */
    private refreshAndReschedule = (dontCallRefresh?: boolean) => {
        if (!dontCallRefresh) {
            this.props.refresh();
        }
        const timerId = window.setTimeout(this.refreshAndReschedule, this.props.refreshRate! * 1000);
        this.setState({ timerId });
    }

    private clearCurrentTimeout() {
        if (this.state.timerId) {
            window.clearTimeout(this.state.timerId);
        }
    }

    async startOrUpdateAutomaticRefresh() {
        // Clear current timeout for the timerId before setup a new timeout for execution.
        this.clearCurrentTimeout();

        const { refreshRate, onChange } = this.props;
        if (!refreshRate || isNaN(refreshRate)) {
            return;
        }
        onChange?.({ refreshRate });
        if (refreshRate < MIN_REFRESH_RATE || refreshRate > MAX_REFRESH_RATE) {
            return;
        } else {
            this.refreshAndReschedule(true);
        }
    }

    componentDidUpdate(prevProps: RefreshButtonProps) {
        if (prevProps?.s.refreshModal === false && this.props.s.refreshModal && !this.entityDescriptorInit) {
            this.updateEntityDescriptor();
        }

        if (prevProps?.refreshRate !== this.props.refreshRate) {
            this.startOrUpdateAutomaticRefresh();
        }
    }

    componentWillUnmount() {
        this.clearCurrentTimeout();
    }

    private updateEntityDescriptor() {
        this.entityDescriptorInit = true;
            
        this.entityDescriptor.fields.enabled.renderFieldEditor = function(formikProps: FormikProps<any>) {
            let props: FieldEditorProps = { formikProps, fieldDescriptor: this };
            return React.createElement(class extends BooleanFieldEditor<BooleanFieldEditorProps & FieldEditorNotUsableStandAloneProps> {
                constructor(props: BooleanFieldEditorProps & FieldEditorNotUsableStandAloneProps) {
                    super(props);
            
                    this.scriptableUiImpl = ScriptableUi.extendImpl(this.scriptableUiImpl, original => ({
                        setFieldValue: (value: any) => {
                            this.props.formikProps.setFieldValue("enabled", value);
                            if (value) {
                                this.props.formikProps.setFieldValue("interval", REFRESH_RATE_ON_ENABLED);
                            }
                        }
                    }));
                }
            } as any, props);
        };

        const refreshRateFieldEditorClass = class RefreshRateFieldEditor extends NumberFieldEditor<number, NumberFieldEditorProps & FieldEditorNotUsableStandAloneProps> {
            getInputProps() {
                return { min: MIN_REFRESH_RATE, max: MAX_REFRESH_RATE, onBlur: () => {
                    const value = this.getValue()
                    if (value === 0) {
                        this.props.formikProps.setFieldValue("enabled", false);
                    } else if (value < MIN_REFRESH_RATE) {
                        this.props.formikProps.setFieldValue("interval", MIN_REFRESH_RATE);
                    } else if (value > MAX_REFRESH_RATE) {
                        this.props.formikProps.setFieldValue("interval", MAX_REFRESH_RATE);
                    }
                }, disabled: !this.props.formikProps.values.enabled };
            }
        }

        this.entityDescriptor.fields.interval.renderFieldEditor = function(formikProps: FormikProps<any>) {
            let props: FieldEditorProps = { formikProps, fieldDescriptor: this };
            return <div style={{ display: "inline-flex", alignItems: "center", }}><div className="small-margin-right">{React.createElement(refreshRateFieldEditorClass, props as any)}</div><RefreshInformation /></div>;
        };
    }

    private onApply = () => {
        const values = this.editorRef.current?.formikContext.values;
        if (values) {
            const enabled = values.enabled;
            const refreshRate = enabled ? values.interval : 0;
            this.props.r.setInReduxState({ refreshModal: false });
            this.props.onChange && this.props.onChange({ refreshRate });
        }
    }

    openAutomaticRefreshMenu(e: any) {
        const rect = document.getElementById("refreshDropdownRef")!.getBoundingClientRect();
        this.props.r.setInReduxState({ refreshModal: [rect.left, rect.bottom] });
    }

    render() {
        return (
            <>
                <ShortcutRefForTest objectToPublish={this} className={"ShortcutRefForTest"} />
                <div className={"tiny-margin-right RefreshButton_refreshContainer " + this.props.className}>
                    <Button data-testid="RefreshButton_refresh" key="refresh" className={this.props.automaticRefresh ? "RefreshButton_refreshButton" : "tiny-margin-right"} color="green" onClick={this.props.refresh} icon="refresh" />
                    {this.props.automaticRefresh && <>
                        <Button data-testid="RefreshButton_refreshRate" key="dropdown" id="refreshDropdownRef" className="RefreshButton_refreshDropdownButton" color="green" icon="dropdown" onClick={(e) => this.openAutomaticRefreshMenu(e)} />
                        <ModalExt className="RefreshButton_refreshModal" open={this.props.s.refreshModal} transparentDimmer onClose={() => this.props.r.setInReduxState({ refreshModal: false })}>
                            <Modal.Header>{_msg("RefreshButton.modal.header")}</Modal.Header>
                            <Modal.Content>
                                <EntityEditorFormSimple ref={this.editorRef}
                                    entity={{ enabled: this.props.refreshRate !== 0, interval: this.props.refreshRate }}
                                    entityDescriptor={this.entityDescriptor}
                                    hideButtonBar
                                />
                                <Message info>{_msg("RefreshButton.modal.message.1")} <a onClick={(e) => this.props.openColumnConfig && this.props.openColumnConfig()}>{_msg("RefreshButton.modal.message.2")}</a>.<p></p>{_msg("RefreshButton.modal.message.3")}</Message>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive onClick={this.onApply}>{_msg("general.apply")}</Button>
                                <Button onClick={() => this.props.r.setInReduxState({ refreshModal: false })}>{_msg("general.cancel")}</Button>
                            </Modal.Actions>
                        </ModalExt></>}
                </div>
            </>
        );
    }

}

export const RefreshButtonRRC = ReduxReusableComponents.connectRRC(RefreshButtonState, Reducers, RefreshButton);

export class RefreshInformation extends React.Component<{}> {
    render() {
        return <Popup trigger={<Icon name="info circle" color="blue" size="large" />} content={_msg("ColumnConfig.autoRefreshInterval.info")} />;
    }
}
"../../entity_crud/EntityDescriptor""../../entity_crud/EntityEditorFormSimple""../../entity_crud/fieldEditors/NumberFieldEditor""../../entity_crud/fieldRenderersEditors""../../entity_crud/FieldType""../../entity_crud/fieldEditors/BooleanFieldEditor""../../entity_crud/fieldEditors/FieldEditor""../../reduxReusableComponents/ReduxReusableComponents"