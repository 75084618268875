import { SelectExt, SelectExtOption, SelectExtProps } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderers/DropdownFieldRenderer";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { FieldInterval } from "../CrudSettings";
import { FieldEditorProps } from "../fieldRenderersEditors";
import { FieldEditor, ScriptableUiFieldEditor } from "./FieldEditor";

interface Props extends FieldEditorProps, Partial<SelectExtProps> {
    fieldIntervals?: FieldInterval[]
}

export class DropdownFieldEditor extends FieldEditor<any, Props> {

    protected getDropdownLabel(fi: FieldInterval): any{
        return getDropdownItemLabel(this.props.fieldDescriptor, fi);
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const { props } = this;
        const { fieldDescriptor } = props;
        const options: SelectExtOption[] = [];
        const defaultValue: SelectExtOption[] = [];
        const value = this.getValue();

        const fieldIntervals = props.fieldIntervals || fieldDescriptor?.fieldDescriptorSettings?.fieldIntervals;
        fieldIntervals && fieldIntervals.forEach(fi => {
            const option = { value: fi.from, label: this.getDropdownLabel(fi) };
            options.push(option);
            // Cast "fi.from" to string because we can have settings when values come as numbers
            if (String(value) === String(fi.from)) {
                defaultValue.push(option);
            }
        });

        return <SelectExt isClearable={true} closeMenuOnSelect={true} options={options} isMulti={false} value={defaultValue}
            onSelectedOptionsChange={(selected: SelectExtOption[]) => s.setFieldValue(hw, selected[0]?.value || null)} {...this.getPropsToForward()} />
    }
}

export default DropdownFieldEditor;"../../components/selectExt/SelectExt""../fieldRenderers/DropdownFieldRenderer"