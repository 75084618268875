import { entityDescriptors } from "../../entity_crud/entityCrudConstants";

type FieldDescriptorForServer = { fid: number, fieldName: string, fieldType: string, fieldLabel: string, customField: boolean }
type EntityDescriptorForServer = { eid: number, entityName: string, fields: { [key: string]: FieldDescriptorForServer } }
export type EntityDescriptorsForServer = { [key: string]: EntityDescriptorForServer };

export class EntityDescriptorForServerUtils {

    static entityDescriptorsForServer: EntityDescriptorsForServer = {};

    static getEntityDescriptor(entity: string | number) {
        const entityName = EntityDescriptorForServerUtils.entityDescriptorsForServer[String(entity)]?.entityName;
    
        if (entityName) {
            const ed = entityDescriptors[entityName];
            if (ed) {
                return ed;
            }
        }
        return undefined;
    }
    
    static getEntityId(entity: string) {
        return EntityDescriptorForServerUtils.entityDescriptorsForServer[entity]?.eid;
    }
    
    static getFieldName(entity: string | number, field: string | number){
        return EntityDescriptorForServerUtils.entityDescriptorsForServer[String(entity)]?.fields[String(field)]?.fieldName;
    }

    static getFieldDescriptor(entity: string | number, field: string | number) {
        const fieldName = EntityDescriptorForServerUtils.getFieldName(entity, field);
        return fieldName ? EntityDescriptorForServerUtils.getEntityDescriptor(entity)?.getFieldDescriptorChain(fieldName)?.[0] : undefined;
    }

    static getFieldId(entity: string, field: string) {
        return EntityDescriptorForServerUtils.entityDescriptorsForServer[entity]?.fields[field]?.fid;
    }
    
}