import React from "react";
import { ScriptableUi, ScriptableUiImpl } from "./ScriptableUi";

export interface ComponentWithScriptableUiProps {
    scriptableUiId?: string;
}

export class ComponentWithScriptableUi<SUI extends ScriptableUi<any>, P = {}, S = {}, SS = any> extends React.Component<P & ComponentWithScriptableUiProps, S, SS> {
    
    protected scriptableUiClass!: abstract new (...args: any) => SUI;
    protected scriptableUiImpl!: ScriptableUiImpl<SUI>;

    protected getScriptableUiId(): string {
        return this.props.scriptableUiId || ScriptableUi.SINGLETON;
    }

    render(): React.ReactNode {
        if (!this.scriptableUiClass || !this.scriptableUiImpl) {
            throw new Error("Please make sure you populate the fields: scriptableUiClass, scriptableUiImpl");
        }
        return <this.scriptableUiClass id={this.getScriptableUiId()} implementation={this.scriptableUiImpl}>{s => this.renderWithScriptableUi(s as any)}</this.scriptableUiClass>
    }

    renderWithScriptableUi(s: SUI): React.ReactNode {
        throw new Error("Implement me!");
    }

}