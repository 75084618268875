import React from "react";
import { Button, Checkbox, Form, Message, Modal, Segment, TextArea } from "semantic-ui-react";
import "./RecordPlayModal.css";
import { ScriptableUi } from "./ScriptableUi";
import { ScriptableUiHighlightWrapper } from "./ScriptableUiHighlightWrapper";
import { ScriptableUiInterceptor } from "./ScriptableUiInterceptor";
import { Capture } from "./Capture";
import { FoundationUtils } from "@famiprog-foundation/utils";
import { ModalExt } from "../modalExt/ModalExt";

const DEFAULT_SCRIPT = `await ScriptableUi.play(async s => {\n});`;
export const ERROR_MESSAGE_LIB_INCLUDED_MULTIPLE_TIMES = `BIG WARNING: the library was included multiple times. Possible causes: using the lib in linked mode (e.g. "yarn link ...")? Or different versions exist in different "node_modules" dirs? Try e.g. "yarn why @famiprog-foundation/scriptable-ui". 
Please fix the issue! Otherwise dubious recording/playing will happen: some interceptions will be handled by "lib 1", others by "lib 2".`;

interface Props {
    onClose?: () => void
}

interface State {
    script: string;
    interceptor?: ScriptableUiInterceptor;
    recording?: boolean;
    playing?: boolean;
    pauseAfterInstruction?: boolean;
}

class RecordPlayModalInternal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            script: DEFAULT_SCRIPT,
            pauseAfterInstruction: true
        }
    }

    componentDidMount(): void {
        const that = this;
        const interceptor = new class extends ScriptableUiInterceptor {
            onRecordInstruction(capture: Capture): void {
                const prev = that.state.script;
                let insertPos = prev.lastIndexOf("});");
                if (insertPos < 0) {
                    insertPos = prev.length - 1;
                }

                that.setState({ script: prev.slice(0, insertPos) + capture.getGeneratedSourceCode2() + "\n});" })
            }

            async onIntercept(capture: Capture, scriptableUi: ScriptableUi<any>, highlighWrapper: ScriptableUiHighlightWrapper) {
                if (that.state.playing && that.state.pauseAfterInstruction) {
                    return new Promise<boolean>(async resolve => {
                        const onClickHandler = () => {
                            highlighWrapper!.cancelHighlight();
                            resolve(true);
                        };
                        
                        if (!await highlighWrapper!.highlight(<Button content="Next" onClick={onClickHandler} />)) {
                            console.log("Cannot get user input. Continuing.");
                            onClickHandler();
                        };
                    });
                }
                return super.onIntercept(capture, scriptableUi, highlighWrapper);
            }

        }();
        interceptor.install();
        this.setState({ interceptor })

    }

    componentWillUnmount(): void {
        this.state.interceptor!.uninstall();
        this.setState({ interceptor: undefined });
    }

    render() {
        const { recording, playing, interceptor, pauseAfterInstruction, script } = this.state;
        return <ModalExt open={true} transparentDimmer={true} className="RecordPlayModal" override-for-modal-ext="" onClose={this.props.onClose} closeIcon>
            <Modal.Header>
                ScriptableUi record/play <small><i>(the popup is moveable)</i></small>
            </Modal.Header>
            <Modal.Content className="RecordPlayModal_content">
                {globalThis.scriptableUiErrorMessageLibIncludedMultipleTimes && <Message error>{ERROR_MESSAGE_LIB_INCLUDED_MULTIPLE_TIMES}</Message>}
                <Segment>
                    <Button icon="circle" color="red" content={recording ? "Recording ..." : "Record"} disabled={playing} onClick={() => {
                        interceptor!.recording = !recording;
                        this.setState({ recording: !recording });
                    }} />
                    <Button icon="play" color="green" content={playing ? "Playing ..." : "Play"} disabled={playing || recording} onClick={async () => {
                        this.setState({ playing: true });
                        await FoundationUtils.setTimeoutAsync();
                        await eval(`async function f() {${script}}; f();`);
                        this.setState({ playing: false });
                    }} />
                    <Button icon="erase" content="Clear" disabled={playing || recording} onClick={() => this.setState({ script: DEFAULT_SCRIPT })} />
                    <Checkbox checked={pauseAfterInstruction} label="While playing, pause after each instruction" onClick={() => this.setState({ pauseAfterInstruction: !pauseAfterInstruction })} />&nbsp;
                </Segment>
                <Form className="RecordPlayModal_form"><TextArea className="RecordPlayModal_textArea" value={script} wrap="off" onChange={(e, data) => this.setState({ script: data.value as any })} /></Form>
            </Modal.Content>
        </ModalExt>
    }

}

// a very odd issue forced me to do this hack. I created: https://github.com/vitejs/vite/issues/16642
// UPDATE: meanwhile the offending line (cf. my message in the issue) was deleted.
export const RecordPlayModal = RecordPlayModalInternal;