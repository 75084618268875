import * as H from 'history';
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { AppMetaTempGlobals } from "../AppMetaTempGlobals";

export type NavLinkNavLinkWithPermissionProps<S = H.LocationState> = { permission: string, hidden: boolean | undefined } & React.PropsWithoutRef<NavLinkProps<S>> & React.RefAttributes<HTMLAnchorElement>;

/**
 * @author Daniela Buzatu
 */
export const NavLinkWithPermission = function (props: NavLinkNavLinkWithPermissionProps) {
    const { permission, ...menuProps } = props;
    if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission)) {
        // Some foundation based apps like Proteus need to not show at all the menu entry when no permission, instead of disabling it
        if (menuProps.to === "/") {
        }
        else if (AppMetaTempGlobals.appMetaInstance.removeMenuEntryWhenNoPermission) {
            return null;
        } else {
            const disabledStyle = { pointerEvents: "none", opacity: 0.5 };
            menuProps.style = menuProps.style ? { ...menuProps.style, ...disabledStyle } : disabledStyle as any;
        }
    }
    return <NavLink {...menuProps} />;
}