
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { HistoryGraphItemRRC } from "./HistoryGraphItem";

export class HistoryGraphItemFactory {
    static INSTANCE = new HistoryGraphItemFactory();
    entityTypes: { [key: string]: typeof HistoryGraphItemRRC[] } = {};

    addItemForEntityType(ed: EntityDescriptor, item: typeof HistoryGraphItemRRC) {
        if (!this.entityTypes[ed.name]) {
            this.entityTypes[ed.name] = [];
        }
        this.entityTypes[ed.name] = this.entityTypes[ed.name].concat(item);
        return this;
    }

    getItemsForEntityType(ed: EntityDescriptor) {
        if (!this.entityTypes[ed.name]) {
            return [];
        }
        return this.entityTypes[ed.name];
    }
};
"../../../entity_crud/EntityDescriptor"