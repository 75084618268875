import { Utils } from "@crispico/foundation-react";
import { Datum, DatumValue, LineProps, PointSymbolProps, Serie } from "@nivo/line";
import React, { ReactNode } from "react";
import SplitPane from "react-split-pane";
import { Button, Checkbox, Grid, Icon, Input, Label, List, Segment, SemanticCOLORS } from "semantic-ui-react";
import { ResponsivePieExt, PieDatum } from "@crispico/foundation-react/components/nivoExt";
import { ChartCurrentSelection } from "@crispico/foundation-react/components/ChartCurrentSelection/ChartCurrentSelection";
import moment from "moment";
import { ResponsiveLineChart, ResponsiveLineChartRRC } from "@crispico/foundation-react/components/responsiveLineChart/ResponsiveLineChart";
import Measure from "react-measure";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import lodash from 'lodash';
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { ChartMapping } from "../BarChart/BarChart";
import { HistogramWithDetailsMeta } from "./HistogramWIthDetailsMeta";
import { ShortcutRefForTest } from "@famiprog-foundation/tests-are-demo";

const { testids } = HistogramWithDetailsMeta;
export const DEFAULT_HISTOGRAM_ROWS: HistogramRow[] = [
    { key: "total", title: _msg("HistogramWithDetails.total"), color: "blue" },
    { key: "in", title: _msg("HistogramWithDetails.enter"), color: "green" },
    { key: "out", title: _msg("HistogramWithDetails.exit"), color: "red" }
];

export interface HistogramSerie extends Serie {
    color: string,
    data: HistogramPoint[],
    maxY?: number
}

export interface PieEntry {
    id: string;
    label: string;
    value: number;
    color: string
}

export interface HistogramPoint extends Datum {
    id: number;
    serieId?: string | number;
    x_label?: string;

    /* For the moment just these keys were used so are considered as defaults, but with the help of HistogramGridOptions.rows everything is dynamic. So the type below can be seen as `[key: string]: string[]`.
    E.g: Add 'customRow' in HistogramPoint in creation of a serie by a component.
    So should add props as HistogramGridOptions.rows = { key: "customRow" } to display/export it.
    */
    in?: string[];
    out?: string[];
    total?: string[];
}

export interface HistogramRow {
    key: string, // the keys should matches what the dev added in HistogramPoint, by default exists examples with "in, out, total" 
    title?: string,
    color?: string
}

export interface HistogramGridOptions {
    rows: HistogramRow[],
}

export class HistogramWithDetailsState extends State {
    pieData = [] as Array<PieDatum>;
    displaySerieIds = {} as { [key: string]: boolean };
    selectedSerieIndex = undefined as number | undefined;
    selectedPointIndex = undefined as number | undefined;
    isPinnedMode = false;
    previousSelectedPointId = undefined as string | undefined;
    quickSearchedText = undefined as string | undefined;
    averagePerSerieIndex = {} as { [key: number]: number };
}

export class HistogramWithDetailsReducers<S extends HistogramWithDetailsState = HistogramWithDetailsState> extends Reducers<S> {
    updateDisplaySerieIds(id: string | number, selectedId: string | undefined) {
        this.s.displaySerieIds[id] = !this.s.displaySerieIds[id];
        if (selectedId && selectedId === id) {
            this.s.isPinnedMode = false;
            this.s.selectedPointIndex = undefined;
        }
    }

    updateAveragePerSerieId(id: number, average: number) {
        this.s.averagePerSerieIndex[id] = average;
    }
}

type HistogramWithDetailsProps = {
    data: Array<HistogramSerie>,
    startDate: number,
    endDate: number,
    legendX?: string,
    legendY?: string,
    gridSerieLabel?: string,
    axisDateFormat?: string,
    gridOptions?: HistogramGridOptions,
    hideExportButton?: boolean
    lineCurve?: LineProps['curve'];
    /* In essence should be small components which wrap a text. Most use case is returning NavLink. */
    renderHistogramPointRows?: (point: HistogramPoint, rowKey: string, rowColor: string) => (React.ReactNode | string)[];
    renderHistogramSerieLabel?: (serie: HistogramSerie) => React.ReactNode | string;
};

type Props = RRCProps<HistogramWithDetailsState, HistogramWithDetailsReducers> & HistogramWithDetailsProps;
type LocalState = { height: number };
export class HistogramWithDetails extends React.Component<Props, LocalState> {

    oldMouseOverId?: string;

    protected responsiveLineChartRef = React.createRef<ResponsiveLineChart>();

    private prepareData() {
        let displaySerieIds = {} as { [key: string]: boolean };
        this.props.data.forEach((value, index) => {
            displaySerieIds[value.id] = true;
            this.updateAveragePerSerie(value, index);
        });
        this.props.r.setInReduxState({ displaySerieIds, isPinnedMode: false, selectedPointIndex: undefined, selectedSerieIndex: undefined });
    }

    constructor(props: Props) {
        super(props);
        this.state = { height: 0 };
    }

    componentDidMount() {
        this.prepareData();
    }

    private updateDisplaySerieIds(id: string | number) {
        this.props.r.updateDisplaySerieIds(id, this.props.s.selectedSerieIndex ? this.props.data[this.props.s.selectedSerieIndex].id as string : undefined);
    }

    private updateAveragePerSerie(serie: HistogramSerie, serieIndex: number) {
        let sum = 0;
        serie.data.forEach(point => {
            // number or string as number, otherwise a date
            if (Number(point.y)) {
                sum += Number(point.y);
            } else if (moment(point.y).isValid()) {
                sum += moment(point.y).valueOf();
            }
        });
        this.props.r.updateAveragePerSerieId(serieIndex, Number((sum / serie.data.length).toFixed(2)));
    }

    updateDisplaySerieIdsForTest(id: string | number) {
       this.updateDisplaySerieIds(id);
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.data, props.data)) {
            this.prepareData();
        }
    }

    getRowsForExport() {
        const histogramRows = this.getHistogramRows();
        let rows = [] as any[];
        const header = [_msg("general.name"), _msg("general.date")];
        if (this.props.legendY) {
            header.push(_msg("HistogramWithDetails.legendY.label", this.props.legendY));
            histogramRows.forEach(histogramRow => header.push(histogramRow.title || histogramRow.key));
        }
        rows.push(header);
        this.props.data.forEach(serie => serie.data.forEach(entry => {
            const row: any[] = [serie.id, moment(entry.x).format(Utils.dateTimeWithSecFormat)];
            if (this.props.legendY) {
                row.push(entry.y);
                histogramRows.forEach(histogramRow => {
                    row.push(entry[histogramRow.key]);
                })
            }
            rows.push(row);
        }));

        return rows;
    }

    private updatePieAndFooterData(p: { pointX: number, pointY: number, pointIndex: number, polygonLabel: string }) {
        let pieData = [] as PieDatum[];
        for (let i = 0; i < this.props.data.length; i++) {
            let pieEntryValue = 0 as DatumValue | null | undefined;

            if (this.props.data[i].id !== p.polygonLabel) {
                let previous = 0;
                for (let j = 0; j < this.props.data[i].data.length; j++) {
                    if (this.props.data[i].data[j].x as number > p.pointX) {
                        // no value found in this interval (e.g. selected 10:00 and all other points >10:00 => 11:00, 15:00)
                        if (j === 0) {
                            break;
                        }
                        pieEntryValue = this.props.data[i].data[previous].y
                        break;
                    } else {
                        previous = j
                    }
                    // arrived at the end of list and no value found, all points are too early
                    // e.g. selected point = 10:00 and others = 05:00 ,06:00, 07:00, the result will
                    // be the closest value found in the list (07:00)
                    if (j === this.props.data[i].data.length - 1 && previous === j) {
                        pieEntryValue = this.props.data[i].data[previous].y
                    }
                }
            } else {
                this.props.r.setInReduxState({ selectedSerieIndex: i as number });
                pieEntryValue = p.pointY;
            }
            if (pieEntryValue !== 0) {
                const pieEntry: PieDatum = {
                    id: this.props.data[i].id,
                    label: this.props.data[i].id,
                    color: this.props.data[i].color,
                    value: pieEntryValue as number,
                }
                pieData.push(pieEntry);
            }
        }

        this.props.r.setInReduxState({ pieData, selectedPointIndex: p.pointIndex });
    }

    updatePieAndFooterDataForTest(p: { pointX: number, pointY: number, pointIndex: number, polygonLabel: string }) {
        this.updatePieAndFooterData(p);
    }

    renderList() {
        return <List data-testid={testids.seriesList} className="HistogramWithDetails_legend">
            {this.props.data.map((data, idx) => {
                return <List.Item key={data.id} className='flex-container-row flex-center'>
                    <Checkbox data-testid={testids.serieCheckbox + "_" + idx} className="tiny-margin-right" checked={this.props.s.displaySerieIds[data.id]} onChange={() => this.updateDisplaySerieIds(data.id)} />
                    <span className="tiny-margin-right" style={{ flexGrow: 1, textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }}>{[(this.props.renderHistogramSerieLabel ? this.props.renderHistogramSerieLabel(data) : data.id), (data.maxY ? (" (" + data.maxY + ")") : "")]}</span>
                    <Label circular style={{ color: 'white', backgroundColor: data.color, minWidth: "1em", minHeight: "1em" }} />
                </List.Item>
            })}
        </List>;
    }

    getPointSymbol = ({ size, color, borderWidth, borderColor, datum }: PointSymbolProps) => {
        const selectedPoint = this.getSelectedPoint();
        if (selectedPoint?.id === datum.id && selectedPoint?.serieId === datum.serieId) {
            return (<g >
                <circle data-testid={testids.serieSelectedPoint} fill="#fff" r={size * 2} strokeWidth={borderWidth} stroke={borderColor} />
                <circle r={size * 2} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
            </g>)
        } else {
            return <circle data-testid={testids.seriePoint + "_" + datum.id + "_" + datum.serieId} r={size / 2} fill={color} pointerEvents="all" />
        }
    }

    getSelectedPoint() {
        const props = this.props;

        let point = null;
        if (props.s.selectedSerieIndex !== undefined) { // test against undefined because can be 0
            point = props.data[props.s.selectedSerieIndex!]?.data[props.s.selectedPointIndex!];
        }
        return point;
    }

    drawBlockingButton() {
        const props = this.props;
        let icon: any
        if (props.s.isPinnedMode) {
            icon = <Icon name='pin' />
        } else {
            icon = <Icon name='pin' rotated="clockwise" />
        }
        return (<React.Fragment>
            <Button data-testid={testids.pinButton} toggle active={props.s.isPinnedMode} onClick={(e) => props.r.setInReduxState({ isPinnedMode: !this.props.s.isPinnedMode })}>
                {icon}{_msg("HistogramWithDetails.point." + (props.s.isPinnedMode ? 'pinned' : 'notPinned'))}</Button>
        </React.Fragment>)

    }

    renderListInfo(point: HistogramPoint, rowKey: string, rowColor: string) {
        // e.g ["John", "Jameson", "Alberto"]
        if (Utils.isNullOrEmpty(point[rowKey])) {
            return null;
        }
        const rowValues: string[] = point[rowKey];
        const renderedRowElements: (React.ReactNode)[] = (this.props.renderHistogramPointRows ? this.props.renderHistogramPointRows(point, rowKey, rowColor) : rowValues)
            .map((element, index, elements) => {
                var renderedElement = element;
                // Search trough corresponding value for quickSearchedText and mark the element if matches
                if (!Utils.isNullOrEmpty(this.props.s.quickSearchedText) && rowValues[index].toLowerCase().indexOf(this.props.s.quickSearchedText!.toLowerCase()) > -1) {
                    renderedElement = <mark>{element}</mark>
                }

                // add comma for all elements except last
                return index < elements.length - 1 ? <React.Fragment>{renderedElement}, </React.Fragment> : <React.Fragment>{renderedElement}</React.Fragment>
            })

        return <React.Fragment>
            <Label data-testid={testids.infoLabel + "_" + rowColor} circular color={rowColor as SemanticCOLORS} >{renderedRowElements.length}</Label>
            <React.Fragment>{renderedRowElements}</React.Fragment><br />
        </React.Fragment>
    }

    getHistogramRows(): HistogramRow[] {
        return Utils.isNullOrEmpty(this.props.gridOptions?.rows) ? DEFAULT_HISTOGRAM_ROWS : this.props.gridOptions!.rows;
    }

    renderFooterInfo(point: HistogramPoint) {
        const props = this.props;
        const colorValue = props.data[props.s.selectedSerieIndex!].color;
        const messageColumnWidth = 2;
        const valueColumnWidth = 14;
        const rows: HistogramRow[] = this.getHistogramRows();

        // added opacity=1 for removing opacity from text
        return (<Grid celled='internally'>
            <Grid.Row>
                <Grid.Column data-testid={testids.gridSerieLabel} width={messageColumnWidth}>{this.props.gridSerieLabel ? this.props.gridSerieLabel : ""}</Grid.Column>
                <Grid.Column width={valueColumnWidth} >
                    <Label style={{ color: colorValue }}><Icon name='flag' /><b data-testid={testids.selectedSerie}> {
                        <React.Fragment key="gridSerie">{this.props.renderHistogramSerieLabel ? this.props.renderHistogramSerieLabel(props.data[props.s.selectedSerieIndex!]) : props.data[props.s.selectedSerieIndex!].id}</React.Fragment>
                    } </b></Label>
                </Grid.Column>
            </Grid.Row>
            {rows.map(row => <Grid.Row>
                <Grid.Column width={messageColumnWidth}>{row.title || row.key}</Grid.Column>
                <Grid.Column width={valueColumnWidth}>{this.renderListInfo(point, row.key, row.color || "blue")}</Grid.Column>
            </Grid.Row>
            )}
        </Grid>);
    }

    render() {
        const props = this.props;
        const selectedPoint = this.getSelectedPoint();
        const displaySerieIdsPie = this.props.s.pieData.filter(p => props.s.displaySerieIds[p.id]);
        const displaySerieIdsHistogram = this.props.data.filter(h => this.props.s.displaySerieIds[h.id]);
        let mapping: ChartMapping[] = [];
        this.props.data.forEach((data, idx) => {
            if (data.maxY) {
                mapping.push({
                    value: data.maxY,
                    color: data.color, id: idx,
                    label: _msg("HistogramWithDetails.maxY.label", data.id),
                    lineStyle: { strokeDasharray: "3, 6" }
                });
            }
        })
        return <div className="flex-container flex-grow-shrink-no-overflow gap5">
            <ShortcutRefForTest objectToPublish={this} />
            <SplitPaneExt split="horizontal" defaultSize="40%" style={{ position: "static", minHeight: "auto" }} primary="second" >
                <Segment className="wh100 overflow-hidden">
                    {this.props.data.length > 0 ? <><SplitPane split="vertical" defaultSize="15%" minSize={100} >
                        <div className="wh100 less-padding">{this.renderList()}</div>
                        <Measure bounds onResize={(contentRect) => this.setState({ height: contentRect.bounds?.height ? contentRect.bounds?.height : 0 })}>
                            {({ measureRef }) => {
                                return <div className="wh100 less-padding small-margin-right" ref={measureRef}>
                                    <ResponsiveLineChartRRC id="lineChart" ref={this.responsiveLineChartRef}
                                        hasZoomSlider={true} mapping={mapping} axisDateFormat={this.props.axisDateFormat}
                                        startDate={this.props.startDate} endDate={this.props.endDate}
                                        currentStartDate={this.props.startDate} currentEndDate={this.props.endDate}
                                        series={displaySerieIdsHistogram} curve={this.props.lineCurve ? this.props.lineCurve : "linear"} parentHeight={this.state.height}
                                        pointSymbol={this.getPointSymbol} legendY={this.props.legendY ? _msg("HistogramWithDetails.legendY.label", this.props.legendY) : ""} legendX={this.props.legendX}
                                        onClick={e => {
                                            const index = (e.data as any).index !== undefined ? (e.data as any).index : parseInt(e.id.substring(e.id.indexOf('.') + 1));
                                            if (this.props.s.isPinnedMode && this.props.s.previousSelectedPointId === e.id) {
                                                this.props.r.setInReduxState({ isPinnedMode: false, previousSelectedPointId: undefined });
                                            } else {
                                                this.updatePieAndFooterData({ pointX: (e.data.x as Date).getTime(), pointY: e.data.y as number, pointIndex: index, polygonLabel: e.serieId as string });
                                                this.props.r.setInReduxState({ isPinnedMode: true });
                                            }
                                            if (this.props.s.isPinnedMode) {
                                                this.props.r.setInReduxState({ previousSelectedPointId: e.id });
                                            }
                                        }}
                                        onMouseMove={(e: any) => {
                                            if (this.props.s.isPinnedMode || e.id === this.oldMouseOverId) {
                                                return;
                                            }
                                            this.oldMouseOverId = e.id;
                                            this.updatePieAndFooterData({ pointX: (e.data.x as Date).getTime(), pointY: e.data.y as number, pointIndex: (e.data as unknown as HistogramPoint).index, polygonLabel: e.serieId as string });
                                        }}
                                    />
                                </div>;
                            }}
                        </Measure>
                    </SplitPane></> : null}
                </Segment>
                <div className="HistogramWithDetails_segmentPie">
                    <ChartCurrentSelection date={selectedPoint ? selectedPoint.x_label || moment(selectedPoint.x).format(Utils.dateTimeFormat) : undefined} currentPointDataCyAttribute="Chart.histogram.date"
                        contentHeaderRight={
                            !this.props.hideExportButton && <Button className="small-margin-top" data-testid={testids.exportButton} primary onClick={() => Utils.exportToCsv("HistogramWithDetails", this.getRowsForExport())}><Icon name="download" style={{ color: "white" }} /> {_msg("dto_crud.export")}</Button>
                        }
                        contentHeader={
                            <>
                                <Label className="gap3" size="large" basic>{_msg("general.average")}: {this.props.s.averagePerSerieIndex[this.props.data.length == 1 ? 0 : this.props.s.selectedSerieIndex!]}</Label>
                                {_msg("CalculateForRecords.quickSearch")} <Input className="less-margin-top-bottom" size="mini" iconPosition="left" icon='search' onChange={(e, data) => this.props.r.setInReduxState({ quickSearchedText: data.value })} />
                                <div className="small-margin-top float-left">{this.drawBlockingButton()} <i>{_msg("HistogramWithDetails.point.info")}</i></div>
                            </>
                        }
                        contentMain={
                            <Segment className="flex-container flex-grow less-padding less-margin-top-bottom">
                                <SplitPaneExt defaultSize="25%">
                                    <div className="HistogramWithDetails_pieContainer">
                                        <ResponsivePieExt data={displaySerieIdsPie} arcLabelsTextColor="white"
                                            layers={['arcs', 'arcLabels', 'arcLinkLabels']} colors={{ datum: 'data.color' }} animate={false}
                                            tooltip={({ datum: { id, value, color } }) => (
                                                <div className={'ResponsivePieChart_Tooltip'}>
                                                    <div style={{ backgroundColor: color, width: '12px', height: '12px', display: 'inline-block' }}></div> <strong>{id}</strong>: {value} {this.props.legendY ? this.props.legendY : ""}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="HistogramWithDetails_segment HistogramWithDetails_segmentWithScroll">
                                        {selectedPoint && this.renderFooterInfo(selectedPoint)}
                                    </div>
                                </SplitPaneExt>
                            </Segment>
                        }
                    />
                </div>
            </SplitPaneExt>
        </div>;
    }
}

export const HistogramWithDetailsRRC = ReduxReusableComponents.connectRRC(HistogramWithDetailsState, HistogramWithDetailsReducers, HistogramWithDetails);
"../..""../nivoExt""../ChartCurrentSelection/ChartCurrentSelection""../responsiveLineChart/ResponsiveLineChart""../ReactSplitPaneExt/ReactSplitPaneExt""../../reduxReusableComponents/ReduxReusableComponents"