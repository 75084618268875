import { Button } from "semantic-ui-react";
import { LOCAL_STORAGE_TESTS_TO_RUN_SERVER, TestClassDescriptor, TestFunctionDescriptor, TestsToRun } from "../TestsAreDemoMaster";
import { Featurebook, FeaturebookUiProps, FeaturebookUiState } from "./Featurebook";
import { TestClassComponentProps } from "./TestClassComponent";
import { fetchJsonCredentials, fetchJsonSafe, fetchSafe, getTestToRunCountLabel } from "./functions";
import { FoundationUtils } from "@famiprog-foundation/utils";
import { ReactNode } from "react";
import { ModalForTestsServer } from "../featureBookServer/ModalForTestsServer";

interface FeaturebookServerProps extends FeaturebookUiProps {
    serverUrlPrefix: string;
}

interface FeaturebookServerState extends FeaturebookUiState {
    testClassDescriptors?: TestClassDescriptor[];
    testsToRun?: TestsToRun;
    modalOpen?: boolean;
}

export class FeaturebookServer extends Featurebook<FeaturebookServerProps, FeaturebookServerState> {

    protected getTestClassDescriptors(): TestClassDescriptor[] | undefined {
        return this.state.testClassDescriptors;
    }

    protected getTestsToRun(): TestsToRun | undefined {
        return this.state.testsToRun;
    }

    updateTestsToRun(testsToRun: TestsToRun) {
        this.setState({ testsToRun });
        localStorage.setItem(LOCAL_STORAGE_TESTS_TO_RUN_SERVER, JSON.stringify(testsToRun));
    }

    async componentDidMount() {
        const testsToRunString = localStorage.getItem(LOCAL_STORAGE_TESTS_TO_RUN_SERVER);
        if (testsToRunString) {
            this.setState({ testsToRun: JSON.parse(testsToRunString) });
        }

        const testClassDescriptors: TestClassDescriptor[] = await fetchJsonSafe(this.props.serverUrlPrefix + "testGoodiesController/getTestClassDescriptors");
        this.setState({ testClassDescriptors });
        // let the state 
        await FoundationUtils.setTimeoutAsync();
        this.onTestClassDescriptorsChanged();
    }

    protected async setSelectedDescriptor(selectedDescriptor: TestClassDescriptor) {
        const functions: TestFunctionDescriptor[] = await fetchJsonSafe(this.props.serverUrlPrefix + "testGoodiesController/getMethodsWithComments?className=" + selectedDescriptor.name);
        super.setSelectedDescriptor({ ...selectedDescriptor, functions });
    }

    protected renderInHeader() {
        return <>
            {this.state.modalOpen && <ModalForTestsServer parent={this}/>}
            <Button disabled={this.props.running} icon="play" content={"Run " + getTestToRunCountLabel(this.getTestsToRun())} onClick={e => this.setState({ modalOpen: true })} />
            <Button content="Save test descriptors" onClick={async e => {
                await fetchSafe(this.props.serverUrlPrefix + "testGoodiesController/saveTestClassDescriptors");
                alert("Done!");
            }} />
        </>
    }
}