import _ from "lodash";
import { PuppeteerMessageWaitingForNextStep, PuppeteerMessageWriteMarkdownReport, CommonLibAndNodeDefaultExport } from "../common-lib-and-node/common-types";
import { TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { SourceCodeInfo } from "./TraceMapCache";
import { Utils } from "../copied/Utils";
import { RecordedTest } from "./recordedTest/RecordedTest";

// @ts-ignore
import d from "../common-lib-and-node/common.mjs";
const { SMALL_SUFFIX } = (d as CommonLibAndNodeDefaultExport);

interface ReportSourceFile {
    sourceCode: string;
    screenshots: { [line: number]: ReportScreenshot[] };
}

interface ReportScreenshot {
    invocationIndex: number;
    file: string;
}

export class Report {
    protected files: { [sourceFile: string]: ReportSourceFile } = {};
    protected lastInvocationIndex = 0;
    visibleLinesForFirstAndLastChunk = 10;
    protected lastSourceCodeState?: SourceCodeInfo;
    protected waitForErrorPromise?: Promise<string>;
    protected waitForErrorPromiseResolve?: (error: string) => void;
    protected error?: string;

    lastScreenshotNumber = 0;
    lastScreenshotName?: string | null;
    testIdChainToCountForFileName: { [testId: string]: number } = {};

    /**
     * If exists, a call to puppeteer is in progress.
     */
    messageSentToPuppeteer?: PuppeteerMessageWaitingForNextStep;
    resumeSendingReportToPuppeteerCallback?: Function;

    protected recodedTest: RecordedTest = { slides: [], sourceFiles: {}, duration: -1 };
    protected creationTime = new Date().getTime();

    constructor(protected master: TestsAreDemoMaster) {
        // nop
    }

    protected calculateLastScreenshotName(lastElementCaptured: Element | null | undefined) {
        let screenshotFileName = lastElementCaptured?.getAttribute(this.master.testIdProp) ? "" : "_LAP"; // means "looked at parents"
        let current = lastElementCaptured;
        while (current) {
            const currentTestid = current.getAttribute(this.master.testIdProp);
            if (currentTestid) {
                if (!screenshotFileName) {
                    screenshotFileName = currentTestid
                } else {
                    screenshotFileName = currentTestid + "_" + screenshotFileName;
                }
            }
            current = current.parentElement;
        }

        if (this.testIdChainToCountForFileName[screenshotFileName] !== undefined) {
            this.testIdChainToCountForFileName[screenshotFileName]++;
            screenshotFileName += "_REP" + this.testIdChainToCountForFileName[screenshotFileName];
        } else {
            this.testIdChainToCountForFileName[screenshotFileName] = 0;
        }
        this.lastScreenshotNumber++;
        this.lastScreenshotName = screenshotFileName;
    }

    onShowSpotlight(sourceCodeState: SourceCodeInfo | undefined, lastElementCaptured: Element | null | undefined) {
        this.calculateLastScreenshotName(lastElementCaptured);

        if (this.messageSentToPuppeteer) {
            const message = "Illegal state: paused/waiting because of `showSpotlight()`, but `showSpotlight()` called again. Maybe there is somewhere an invocation to a function like `tad.awaitable...` w/o prefixing w/ `await`?\n" +
                `Old screenshot = ${JSON.stringify(this.messageSentToPuppeteer, undefined, " ")}, new screenshot = ${JSON.stringify(this.createMessageForPuppeteer(), undefined, " ")}`;
            throw new Error(message);
        }

        if (!sourceCodeState) {
            if (!this.lastSourceCodeState) {
                // the source map code failed. Fallback: we'll add the screenshots, w/o code
                this.lastSourceCodeState = { sourceFile: "Source file not available", sourceCode: "", sourceLine: 1, sourceColumn: 1 };
            } // else: happens for the "type" action. We do intercept the code, but the stack trace doesn't lead any more to the test. 
            // For the moment, the workaround to use the last line: seems OK

            sourceCodeState = this.lastSourceCodeState;
        } else {
            this.lastSourceCodeState = sourceCodeState;
        }
        const { sourceFile, sourceCode, sourceLine } = sourceCodeState;

        let reportSourceFile = this.files[sourceFile];
        if (!reportSourceFile) {
            reportSourceFile = { sourceCode, screenshots: {} };
            this.files[sourceFile] = reportSourceFile;
        }
        let screenshots = reportSourceFile.screenshots[sourceLine];
        if (!screenshots) {
            screenshots = [];
            reportSourceFile.screenshots[sourceLine] = screenshots;
        }
        screenshots.push({ invocationIndex: ++this.lastInvocationIndex, file: this.lastScreenshotName! });

        if (!this.recodedTest.sourceFiles[sourceFile]) {
            this.recodedTest.sourceFiles[sourceFile] = sourceCode;
        }
        this.recodedTest.slides.push({
            screenshot: this.lastScreenshotName!,
            sourceFile, sourceLine
        });

        this.sendScreenshotToPuppeteer();
    }

    async sendScreenshotToPuppeteer() {
        this.messageSentToPuppeteer = this.createMessageForPuppeteer();
        if (this.master.slave.lastElementCaptured) {
            const rectSrc = this.master.slave.lastElementCaptured.getBoundingClientRect();
            this.messageSentToPuppeteer.rect = { x: rectSrc.x, y: rectSrc.y, width: rectSrc.width, height: rectSrc.height };
        }
        // this is how the puppeteer script will know that know is the time to take
        // a screenshot, and then "press" on next
        this.master.sendMessageToPuppeteer("waitingForNextStep", this.messageSentToPuppeteer);
    }

    protected createMessageForPuppeteer(): PuppeteerMessageWaitingForNextStep {
        return {
            dir: this.master.slave.reporterFromSlaveToMaster.currentClass + "/" + this.master.slave.reporterFromSlaveToMaster.currentFunction,
            screenshotNumber: this.lastScreenshotNumber,
            fileWithoutExtension: this.lastScreenshotName!,
        };
    }

    /**
     * Called by puppeteer when it finished saving the screenshot on the disk.
     */
    onScreenshotSavedByPuppeteer(whatPuppeteerSaved: PuppeteerMessageWaitingForNextStep) {
        if (this.messageSentToPuppeteer!.dir !== whatPuppeteerSaved.dir || this.messageSentToPuppeteer!.fileWithoutExtension !== whatPuppeteerSaved.fileWithoutExtension || this.messageSentToPuppeteer!.screenshotNumber !== whatPuppeteerSaved.screenshotNumber) {
            throw new Error(`Something went wrong. Puppeteer and the app are not in sync any more. Screenshot sent by the app to be saved = ${JSON.stringify(this.messageSentToPuppeteer, undefined, " ")}. But puppeteer says it just saved = ${JSON.stringify(whatPuppeteerSaved, undefined, " ")}`);
        } else if (this.master.slave.reporterFromSlaveToMaster.testFailed) {
            // An async error was raised, which finished the current test. So we don't want to call the callback, which
            // will practically continue the test, provoking like multi threading

            // while waiting, we wanted to print the report. But we paused that as well, i.e. this callback.
            // so let's continue
            this.resumeSendingReportToPuppeteerCallback?.();
            this.messageSentToPuppeteer = undefined;
        } else {
            this.master.nextStep();
        }
    }

    waitForError() {
        if (this.waitForErrorPromise) {
            throw new Error("Illegal! waitForError() was called, errorArrived() was NOT called, and then waitForError() was called again");
        }
        this.waitForErrorPromise = new Promise(resolve => this.waitForErrorPromiseResolve = resolve);
    }

    errorArrived(error: string) {
        this.recodedTest.error = error;
        this.waitForErrorPromiseResolve?.(error);
    }

    async getError(): Promise<string | undefined> {
        // respectively: error arrived, error will arrive, no error
        return this.error || this.waitForErrorPromise || undefined;
    }

    protected processComment(comment: string) {
        let processedComment = comment;
        processedComment = processedComment.replace(/^\s*/, ""); // remove all white space like, from the beginning until the first "printable" character
        processedComment = processedComment.replace(/\s*$/, ""); // remove all white space like, from the last "printable" character until the end
        return processedComment.split(/\n/); // split by new line
    }

    async printMarkdown(dir: string) {
        const c = Utils.substringBefore(dir, ".", true);
        const f = Utils.substringAfter(dir, ".", true);
        const clazz = this.master.state.testClassDescriptors?.find(d => d.name === c);
        const func = clazz?.functions.find(d => d.functionName === f);
        let result = `
# ${dir}()

### ${func?.scenario}
`;
        if (func?.comments) {
            result += "\n```\n";
            func?.comments?.forEach(c => {
                this.processComment(c).forEach(l => { result += `// ${l.trim()}\n`; });
            });
            result += "```\n";
        }
        result += `
---
`;
        const error = await this.getError();
        (error !== undefined) && (result += `
## The following error was caught while running the test:

\`\`\`
${error}
\`\`\`

---

`);
        result +=
            `
There are ${this.lastInvocationIndex} screenshots. [Go to first](#screenshot-1)
`;

        for (let sourceFile in this.files) {
            const reportSourceFile = this.files[sourceFile];
            result += `
## ${sourceFile}
`;
            const screenshotsAsNumbers = Object.getOwnPropertyNames(reportSourceFile.screenshots).map(pn => parseInt(pn));
            screenshotsAsNumbers.sort((a, b) => a - b);

            const lines = reportSourceFile.sourceCode.split("\n");
            const digits = lines.length.toString().length;
            lines.forEach((line, i) => lines[i] = "/*" + (i + 1).toString().padStart(digits) + "*/" + line);

            let previousLine = 0;

            // hide lines at the beginning
            const firstLine = screenshotsAsNumbers.length > 0 && screenshotsAsNumbers[0];
            if (firstLine !== false && firstLine > this.visibleLinesForFirstAndLastChunk) {
                previousLine = firstLine - this.visibleLinesForFirstAndLastChunk;
                result += this.printChunk(dir, lines, 0, previousLine);
            }

            // print chunks = code + screenshots
            for (let line of screenshotsAsNumbers) {
                result += this.printChunk(dir, lines, previousLine, line, reportSourceFile.screenshots[line]);
                previousLine = line;
            }

            // hide lines at the end
            result += this.printChunk(dir, lines, previousLine, undefined);
        }
        return result;
    }

    protected printChunk(dir: string, lines: string[], startIndex: number, endIndex: number | undefined, screenshots?: ReportScreenshot[]) {
        let result = `${screenshots ? "" : "\n<details><summary>Click to expand the hidden lines of code</summary>\n"}
\`\`\`tsx
${lines.slice(startIndex, endIndex).join("\n")}
\`\`\`
${screenshots ? "" : "\n</details>\n"}`;
        if (!screenshots) { return result; }

        for (let screenshot of screenshots) {
            result += `
<table><tr>
<td>

### Screenshot ${screenshot.invocationIndex}

${screenshot.invocationIndex <= 1 ? "" : `[Go to previous](#screenshot-${screenshot.invocationIndex - 1})`}${screenshot.invocationIndex <= 1 || screenshot.invocationIndex >= this.lastInvocationIndex ? "" : " | "
                }${screenshot.invocationIndex >= this.lastInvocationIndex ? "" : ` [Go to next](#screenshot-${screenshot.invocationIndex + 1})`}

<details><summary>Click to expand full image</summary>

${dir}/${screenshot.file}.png <br/>
![](${screenshot.file}.png)

</details>
</td>
<td>

![](${screenshot.file}${SMALL_SUFFIX}.png)

</td>
</tr></table>
`;
        }
        return result;
    }

    async sendMarkdownToPuppeteer() {
        this.recodedTest.duration = new Date().getTime() - this.creationTime;
        let resolve: Function;
        const f = async () => {
            const dir = this.master.slave.reporterFromSlaveToMaster.currentClass + "/" + this.master.slave.reporterFromSlaveToMaster.currentFunction;
            this.master.sendMessageToPuppeteer("writeMarkdownReport", {
                dir,
                markdown: await this.printMarkdown(dir),
                recordedTest: this.recodedTest
            } as PuppeteerMessageWriteMarkdownReport);
            this.resumeSendingReportToPuppeteerCallback = undefined;
            resolve?.apply(null);
        }

        if (this.messageSentToPuppeteer) {
            // call to puppeteer still in progress
            if (this.master.slave.reporterFromSlaveToMaster.testFailed) {
                // the test has failed; some async code, e.g. something within a `setTimeout()`
                // let's wait until puppeteer returns; then we'll continue
                // we want to stop the communication w/ puppeteer for the moment, to let it properly finish its
                // current job (i.e. printing images). Not doing so, we'll start 2 "pseudo"-threads
                console.log("We want to print the Markdown report. But the call from puppeteer didn't return. So we'll wait, and then we'll continue w/ writing the report");

                this.resumeSendingReportToPuppeteerCallback = f;
                return new Promise<void>(r => resolve = r);
            } else {
                throw new Error("There is no test failure (because of async code), a screenshot save is in progress, so why are we trying to send the report?");
            }
        } else {
            await f();
        }
    }
}