import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import React from "react";
import { Button, Checkbox, CheckboxProps, Dimmer, Dropdown, DropdownItemProps, DropdownProps, Form, Icon, Label, Loader, Menu, Modal, Popup, Radio, Tab, TabProps, TextArea, TextAreaProps } from "semantic-ui-react";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { ParseWorkerConfig, ParseResult, UnparseConfig } from 'papaparse';
import { usePapaParse } from 'react-papaparse';
import { Cell, Column, Table } from "fixed-data-table-2";
import Measure from "react-measure";
import { FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { AuditUtils } from "@crispico/foundation-react/pages/Audit/AuditUtils";
import { Optional } from "@crispico/foundation-react/CompMeta";
import _ from "lodash";
import { createTestids } from "@famiprog-foundation/tests-are-demo";

export const csvDelimiter = ",";
// textArea always uses \n as the end of the line, so if you change the constant below, 
// it should also be treated for textArea (the separator cannot be set, but string.replace can be used)
export const csvNewLine = "\n";
export const csvComment = "////";
export const regexCsvText = new RegExp("(" + csvComment.replace(new RegExp(_.escapeRegExp("/"), "g"), "\\/") + "\\{.*\\}\\" + csvNewLine + ")", "g");//ex: ////{"entity":"Employee"}\n

export function getCsvOptions(complete: (results: ParseResult<{}>) => void): ParseWorkerConfig {
    return {
        delimiter: csvDelimiter,
        newline: csvNewLine,
        comments: csvComment,
        worker: true,
        header: true,
        complete: complete
    };
};

export type RowsType = { [key: string]: any }[]

export type Entity = { entityName: string, rows: RowsType, columns: string[] }
export type EntityType = { [key: number]: Entity };

export class MultiCsvEditorState extends State {
    entities: EntityType = {};
    confirmDeleteEntity: boolean = false;
    currentTabIndex: number = 0;
    currentTabViewIndex: number = 0;
    dropdownOpened: boolean = false;
    loading: boolean = false;
}

export class MultiCsvEditorReducers<S extends MultiCsvEditorState = MultiCsvEditorState> extends Reducers<S> {

}
export type PropsNotFromState = { csvText: string; }
type GlobalProps = RRCProps<MultiCsvEditorState, MultiCsvEditorReducers> & PropsNotFromState;
type LocalProps = { currentCsv: string, measuredWidth: number | undefined, measuredHeight: number | undefined };

type Props = GlobalProps;

export const multiCsvEditorTestids = createTestids("MultiCsvEditor", {
    tabItem: "",
    tableView: "", textView: "",
    checkboxItem: "", checkboxItemGlobal: "",
    tabLabel: "",
    tableItem: ""
});

export class MultiCsvEditor<T extends Props = Props> extends React.Component<T, LocalProps> {

    constructor(props: T) {
        super(props);
        this.onRemove = this.onRemove.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
    }

    componentDidMount() {
        this.onLoadText();
    }

    componentDidUpdate(prevProps: GlobalProps) {
        this.onLoadText(prevProps);
    }

    public getCsv() {
        let csvText = "";
        for (var tabIndex of Object.keys(this.props.s.entities)) {
            csvText += (csvText ? csvNewLine : "");
            if (this.props.s.currentTabViewIndex === 1 && this.props.s.currentTabIndex === Number.parseInt(tabIndex)) {
                csvText += this.state.currentCsv;
            } else {
                csvText += this.convertJsonToCsv(Number.parseInt(tabIndex));
            }
        }
        return csvText;
    }

    protected onLoadText(prevProps?: GlobalProps) {
        if (prevProps && prevProps.csvText === this.props.csvText) {
            return;
        }

        const { csvText } = this.props;
        if (!csvText || csvText.trim().length === 0) {
            return;
        }

        const csvList = csvText.split(regexCsvText).filter(x => x && x.trim());

        this.props.r.setInReduxState({ entities: {} })

        let i = 0;
        let currentTabIndex = 0;
        while (i < csvList.length) {
            if (!csvList[i] || !csvList[i].startsWith(csvComment)) {
                i++;
                continue;
            }
            const fileSeparator: { entity: string } = JSON.parse(csvList[i].replace(csvComment, "").trim());
            i++;
            if (!csvList[i] || csvList[i].startsWith(csvComment)) {
                continue;
            } else {
                if (this.props.s.currentTabViewIndex === 1 && this.props.s.currentTabIndex === currentTabIndex) {
                    this.setState({ currentCsv: csvList[i - 1].trim() + csvNewLine + csvList[i].trim() });
                }
                this.convertCsvToJson(currentTabIndex++, fileSeparator.entity, csvList[i].trim());
                i++;
            }
        }

        if (this.props.s.currentTabIndex >= currentTabIndex) {
            this.setState({ currentCsv: "" })
            this.props.r.setInReduxState({ currentTabIndex: 0, currentTabViewIndex: 0 });
        }
    }

    protected convertCsvToJson(tabIndex: number, entityName: string, csvString: string) {
        // TODO de fixat; temporar am silenced eroarea
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { readString } = usePapaParse();
        csvString = csvString.replace(regexCsvText, "");
        const csvOptions = getCsvOptions((results: ParseResult<{}>) => {
            const entities = Object.assign({}, this.props.s.entities);
            entities[tabIndex] = { entityName, rows: results.data, columns: results.meta.fields || [] };
            this.props.r.setInReduxState({ entities });
            this.props.r.setInReduxState({ loading: false });
        });
        this.props.r.setInReduxState({ loading: true });
        readString(csvString, csvOptions);
    }

    protected convertJsonToCsv(tabIndex: number, entityModified?: { entityName: string; rows: RowsType; columns: string[]; }) {
        // TODO de fixat; temporar am silenced eroarea
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { jsonToCSV } = usePapaParse();
        const entity = entityModified ? entityModified : this.props.s.entities[tabIndex];

        let csvText = "";
        if (entity.columns.length > 0) {
            const csvOptions: UnparseConfig = {
                delimiter: csvDelimiter,
                newline: csvNewLine,
                columns: entity.columns
            }

            csvText = jsonToCSV(entity.rows, csvOptions);
            if (!csvText) {
                csvText = entity.columns.join(csvDelimiter);
            }
        }
        return csvComment + JSON.stringify({ entity: entity.entityName }) + csvNewLine + csvText;
    }

    protected onAdd(entityName: string) {
        const entities = Object.assign({}, this.props.s.entities);
        const newId = Object.keys(entities).length;
        const ed = entityDescriptors[entityName];
        entities[newId] = {
            entityName, rows: [],
            columns: Object.keys(ed.fields).filter(key => !ed.fields[key].clientOnly)
        };
        this.props.r.setInReduxState({ entities: entities });

        this.props.r.setInReduxState({ dropdownOpened: false, currentTabIndex: newId, currentTabViewIndex: 0 })
    }

    protected onRemove() {
        const entities = Object.assign({}, this.props.s.entities);
        const tabIndex = this.props.s.currentTabIndex;

        this.props.r.setInReduxState({ currentTabViewIndex: 0, confirmDeleteEntity: false });

        const maxId = Object.keys(entities).length - 1;
        for (var i = tabIndex; i < maxId; i++) {
            entities[i] = entities[i + 1];
        }
        delete entities[maxId];
        this.props.r.setInReduxState({ entities: entities });
        if (tabIndex === maxId) {
            this.props.r.setInReduxState({ currentTabIndex: tabIndex - 1 });
        }
    }

    protected onRadioChange(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) {
        if (data.value as number === this.props.s.currentTabViewIndex) {
            return;
        }
        if (data.value as number === 1) {
            this.setState({ currentCsv: this.convertJsonToCsv(this.props.s.currentTabIndex) });
        } else {
            this.convertCsvToJson(this.props.s.currentTabIndex, this.props.s.entities[this.props.s.currentTabIndex].entityName, this.state.currentCsv);
        }
        this.props.r.setInReduxState({ currentTabViewIndex: data.value as number });
    }

    protected renderHeaderButtons(tabLabel: string) {
        return <>
            <Form.Button color="red" compact key={tabLabel} onClick={() => this.props.r.setInReduxState({ confirmDeleteEntity: true })}>
                {_msg("general.remove") + " " + tabLabel}
            </Form.Button>
            <ModalExt
                severity={Severity.CONFIRMATION}
                open={this.props.s.confirmDeleteEntity}
                content={_msg("MultiCsvEditor.confirmation.deleteTable", _msg(this.props.s.entities[this.props.s.currentTabIndex]?.entityName + ".label"), this.props.s.entities[this.props.s.currentTabIndex]?.rows.length)}
                onClose={() => this.props.r.setInReduxState({ confirmDeleteEntity: false })}
                actions={[
                    <Button key="close" onClick={() => this.props.r.setInReduxState({ confirmDeleteEntity: false })}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={this.onRemove}>{_msg("general.ok")}</Button>
                ]}
            /></>
    }

    protected getTabHeader(tabLabel: string) {
        return <Form>
            <Form.Group className="flex-center">
                <Form.Field>{_msg("MultiCsvEditor.viewAs")}</Form.Field>
                <Form.Field>
                    <Radio data-testid={multiCsvEditorTestids.tableView} label={_msg("MultiCsvEditor.table")} value={0} checked={this.props.s.currentTabViewIndex === 0} onChange={this.onRadioChange} />
                </Form.Field>
                <Form.Field>
                    <Radio data-testid={multiCsvEditorTestids.textView} label={_msg("MultiCsvEditor.text")} value={1} checked={this.props.s.currentTabViewIndex === 1} onChange={this.onRadioChange} />
                </Form.Field>
                <Form.Group className="flex-center CsvExporter_buttonGroup">
                    {this.renderHeaderButtons(tabLabel)}
                </Form.Group>
            </Form.Group>
        </Form>
    }

    protected getTableWidth() {
        return this.state?.measuredWidth ? this.state.measuredWidth : 0;
    }

    protected getTableHeight() {
        return this.state?.measuredHeight ? this.state.measuredHeight - 10 : 0
    }

    protected _getCurrentEntity() {
        return this.props.s.entities[this.props.s.currentTabIndex];
    }

    renderAdditionalColumns(entity: { entityName: string; rows: { [key: string]: any; }[]; columns: string[]; }) {
    }

    doubleClickAction(index: number) {

    }

    protected renderTable() {
        const entity = this.props.s.entities[this.props.s.currentTabIndex];

        if (!entity || entity.columns.length === 0) {
            return <span>{_msg("general.no.data")}</span>
        }

        const dummyField = new FieldDescriptor();
        dummyField.type = FieldType.defaultScalar;

        return <Table rowsCount={entity.rows.length} rowHeight={40} width={this.getTableWidth()} maxHeight={this.getTableHeight()}
            headerHeight={40} touchScrollEnabled isColumnResizing={false} isColumnReordering={false} >

            {this.renderAdditionalColumns(entity)}
            {entity.columns.map((field, index) => {
                const fd = undefined as Optional<FieldDescriptor>;//ed?.getFieldDescriptorChain(field)?.[0];
                return <Column key={entity.entityName + "_" + field} columnKey={entity.entityName + "_" + field}
                    allowCellsRecycling width={150} isResizable={false} isReorderable={false}
                    flexGrow={index === entity.columns.length - 1 ? 1 : undefined}
                    header={props => {
                        return <Cell>{fd?.getIcon()}{fd?.getLabel() || field}</Cell>
                    }}
                    cell={props => {
                        if (props.rowIndex < entity.rows.length) {
                            const value = entity.rows[props.rowIndex][field];
                            let simulatedEntity = {};
                            if (fd && !fd.typeIsEntity()) {
                                simulatedEntity = { [fd.name]: AuditUtils.convertAuditValue(fd, value) }
                            } else {
                                dummyField.name = field;
                                simulatedEntity = { [field]: value }
                            }
                            return <Cell data-testid={multiCsvEditorTestids.tableItem + '_' + field + '_' + props.rowIndex} onDoubleClick={() => this.doubleClickAction(props.rowIndex)}>{fd && !fd.typeIsEntity() ? fd.renderField(simulatedEntity) : dummyField.renderField(simulatedEntity)}</Cell>
                        }
                        return <></>;
                    }} />
            })}
        </Table>
    }

    protected onTextChanged(event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) {
        this.setState({ currentCsv: data.value as string });
    }

    protected getTabDetail() {
        if (this.props.s.currentTabViewIndex === 0) {
            return <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width, measuredHeight: contentRect.bounds?.height })}>
                {({ measureRef }) => (<div className="flex-container flex-grow" ref={measureRef}>
                    {this.renderTable()}
                </div>)}
            </Measure>
        } else {
            return <TextArea className="flex-container flex-grow" value={this.state.currentCsv} onChange={this.onTextChanged} />;
        }
    }

    getTabName(entity: { entityName: string, rows: { [key: string]: any }[], columns: string[] }, index: number) {
        return `${entity.rows.length}`
    }

    protected pushAddButton(panes: any) {
        panes.push({
            menuItem:
                <Menu.Item className="less-padding" index={panes.length} fitted="vertically" active={false} disabled key="addTabButton">
                    <Button className="less-padding" positive onClick={() => this.props.r.setInReduxState({ dropdownOpened: true })}>{_msg("general.add")}</Button>
                    <ModalExt className="ModalExt_content" open={this.props.s.dropdownOpened} onClose={() => this.props.r.setInReduxState({ dropdownOpened: false })} closeOnDimmerClick >
                        <Modal.Header>{_msg("general.entityName")}</Modal.Header>
                        <Modal.Content>
                            <Dropdown fluid selection compact options={this.getDropdownOptions()}
                                onChange={(event: any, data: DropdownProps) => this.onAdd(data.value as string)} />
                        </Modal.Content>
                    </ModalExt>
                </Menu.Item>,
            render: () => <Tab.Pane />
        })
    }

    protected renderTabLabel(index: number, entity: { entityName: string, rows: { [key: string]: any }[], columns: string[] }) {
        return <Label circular>{this.getTabName(entity, index)}</Label>
    }

    protected getTabPanes() {
        const { entities } = this.props.s;

        const panes: any = [];
        // Object.keys - return strings && index of Menu.Item is not used correctly
        Object.keys(entities).sort((a: string, b: string) => Number.parseInt(a) - Number.parseInt(b)).forEach((key, index) => {
            const entity = entities[Number.parseInt(key)];
            const tabLabel = entityDescriptors[entity.entityName]?.getLabel() || entity.entityName;

            panes.push({
                menuItem:
                    <Menu.Item data-testid={multiCsvEditorTestids.tabItem + '_' + entity.entityName} className="less-padding" fitted="vertically" index={Number.parseInt(key)} key={entity.entityName}>
                        {tabLabel}
                        {this.renderTabLabel(index, entity)}
                    </Menu.Item>,
                render: () =>
                    <Tab.Pane className="flex-container flex-grow MultiCsvEditor_TabPane">
                        {this.getTabHeader(tabLabel)}
                        {this.getTabDetail()}
                    </Tab.Pane>
            });
        });

        this.pushAddButton(panes)
        return panes;
    }

    protected getDropdownOptions() {
        let options: DropdownItemProps[] = [];
        Object.values(entityDescriptors).sort((a, b) => a.getLabel().localeCompare(b.getLabel())).forEach(ed => {
            if (Object.values(this.props.s.entities).find(entity => entity.entityName === ed.name)) {
                return;
            }

            options.push({ key: ed.name, text: ed.getLabel(), value: ed.name });
        });

        return options;
    }

    protected onTabChange(event: React.MouseEvent<HTMLDivElement>, data: TabProps) {
        if (data.activeIndex as number === this.props.s.currentTabIndex) {
            return;
        }

        if (this.props.s.currentTabViewIndex === 1) {
            this.convertCsvToJson(this.props.s.currentTabIndex, this.props.s.entities[this.props.s.currentTabIndex].entityName, this.state.currentCsv);
        }
        this.props.r.setInReduxState({ currentTabIndex: data.activeIndex as number, currentTabViewIndex: 0 });
    }

    render() {
        return <>
            <Dimmer inverted active={this.props.s.loading}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
            <Tab menu={{ attached: true, tabular: true, className: "flex-wrap" }} className="flex-container flex-grow" panes={this.getTabPanes()} activeIndex={this.props.s.currentTabIndex} onTabChange={this.onTabChange} />
        </>
    }
}

export const MultiCsvEditorRRC = ReduxReusableComponents.connectRRC(MultiCsvEditorState, MultiCsvEditorReducers, MultiCsvEditor);"../../entity_crud/entityCrudConstants""../../reduxReusableComponents/ReduxReusableComponents""../ModalExt/ModalExt""../../entity_crud/EntityDescriptor""../../entity_crud/FieldType""../../pages/Audit/AuditUtils""../../CompMeta"