import { apolloClientHolder } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTableSimpleRRC, EntityTableSimpleProps, EntityTableSimple, ITableActionParamForRun } from "@crispico/foundation-react/entity_crud/EntityTableSimple";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { TestUtils } from "@crispico/foundation-react/utils/TestUtils";
import { ENT_TABLE, Utils } from "@crispico/foundation-react/utils/Utils";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Container, Dropdown, DropdownItemProps, DropdownProps, Header, Icon, Segment } from "semantic-ui-react";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { PrivateRoute } from "@crispico/foundation-react/reduxHelpers";

export enum TriggerType { FIXED_DELAY, FIXED_RATE, CRON }

enum LoadingState { NONE, RESCHEDULE_ALL, REFRESH }

const lastBeginFieldDescriptor = new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "lastBegin";
        this.type = FieldType.date;
        this.format = Utils.dateTimeWithSecFormat;
    }

    getFieldValue(values: any) {
        if (values?.currentBegin) {
            return values.currentBegin;
        }
        return super.getFieldValue(values);
    }
}()

const lastEndFieldDescriptor = new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "lastEnd";
        this.type = FieldType.date;
        this.format = Utils.dateTimeWithSecFormat;
    }

    getFieldValue(values: any) {
        if (values?.currentBegin) {
            return null;
        }
        return super.getFieldValue(values);
    }
}()

const lastDurationFieldDescriptor = new class extends FieldDescriptor {
    constructor() {
        super();
        this.name = "lastDuration";
        this.type = FieldType.string;
    }

    getFieldValue(entity: any) {
        return entity;
    }

    getDuration(currentBegin: Date, lastBegin: Date, lastEnd: Date) {
        if (currentBegin || !lastBegin || !lastEnd) {
            return "";
        }

        let seconds = moment.duration(moment(lastEnd).diff(moment(lastBegin))).asSeconds()
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes = Math.floor(seconds / 60);
        seconds = Math.floor(seconds - minutes * 60);

        return (hours > 0 ? hours + " h " : "") + (minutes > 0 ? minutes + " min " : "") + (seconds >= 0 ? seconds + " sec" : "");
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps) {
        const entity = props.value;
        return <span>{entity.cancelByUser ? "Cancel by user" : this.getDuration(entity.currentBegin, entity.lastBegin, entity.lastEnd)}</span>
    }
}()

class ScheduledTaskEntityDescriptor extends EntityDescriptor {

    constructor() {
        super({ name: "ScheduledTask" }, false);
    }

    renderTable() {
        return <ScheduledTaskPageRRC id={"scheduledTaskTablePage"} />
    }

    renderTableRoute() {
        return <PrivateRoute key={"ScheduledTaskTable"} path={"/ScheduledTaskTable"}
            render={(props) => this.renderTable()} />
    }

}

export const scheduledTaskEntityDescriptor = new ScheduledTaskEntityDescriptor()
    .addFieldDescriptor({ name: "config.uid", type: FieldType.string })
    .addFieldDescriptor({ name: "config.triggerType", type: FieldType.string })
    .addFieldDescriptor({ name: "config.triggerExpression", type: FieldType.string })
    .addFieldDescriptor({ name: "config.task", type: FieldType.string })
    .addFieldDescriptor(lastBeginFieldDescriptor)
    .addFieldDescriptor(lastEndFieldDescriptor)
    .addFieldDescriptor(lastDurationFieldDescriptor)
    .addFieldDescriptor({ name: "currentBegin", type: FieldType.date })
    .addFieldDescriptor({ name: "nextRun", type: FieldType.date, additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }), additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat }) })

class ScheduledTaskPageState extends State {
    organizations: DropdownItemProps[] = [];
    selectedOrganization: string = "";
    loading: LoadingState = LoadingState.NONE;
    scheduledTaskServiceIsActive: boolean = true;
}

export class ScheduledTaskPageReducers<S extends ScheduledTaskPageState = ScheduledTaskPageState> extends Reducers<S> {
}

type ScheduledTaskPageProps = RRCProps<ScheduledTaskPageState, ScheduledTaskPageReducers>;

class ScheduledTaskPage<P extends ScheduledTaskPageProps = ScheduledTaskPageProps> extends TabbedPage<P> {

    entityTableSimpleRef = ReduxReusableComponents.createRef<EntityTableSimple<EntityTableSimpleProps>>();

    constructor(props: P) {
        super(props);

        this.provideActionsForRow = this.provideActionsForRow.bind(this);
        this.renderFooter = this.renderFooter.bind(this);

        this.getScheduledTaskServiceOrganizations();
    }

    async scheduledTaskServiceIsActive() {
        const operationName = "organizationService_scheduledTaskServiceIsActive";
        const result: boolean = (await apolloClientHolder.apolloClient.query({ query: gql(`query q { ${operationName} }`), variables: null })).data[operationName];

        this.props.r.setInReduxState({ scheduledTaskServiceIsActive: result });
    }

    async getScheduledTaskServiceOrganizations() {
        if (TestUtils.storybookMode) {
            return;
        }

        await this.scheduledTaskServiceIsActive();

        if (!this.props.s.scheduledTaskServiceIsActive) {
            return;
        }

        await this.props.r.setInReduxState({ loading: LoadingState.RESCHEDULE_ALL });

        const operationName = "scheduledTaskFrontendService_scheduledTaskServiceOrganizations";
        const result: string[] = (await apolloClientHolder.apolloClient.query({ query: gql(`query q { ${operationName} }`), variables: null })).data[operationName];

        if (!result || result.length === 0) {
            return;
        }

        await this.props.r.setInReduxState({ selectedOrganization: result[0], organizations: result.map(x => { return { key: x, text: x, value: x }; }), loading: LoadingState.NONE });

        await this.getInfos();
    }

    async getInfos() {
        if (TestUtils.storybookMode) {
            return;
        }

        if (!this.props.s.loading) {
            await this.props.r.setInReduxState({ loading: LoadingState.REFRESH });
        }

        const operationName = "scheduledTaskFrontendService_infos";
        const result = (await apolloClientHolder.apolloClient.query({
            query: gql(`query q($organization: String) {  
                ${operationName} (organization: $organization) {
                    config { uid triggerType triggerExpression task } cancelByUser lastBegin lastEnd nextRun currentBegin
                } 
            }`), variables: { organization: this.props.s.selectedOrganization }
        })).data[operationName];

        this.entityTableSimpleRef.current?.setEntities(result ? result : []);

        this.props.r.setInReduxState({ loading: LoadingState.NONE });
    }

    async scheduleTask(entity: any) {
        const mutation = gql(`mutation q($organization: String, $scheduleTaskConfigId: String) { 
            scheduledTaskFrontendService_scheduleTask(organization: $organization, scheduleTaskConfigId: $scheduleTaskConfigId)
        }`);
        await apolloClientHolder.apolloClient.mutate({ mutation, variables: { organization: this.props.s.selectedOrganization, scheduleTaskConfigId: Utils.navigate(entity, ["config", "uid"]) } });

        await this.getInfos();
    }

    async scheduleTasks() {
        const mutation = gql(`mutation q($organization: String) { 
            scheduledTaskFrontendService_scheduleTasks(organization: $organization)
        }`);
        await apolloClientHolder.apolloClient.mutate({ mutation, variables: { organization: this.props.s.selectedOrganization } });

        await this.getInfos();
    }

    async runTaskNow(entity: any) {
        let mutation: any;
        let variables: any;

        if (Utils.navigate(entity, ["config", "uid"])) {
            mutation = gql(`mutation q($organization: String, $scheduleTaskConfigId: String) { 
            scheduledTaskFrontendService_runScheduledTaskNow(organization: $organization, scheduleTaskConfigId: $scheduleTaskConfigId)
        }`);
            variables = { organization: this.props.s.selectedOrganization, scheduleTaskConfigId: Utils.navigate(entity, ["config", "uid"]) }
        } else {
            mutation = gql(`mutation q($organization: String, $task: String) { 
            scheduledTaskFrontendService_runNotScheduledTaskNow(organization: $organization, task: $task)
        }`);
            variables = { organization: this.props.s.selectedOrganization, task: entity["task"] }
        }

        await apolloClientHolder.apolloClient.mutate({ mutation, variables });

        await this.getInfos();
    }

    async stopTask(entity: any) {
        const mutation = gql(`mutation q($organization: String, $scheduleTaskConfigId: String) { 
            scheduledTaskFrontendService_stopTask(organization: $organization, scheduleTaskConfigId: $scheduleTaskConfigId)
        }`);
        await apolloClientHolder.apolloClient.mutate({ mutation, variables: { organization: this.props.s.selectedOrganization, scheduleTaskConfigId: Utils.navigate(entity, ["config", "uid"]) } });

        await this.getInfos();
    }

    protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] {
        return [
            {
                icon: "clock",
                label: _msg("ScheduledTask.table.reschedule"),
                run: (param) => {
                    this.scheduleTask(this.entityTableSimpleRef.currentNotNull.getEntity(param.selection[0]));
                }
            },
            {
                icon: "play",
                label: _msg("ScheduledTask.table.forceRun"),
                run: (param) => {
                    this.runTaskNow(this.entityTableSimpleRef.currentNotNull.getEntity(param.selection[0]));
                }
            },
            {
                icon: "stop",
                label: _msg("ScheduledTask.table.forceStop"),
                run: (param) => {
                    this.stopTask(this.entityTableSimpleRef.currentNotNull.getEntity(param.selection[0]));
                }
            },
        ];
    }

    protected renderFooter() {
        return <span>{_msg("entityCrud.table.totalCount")} <b>{this.entityTableSimpleRef.current?.getEntities().length}</b></span>;
    }

    protected getTitle(): string | { icon: string | JSX.Element; title: JSX.Element | string; } {
        return { icon: scheduledTaskEntityDescriptor.icon, title: scheduledTaskEntityDescriptor.getLabel() + " [" + _msg("entityCrud.editor.table") + "]" };
    }

    renderHeader() {
        return (<Header as="h2" dividing>
            {typeof scheduledTaskEntityDescriptor.icon === 'string' ? <Icon name={scheduledTaskEntityDescriptor.icon} /> : scheduledTaskEntityDescriptor.icon}
            <Header.Content>
                {scheduledTaskEntityDescriptor.getLabel()}
                <Header.Subheader>{_msg("entityCrud.table.subheader", _msg("entityCrud.editor.save"))}</Header.Subheader>
            </Header.Content>
        </Header>);
    }

    renderMain() {
        const permission = Utils.pipeJoin([ENT_TABLE, scheduledTaskEntityDescriptor.name]);
        if (!AppMetaTempGlobals.appMetaInstance.hasPermission(permission)) {
            return <Redirect to={{ pathname: '/error', state: { from: AppMetaTempGlobals.history.location, headerMessage: _msg("error.insufficient.rights.title"), errorMessage: _msg("error.insufficient.rights.details", permission) } }} />;
        }
        if (!this.props.s.scheduledTaskServiceIsActive) {
            return <Redirect to={{ pathname: '/error', state: { from: AppMetaTempGlobals.history.location, headerMessage: "Error", errorMessage: _msg("ScheduledTask.serviceNotActive.error.label") } }} />;
        }
        return (
            <Container className="EntityTablePage_container" fluid>
                <Segment className="EntityTablePage_segment flex-container" compact>
                    {this.renderHeader()}
                    <div className="flex-container-row flex-center">
                        <Dropdown className="ScheduledTask_organizationDropdown" value={this.props.s.selectedOrganization} options={this.props.s.organizations} selection
                            clearable={true} selectOnNavigation={false} selectOnBlur={false} scrolling wrapSelection search={true} placeholder={_msg("ScheduledTask.table.organization")}
                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                this.props.r.setInReduxState({ selectedOrganization: data.value as string });
                                this.getInfos();
                            }} />
                        <Button icon={this.props.s.loading === LoadingState.REFRESH ? "spinner" : "refresh"} color="blue" content={_msg("ScheduledTask.table.refresh")} onClick={() => this.getInfos()} />
                        <Button icon={this.props.s.loading === LoadingState.RESCHEDULE_ALL ? "spinner" : "clock"} color="blue" content={_msg("ScheduledTask.table.rescheduleAll")} onClick={() => this.scheduleTasks()} />
                    </div>
                    {/* Not all components are migrated to RRC so the id from EntityTableSimple can be conflicting if other table is rendered in the same page. */}
                    <EntityTableSimpleRRC id='entityTableSimple2' ref={this.entityTableSimpleRef}
                        entityDescriptor={scheduledTaskEntityDescriptor}
                        provideActionsForRow={this.provideActionsForRow}
                        renderFooter={this.renderFooter}
                        onDoubleClickItem={undefined}
                        columns={scheduledTaskEntityDescriptor.getDefaultColumnConfig().configObject.columns!.filter(column => column.name !== "currentBegin")}
                    />
                </Segment>
            </Container>);
    }
}

export const ScheduledTaskPageRRC = ReduxReusableComponents.connectRRC(ScheduledTaskPageState, ScheduledTaskPageReducers, ScheduledTaskPage);"../../apolloClient""../../AppMetaTempGlobals""../../components/TabbedPage/TabbedPage""../../entity_crud/EntityDescriptor""../../entity_crud/EntityTableSimple""../../entity_crud/fieldEditors/DatePickerFieldEditor""../../entity_crud/fieldRenderersEditors""../../entity_crud/fieldRenderers/DateFieldRenderer""../../entity_crud/FieldType""../../utils/TestUtils""../../utils/Utils""../../reduxReusableComponents/ReduxReusableComponents""../../reduxHelpers"