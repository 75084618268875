import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { FormEvent } from "react";
import { Checkbox, CheckboxProps, StrictCheckboxProps } from "semantic-ui-react";
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "./FieldEditor";

export type BooleanFieldEditorProps = FieldEditorProps & StrictCheckboxProps;

export class BooleanFieldEditor<P extends BooleanFieldEditorProps = BooleanFieldEditorProps> extends FieldEditor<boolean, P> {
    
    protected getValue() {
        return super.getValue() || false;
    }

    // Overridable method. We can have editors where we want to do changes based also on event
    protected onChange(event: FormEvent<HTMLInputElement>, data: CheckboxProps, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        s.setFieldValue(hw, data.checked);
    }
    
    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        let checked = this.getValue();
        // CS: I don't like this very much; I wonder where this was used; and if it still is
        if (typeof checked === "string") {
            checked = JSON.parse(checked);
        }
        return <Checkbox data-testid="BooleanFieldEditor" ref={this.props.refForFocus} checked={checked} onChange={(event, data) => this.onChange(event, data, s, hw)} {...this.getPropsToForward()} />
    }
}